<template>
  <div>
    <h1>Les mots interrogatif</h1>
    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Créer une phrase interrogatif'" :link="'#phrase_interrogatif'" />
    <Button1 :text="'Mot issu de la combinaison des mots interrogatifs avec des particules'" :link="'#mot_combiner'" />
    
    <h3 class="title_subcontent" id="phrase_interrogatif">Créer une phrase interrogatif</h3>
    <p class="text_no_margin">
      Pour créer une interrogation, il faut un mot interrogatif qui va désigner l'élément inconnu et mettre la phrase à la forme interrogative.<br>
      Liste des mots interrogatifs :<br>
      <span class="text_li">- <span class="red_text">誰　(だれ (dare))</span> Permet de poser une question sur une personne (qui ?)</span><br>
      <span class="text_li">- <span class="red_text">何　(なに (nani))</span> Permet de poser une question sur une chose (quoi ? que ?)</span><br>
      <span class="text_lix2">なに (nani) se prononcera なん (nan) devant les kana "t","d" et "n" donc た,ち,つ,て,と et だ,ぢ,づ,で,ど et な,に,ぬ,ね,の</span><br>
      <span class="text_lix2">Il ne faut pas confondre le nan de "nani" et le nan de la tournure "noda"</span><br>
      <span class="text_li">- <span class="red_text">いつ (itsu)</span> Permet de poser une question sur le temps (quand ?)</span><br>
      <span class="text_li">- <span class="red_text">なぜ (naze)</span> Permet de poser une question sur la raison (pourquoi ?)</span><br>
      <span class="text_li">- Liste des mots interrogatifs commençant par le préfixe ど (do)</span><br>
      <span class="text_lix2">- <span class="red_text">どこ (doko)</span> Poser une question sur un lieu (où ?)</span><br>
      <span class="text_lix2"><Button1 :text="'Liste complète dans la section Prefixes'" :link="'Prefixes'" :isInterneBp="true" /></span><br>
      Pour créer une question, on peut partir de la forme déclarative et remplacer le mot sur lequel se pose la question par le mot interrogatif.<br>
      Puis on ajoute か (ka) à la fin de la phrase ou on monte l'intonation en fin de phrase.<br>
      Petite précision pour は (wa). On ne peut pas faire en japonais d'un mot interrogatif un thème. Car le thème est ce sur quoi on va donner des informations, ça ne peut pas être quelque chose que l'on ne connait pas. On va donc remplacer は (wa) par が (ga).<br>
    </p>
    <!--<p class="box_example">
      香は今晩台所で水を飲む。= kaori wa konban daidokoro de mizu o nomu。= kaori boit de l'eau dans la cuisine ce soir. (phrase déclarative)<br>
      香は今晩台所で<span class="red_text">何</span>を飲むか。= kaori wa konban daidokoro de <span class="red_text">nani</span> o nomu ka。= <span class="red_text">Que</span> kaori boit-elle dans la cuisine ce soir ? (phrase interrogative, on remplace la réponse à notre question "mizu" 水 (mizu) par なに (nani))<br>
      香は今晩<span class="red_text">どこ</span>で水を飲むか。= kaori wa konban <span class="red_text">doko</span> de mizu o nomu ka。= <span class="red_text">Où</span> kaori boit-elle de l'eau ce soir ? (phrase interrogative, on remplace la réponse à notre question "cuisine" 台所 (daidokoro) par どこ (doko))<br>
      香は<span class="red_text">いつ</span>台所で水を飲むか。= kaori wa <span class="red_text">itsu</span> daidokoro de mizu o nomu ka。= <span class="red_text">Quand</span> kaori boit-elle de l'eau dans la cuisine ? (phrase interrogative, on remplace la réponse à notre question "ce soir" 今晩 (konban) par いつ (itsu))<br>
      <span class="red_text">誰</span><span class="blue_text">は</span>今晩台所で水を飲むか。= <span class="red_text">dare</span> <span class="blue_text">ga</span> konban daidokoro de mizu o nomu ka。= <span class="red_text">Qui</span> boit de l'eau dans la cuisine ce soir ? (phrase interrogative, on remplace la réponse à notre question "kaori" 香 (kaori) par 誰 (dare))<br>
      On n'oublie pas de remplacer は (wa) par が (ga). car notre sujet devient 誰 (dare) et plus 香 (kaori). On répondra donc aussi avec が (ga) pour attirer l'attention sur le sujet du verbe<br>
      香<span class="blue_text">が</span>今晩台所で水を飲む。= kaori <span class="blue_text">ga</span> konban daidokoro de mizu o nomu。= <span class="blue_text">C'est kaori qui</span> boit de l'eau dans la cuisine ce soir. (phrase déclarative avec が (ga), on répond en accentuant l'attention sur kaori)<br>
      香<span class="blue_text">が</span>飲む。= kaori <span class="blue_text">ga</span> nomu。= <span class="blue_text">C'est kaori qui</span> boit (on peut aussi faire une phrase déclarative avec が (ga) en plus court)<br>
    </p>-->
    <BoxExample 
      :kanji="'香は今晩台所で水を飲む。'"
      :kanjiraw="'香は今晩台所で水を飲む。'"
      :kana="''" 
      :roomaji="'kaori wa konban daidokoro de mizu o nomu。'"
      :trad="'kaori boit de l\'eau dans la cuisine ce soir.'" 
      :explication="'Phrase déclarative'"
    />
    <BoxExample 
      :kanji="'香は今晩台所で<span class=\'red_text\'>何</span>を飲むか。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'kaori wa konban daidokoro de <span class=\'red_text\'>nani</span> o nomu ka。'"
      :trad="'<span class=\'red_text\'>Que</span> kaori boit-elle dans la cuisine ce soir ?'" 
      :explication="'Phrase interrogative, on remplace la réponse à notre question “mizu” 水 (mizu) par なに (nani).'"
    />
    <BoxExample 
      :kanji="'香は今晩<span class=\'red_text\'>どこ</span>で水を飲むか。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'kaori wa konban <span class=\'red_text\'>doko</span> de mizu o nomu ka。'"
      :trad="'<span class=\'red_text\'>Où</span> kaori boit-elle de l\'eau ce soir ?'" 
      :explication="'Phrase interrogative, on remplace la réponse à notre question “cuisine” 台所 (daidokoro) par どこ (doko).'"
    />
    <BoxExample 
      :kanji="'香は<span class=\'red_text\'>いつ</span>台所で水を飲むか。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'kaori wa <span class=\'red_text\'>itsu</span> daidokoro de mizu o nomu ka。'"
      :trad="'<span class=\'red_text\'>Quand</span> kaori boit-elle de l\'eau dans la cuisine ?'" 
      :explication="'Phrase interrogative, on remplace la réponse à notre question “ce soir” 今晩 (konban) par いつ (itsu).'"
    />
    <BoxExample 
      :kanji="'<span class=\'red_text\'>誰</span><span class=\'blue_text\'>は</span>今晩台所で水を飲むか。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>dare</span> <span class=\'blue_text\'>ga</span> konban daidokoro de mizu o nomu ka。'"
      :trad="'<span class=\'red_text\'>Qui</span> boit de l\'eau dans la cuisine ce soir ?'" 
      :explication="'Phrase interrogative, on remplace la réponse à notre question “kaori” 香 (kaori) par 誰 (dare).'"
    />
    <BoxExample 
      :explication="'On n\'oublie pas de remplacer は (wa) par が (ga). Car notre sujet devient 誰 (dare) et plus 香 (kaori). On répondra donc aussi avec が (ga) pour attirer l\'attention sur le sujet du verbe.'"
    />
    <BoxExample 
      :kanji="'香<span class=\'blue_text\'>が</span>今晩台所で水を飲む。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'kaori <span class=\'blue_text\'>ga</span> konban daidokoro de mizu o nomu。'"
      :trad="'<span class=\'blue_text\'>C\'est kaori qui</span> boit de l\'eau dans la cuisine ce soir.'" 
      :explication="'Phrase déclarative avec が (ga), on répond en accentuant l\'attention sur kaori.'"
    />
    <BoxExample 
      :kanji="'香<span class=\'blue_text\'>が</span>飲む。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'kaori <span class=\'blue_text\'>ga</span> nomu。'"
      :trad="'<span class=\'blue_text\'>C\'est kaori qui</span> boit'" 
      :explication="'On peut aussi faire une phrase déclarative avec が (ga) en plus court.'"
    />
    <p class="text_no_margin">
      On peut ommettre か (ka) en montant l'intonation en fin de phrase.<br>
    </p>
    <!--<p class="box_example">
      香のスマホは<span class="red_text">どこ</span>にいる。↗ = kaori no sumaho wa <span class="red_text">doko</span> ni iru ? ↗ = <span class="red_text">Où</span> est le smartphone de Kaori ?<br>
      ポールは<span class="red_text">誰</span>の恋人なの。↗= pooru wa <span class="red_text">dare</span> no koibito na no。↗= <span class="red_text">De qui</span> Paul est-il le petit ami ?<br>
    </p>-->
    <BoxExample 
      :kanji="'香のスマホは<span class=\'red_text\'>どこ</span>にいる。↗'"
      :kanjiraw="'香のスマホはどこにいる。'"
      :kana="''" 
      :roomaji="'kaori no sumaho wa <span class=\'red_text\'>doko</span> ni iru ? ↗'"
      :trad="'<span class=\'red_text\'>Où</span> est le smartphone de Kaori ?'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'ポールは<span class=\'red_text\'>誰</span>の恋人なの。↗'"
      :kanjiraw="'ポールは誰の恋人なの。'"
      :kana="''" 
      :roomaji="'pooru wa <span class=\'red_text\'>dare</span> no koibito na no。↗'"
      :trad="'<span class=\'red_text\'>De qui</span> Paul est-il le petit ami ?'" 
      :explication="''"
    />

    <h3 class="title_subcontent" id="mot_combiner">Mot issu de la combinaison des mots interrogatifs avec des particules</h3>
    <p class="text_no_margin">
      Ce sont de nouveaux mots créés à partir de la combinaison des mots interrogatifs et d'une particule. (Ce ne sont plus des mots interrogatifs pour poser une question).<br>
      On va utiliser ces mots comme des noms dans nos phrases.<br>
      <span class="blue_text">Mot interrogatif + か (ka)</span>: mot indéfini<br>
      <span class="text_li">- 誰か (dare + ka) = personne indéfinie (quelqu'un)</span><br>
      <span class="text_li">- どこか (doko + ka) = lieu indéfini (quelque part)</span><br>
      <span class="text_li">- 何か (nani + ka) = chose indéfinie (quelque chose)</span><br>
      <span class="text_li">- いつか (itsu + ka) = moment indéfini (un beau jour)</span><br>
    </p>
    <!--<p class="box_example">
      庭には<span class="red_text">誰か</span>がいる。= niwa ni wa <span class="red_text">dareka</span> ga iru = Dans le jardin, il y a <span class="red_text">quelqu'un</span>.
    </p>-->
    <BoxExample 
      :kanji="'庭には<span class=\'red_text\'>誰か</span>がいる。'"
      :kanjiraw="'庭には誰かがいる。'"
      :kana="''" 
      :roomaji="'niwa ni wa <span class=\'red_text\'>dareka</span> ga iru.'"
      :trad="'Dans le jardin, il y a <span class=\'red_text\'>quelqu\'un</span>.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      <span class="blue_text">Mot interrogatif + も (mo)</span>: indiquer une valeur positive ou négative absolue.<br>
      <span class="text_li">- 誰も (dare + mo) = toutes les personnes (tout le monde)</span><br>
      <span class="text_li">- どこも (doko + mo) = tous les lieux (partout)</span><br>
      <span class="text_li">- 何も (nani + mo) = toutes les choses (tout)</span><br>
      <span class="text_li">- いつも (itsu + mo) = tous les moments (toujours)</span><br>
      Si on utilise ces <span class="blue_text">mots avec un verbe négatif</span>, on pourra exprimer une valeur négative absolue.<br>
      <span class="text_li">- 誰も (dare + mo + verbe négatif) = aucune personne (personne)</span><br>
      <span class="text_li">- どこも (doko + mo + verbe négatif) = aucun lieu (nulle part)</span><br>
      <span class="text_li">- 何も (nani + mo + verbe négatif) = aucune chose (rien)</span><br>
      <span class="text_li">- いつも (itsu + mo + verbe négatif) = aucun jour (jamais)</span><br>
    </p>
    <!--<p class="box_example">
      香は<span class="red_text">どこ</span>に<span class="red_text">も</span>いない。= kaori wa <span class="red_text">doko</span> ni <span class="red_text">mo</span> inai。= Kaori n'est <span class="red_text">nulle part</span>. (inai est le verbe iru au négatif)<br>
      On peut mettre une particule entre どこ (doko) le mot interrogatif et も (mo) la particule, pour indiquer la fonction grammatical de doko. Sauf は (wa), が (ga) et を (wo) qui sont remplacé<br>
    </p>-->
    <BoxExample 
      :kanji="'香は<span class=\'red_text\'>どこ</span>に<span class=\'red_text\'>も</span>いない。'"
      :kanjiraw="'香はどこにもいない。'"
      :kana="''" 
      :roomaji="'kaori wa <span class=\'red_text\'>doko</span> ni <span class=\'red_text\'>mo</span> inai。'"
      :trad="'Kaori n\'est <span class=\'red_text\'>nulle part</span>.'" 
      :explication="'“inai” est le verbe iru au négatif.'"
    />
    <BoxExample 
      :explication="'On peut mettre une particule entre le mot interrogatif どこ (doko) et la particule も (mo), pour indiquer la fonction grammaticale de doko. Sauf は (wa), が (ga) et を (wo) qui sont remplacés.'"
    />
    <p class="text_no_margin">
      <span class="blue_text">Mot interrogatif + でも (demo)</span>: englober tous les éléments, quels qu'ils soient<br>
      <span class="text_li">- 誰でも (dare + demo) = toutes les personnes, quelles qu'elles soient (n'importe qui)</span><br>
      <span class="text_li">- どこでも (doko + demo) = tous les lieux, quels qu'ils soient (n'importe où)</span><br>
      <span class="text_li">- 何でも (nan + demo) = toutes les choses, quelles qu'elles soient (n'importe quoi)</span><br>
      <span class="text_li">- いつでも (itsu + demo) = tous les moments, quels qu'ils soient (n'importe quand)</span><br>
    </p>
    <!--<p class="box_example">
      香は<span class="red_text">何でも</span>食べる。= kaori wa <span class="red_text">nandemo</span> taberu = Kaori mange <span class="red_text">n'importe quoi</span><br>
    </p>-->
    <BoxExample 
      :kanji="'香は<span class=\'red_text\'>何でも</span>食べる。'"
      :kanjiraw="'香は何でも食べる。'"
      :kana="''" 
      :roomaji="'kaori wa <span class=\'red_text\'>nandemo</span> taberu'"
      :trad="'Kaori mange <span class=\'red_text\'>n\'importe quoi</span>'" 
      :explication="''"
    />
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    Button1,
    BoxExample
  },
}
</script>

<style>

</style>