<template>
  <div id="de">
    <h3 class="title_subcontent">Particule : de (で)</h3>
    <p class="text_no_margin">Cette particule s'utilise de deux façons différentes</p>

    <h4 class="title_no_bottom">Indiquer un lieu d'action</h4>
    <p class="text_no_margin">
        Cette particule permet d'indiquer un lieu dans lequel il se passe quelque chose, une action.<br>
        Pour utiliser cette particule, il faut utiliser des verbes actions dans la phrase, contrairement à la particule "ni" pour indiquer un lieu de présence qui requiert que des verbes de présence.<br>
        On considère que tous les verbes sont des verbes d'actions sauf "いる" (iru), "ある" (aru) et "残る" (nokoru) (rester).<br>
        <span class="blue_text">Lieu + de</span>
    </p>
    <!--<p class="box_example">
        トムは<span class="red_text">レストラン<b>で</b></span>食べる。= tomu wa <span class="red_text">resutoran <b>de</b></span> taberu。= Tom mange <span class="red_text">au restaurant</span>.<br>
        トムとポールは<span class="red_text">神社の前<b>で</b></span>飲む。= tomu to pooru wa <span class="red_text">jinja no mae <b>de</b></span> nomu。= tom et paul boivent <span class="red_text">devant le temps</span>.<br>
    </p>-->
    <BoxExample 
      :kanji="'トムは<span class=\'red_text\'>レストラン<b>で</b></span>食べる。'"
      :kanjiraw="'トムはレストランで食べる。'"
      :kana="''" 
      :roomaji="'tomu wa <span class=\'red_text\'>resutoran <b>de</b></span> taberu.'"
      :trad="'Tom mange <span class=\'red_text\'>au restaurant</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'トムとポールは<span class=\'red_text\'>神社の前<b>で</b></span>飲む。'"
      :kanjiraw="'トムとポールは神社の前で飲む。'"
      :kana="''" 
      :roomaji="'tomu to pooru wa <span class=\'red_text\'>jinja no mae <b>de</b></span> nomu.'"
      :trad="'tom et paul boivent <span class=\'red_text\'>devant le temps</span>.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Indiquer le moyen</h4>
    <p class="text_no_margin">
        Cette particule permet d'indiquer le moyen, l'instrument permettant l'action<br>
        Nous utilisons toujours que les verbes d'actions vu ci-dessus<br>
        <span class="blue_text">moyen + de</span>
    </p>
    <!--<p class="box_example">
        <span class="red_text">バスで</span> = <span class="red_text">basu de</span> = <span class="red_text">en bus</span><br>
        私は<span class="red_text">タクシー<b>で</b></span>行く。= watashi wa <span class="red_text">takushii <b>de</b></span> iku。= je vais <span class="red_text">en taxi</span><br>
        私は<span class="red_text">電話<b>で</b></span>話す = watashi wa <span class="red_text">denwa <b>de</b></span> hanasu = je parle <span class="red_text">par téléphone</span><br>
        トムは<span class="red_text">スプーン<b>で</b></span>食べる = tomu wa <span class="red_text">supoon <b>de</b></span> taberu = tom mange <span class="red_text">avec une cuillère</span><br>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>バスで</span>。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>basu de</span>.'"
      :trad="'<span class=\'red_text\'>en bus</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'私は<span class=\'red_text\'>タクシー<b>で</b></span>行く。'"
      :kanjiraw="'私はタクシーで行く。'"
      :kana="''" 
      :roomaji="'watashi wa <span class=\'red_text\'>takushii <b>de</b></span> iku。'"
      :trad="'je vais <span class=\'red_text\'>en taxi</span>'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'私は<span class=\'red_text\'>電話<b>で</b></span>話す。'"
      :kanjiraw="'私は電話で話す。'"
      :kana="''" 
      :roomaji="'watashi wa <span class=\'red_text\'>denwa <b>de</b></span> hanasu.'"
      :trad="'je parle <span class=\'red_text\'>par téléphone</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'トムは<span class=\'red_text\'>スプーン<b>で</b></span>食べる。'"
      :kanjiraw="'トムはスプーンで食べる。'"
      :kana="''" 
      :roomaji="'tomu wa <span class=\'red_text\'>supoon <b>de</b></span> taberu.'"
      :trad="'tom mange <span class=\'red_text\'>avec une cuillère</span>.'" 
      :explication="''"
    />
  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>