<template>
  <div>
    <h1>Les Pronoms Personnels</h1>
    <p class="text_no_margin">
      Le verbe ne change pas en fonction du pronom personnel utilisé.<br>
      Tous ces pronoms personnel, ce sont des noms qui vont désignés de manière différente la première, deuxième et troisième personne. On va donc utiliser ces pronoms personnels comme des noms et attacher différente particule pour faire des nuances.<br>
    </p>
    <p class="box_example">
      je = <span class="red_text">私</span>が<br>
      me = <span class="red_text">私</span>を<br>
      moi = <span class="red_text">私</span>に<br>
    </p>

    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Première personne du singulier'" :link="'#premiere_pers_singulier'" />
    <Button1 :text="'Deuxième personne du singulier'" :link="'#deuxieme_pers_singulier'" />
    <Button1 :text="'Troisième personne du singulier'" :link="'#troisieme_pers_singulier'" />

    <h3 class="title_subcontent" id="premiere_pers_singulier">Première personne du singulier</h3>
    <h4 class="title_no_bottom">Les pronoms personnels "je"</h4>
    <p class="text_no_margin">
      L'ensemble de ces pronoms personnels permet de dire "je" mais dans différent contexte et personnalité de la personne.<br>
      On retrouvera par exemple dans des mangas, jeux vidéo. suivant la personnalité de la personne dire "boku" si c'est un personnage timide, "ore" si c'est un personnage vénère, "washi" pour le grand père, etc.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('私', 'Tracé')">私</span></span> - <span class="blue_text">わたし (watashi)</span>: pronom personnel <span class="blue_text">formel</span> de base, principal, passe-partout.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('僕', 'Tracé')">僕</span></span> - <span class="blue_text">ぼく (boku)</span>: pronom personnel <span class="blue_text">formel utilisé par les hommes</span>. il a une connotation humble, utilisé par les jeunes garçons ou pour paraître jeune pour les adultes.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('俺', 'Tracé')">俺</span></span> - <span class="blue_text">おれ (ore)</span>: pronom personnel <span class="blue_text">informel utilisé par les hommes</span>. Permet d'affirmer son côté masculin, viril.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('我', 'Tracé')">我</span></span> - <span class="blue_text">われ (ware)</span>: pronom personnel <span class="blue_text">très formel</span>. Manière de s'exprimer littéraire, qui fait ancien, noble.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('私', 'Tracé')">私</span></span> - <span class="blue_text">わたくし (watakushi)</span>: pronom personnel <span class="blue_text">très formel</span>. Qui fait très très bien parler. "watashi" est une abréviation de "watakushi".<br>
      <span class="red_text">あたし</span> <span class="blue_text">(atashi)</span>: pronom personnel <span class="blue_text">informel utilisé par les femme</span>. c'est une version féminine de "watashi", en plus mignon<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('儂', 'Tracé')">儂</span></span> - <span class="blue_text">わし (washi)</span>: pronom personnel <span class="blue_text">formel utilisé par les hommes</span>. qui réfère à une personne agée, ça fait papy, mamie.<br>
      <span class="red_text">おら</span> <span class="blue_text">(ora)</span>: pronom personnel <span class="blue_text">informel</span>. c'est un dérivé de "ore", qui fait très campagnard, très mal parlé.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('内', 'Tracé')">内</span> ou <span class="kanji_button" @click="showDrawKanji('家', 'Tracé')">家</span></span> - <span class="blue_text">うち (uchi)</span>: pronom personnel <span class="blue_text">informel</span> (féminin). qui se traduit par "sa propre maison, chez sois", souvent utilisé dans le kansai (ouest du japon, kyoto, osaka).<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('自', 'Tracé')">自</span><span class="kanji_button" @click="showDrawKanji('分', 'Tracé')">分</span></span> - <span class="blue_text">ジブン (jibun)</span>: pronom personnel <span class="blue_text">formel</span> (masculin). c'est un pronom personnel réfléchit "moi même, toi même, sois même". Il s'utilise souvent avec la particule で (de) pour dire "par sois même".<br>
    </p>
    <!--<p class="box_example">
      自分で飲む = jibun de nomu = boire par sois même.<br>
      le pronom met une certaine distance avec le locuteur et il est utilisé dans le kansai pour dire "tu" de manière informel<br>
    </p>-->
    <BoxExample 
      :kanji="'自分で飲む。'"
      :kanjiraw="'自分で飲む。'"
      :kana="''" 
      :roomaji="'jibun de nomu.'"
      :trad="'boire par sois même.'" 
      :explication="'Le pronom met une certaine distance avec le locuteur et il est utilisé dans le kansai pour dire “tu” de manière informel.'"
    />

    <p class="text_no_margin">
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('余', 'Tracé')">余</span></span> - <span class="blue_text">ヨ (yo)</span> pronom personnel <span class="blue_text">archaïque</span> (masculin). Il n'est plus utilisé aujourd'hui.<br>
    </p>

    <h4 class="title_no_bottom">En utilisant son prénom</h4>
    <p class="text_no_margin">
     On peut s'appeler par son propre prénom, mais ça fait très informel, enfant.<br>
    </p>
    <!--<p class="box_example">
      paul: <span class="red_text">ポール</span>はここに残る。= <span class="red_text">pooru</span> wa koko ni nokoru = <span class="red_text">je</span> reste ici! (en utilisant son prénom comme "je")
    </p>-->
    <BoxExample 
      :kanji="'paul: <span class=\'red_text\'>ポール</span>はここに残る。'"
      :kanjiraw="'ポールはここに残る。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>pooru</span> wa koko ni nokoru.'"
      :trad="'<span class=\'red_text\'>je</span> reste ici!'" 
      :explication="'En utilisant son prénom comme “je”.'"
    />

    <h3 class="title_subcontent" id="deuxieme_pers_singulier">Deuxième personne du singulier</h3>
    <h4 class="title_no_bottom">Les pronoms personnels "tu"</h4>
    <p class="text_no_margin">
      L'ensemble de ces pronoms personnels permet de dire "tu" mais dans différent contexte.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('其', 'Tracé')">其</span><span class="kanji_button" @click="showDrawKanji('方', 'Tracé')">方</span></span> - <span class="blue_text">そなた (sonata)</span>: pronom personnel <span class="blue_text">archaïque</span>, il n'est plus utilisé aujourd'hui.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('貴', 'Tracé')">貴</span><span class="kanji_button" @click="showDrawKanji('方', 'Tracé')">方</span></span> - <span class="blue_text">あなた (anata)</span>: pronom personnel <span class="blue_text">formel</span> de base, principal, initialement il vient à la base de "sonata". "anata" a une connotation un peu proche de la personne, à éviter si possible avec les supérieur hiérarchique.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('君', 'Tracé')">君</span></span> - <span class="blue_text">きみ (kimi)</span>: pronom personnel <span class="blue_text">informel</span>. qui permet de tutoyer en étant affectueux (mon peti, ma petite).<br>
      <span class="red_text">お<span class="kanji_button" @click="showDrawKanji('前', 'Tracé')">前</span></span> - <span class="blue_text">おまえ (omae)</span>: pronom personnel <span class="blue_text">très informel</span>. permet de designer ce qui est devant nous "toi, là devant", ceci à un ton de supériorité ou très décontracté avec sa compagne.<br>
      <span class="red_text">あんた</span> <span class="blue_text">(anta)</span>: pronom personnel <span class="blue_text">informel</span>. c'est une contraction oral de "anata". c'est assez familier, ça transmet une certaine colère<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('手', 'Tracé')">手</span><span class="kanji_button" @click="showDrawKanji('前', 'Tracé')">前</span></span> - <span class="blue_text">てまえ (temae) ou てめえ (temee)</span>: pronom personnel <span class="blue_text">agressif</span>. permet de designer ce qui est devant nous aggresivement, c'est un "omae" plus aggresif.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('貴', 'Tracé')">貴</span><span class="kanji_button" @click="showDrawKanji('様', 'Tracé')">様</span></span> - <span class="blue_text">キさま (kisama)</span>: pronom personnel <span class="blue_text">agressif</span>. ceci traduit une profonde hostilité, manque de respect, du mépris envers les autres.<br>
      <span class="red_text">お<span class="kanji_button" @click="showDrawKanji('宅', 'Tracé')">宅</span></span> - <span class="blue_text">おタク (otaku)</span>: pronom personnel <span class="blue_text">formel</span>. qui se traduit par "votre maison, chez vous"<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('己', 'Tracé')">己</span></span> - <span class="blue_text">おのれ (onore)</span>:  pronom personnel <span class="blue_text">archaïque</span>, c'est un "tu" aggresif, dégradant sauf si on utilise comme "je" c'est humble.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('汝', 'Tracé')">汝</span></span> - <span class="blue_text">なんじ (nanji)</span>: pronom personnel <span class="blue_text">archaïque</span>, que l'on croise dans des textes très anciens, mythologique.<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('御', 'Tracé')">御</span><span class="kanji_button" @click="showDrawKanji('主', 'Tracé')">主</span></span> - <span class="blue_text">おのし (onoshi)</span>: pronom personnel <span class="blue_text">archaïque</span>, utilisé par les anciens, vieux sage, samourai vers leurs disciples, envers les personnes inférieurs ou égaux.<br>
    </p>

    <h4 class="title_no_bottom">En utilisant le prénom de son interlocuteur</h4>
    <p class="text_no_margin">
      On peut appeler son interlocuteur directement par son prénom ou par son nom.<br>
    </p>
    <!--<p class="box_example">
      à tom: <span class="red_text">トム</span>はどれを選ぶ? = <span class="red_text">tomu</span> wa dore o erabu ? = que choisis-<span class="red_text">tu</span> ?<br>
    </p>-->
    <BoxExample 
      :kanji="'à tom: <span class=\'red_text\'>トム</span>はどれを選ぶ?'"
      :kanjiraw="'トムはどれを選ぶ?'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>tomu</span> wa dore o erabu ?'"
      :trad="'que choisis-<span class=\'red_text\'>tu</span> ?'" 
      :explication="''"
    />


    <h3 class="title_subcontent" id="troisieme_pers_singulier">Troisième personne du singulier</h3>
    <h4 class="title_no_bottom">Les pronoms personnels "il" ou "elle"</h4>
    <p class="text_no_margin">
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('彼', 'Tracé')">彼</span></span> - <span class="blue_text">かれ (kare)</span>: pronom personnel <span class="blue_text">formel</span> désignant un homme "il".<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('彼', 'Tracé')">彼</span><span class="kanji_button" @click="showDrawKanji('女', 'Tracé')">女</span></span> - <span class="blue_text">かのジョ (kanojo)</span>: pronom personnel <span class="blue_text">formel</span> désignant une femme "elle".<br>
      <span class="red_text"><span class="kanji_button" @click="showDrawKanji('奴', 'Tracé')">奴</span></span> - <span class="blue_text">やつ (yatsu)</span>: pronom personnel <span class="blue_text">informel</span>. littéralement "un type", c'est péjoratif, familier.<br>
      <span class="blue_text">この奴 (konoyatsu), こやつ (koyatsu), こいつ (koitsu)</span>: pronom personnel <span class="blue_text">informel familier</span>. littéralement "ce type".<br>
      <span class="blue_text">この人 (konohito), この方 (konokata)</span>: pronom personnel <span class="blue_text">formel, poli</span>. littéraire "cette personne".<br>
    </p>
    
    <KanjiDraw v-if="kanjiDrawCurrent" :title="kanjiDrawCurrentTitle" :kanji="kanjiDrawCurrent" @endKanjiDraw="endPopupKanjiDraw"/>

  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import KanjiDraw from "@/components/Composants/KanjiDraw.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  name: 'Classificateurs',
  data(){
    return{
      kanjiDrawCurrent: '',
      kanjiDrawCurrentTitle: '',
    }
  },
  components: {
    Button1,
    KanjiDraw,
    BoxExample
  },
  methods:{
    // KanjiDraw Method
    showDrawKanji(kanji, title){
      this.kanjiDrawCurrent = kanji;
      this.kanjiDrawCurrentTitle = title;
    },
    // event
    endPopupKanjiDraw(){
      this.kanjiDrawCurrent = ''
    },
  }
}
</script>

<style lang="scss" scoped>
/* kanji draw button*/
.kanji_bottom{
  display: flex;
  justify-content: center;
}
.kanji_button{
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 1px;
  &:hover{
    opacity: 0.75;
    background: rgb(221 221 221 / 50%);
  }
}
</style>