<template>
  <div id="ni">
    <h3 class="title_subcontent">Particule : ni (に)</h3>
    <p class="text_no_margin">Cette particule s’utilise de différentes façons.</p>

    <h4 class="title_no_bottom">Indiquer un lieu de présence, où il ne se passe rien</h4>
    <p class="text_no_margin">
        Elle indique un lieu où se trouve quelque chose ou quelqu'un à un point d'arrivée et dont il ne se passe rien, il n'y a pas d'action sur ce lieu, à part notifier la présence.<br> 
        Pour indiquer un lieu de présence, on va utiliser les verbes de présence "iru" et "aru" après "ni".<br>
        <span class="text_lix2">- いる (iru) à utiliser pour tous les êtres vivants (animaux et humains).</span><br>
        <span class="text_lix2">- ある (aru) à utiliser pour toutes les choses non vivants (objets) ou inanimés.</span><br>
        <span class="blue_text">Lieu + ni + iru ou aru</span>
    </p>
    <!--<p class="box_example">
        resutoran <span class="red_text">ni</span> aru = <span class="red_text">il y a</span> un restaurant<br>
        <span class="green_text">naruto wa</span> <span class="red_text">ramen no resutoran ni</span> <span class="blue_text">iru</span> = <span class="green_text">naruto</span> <span class="blue_text">est</span> <span class="red_text">au restaurant de ramen</span><br>
        <span class="green_text">私は</span>サクラと<span class="red_text">映画館に</span><span class="blue_text">いる</span> = <span class="green_text">watashi wa</span> sakura to <span class="red_text">eigakan ni</span> <span class="blue_text">iru</span> = <span class="green_text">je</span> <span class="blue_text">suis</span> <span class="red_text">au cinéma</span> avec sakura-->
        <!--furansu <span class="red_text">ni</span> sumu = j'habite <span class="red_text">en</span> france<br>-->
    <!--</p>-->
    <BoxExample 
      :kanji="'レストラン<span class=\'red_text\'>に</span>ある。'"
      :kanjiraw="'レストランにある。'"
      :kana="''" 
      :roomaji="'resutoran <span class=\'red_text\'>ni</span> aru.'"
      :trad="'<span class=\'red_text\'>il y a</span> un restaurant.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'green_text\'>なるとは</span><span class=\'red_text\'>ラメんのレストランに</span><span class=\'blue_text\'>いる</span>。'"
      :kanjiraw="'なるとはラメんのレストランにいる。'"
      :kana="''" 
      :roomaji="'<span class=\'green_text\'>naruto wa</span> <span class=\'red_text\'>ramen no resutoran ni</span> <span class=\'blue_text\'>iru</span>.'"
      :trad="'<span class=\'green_text\'>naruto</span> <span class=\'blue_text\'>est</span> <span class=\'red_text\'>au restaurant de ramen</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'green_text\'>私は</span>サクラと<span class=\'red_text\'>映画館に</span><span class=\'blue_text\'>いる</span>。'"
      :kanjiraw="'私は映画館にいる。'"
      :kana="''" 
      :roomaji="'<span class=\'green_text\'>watashi wa</span> sakura to <span class=\'red_text\'>eigakan ni</span> <span class=\'blue_text\'>iru</span>.'"
      :trad="'<span class=\'green_text\'>je</span> <span class=\'blue_text\'>suis</span> <span class=\'red_text\'>au cinéma</span> avec sakura.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Indiquer une destination, un point d'arrivée</h4>
    <p class="text_no_margin">
        En utilisant les verbes de mouvement directionnel comme « iku », « kuru », « kaeru » etc. on peut définir une destination, un point d'arrivée. À ne pas confondre avec la particule "e" qui indique aussi une destination mais plus dans la manière de se déplacer que la destination finale.<br> 
        La notion de direction est important, le verbe "marcher" ne marche pas ici car elle n'indique pas une direction mais un état de marche.<br>
        <span class="text_li">- 行く (いく): aller</span><br>
        <span class="text_li">- 来る　(くる): venir</span><br>
        <span class="text_li">- 帰る　(かえる): rentrer chez soi</span><br>
        <span class="text_li">- 戻る　(もどる): revenir, retourner</span><br>
        <span class="text_li">- 向かう　(むかう): se diriger vers</span><br>
        <span class="text_li">- 進む　(すすむ): avancer, progresser</span><br>
        <span class="blue_text">Lieu + ni + verbe de déplacement</span>
    </p>
    <!--<p class="box_example">
        karaoke <span class="red_text">ni</span> iku = aller <span class="red_text">au</span> karaoke<br>
        kuruma <span class="red_text">ni</span> noru = monter <span class="red_text">en</span> voiture<br>
    </p>-->
    <BoxExample 
      :kanji="'カラオケ<span class=\'red_text\'>に</span>行く。'"
      :kanjiraw="'カラオケに行く。'"
      :kana="''" 
      :roomaji="'karaoke <span class=\'red_text\'>ni</span> iku.'"
      :trad="'aller <span class=\'red_text\'>au</span> karaoke.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'車<span class=\'red_text\'>に</span>のる。'"
      :kanjiraw="'車にのる。'"
      :kana="''" 
      :roomaji="'kuruma <span class=\'red_text\'>ni</span> noru.'"
      :trad="'monter <span class=\'red_text\'>en</span> voiture.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">En Complément d'objet indirect</h4>
    <p class="text_no_margin">
        Cette particule sert aussi de COI (complément d'objet indirect) à un verbe, c'est ceux vis à vis de quoi est faite l'action.<br>
        On peut reconnaître le COI en se posant la question "<b>a qui ?</b>", "<b>a quoi ?</b>", "<b>de qui ?</b>", "<b>de quoi ?</b>","<b>pour qui ?</b>", "<b>pour quoi ?</b>", "<b>contre qui ?</b>", "<b>contre quoi ?</b>".<br>
        PS : il est conseillé de voir avant, la Particule o (を) qui indique le COD.<br>
        <span class="blue_text">COI + ni</span>
    </p>
    <!--<p class="box_example">
        ポールは<span class="red_text">トム<b>に</b></span><span class="blue_text">カプセル<b>を</b></span>売る = pooru wa <span class="red_text">tomu <b>ni</b></span> <span class="blue_text">kapuseru <b>o</b></span> uru = paul vend <span class="blue_text"><b>des</b> capsules</span> <span class="red_text"><b>à</b> tom</span> (paul vend des capsules à qui ? à tom)<br>
        孫悟空はナメック星で<span class="red_text">ベジータ<b>に</b></span><span class="blue_text">野菜<b>を</b></span>あげる。= Songokuu wa namekkusei de <span class="red_text">bejiita <b>ni</b></span> <span class="blue_text">yasai <b>o</b></span> ageru = Son Goku donne <span class="blue_text"><b>des</b> légumes</span> <span class="red_text"><b>à</b> Végéta</span> sur la planète Namek<br>
    </p>-->
    <BoxExample 
      :kanji="'ポールは<span class=\'red_text\'>トム<b>に</b></span><span class=\'blue_text\'>カプセル<b>を</b></span>売る。'"
      :kanjiraw="'ポールはトムにカプセルを売る。'"
      :kana="''" 
      :roomaji="'pooru wa <span class=\'red_text\'>tomu <b>ni</b></span> <span class=\'blue_text\'>kapuseru <b>o</b></span> uru.'"
      :trad="'paul vend <span class=\'blue_text\'><b>des</b> capsules</span> <span class=\'red_text\'><b>à</b> tom</span>.'" 
      :explication="'paul vend des capsules à qui ? à tom'"
    />
    <BoxExample 
      :kanji="'孫悟空はナメック星で<span class=\'red_text\'>ベジータ<b>に</b></span><span class=\'blue_text\'>野菜<b>を</b></span>あげる。'"
      :kanjiraw="'孫悟空はナメック星でベジータに野菜をあげる。'"
      :kana="''" 
      :roomaji="'Songokuu wa namekkusei de <span class=\'red_text\'>bejiita <b>ni</b></span> <span class=\'blue_text\'>yasai <b>o</b></span> ageru.'"
      :trad="'Son Goku donne <span class=\'blue_text\'><b>des</b> légumes</span> <span class=\'red_text\'><b>à</b> Végéta</span> sur la planète Namek.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Préciser un point précis dans le temps</h4>
    <p class="text_no_margin">
      Elle symbolise un moment précis dans le temps où une action se passe.<br> 
      Les compléments de temps suivant s’utilisent souvent juste après le thème et は (wa).<br>
      <span class="blue_text">Temps + ni</span></p>
    <!--<p class="box_example">
        私は7時<span class="red_text">に</span>起きる = Watashi wa 7-ji <span class="red_text">ni</span> okiru = Je me lève <span class="red_text">à</span> sept heures<br>
        6時<span class="red_text">に</span> = 6 ji <span class="red_text">ni</span> = <span class="red_text">à</span> 6h
    </p>-->
    <BoxExample 
      :kanji="'私は7時<span class=\'red_text\'>に</span>起きる。'"
      :kanjiraw="'私は7時に起きる。'"
      :kana="''" 
      :roomaji="'Watashi wa 7-ji <span class=\'red_text\'>ni</span> okiru.'"
      :trad="'Je me lève <span class=\'red_text\'>à</span> sept heures.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'6時<span class=\'red_text\'>に</span>。'"
      :kanjiraw="'6時に。'"
      :kana="''" 
      :roomaji="'6 ji <span class=\'red_text\'>ni</span>.'"
      :trad="'<span class=\'red_text\'>à</span> 6h.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Si on utilise des mots relatifs au moment de l'énonciation, tel que "maintenant, hier, demain, etc", des mots temporels non précis. Il n'y a pas besoin de mettre "に" (ni).<br>
    </p>
    <!--<p class="box_example">
        昨日 = kinou = hier <br>
        今 = ima = maintenant<br>
        ポールは<span class="red_text">今日</span>学校に行く = pooru wa <span class="red_text">kyou</span> gakkou ni iku = paul va à l'école <span class="red_text">aujourd'hui</span> (pas de ni après "aujourd'hui" qui est un mot temporel non précis)<br>
        ポールは<span class="red_text">今日8時に</span>学校に行く = pooru wa <span class="red_text">kyou 8ji ni</span> gakkou ni iku = paul va à l'école <span class="red_text">aujourd'hui à 8h</span> (On peut combiner les deux système. pas de "ni" après un mot temporel non précis et un "ni" après une indication temporel précis)<br>
    </p>-->
    <BoxExample 
      :kanji="'昨日'"
      :kanjiraw="'昨日'"
      :kana="''" 
      :roomaji="'kinou'"
      :trad="'hier'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'今'"
      :kanjiraw="'今'"
      :kana="''" 
      :roomaji="'ima'"
      :trad="'maintenant'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'ポールは<span class=\'red_text\'>今日</span>学校に行く。'"
      :kanjiraw="'ポールは今日学校に行く。'"
      :kana="''" 
      :roomaji="'pooru wa <span class=\'red_text\'>kyou</span> gakkou ni iku.'"
      :trad="'paul va à l\'école <span class=\'red_text\'>aujourd\'hui</span>.'" 
      :explication="'pas de ni après “aujourd\'hui” qui est un mot temporel non précis'"
    />
    <BoxExample 
      :kanji="'ポールは<span class=\'red_text\'>今日8時に</span>学校に行く。'"
      :kanjiraw="'ポールは今日8時に学校に行く。'"
      :kana="''" 
      :roomaji="'pooru wa <span class=\'red_text\'>kyou 8ji ni</span> gakkou ni iku.'"
      :trad="'paul va à l\'école <span class=\'red_text\'>aujourd\'hui à 8h</span>.'" 
      :explication="'On peut combiner les deux systèmes. pas de “ni” après un mot temporel non précis et un “ni” après une indication temporelle précis.'"
    />
    <p class="text_no_margin">
        Liste non-exhaustive des mots relatifs au moment de l'énoncation.<br>
    </p>

    <table class="content-table combinations">
        <thead>
          <tr>
            <th></th>
            <th>Passé</th>
            <th>Présent</th>
            <th>Futur</th>
            <th>Tous le temps</th>
          </tr>
        </thead>
        <tr>
          <th>日 (Jour)</th>
          <td>
            <span class="trad">hier</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('昨', 'Tracé')">昨</span>
              <span class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</span>
            </span>
            <span class="furigana">きのう</span>
            <span class="trad">kinou</span>
          </td>
          <td>
            <span class="trad">aujourd'hui</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('今', 'Tracé')">今</span>
              <span class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</span>
            </span>
            <span class="furigana">きょう</span>
            <span class="trad">kyou</span>
          </td>
          <td>
            <span class="trad">demain</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('明', 'Tracé')">明</span>
              <span class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</span>
            </span>
            <span class="furigana">あした</span>
            <span class="trad">ashita</span>
          </td>
          <td>
            <span class="trad">tous les jours</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('毎', 'Tracé')">毎</span>
              <span class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</span>
            </span>
            <span class="furigana">マイニチ</span>
            <span class="trad">mainichi</span>
          </td>
        </tr>
        <tr>
          <th>週 (Semaine)</th>
          <td>
            <span class="trad">semaine dernière</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('先', 'Tracé')">先</span>
              <span class="kanji_button" @click="showDrawKanji('週', 'Tracé')">週</span>
            </span>
            <span class="furigana">センシュウ</span>
            <span class="trad">senshuu</span>
          </td>
          <td>
            <span class="trad">cette semaine</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('今', 'Tracé')">今</span>
              <span class="kanji_button" @click="showDrawKanji('週', 'Tracé')">週</span>
            </span>
            <span class="furigana">コンシュウ</span>
            <span class="trad">konshuu</span>
          </td>
          <td>
            <span class="trad">semaine prochaine</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('来', 'Tracé')">来</span>
              <span class="kanji_button" @click="showDrawKanji('週', 'Tracé')">週</span>
            </span>
            <span class="furigana">ライシュウ</span>
            <span class="trad">raishuu</span>
          </td>
          <td>
            <span class="trad">toutes les semaines</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('毎', 'Tracé')">毎</span>
              <span class="kanji_button" @click="showDrawKanji('週', 'Tracé')">週</span>
            </span>
            <span class="furigana">マイシュウ</span>
            <span class="trad">maishuu</span>
          </td>
        </tr>
        <tr>
          <th>月 (Mois)</th>
          <td>
            <span class="trad">mois dernier</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('先', 'Tracé')">先</span>
              <span class="kanji_button" @click="showDrawKanji('月', 'Tracé')">月</span>
            </span>
            <span class="furigana">センゲツ</span>
            <span class="trad">sengetsu</span>
          </td>
          <td>
            <span class="trad">ce mois-ci</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('今', 'Tracé')">今</span>
              <span class="kanji_button" @click="showDrawKanji('月', 'Tracé')">月</span>
            </span>
            <span class="furigana">コンゲツ</span>
            <span class="trad">kongetsu</span>
          </td>
          <td>
            <span class="trad">mois prochain</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('来', 'Tracé')">来</span>
              <span class="kanji_button" @click="showDrawKanji('月', 'Tracé')">月</span>
            </span>
            <span class="furigana">ライゲツ</span>
            <span class="trad">raigetsu</span>
          </td>
          <td>
            <span class="trad">tous les mois</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('毎', 'Tracé')">毎</span>
              <span class="kanji_button" @click="showDrawKanji('月', 'Tracé')">月</span>
            </span>
            <span class="furigana">マイつき</span>
            <span class="trad">maitsuki</span>
          </td>
        </tr>
        <tr>
          <th>年 (Année)</th>
          <td>
            <span class="trad">année dernière</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('去', 'Tracé')">去</span>
              <span class="kanji_button" @click="showDrawKanji('年', 'Tracé')">年</span>
            </span>
            <span class="furigana">キョネン</span>
            <span class="trad">kyonen</span>
          </td>
          <td>
            <span class="trad">cette année</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('今', 'Tracé')">今</span>
              <span class="kanji_button" @click="showDrawKanji('年', 'Tracé')">年</span>
            </span>
            <span class="furigana">ことし</span>
            <span class="trad">kotoshi</span>
          </td>
          <td>
            <span class="trad">année prochaine</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('来', 'Tracé')">来</span>
              <span class="kanji_button" @click="showDrawKanji('年', 'Tracé')">年</span>
            </span>
            <span class="furigana">ライネン</span>
            <span class="trad">rainen</span>
          </td>
          <td>
            <span class="trad">tous les ans</span>
            <span class="kanji">
              <span class="kanji_button" @click="showDrawKanji('毎', 'Tracé')">毎</span>
              <span class="kanji_button" @click="showDrawKanji('年', 'Tracé')">年</span>
            </span>
            <span class="furigana">マイとし</span>
            <span class="trad">maitoshi</span>
          </td>
        </tr>
    </table>

    <!--<h4 class="title_no_bottom">Indiquer un but</h4>
    <p class="text_no_margin">On peut indiquer un but<br> <span class="blue_text">Verbe + ni + ikimasu</span></p>
    <p class="box_example">
        resutoran wo tabe <span class="red_text">ni</span> ikimasu = je vais aller manger <span class="red_text">au</span> restaurant<br>
    </p>

    <h4 class="title_no_bottom">Préciser qui donne ou qui reçoit</h4>
    <p class="text_no_margin">Avec les verbes « agemasu » et « moraimasu » on peut indiquer qui donne ou qui reçoit<br> <span class="blue_text">agemasu et moraimasu </span></p>
    <p class="box_example">
        Watashi wa ane <span class="red_text">ni</span> ringo wo moraimashita = j'ai reçu une pomme <span class="red_text">de</span> ma soeur<br>
        Watashi wa papa <span class="red_text">ni</span> purezento wo agemashita = j'ai donnée un cadeau <span class="red_text">à</span> mon père<br>
    </p>-->

    <KanjiDraw v-if="kanjiDrawCurrent" :title="kanjiDrawCurrentTitle" :kanji="kanjiDrawCurrent" @endKanjiDraw="endPopupKanjiDraw"/>

  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";
import KanjiDraw from "@/components/Composants/KanjiDraw.vue";

export default {
  data(){
    return{
      kanjiDrawCurrent: '',
      kanjiDrawCurrentTitle: '',
    }
  },
  components: {
    BoxExample,
    KanjiDraw
  },
  methods:{
    // KanjiDraw Method
    showDrawKanji(kanji, title){
      this.kanjiDrawCurrent = kanji;
      this.kanjiDrawCurrentTitle = title;
    },
    // event
    endPopupKanjiDraw(){
      this.kanjiDrawCurrent = ''
    },
  }
}
</script>

<style lang="scss" scoped>
/*table*/
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.combinations{
  th {
    background-color: #009879;
    color: #ffffff;
    font-weight: bold;
  }
  td{
    font-size: var(--normal-font-size);
    span{
      display: inline-block;
      width: 100%;
    }
  }
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
/* kanji draw button*/
.kanji{
  display: flex !important;
  justify-content: center;
}
.kanji_button{
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 1px;
  width: 27px!important;
  &:hover{
    opacity: 0.75;
    background: rgb(221 221 221 / 50%);
  }
}
</style>