<template>
  <div id="mo">
    <h3 class="title_subcontent">Particule : mo (も)</h3>
    <!--<p class="text_no_margin">Cette particule s'utilise deux façon différents</p>-->

    <h4 class="title_no_bottom">Introduire dans la phrase une chose identique, précédemment énoncée</h4>
    <p class="text_no_margin">
      Cette particule peut être comparé à la liaison "<b>aussi</b>", "<b>de même</b>" en français.<br>
      La particule "も" (mo) peut s'appliquer à n'importe quel complément, particule, COD, Temps, COI, etc.<br>
      On peut avoir plusieurs particules も (mo) dans une même phrase, tant qu'il y a des équivalences.<br>
    <span class="blue_text">nom + mo </span></p>
    <!--<p class="box_example">
      <span class="red_text">レモン<b>も</b></span>果物だ。= <span class="red_text">remon <b>mo</b></span> kudamono da。= <span class="red_text">le citron <b>aussi</b></span> est un fruit. (dans le contexte de la conversation, on parle de fruit)<br>
      <span class="red_text">バナナ<b>も</b>レモン<b>も</b></span>果物だ = <span class="red_text">banana <b>mo</b> remon <b>mo</b></span> kudamono da。= <span class="red_text">la banane et le citron sont <b>aussi</b></span> des fruit. (on peut mettre autant de "も"(mo) que l'on a équivalent)<br>-->
      <!--<span class="green_text">kanojo</span> <span class="red_text">mo</span> ikitai = Ma <span class="green_text">copine</span> veut y aller <span class="red_text">aussi</span><br>-->
      <!--furansu ni <span class="red_text">mo</span> banana o taberu = En france <span class="red_text">aussi</span> on mange des bananes<br>-->
    <!--</p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>レモン<b>も</b></span>果物だ。'"
      :kanjiraw="'レモンも果物だ。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>remon <b>mo</b></span> kudamono da。'"
      :trad="'<span class=\'red_text\'>le citron <b>aussi</b></span> est un fruit.'" 
      :explication="'Dans le contexte de la conversation, on parle de fruit'"
    />
    <BoxExample 
      :kanji="'<span class=\'red_text\'>バナナ<b>も</b>レモン<b>も</b></span>果物だ。'"
      :kanjiraw="'バナナもレモンも果物だ。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>banana <b>mo</b> remon <b>mo</b></span> kudamono da。'"
      :trad="'<span class=\'red_text\'>la banane et le citron sont <b>aussi</b></span> des fruit.'" 
      :explication="'On peut mettre autant de “も”(mo) que l\'on a équivalent'"
    />
    <p class="text_no_margin">
      Pour les particules "は" (wa), "が" (ga) et "を" (o) on remplace la particule par "も" (mo)<br>
      Pour toutes les autres particules へ (e), から (kara), まで (made), etc. On ajoute "も" (mo) après la particule<br>
    </p>
    <!--<p class="box_example">
      日本では猿が毎日3時にオオカミに桃をあげる。= nihon de wa saru ga mainichi 3ji ni ookami ni momo ageru = au Japon, les singes donnent des pêche aux loups tous les jours à 3h.<br>
      <span class="red_text">中国で<b>も</b></span>猿が毎日3時にオオカミに桃をあげる。= <span class="red_text">chuugoku de <b>mo</b></span> saru ga mainichi 3ji ni ookami ni momo ageru = <span class="red_text">En chine <b>aussi</b></span>, les singes donnent des pêche aux loups tous les jours à 3h. (pour le thème: on remplace "は" (wa) par "も" (mo))<br>
      日本では<span class="red_text">キツネ<b>も</b></span>毎日3時にオオカミに桃をあげる。= nihon de wa <span class="red_text">kitsune <b>mo</b></span> mainichi 3ji ni ookami ni momo ageru = au Japon, <span class="red_text">les renard <b>aussi</b></span> donnent des pêche aux loups tous les jours à 3h. (pour le sujet du verbe: on remplace "が" (ga) par "も" (mo))<br>
      日本では猿が毎日<span class="red_text">5時に<b>も</b></span>オオカミに桃をあげる。= nihon de wa saru ga mainichi <span class="red_text">5ji ni <b>mo</b></span> ookami ni momo ageru = au Japon, les singes donnent des pêche aux loups tous les jours <span class="red_text"><b>aussi</b> à 5h</span>. (pour un temps précis: on ajoute "も" (mo) après "に" (ni))<br>
      日本では猿が毎日3時に<span class="red_text">象に<b>も</b></span>桃をあげる。= nihon de wa saru ga mainichi 3ji ni <span class="red_text">zoo ni <b>mo</b></span> momo ageru = au Japon, les singes donnent des pêche <span class="red_text"><b>aussi</b> aux éléphants</span> tous les jours à 3h. (pour un COI: on ajoute "も" (mo) après "に" (ni))<br>
      日本では猿が毎日3時にオオカミに<span class="red_text">梅<b>も</b></span>あげる。= nihon de wa saru ga mainichi 3ji ni ookami ni <span class="red_text">ume <b>mo</b></span> ageru = au Japon, les singes donnent <span class="red_text"><b>aussi</b> des prunes</span> aux loups tous les jours à 3h. (pour un COD: on remplace "を" (o) par "も" (mo))<br>
      avec la particule へ (e) on ajoutera も (mo) = へも<br>
      avec la particule から (kara) on ajoutera も (mo) = からも<br>
      avec la particule まで (made) on ajoutera も (mo) = までも<br>
    </p>-->
    <BoxExample 
      :kanji="'日本では猿が毎日3時にオオカミに桃をあげる。'"
      :kanjiraw="'日本では猿が毎日3時にオオカミに桃をあげる。'"
      :kana="''" 
      :roomaji="'nihon de wa saru ga mainichi 3ji ni ookami ni momo ageru.'"
      :trad="'Au Japon, les singes donnent des pêche aux loups tous les jours à 3h.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'red_text\'>中国で<b>も</b></span>猿が毎日3時にオオカミに桃をあげる。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>chuugoku de <b>mo</b></span> saru ga mainichi 3ji ni ookami ni momo ageru.'"
      :trad="'<span class=\'red_text\'>En chine <b>aussi</b></span>, les singes donnent des pêche aux loups tous les jours à 3h.'" 
      :explication="'Pour le thème: on remplace “は” (wa) par “も” (mo).'"
    />
    <BoxExample 
      :kanji="'日本では<span class=\'red_text\'>キツネ<b>も</b></span>毎日3時にオオカミに桃をあげる。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'nihon de wa <span class=\'red_text\'>kitsune <b>mo</b></span> mainichi 3ji ni ookami ni momo ageru.'"
      :trad="'au Japon, <span class=\'red_text\'>les renard <b>aussi</b></span> donnent des pêche aux loups tous les jours à 3h.'" 
      :explication="'Pour le sujet du verbe: on remplace “が” (ga) par “も” (mo)'"
    />
    <BoxExample 
      :kanji="'日本では猿が毎日<span class=\'red_text\'>5時に<b>も</b></span>オオカミに桃をあげる。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'nihon de wa saru ga mainichi <span class=\'red_text\'>5ji ni <b>mo</b></span> ookami ni momo ageru.'"
      :trad="'Au Japon, les singes donnent des pêche aux loups tous les jours <span class=\'red_text\'><b>aussi</b> à 5h</span>.'" 
      :explication="'Pour un temps précis: on ajoute “も” (mo) après “に” (ni)'"
    />
    <BoxExample 
      :kanji="'日本では猿が毎日3時に<span class=\'red_text\'>象に<b>も</b></span>桃をあげる。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'nihon de wa saru ga mainichi 3ji ni <span class=\'red_text\'>zoo ni <b>mo</b></span> momo ageru.'"
      :trad="'Au Japon, les singes donnent des pêche <span class=\'red_text\'><b>aussi</b> aux éléphants</span> tous les jours à 3h.'" 
      :explication="'pour un COI: on ajoute “も” (mo) après “に” (ni).'"
    />
    <BoxExample 
      :kanji="'日本では猿が毎日3時にオオカミに<span class=\'red_text\'>梅<b>も</b></span>あげる。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'nihon de wa saru ga mainichi 3ji ni ookami ni <span class=\'red_text\'>ume <b>mo</b></span> ageru'"
      :trad="'Au Japon, les singes donnent <span class=\'red_text\'><b>aussi</b> des prunes</span> aux loups tous les jours à 3h.'" 
      :explication="'pour un COD: on remplace “を” (o) par “も” (mo).'"
    />
    <p class="text_no_margin">
      avec la particule へ (e) on ajoutera も (mo) = へも<br>
      avec la particule から (kara) on ajoutera も (mo) = からも<br>
      avec la particule まで (made) on ajoutera も (mo) = までも<br>
    </p>

    <!--<h4 class="title_no_bottom">Indiquer une négation</h4>
    <p class="text_no_margin">Elle permet d'indiquer une négation complète lorsqu'elle est attaché à un mots interrogatifs (doko, nani, dare,...) et cela amène obligatoirement le verbe à la forme négative <br>
    <span class="blue_text">interrogatif + mo</span></p>
    <p class="box_example">
        kinou <span class="red_text">nanimo</span> tabemasendeshita = je n'ai rien mangé hier<br>
    </p>-->
  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>