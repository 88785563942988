<template>
  <div>
    <h1 id="particule adverbiale">Autres Particules</h1>
    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Particules Adverbiales'" :link="'#particuleadverbiale'" />
    <Button1 :text="'Particules d\'Emphase'" :link="'#particuleemphase'" />
    <Button1 :text="'Particules de fin de phrase'" :link="'#particulefinphrase'" />
    
    <ParticuleAdverbiale/>
    <ParticuleEmphase/>
    <ParticuleFinPhrase/>
    <ParticuleNoda/>

  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import ParticuleAdverbiale from "@/components/ParticulesAutres/ParticuleAdverbiale.vue";
import ParticuleEmphase from "@/components/ParticulesAutres/ParticuleEmphase.vue";
import ParticuleFinPhrase from "@/components/ParticulesAutres/ParticuleFinPhrase.vue";
import ParticuleNoda from "@/components/ParticulesAutres/ParticuleNoda.vue";

import autoscrollMixin from "@/mixins/autoscrollMixins";

export default {
  name: 'ParticuleAutre',
  components: {
    Button1,
    ParticuleAdverbiale,
    ParticuleEmphase,
    ParticuleFinPhrase,
    ParticuleNoda
  },
  mixins:[autoscrollMixin]
}
</script>

<style>

</style>