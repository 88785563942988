<template>
  <div id="kara_made">
      <h3 class="title_subcontent">Particule : kara & made (から & まで)</h3>
      <p class="text_no_margin">
        Ces deux particules indiquent un point de départ et d'arrivée relatif à l'espace ou dans le temps ou concret ou abstrait.<br> 
      </p>
      
      <h4 class="title_no_bottom">Kara, indique le point de départ</h4>
      <p class="text_no_margin">
        Kara indique le point de départ, d’où on commence.<br>
      </p>
      <!--<p class="box_example">
        私は<span class="red_text">午後2時<b>から</b></span>遊ぶ。= watashi wa <span class="red_text">gogo 2ji <b>kara</b></span> asobu = je joue <span class="red_text"><b>à partir</b> de 14h</span> (relatif au temps)<br>
        ミサは<span class="red_text">心<b>から</b></span>祈る。= misa wa <span class="red_text">koko <b>kara</b></span> inoru = misa prie de tout son coeur (elle prit <span class="red_text"><b>à partir</b> de son coeur</span> (c'est un point de départ plus abstrait))<br>
        ワタリは<span class="red_text">ロンドン<b>から</b></span>京都に戻る = watari wa <span class="red_text">rondon <b>kara</b></span> kyoto ni modoru = watari retourne à kyoto <span class="red_text">depuis londres</span> (notre point de départ est londre et le point de destination est kyoto (kyoto, sans notion de durée on parle juste du lieu)).
      </p>-->
      <BoxExample 
        :kanji="'私は<span class=\'red_text\'>午後2時<b>から</b></span>遊ぶ。'"
        :kanjiraw="'私は午後2時から遊ぶ。'"
        :kana="''" 
        :roomaji="'watashi wa <span class=\'red_text\'>gogo 2ji <b>kara</b></span> asobu.'"
        :trad="'je joue <span class=\'red_text\'><b>à partir</b> de 14h</span>'" 
        :explication="'relatif au temps'"
      />
      <BoxExample 
        :kanji="'ミサは<span class=\'red_text\'>心<b>から</b></span>祈る。'"
        :kanjiraw="'ミサは心から祈る。'"
        :kana="''" 
        :roomaji="'misa wa <span class=\'red_text\'>koko <b>kara</b></span> inoru'"
        :trad="'misa prie de tout son coeur (elle prit <span class=\'red_text\'><b>à partir</b> de son coeur</span>).'" 
        :explication="'C\'est un point de départ plus abstrait.'"
      />
      <BoxExample 
        :kanji="'ワタリは<span class=\'red_text\'>ロンドン<b>から</b></span>京都に戻る。'"
        :kanjiraw="'ワタリはロンドンから京都に戻る。'"
        :kana="''" 
        :roomaji="'watari wa <span class=\'red_text\'>rondon <b>kara</b></span> kyoto ni modoru.'"
        :trad="'watari retourne à kyoto <span class=\'red_text\'>depuis londres</span>.'" 
        :explication="'Notre point de départ est londre et le point de destination est kyoto (kyoto, sans notion de durée on parle juste du lieu).'"
      />

      <h4 class="title_no_bottom">Made, indique le point d'arrivée (la limite)</h4>
      <p class="text_no_margin">
        Made indique le point d'arrivée pour une action sur la durée, dont il y a une limite.<br>
        Si on parle simplement d'un lieu de présence au point de destination, qui ne nécessite pas de durée, on va utiliser les autres particules comme に (ni).<br>
      </p>
      <!--<p class="box_example">
        私は<span class="red_text">午後6時<b>まで</b></span>遊ぶ。= watashi wa <span class="red_text">gogo 6ji <b>made</b></span> asobu = je joue <span class="red_text"><b>jusqu'a</b> 18h</span> (relatif au temps)<br>
        トムの授業は<span class="red_text">10時<b>まで</b></span>だ。= tomu no juugyou wa <span class="red_text">10ji <b>made</b></span> da = le cours de Tom se <span class="red_text">termine à 10h</span> (le point d'arrivé est 10h)<br>
      </p>-->
      <BoxExample 
        :kanji="'私は<span class=\'red_text\'>午後6時<b>まで</b></span>遊ぶ。'"
        :kanjiraw="'私は午後6時まで遊ぶ。'"
        :kana="''" 
        :roomaji="'watashi wa <span class=\'red_text\'>gogo 6ji <b>made</b></span> asobu.'"
        :trad="'je joue <span class=\'red_text\'><b>jusqu\'a</b> 18h</span>.'" 
        :explication="'relatif au temps'"
      />
      <BoxExample 
        :kanji="'トムの授業は<span class=\'red_text\'>10時<b>まで</b></span>だ。'"
        :kanjiraw="'トムの授業は10時までだ。'"
        :kana="''" 
        :roomaji="'tomu no juugyou wa <span class=\'red_text\'>10ji <b>made</b></span> da.'"
        :trad="'le cours de Tom se <span class=\'red_text\'>termine à 10h</span>.'" 
        :explication="'le point d\'arrivé est 10h.'"
      />

      <h4 class="title_no_bottom">Kara et Made, ensemble</h4>
      <p class="text_no_margin">
        Lorsqu'on utilise ces deux particules ensemble, on utilise toujours "kara" en premier puis "made" en second. (Point de départ puis point d'arrivée).<br>
      </p>
      <!--<p class="box_example">
        私は<span class="red_text">公園<b>から</b></span><span class="blue_text">家<b>まで</b></span>歩く。= watashi wa <span class="red_text">kouen <b>kara</b></span> <span class="blue_text">ie <b>made</b></span> aruku。 = je marche <span class="red_text"><b>à partir</b> du parc</span> <span class="blue_text"><b>jusqu'à</b> chez moi</span>.<br>
        私は<span class="red_text">午後2時<b>から</b></span><span class="blue_text">午後6時<b>まで</b></span>遊ぶ。= watashi wa <span class="red_text">gogo 2ji <b>kara</b></span> <span class="blue_text">gogo 6ji <b>made</b></span> asobu = je joue <span class="red_text"><b>(à partir)</b> de 14h</span> <span class="blue_text"><b>(jusqu')</b>a 18h</span> (relatif au temps)<br>
        ポールは電車で<span class="red_text">大阪<b>から</b></span><span class="blue_text">広島<b>まで</b></span>いく。= pooru wa densha de <span class="red_text">oosaka <b>kara</b></span> <span class="blue_text">hiroshima <b>made</b></span> iku。= paul va <span class="red_text"><b>d</b>'Osaka</span> <span class="blue_text"><b>à</b> Hiroshima</span> en train. (relatif à un déplacement concret)<br>
      </p>-->
      <BoxExample 
        :kanji="'私は<span class=\'red_text\'>公園<b>から</b></span><span class=\'blue_text\'>家<b>まで</b></span>歩く。'"
        :kanjiraw="'私は公園から家まで歩く。'"
        :kana="''" 
        :roomaji="'watashi wa <span class=\'red_text\'>kouen <b>kara</b></span> <span class=\'blue_text\'>ie <b>made</b></span> aruku.'"
        :trad="'je marche <span class=\'red_text\'><b>à partir</b> du parc</span> <span class=\'blue_text\'><b>jusqu\'à</b> chez moi</span>.'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'私は<span class=\'red_text\'>午後2時<b>から</b></span><span class=\'blue_text\'>午後6時<b>まで</b></span>遊ぶ。'"
        :kanjiraw="'私は午後2時から午後6時まで遊ぶ。'"
        :kana="''" 
        :roomaji="'watashi wa <span class=\'red_text\'>gogo 2ji <b>kara</b></span> <span class=\'blue_text\'>gogo 6ji <b>made</b></span> asobu.'"
        :trad="'je joue <span class=\'red_text\'><b>(à partir)</b> de 14h</span> <span class=\'blue_text\'><b>(jusqu\')</b>a 18h</span>.'" 
        :explication="'Relatif au temps.'"
      />
      <BoxExample 
        :kanji="'ポールは電車で<span class=\'red_text\'>大阪<b>から</b></span><span class=\'blue_text\'>広島<b>まで</b></span>いく。'"
        :kanjiraw="'ポールは電車で大阪から広島までいく。'"
        :kana="''" 
        :roomaji="'pooru wa densha de <span class=\'red_text\'>oosaka <b>kara</b></span> <span class=\'blue_text\'>hiroshima <b>made</b></span> iku.'"
        :trad="'paul va <span class=\'red_text\'><b>d</b>\'Osaka</span> <span class=\'blue_text\'><b>à</b> Hiroshima</span> en train.'" 
        :explication="'Relatif à un déplacement concret.'"
      />
  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>