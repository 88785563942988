export default{
  mounted(){
    // when the page is load (from another page) -- scroll if params exist
    // nextick is a better form of setimeout
    this.$nextTick(() => {
      if(this.$route.params.id){
        if(document.getElementById(this.$route.params.id)){
          document.getElementById(this.$route.params.id).scrollIntoView({
            behavior: "smooth",
          });
        }
      }
    })
  }
}