<template>
  <div class="footer">
    Copyright 2021 - Japonais Recap - <a href="mailto:japonais.recap@gmail.com">japonais.recap@gmail.com</a>
  </div>
</template>

<script>
export default {
    name: 'Footer',
}
</script>

<style scoped>
.footer{
    bottom: 0;
    margin: 0 -2rem;
    background-color: var(--bg-color);
    color: var(--white-color);
    position: fixed;
    width: 100%;
    height: 25px;
    text-align: center;
}
a {
    color: var(--white-color); 
}
</style>