<template>
  <div id="to">
    <h3 class="title_subcontent">Particule : to (と)</h3>
    <p class="text_no_margin">Cette particule s'utilise de deux façons différentes</p>

    <h4 class="title_no_bottom">Une énumération finie</h4>
    <p class="text_no_margin">On vient énumérer chaque objet ou personne d'une liste finie contrairement à "ya" qui est non fini.<br>
    C'est plus rare, mais il peut arriver que l'on utilise "to" pour une liste non finie mais dont on a énuméré aux moins tous les objets ou personnes importantes à la conversation.<br>
    On peut comparer à la liaison <b>"et"</b> en français et elle se place du coup à chaque fois entre deux noms.<br> <span class="blue_text">nom + to + nom </span></p>
    <!--<p class="box_example">
        <span class="green_text">niku</span> <span class="red_text">to</span> sakana = De la <span class="green_text">viande</span> <span class="red_text">et</span> du poisson<br>
        banana <span class="red_text">to</span> ringo <span class="red_text">to</span> ichigo = Des bananes<span class="red_text">,</span> des pommes <span class="red_text">et</span> des fraises<br>
        マリオ<span class="red_text">と</span>ピーチのスニーカー<span class="red_text">と</span>マフラーだ = mario <span class="red_text">to</span> piichi no suniika <span class="red_text">to</span> mafuraa da = ce sont les baskets <span class="red_text">et</span> les écharpes de Mario <span class="red_text">et</span> Peach<br>
        にわとり<span class="red_text">と</span>ことり<span class="red_text">と</span>わに = niwatori <span class="red_text">to</span> kotori <span class="red_text">to</span> wani = la poule <span class="red_text">et</span> l'oiseaux <span class="red_text">et</span> le crocodile (C'est un palindrome japonais, on peut lire niwatoritokotoritowani à l'envers aussi, en partant de la fin et nous aurons toujours la même phrase)
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'green_text\'>肉</span><span class=\'red_text\'>と</span>魚。'"
      :kanjiraw="'肉と魚。'"
      :kana="''" 
      :roomaji="'<span class=\'green_text\'>niku</span> <span class=\'red_text\'>to</span> sakana.'"
      :trad="'De la <span class=\'green_text\'>viande</span> <span class=\'red_text\'>et</span> du poisson.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'バナナ<span class=\'red_text\'>と</span>林檎<span class=\'red_text\'>と</span>苺。'"
      :kanjiraw="'バナナと林檎と苺。'"
      :kana="''" 
      :roomaji="'banana <span class=\'red_text\'>to</span> ringo <span class=\'red_text\'>to</span> ichigo.'"
      :trad="'Des bananes<span class=\'red_text\'>,</span> des pommes <span class=\'red_text\'>et</span> des fraises.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'マリオ<span class=\'red_text\'>と</span>ピーチのスニーカー<span class=\'red_text\'>と</span>マフラーだ。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'mario <span class=\'red_text\'>to</span> piichi no suniika <span class=\'red_text\'>to</span> mafuraa da.'"
      :trad="'ce sont les baskets <span class=\'red_text\'>et</span> les écharpes de Mario <span class=\'red_text\'>et</span> Peach.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'にわとり<span class=\'red_text\'>と</span>ことり<span class=\'red_text\'>と</span>わに。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'niwatori <span class=\'red_text\'>to</span> kotori <span class=\'red_text\'>to</span> wani.'"
      :trad="'la poule <span class=\'red_text\'>et</span> l\'oiseau <span class=\'red_text\'>et</span> le crocodile.'" 
      :explication="'C\'est un palindrome japonais, on peut lire niwatoritokotoritowani à l\'envers aussi, en partant de la fin et nous aurons toujours la même phrase.'"
    />

    <h4 class="title_no_bottom">Indiquer un accompagnement</h4>
    <p class="text_no_margin">Elle permet d'indiquer avec qui la personne est accompagné.<br>
    On ne peut donc pas l'utiliser avec des objets, seulement avec des personnes.<br>
    On peut comparer la particule "to" à la liaison <b>"avec"</b> en français.<br>
    <span class="blue_text">nom + to</span></p>
    <!--<p class="box_example">
        kanojo <span class="red_text">to</span> = <span class="red_text">avec</span> ma copine<br>
        kazoku <span class="red_text">to</span> iku = j'y vais <span class="red_text">avec</span> ma famille<br>
        otousan <span class="red_text">to</span> okaasan <span class="red_text">to</span> = <span class="red_text">avec</span> mon père <span class="red_text">et</span> ma mère<br>
    </p>-->

    <BoxExample 
      :kanji="'彼女<span class=\'red_text\'>と</span>。'"
      :kanjiraw="'彼女と。'"
      :kana="''" 
      :roomaji="'kanojo <span class=\'red_text\'>to</span>.'"
      :trad="'<span class=\'red_text\'>avec</span> ma copine.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'家族<span class=\'red_text\'>と</span>行く。'"
      :kanjiraw="'家族と行く。'"
      :kana="''" 
      :roomaji="'kazoku <span class=\'red_text\'>to</span> iku.'"
      :trad="'j\'y vais <span class=\'red_text\'>avec</span> ma famille.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'お父さん<span class=\'red_text\'>と</span>お母さん<span class=\'red_text\'>と</span>。'"
      :kanjiraw="'お父さんとお母さんと。'"
      :kana="''" 
      :roomaji="'otousan <span class=\'red_text\'>to</span> okaasan <span class=\'red_text\'>to</span>.'"
      :trad="'<span class=\'red_text\'>avec</span> mon père <span class=\'red_text\'>et</span> ma mère.'" 
      :explication="''"
    />

  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>