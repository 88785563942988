<template>
  <div class="icon_chargement">
      <figure></figure> 
      <div v-if="title">
          {{ title }}
      </div>
      <div v-else>
          Chargement ...
      </div>
  </div>
</template>

<script>
export default {
    name: 'Chargement',
    props: ["title"],
}
</script>

<style lang="scss" scoped>
@property --start {
	initial-value: 0%;
	syntax: '<percentage>';
	inherits: false;
}

@property --end {
	initial-value: 0%;
	syntax: '<percentage>';
	inherits: false;
}

@keyframes spin {
	0% {
		--start: 0%;
		--end: 10%;
		transform: rotate(0);
	}
	
	50% {
		--start: 10%;
		--end: 90%;
	}
	
	100% {
		--start: 90%;
		--end: 100%;
		transform: rotate(0.1turn);
	}
}

@keyframes rotate {
	from {
		transform: rotate(0turn);
	}
	
	to {
		transform: rotate(1turn);
	}
}

.icon_chargement{
    display: inline-block;
    text-align: center;
}

figure {
	--thickness: 20%;
	position: relative;
	width: 4em;
	height: 4em;
	color: #5485ec;
	animation: rotate 2.6s linear infinite;
	
	&::after {
		content: '';
		display: block;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 20, 80, 0.05);
		background-image: conic-gradient(
			transparent, 
			transparent var(--start), 
			currentcolor var(--start), 
			currentcolor var(--end), 
			transparent var(--end), 
			transparent);
		mask-image: radial-gradient(
			closest-side at center, 
			transparent, 
			transparent calc(100% - var(--thickness)), 
			white calc(100% - var(--thickness) + 1px));
		border-radius: 20rem;
		animation: spin 1.5s both linear infinite;
	}
}

</style>