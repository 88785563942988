<template>
  <div>
    <h1>Dates</h1>

    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'jours de semaine'" :link="'#semaine'" />
    <Button1 :text="'Les mois de l\'année'" :link="'#mois'" />
    <Button1 :text="'Le numéro des jours'" :link="'#jour_mois'" />
    <Button1 :text="'Écrire une date'" :link="'#write_date'" />

    <h3 class="title_subcontent" id="semaine">Les jours de la semaine</h3>
    <p class="text_no_margin">
      - Chaque jour de semaine est liée à un Kanji qui va représenter un jour et dont on va attacher une terminaison 曜日(youbi) = <span class="kanji_button" @click="showDrawKanji('曜', 'Tracé')">曜</span> (ヨウ)(you - sino-japonais) et <span class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</span> (び)(bi - purement japonais) qui veut dire "jour de la semaine".<br>
      - Les jours de la semaine commencent par Dimanche en japonais pour finir sur Samedi comme sur le calendrier américain.<br>
    </p>

    <table class="content-table combinations">
        <thead>
          <tr>
            <th></th>
            <th>Kanji Signification</th>
            <th>Mot complet</th>
            <th>Furigana</th>
            <th>Roomaji</th>
          </tr>
        </thead>
        <tr>
          <th>Dimanche</th>
          <td><span class="red_text">日</span>　(soleil)</td>
          <td>
            <div>
              <span class="red_text">日</span>曜日
            </div>
            <div class="kanji_bottom">
              <div class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</div>
              <div class="kanji_button" @click="showDrawKanji('曜', 'Tracé')">曜</div>
              <div class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</div>
            </div>
          </td>
          <td><span class="red_text">ニチ</span>ヨウび</td>
          <td><span class="red_text">nichi</span>youbi</td>
        </tr>
        <tr>
          <th>Lundi</th>
          <td><span class="red_text">月</span>　(lune)</td>
          <td>
            <div><span class="red_text">月</span>曜日</div>
            <div class="kanji_bottom">
              <div class="kanji_button" @click="showDrawKanji('月', 'Tracé')">月</div>
              <div class="kanji_button" @click="showDrawKanji('曜', 'Tracé')">曜</div>
              <div class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</div>
            </div>
          </td>
          <td><span class="red_text">ゲツ</span>ヨウび</td>
          <td><span class="red_text">getsu</span>youbi</td>
        </tr>
        <tr>
          <th>Mardi</th>
          <td><span class="red_text">火</span>　(feu)</td>
          <td>
            <div><span class="red_text">火</span>曜日</div>
            <div class="kanji_bottom">
              <div class="kanji_button" @click="showDrawKanji('火', 'Tracé')">火</div>
              <div class="kanji_button" @click="showDrawKanji('曜', 'Tracé')">曜</div>
              <div class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</div>
            </div>
          </td>
          <td><span class="red_text">カ</span>ヨウび</td>
          <td><span class="red_text">ka</span>youbi</td>
        </tr>
        <tr>
          <th>Mercredi</th>
          <td><span class="red_text">水</span>　(eau)</td>
          <td>
            <div><span class="red_text">水</span>曜日</div>
            <div class="kanji_bottom">
              <div class="kanji_button" @click="showDrawKanji('水', 'Tracé')">水</div>
              <div class="kanji_button" @click="showDrawKanji('曜', 'Tracé')">曜</div>
              <div class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</div>
            </div>
          </td>
          <td><span class="red_text">スイ</span>ヨウび</td>
          <td><span class="red_text">sui</span>youbi</td>
        </tr>
        <tr>
          <th>Jeudi</th>
          <td><span class="red_text">木</span>　(bois)</td>
          <td>
            <div><span class="red_text">木</span>曜日</div>
            <div class="kanji_bottom">
              <div class="kanji_button" @click="showDrawKanji('木', 'Tracé')">木</div>
              <div class="kanji_button" @click="showDrawKanji('曜', 'Tracé')">曜</div>
              <div class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</div>
            </div>
          </td>
          <td><span class="red_text">モク</span>ヨウび</td>
          <td><span class="red_text">moku</span>youbi</td>
        </tr>
        <tr>
          <th>Vendredi</th>
          <td><span class="red_text">金</span>　(métal)</td>
          <td>
            <div><span class="red_text">金</span>曜日</div>
            <div class="kanji_bottom">
              <div class="kanji_button" @click="showDrawKanji('金', 'Tracé')">金</div>
              <div class="kanji_button" @click="showDrawKanji('曜', 'Tracé')">曜</div>
              <div class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</div>
            </div>
          </td>
          <td><span class="red_text">キン</span>ヨウび</td>
          <td><span class="red_text">kin</span>youbi</td>
        </tr>
        <tr>
          <th>Samedi</th>
          <td><span class="red_text">土</span>　(terre)</td>
          <td>
            <div><span class="red_text">土</span>曜日</div>
            <div class="kanji_bottom">
              <div class="kanji_button" @click="showDrawKanji('土', 'Tracé')">土</div>
              <div class="kanji_button" @click="showDrawKanji('曜', 'Tracé')">曜</div>
              <div class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</div>
            </div>
          </td>
          <td><span class="red_text">ド</span>ヨウび</td>
          <td><span class="red_text">do</span>youbi</td>
        </tr>
    </table>

    <h3 class="title_subcontent" id="mois">Les mois de l'année</h3>
    <p class="text_no_margin">
      - Chaque mois est liée à un kansūji qui va representer un mois et dont on va attacher une terminaison <span class="kanji_button" @click="showDrawKanji('月', 'Tracé')">月</span>(gatsu - sino-japonais) = qui veux dire "lune" car un mois correspond à une lunaison.<br>
    </p>

    <table class="content-table combinations">
      <thead>
        <tr>
          <th></th>
          <th>kansūji</th>
          <th>Mot complet</th>
          <th>Furigana</th>
          <th>Roomaji</th>
        </tr>
      </thead>
      <tr>
        <th>Janvier</th>
        <td><span class="red_text">一</span>　(un)</td>
        <td><span class="red_text">一</span>月</td>
        <td><span class="red_text">イチ</span>ガツ</td>
        <td><span class="red_text">ichi</span>gatsu</td>
      </tr>
      <tr>
        <th>Février</th>
        <td><span class="red_text">ニ</span>　(deux)</td>
        <td><span class="red_text">ニ</span>月</td>
        <td><span class="red_text">ニ</span>ガツ</td>
        <td><span class="red_text">ni</span>gatsu</td>
      </tr>
      <tr>
        <th>Mars</th>
        <td><span class="red_text">三</span>　(trois)</td>
        <td><span class="red_text">三</span>月</td>
        <td><span class="red_text">サン</span>ガツ</td>
        <td><span class="red_text">san</span>gatsu</td>
      </tr>
      <tr>
        <th>Avril</th>
        <td><span class="red_text">四</span>　(quatre)</td>
        <td><span class="red_text">四</span>月</td>
        <td><span class="red_text">シ</span>ガツ</td>
        <td><span class="red_text">shi</span>gatsu</td>
      </tr>
      <tr>
        <th>Mai</th>
        <td><span class="red_text">五</span>　(cinq)</td>
        <td><span class="red_text">五</span>月</td>
        <td><span class="red_text">ゴ</span>ガツ</td>
        <td><span class="red_text">go</span>gatsu</td>
      </tr>
      <tr>
        <th>Juin</th>
        <td><span class="red_text">六</span>　(six)</td>
        <td><span class="red_text">六</span>月</td>
        <td><span class="red_text">ロク</span>ガツ</td>
        <td><span class="red_text">roku</span>gatsu</td>
      </tr>
      <tr>
        <th>Juillet</th>
        <td><span class="red_text">七</span>　(sept)</td>
        <td><span class="red_text">七</span>月</td>
        <td><span class="red_text">シチ</span>ガツ</td>
        <td><span class="red_text">shichi</span>gatsu</td>
      </tr>
      <tr>
        <th>Août</th>
        <td><span class="red_text">八</span>　(huit)</td>
        <td><span class="red_text">八</span>月</td>
        <td><span class="red_text">ハチ</span>ガツ</td>
        <td><span class="red_text">hachi</span>gatsu</td>
      </tr>
      <tr>
        <th>Septembre</th>
        <td><span class="red_text">九</span>　(neuf)</td>
        <td><span class="red_text">九</span>月</td>
        <td><span class="red_text">ク</span>ガツ</td>
        <td><span class="red_text">ku</span>gatsu</td>
      </tr>
      <tr>
        <th>Octobre</th>
        <td><span class="red_text">十</span>　(dix)</td>
        <td><span class="red_text">十</span>月</td>
        <td><span class="red_text">ジュウ</span>ガツ</td>
        <td><span class="red_text">juu</span>gatsu</td>
      </tr>
      <tr>
        <th>Novembre</th>
        <td><span class="red_text">十一</span>　(onze)</td>
        <td><span class="red_text">十一</span>月</td>
        <td><span class="red_text">ジュウイチ</span>ガツ</td>
        <td><span class="red_text">juuichi</span>gatsu</td>
      </tr>
      <tr>
        <th>Décembre</th>
        <td><span class="red_text">十ニ</span>　(douze)</td>
        <td><span class="red_text">十ニ</span>月</td>
        <td><span class="red_text">ジュウニ</span>ガツ</td>
        <td><span class="red_text">juni</span>gatsu</td>
      </tr>

    </table>

    <h3 class="title_subcontent" id="jour_mois">Le numéro des jours</h3>
    <p class="text_no_margin">
      - Chaque jour du mois est liée à un kansūji qui va representer un jour du mois et dont on va attacher une terminaison 日 (nichi - sino-japonaise).<br>
      - La prononciation suit la règle du "chiffre en sino-japonais" + "日 (nichi)" sauf exception pour les jours de 1 à 10, 14, 20 et 24 dont le chiffre est prononcé en purement japonais.<br>
      - Pour 30, misoka est une autre forme de prononciation, mais qui n'est plus utilisé.
    </p>

    <table class="content-table combinations">
      <thead>
        <tr>
          <th></th>
          <th>kansūji</th>
          <th>Mot complet</th>
          <th>Furigana</th>
          <th>Roomaji</th>
        </tr>
      </thead>
      <tr>
        <th>1er</th>
        <td><span class="red_text">一</span>　(un)</td>
        <td><span class="red_text">一日</span></td>
        <td><span class="red_text">ついたち</span></td>
        <td><span class="red_text">tsuitachi</span></td>
      </tr>
      <tr>
        <th>2</th>
        <td><span class="red_text">ニ</span>　(deux)</td>
        <td><span class="red_text">ニ日</span></td>
        <td><span class="red_text">ふつか</span></td>
        <td><span class="red_text">futsuka</span></td>
      </tr>
      <tr>
        <th>3</th>
        <td><span class="red_text">三</span>　(trois)</td>
        <td><span class="red_text">三日</span></td>
        <td><span class="red_text">みっか</span></td>
        <td><span class="red_text">mikka</span></td>
      </tr>
      <tr>
        <th>4</th>
        <td><span class="red_text">四</span>　(quatre)</td>
        <td><span class="red_text">四日</span></td>
        <td><span class="red_text">よっか</span></td>
        <td><span class="red_text">yokka</span></td>
      </tr>
      <tr>
        <th>5</th>
        <td><span class="red_text">五</span>　(cinq)</td>
        <td><span class="red_text">五日</span></td>
        <td><span class="red_text">いつか</span></td>
        <td><span class="red_text">itsuka</span></td>
      </tr>
      <tr>
        <th>6</th>
        <td><span class="red_text">六</span>　(six)</td>
        <td><span class="red_text">六日</span></td>
        <td><span class="red_text">むいか</span></td>
        <td><span class="red_text">muika</span></td>
      </tr>
      <tr>
        <th>7</th>
        <td><span class="red_text">七</span>　(sept)</td>
        <td><span class="red_text">七日</span></td>
        <td><span class="red_text">なのか</span></td>
        <td><span class="red_text">nanoka</span></td>
      </tr>
      <tr>
        <th>8</th>
        <td><span class="red_text">八</span>　(huit)</td>
        <td><span class="red_text">八日</span></td>
        <td><span class="red_text">ようか</span></td>
        <td><span class="red_text">youka</span></td>
      </tr>
      <tr>
        <th>9</th>
        <td><span class="red_text">九</span>　(neuf)</td>
        <td><span class="red_text">九日</span></td>
        <td><span class="red_text">ここのか</span></td>
        <td><span class="red_text">kokonoka</span></td>
      </tr>
      <tr>
        <th>10</th>
        <td><span class="red_text">十</span>　(dix)</td>
        <td><span class="red_text">十日</span></td>
        <td><span class="red_text">とうか</span></td>
        <td><span class="red_text">tooka</span></td>
      </tr>
      <tr>
        <th>11</th>
        <td>十一 (onze)</td>
        <td>十一日</td>
        <td>ジュウイチニチ</td>
        <td>juuichinichi</td>
      </tr>
      <tr>
        <th>12</th>
        <td>十ニ (douze)</td>
        <td>十ニ日</td>
        <td>ジュウニニチ</td>
        <td>juuninichi</td>
      </tr>
      <tr>
        <th>13</th>
        <td>十三 (treize)</td>
        <td>十三日</td>
        <td>ジュウサンニチ</td>
        <td>juusannichi</td>
      </tr>
      <tr>
        <th>14</th>
        <td><span class="red_text">十四</span> (quatorze)</td>
        <td><span class="red_text">十四日</span></td>
        <td><span class="red_text">ジュウよっか</span></td>
        <td><span class="red_text">juuyokka</span></td>
      </tr>
      <tr>
        <th>15</th>
        <td>十五 (quinze)</td>
        <td>十五日</td>
        <td>ジュウゴニチ</td>
        <td>juugonichi</td>
      </tr>
      <tr>
        <th>16</th>
        <td>十六 (seize)</td>
        <td>十六日</td>
        <td>ジュウロクニチ</td>
        <td>juurokunichi</td>
      </tr>
      <tr>
        <th>17</th>
        <td>十七 (dix-sept)</td>
        <td>十七日</td>
        <td>ジュウシチニチ</td>
        <td>juushichinichi</td>
      </tr>
      <tr>
        <th>18</th>
        <td>十八 (dix-huit)</td>
        <td>十八日</td>
        <td>ジュウハチニチ</td>
        <td>juuhachinichi</td>
      </tr>
      <tr>
        <th>19</th>
        <td>十九 (dix-neuf)</td>
        <td>十九日</td>
        <td>ジュウキュウニチ</td>
        <td>juukyuunichi</td>
      </tr>
      <tr>
        <th>20</th>
        <td><span class="red_text">ニ十</span> (vingt)</td>
        <td><span class="red_text">ニ十日</span></td>
        <td><span class="red_text">はつか</span></td>
        <td><span class="red_text">hatsuka</span></td>
      </tr>
      <tr>
        <th>21</th>
        <td>ニ十一 (vingt et un)</td>
        <td>ニ十一日</td>
        <td>ニジュウイチニチ</td>
        <td>nijuuichinichi</td>
      </tr>
      <tr>
        <th>22</th>
        <td>ニ十ニ (vingt-deux)</td>
        <td>ニ十ニ日</td>
        <td>ニジュウニニチ</td>
        <td>nijuuninichi</td>
      </tr>
      <tr>
        <th>23</th>
        <td>ニ十三 (vingt-trois)</td>
        <td>ニ十三日</td>
        <td>ニジュウサンニチ</td>
        <td>nijuusannichi</td>
      </tr>
      <tr>
        <th>24</th>
        <td><span class="red_text">ニ十四</span> (vingt-quatre)</td>
        <td><span class="red_text">ニ十四日</span></td>
        <td><span class="red_text">ニジュよっか</span></td>
        <td><span class="red_text">nijuuyokka</span></td>
      </tr>
      <tr>
        <th>25</th>
        <td>ニ十五 (vingt-cinq)</td>
        <td>ニ十五日</td>
        <td>ニジュウゴニチ</td>
        <td>nijuugonichi</td>
      </tr>
      <tr>
        <th>26</th>
        <td>ニ十六 (vingt-six)</td>
        <td>ニ十六日</td>
        <td>ニジュウロクニチ</td>
        <td>nijuurokunichi</td>
      </tr>
      <tr>
        <th>27</th>
        <td>ニ十七 (vingt-sept)</td>
        <td>ニ十七日</td>
        <td>ニジュウシチニチ</td>
        <td>nijuushichinichi</td>
      </tr>
      <tr>
        <th>28</th>
        <td>ニ十八 (vingt-huit)</td>
        <td>ニ十八日</td>
        <td>ニジュウハチニチ</td>
        <td>nijuuhachinichi</td>
      </tr>
      <tr>
        <th>29</th>
        <td>ニ十九 (vingt-neuf)</td>
        <td>ニ十九日</td>
        <td>ニジュウキュウニチ</td>
        <td>nijuukyuunichi</td>
      </tr>
      <tr>
        <th>30</th>
        <td>三十 (trente)</td>
        <td>三十日</td>
        <td>サンジュウニチ (みそか)</td>
        <td>sanjuunichi (misoka)</td>
      </tr>
      <tr>
        <th>31</th>
        <td>三十一 (trente et un)</td>
        <td>三十一日</td>
        <td>サンジュウイチニチ</td>
        <td>sanjuuichinichi</td>
      </tr>
    </table>

    <h3 class="title_subcontent" id="write_date">Écrire une date</h3>
    <h4 class="title_no_bottom">Dates</h4>
    <p class="text_no_margin">
      - le kanji pour l'année est <span class="kanji_button" @click="showDrawKanji('年', 'Tracé')">年</span> ((とし)toshi - purement japonais) et ((ネン)nen - sino-japonais)<br>
      - les dates s'écrivent de cette façon en japonais "Année Mois Jour" contrairement au français qui est "Jour Mois Année"<br>
      <span class="text_li">- Japonais: aaaa mm jj</span><br>
      <span class="text_li">- Français: jj mm aaaa</span><br>
      <span class="text_li">- Americains: mm jj aaaa</span><br>
    </p>
    <p class="box_example">
      ces deux systèmes sont utilisés:<br>
      <span class="text_li">2020年 12月　24日 木曜日 = Jeudi 24 décembre 2020</span><br>
      <span class="text_li">ニ〇ニ〇年 十ニ月 ニ十四日 = Jeudi 24 décembre 2020</span><br>
    </p>

    <h4 class="title_no_bottom">Dates avec les ères japonaises</h4>
    <p class="text_no_margin">
      - À chaque fois qu'il y a un nouvel empereur qui monte sur le trône au Japon, il y a une nouvelle ère qui commence.<br> 
      - Voici une liste des principales ères japonaises :<br>
      <span class="text_li">- 1868 à 1911: <span class="kanji_button" @click="showDrawKanji('明', 'Tracé')">明</span><span class="kanji_button" @click="showDrawKanji('治', 'Tracé')">治</span> (Ère Meiji)</span><br>
      <span class="text_li">- 1912 à 1925: <span class="kanji_button" @click="showDrawKanji('大', 'Tracé')">大</span><span class="kanji_button" @click="showDrawKanji('正', 'Tracé')">正</span> (Ère Taishō)</span><br>
      <span class="text_li">- 1926 à 1988: <span class="kanji_button" @click="showDrawKanji('昭', 'Tracé')">昭</span><span class="kanji_button" @click="showDrawKanji('和', 'Tracé')">和</span> (Ère Shōwa)</span><br>
      <span class="text_li">- 1989 à 2018: <span class="kanji_button" @click="showDrawKanji('平', 'Tracé')">平</span><span class="kanji_button" @click="showDrawKanji('成', 'Tracé')">成</span> (Ère Heisei)</span><br>
      <span class="text_li">- 2019 à aujourd'hui: <span class="kanji_button" @click="showDrawKanji('令', 'Tracé')">令</span><span class="kanji_button" @click="showDrawKanji('和', 'Tracé')">和</span> (Ère Reiwa)</span><br>
      - On peut ainsi remplacer l'année par l'ère pour donner une date.<br>
      - Pour trouver la date en ère japonaise, il faudra prendre la date de début d'une ère puis soustraire à l'année actuel et ajouter +1. Par exemple pour 2021 en comptant à partir de l'ère Heisei. On fait 2021 (année actuel) - 1989 (début Heisei) + 1 = 平成 (ère) 33年 (année).
      Pour résumer:<br>
    </p>
    <p class="box_example">
      <span class="text_li">(année du calendrier grégorien) - (année de début de l'ère japonaise) + 1 = année exprimée selon l'ère japonaise</span><br>
      <span class="text_li">2021 - 2019 + 1 = 3 令和 (ère Reiwa)</span><br>
      <span class="text_li">(année exprimé selon l'ère japonaise) + (année de début de l'ère japonaise) -1 = année du calendrier grégorien</span><br>
      <span class="text_li">3 + 2019 - 1 = 2021 (Calendrier grégorien)</span><br>
    </p>
    <p class="box_example">
      <span class="text_li">令和3年 12月　24日 木曜日 = Jeudi 24 décembre 2020 (à partir de l'ère Reiwa, de préférence on utilise l'ère la plus récente, soit celle-ci)</span><br>
      <span class="text_li">平成33年 12月　24日 木曜日 = Jeudi 24 décembre 2020 (à partir de l'ère Heisei)</span><br>
    </p>
    <p class="text_no_margin">
      - Lors qu'on veut dire la première date de début d'une ère il faut utiliser le kanji 元 (gen - sino-japonaise) qui veut dire origine, 1er anné de l'ère.
    </p>
    <p class="box_example">
      <span class="text_li">令和元年 12月　26日 木曜日 = Jeudi 26 décembre 2019 (à partir de l'ère Reiwa, date de départ de l'ère)</span><br>
    </p>

    <h4 class="title_no_bottom">Dates avec les siècles</h4>
    <p class="text_no_margin">
      - Pour dire siècle on utilise ces kanji "<span class="kanji_button" @click="showDrawKanji('世', 'Tracé')">世</span><span class="kanji_button" @click="showDrawKanji('紀', 'Tracé')">紀</span>" (seiki)
    </p>
    <p class="box_example">
      <span class="text_li">5ème siècle = 五 (go (cinq)) + 世紀 (seiki (siècle)) = 五世紀 (goseiki)</span><br>
    </p>

    <Button1 :text="'Liste complète de toutes les ères japonaises'" :target="'_blank'" :link="'https://fr.wikipedia.org/wiki/Ères_du_Japon'" />

    <KanjiDraw v-if="kanjiDrawCurrent" :title="kanjiDrawCurrentTitle" :kanji="kanjiDrawCurrent" @endKanjiDraw="endPopupKanjiDraw"/>
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import KanjiDraw from "@/components/Composants/KanjiDraw.vue";

export default {
  name: 'Date',
  components: {
    Button1,
    KanjiDraw
  },
  data() {
    return {
      kanjiDrawCurrent: '',
      kanjiDrawCurrentTitle: '',
    };
  },
  methods:{
    // KanjiDraw Method
    showDrawKanji(kanji, title){
      this.kanjiDrawCurrent = kanji;
      this.kanjiDrawCurrentTitle = title;
    },
    // event
    endPopupKanjiDraw(){
      this.kanjiDrawCurrent = ''
    },
  }
}
</script>

<style lang="scss" scoped>
/*table*/
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.combinations th {
  background-color: #009879;
  color: #ffffff;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
/* kanji draw button*/
.kanji_bottom{
  display: flex;
  justify-content: center;
}
.kanji_button{
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 1px;
  &:hover{
    opacity: 0.75;
    background: rgb(221 221 221 / 50%);
  }
}
</style>