<template>
  <div>
    <h1>Annexes - Liens externes</h1>
    <ul class="introduction">
      <li class="entete"><h4 class="title_no_bottom">Introducation à la langue japonaise</h4></li>
      <li>
        - Introduction à la langue japonaise : 
        <Button1 :text="'Apprendre le japonais en une vidéo (Tev - ici japon)'" :target="'_blank'" :link="'https://www.youtube.com/watch?v=o22o2URXJAM'" />
      </li>
    </ul>
    <ul class="cours">
        <li class="entete"><h4 class="title_no_bottom">Cours de Japonais</h4></li>
        <li>
          - Cours de japonais en français sur youtube :
          <Button1 :text="'Cours de japonais par Julien Fontanier'" :target="'_blank'" :link="'https://www.youtube.com/c/CoursdejaponaisFR/featured'" />
        </li>
        <li>
          - Cours de japonais en Anglais sur youtube :
          <Button1 :text="'Japanese Ammo with Misa'" :target="'_blank'" :link="'https://www.youtube.com/c/JapaneseAmmowithMisa/playlists'" />
        </li>
        <li>
          - Site d'apprentisage du japonais : 
          <Button1 :text="'Japan Activator'" :target="'_blank'" :link="'https://www.japan-activator.com/fr'" />
        </li>
    </ul>
    <ul class="cours">
        <li class="entete"><h4 class="title_no_bottom">Lecture</h4></li>
        <li>
          - Lecture de texte japonais (histoire, news, etc) en Kanji avec traduction, Rōmaji, etc pour apprendre le japonais à travers différent thème de lecture :
          <Button1 :text="'Satori Reader'" :target="'_blank'" :link="'https://www.satorireader.com'" />
        </li>
    </ul>
    <ul class="dictionnaire">
        <li class="entete"><h4 class="title_no_bottom">Dictionnaires</h4></li>
        <li>
          - Dictionnaire en ligne avec recherche de mot Français et Japonais :
          <Button1 :text="'Dictionnaire-japonais'" :target="'_blank'" :link="'http://www.dictionnaire-japonais.com'" />
        </li>
        <li>
          - Dictionnaire en ligne avec recherche de mot Anglais, Japonais et Roomaji. Permet aussi de visualiser le Tracé des Kanji et leur lecture On et Kun :
          <Button1 :text="'Jisho'" :target="'_blank'" :link="'https://jisho.org'" />
        </li>
        <li>
          - Dictionnaire de conjugaison des verbes :
          <Button1 :text="'japaneseverbconjugator'" :target="'_blank'" :link="'http://www.japaneseverbconjugator.com'" />
          <Button1 :text="'jlearn'" :target="'_blank'" :link="'https://jlearn.net'" />
        </li>
        <li>
          - Wikipédia en Japonais donne souvent le nom exact du mot, il est très pratique pour tout ce qui est noms, prénoms de personnes, pays, etc :
          <Button1 :text="'Exemple avec Charles de Gaulle écrit en Katakana'" :target="'_blank'" :link="'https://ja.wikipedia.org/wiki/シャルル・ド・ゴール'" />
        </li>
        <li>
          - Dictionnaire Électronique, peut être pratique sur place au japon, pour pouvoir traduire des mots. Ils sont assez compacts et peuvent être transporté :
          <Button1 :text="'Dictionnaire Électronique'" :target="'_blank'" :link="'https://www.google.com/search?client=opera&q=dictionnaire+electronique+japonais&sourceid=opera&ie=UTF-8&oe=UTF-8'" />
        </li>
        <li>
          - Recherche en ligne de Kanji par tracé
          <Button1 :text="'Handwritten kanji search'" :target="'_blank'" :link="'https://kanji.sljfaq.org'" />
        </li>
    </ul>
    <ul class="traduction">
      <li class="entete"><h4 class="title_no_bottom">Traduction</h4></li>
      <li>
        - Pour les Traductions, vous pouvez utiliser Google Translate mais seulement pour des mots. Évitez de mettre des phrases, plus elles seront longues et plus elles seront mal traduites.<br>
        Éviter aussi le Français au Japonais ou inversement car google translate convertit d'abord votre mot Français en Anglais pour ensuite Tranduire Anglais au Japonais, ce qui créé une étape de traduction supplémentaire : 
        <Button1 :text="'Google Translate: (Anglais -> Japonais) ou inversement'" :target="'_blank'" :link="'https://translate.google.fr/?hl=fr&sl=en&tl=ja&op=translate'" />
      </li>
      <li>
        - L'application Google Translate possède la fonctionnalité de prendre en photo quelque chose, puis de sélectionner dans la photo le texte qu'on veut traduire.<br>
        <Button1 :text="'Google Translate application PlayStore'" :target="'_blank'" :link="'https://play.google.com/store/apps/details?id=com.google.android.apps.translate&hl=fr&gl=US'" /><br>
        <Button1 :text="'Google Translate application AppStore'" :target="'_blank'" :link="'https://apps.apple.com/fr/app/google-traduction/id414706506'" />
      </li>
      <li>
        - N'hésitez pas à faire une recherche du mot japonais sur Google Image, ceci vous donnera une idée du mot associé à son image
        <Button1 :text="'Google Image'" :target="'_blank'" :link="'https://images.google.com/imghp?hl=fr&gl=fr&gws_rd=ssl'" />
      </li>
    </ul>
    <ul class="jlpt">
      <li class="entete"><h4 class="title_no_bottom">JLPT (Japanese-Language Proficiency Test)</h4></li>
      <li>
        - Le JLPT est un peu comme le TOIEC pour Anglais. Il atteste votre niveau en japonais.<br>
        - Ils existent 5 niveaux de JLPT. N5 débutant à N1 Billingue.<br>
        - Les niveaux N4 et N3 correspondent à un niveau acceptable pour parler et lire dans la vie de tous les jours<br>
        - Les niveaux N2 et N1 correspondent à un niveau avancé du japonais et ils peuvent être requis pour certains métier au Japon.<br>
        - Voici une liste des points de grammaire, vocabulaire, kanji, etc par niveau de JLPT:
        <Button1 :text="'JLPT liste des choses à savoir par niveau'" :target="'_blank'" :link="'http://jlptgo.com'" />
      </li>
    </ul>
    <ul class="flashcard">
      <li class="entete"><h4 class="title_no_bottom">Flashcard</h4></li>
      <li>
        - Quizlet est un outil de flashcard pour travailler son vocabulaire. <br>
        Il y a une synchronisation des flashcards entre le site web et l'application mobile<br>
        Il y aussi une lecture audio des flashcards dans n'importe quel langue
        <Button1 :text="'Quizlet'" :target="'_blank'" :link="'https://quizlet.com'" />
      </li>
    </ul>
    <ul class="application">
      <li class="entete"><h4 class="title_no_bottom">Application Android</h4></li>
      <li>
        - Application pour apprendre à reconnaitre et tracer les Kana et Kanji
        <Button1 :text="'Japanese Kanji Study'" :target="'_blank'" :link="'https://play.google.com/store/apps/details?id=com.mindtwisted.kanjistudy&hl=en&gl=US'" />
      </li>
    </ul>
    <ul class="animation">
      <li class="entete"><h4 class="title_no_bottom">Animation</h4></li>
      <li>
        - Est-ce que les animés japonais peuvent aider à apprendre le japonais ? réponse reddit sur le sujet:
        <Button1 :text="'Reddit: Misconception: You can\'t learn Japanese from anime'" :target="'_blank'" :link="'https://www.reddit.com/r/anime/comments/35kwqr/misconception_you_cant_learn_japanese_from_anime/'" /><br>
        Oui les animés peuvent être utile mais seulement après avoir vu aux moins les 2 premier thème en entier "Comprendre l'écriture et la prononciation" et "Comprendre la grammaire" de ce fait on pourra comprendre la construction des phrases et élargir le vocabulaire à travers les animés. De plus les acteurs parle très clairement dans la pluspart des animés, ce qui n'est peut-être pas toujours le cas en face à face avec des personnes.<br>
      </li>
      <li>
        - Site d'animé avec sous titre japonais (kanji, katakana, hiragana, roomaji et sous titre anglais)
        <Button1 :text="'Animelon'" :target="'_blank'" :link="'https://animelon.com'" />
      </li>
    </ul>
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";

export default {
  name: 'Link',
  components: {
    Button1
  }
}
</script>

<style scoped lang="scss">
ul{
  margin-bottom: 10px;
  li:not(.entete){ // all li except entete
    //padding: 15px;
    /*a{
      margin: 5px 10px;
      padding: 10px;
      border: solid 1px #ddd;
      border-radius: 15px;
      color: black;
      background: white;
      display: inline-block;
      &:hover{
        background:cornflowerblue;
        color: white;
      }
    }*/
  }
}
</style>