<template>
  <div class="box_example">
    <div v-if="positionGrid" class="box_grid">
      <div v-if="kanji" class="kanji">
        <div class="sub_title"><i class="fas fa-book"></i></div>
        <span v-html="kanji"></span>
      </div>
      <div class="kana_example sub_box">
        <div v-if="kana" class="kana">
          <div class="sub_title"><i class="fas fa-book-open"></i></div>
          <span v-html="kana"></span>
        </div>
        <div v-if="roomaji" class="roomaji">
          <div class="sub_title"><i class="fas fa-bookmark"></i></div>
          <span v-html="roomaji"></span>
        </div>
        <div v-if="trad" class="trad">
          <div class="sub_title"><i class="fas fa-audio-description"></i></div>
          <span v-html="trad"></span>
        </div>
      </div>
      <div v-if="explication" class="explication sub_box">
        <div class="sub_title"><i class="fas fa-question-circle"></i></div>
        <span v-html="explication"></span>
      </div>
    </div>
    <div v-else class="box_list">
      <div v-if="kanji" class="kanji sub_box">
        <div class="sub_title"><i class="fas fa-book"></i></div>
        <span v-html="kanji"></span>
        <div v-if="kanjiraw" class="kanji_bottom">
          <div class="sub_title"><i class="fas fa-book"></i></div>
          <div v-for="(kanjiraw,index) in kanjiraw.replaceAll(/\s/g,'').replace(/[{()}]/g, '').split('')" :key="index">
              <div class="kanji_button" @click="showDrawKanji(kanjiraw, 'Tracé de')">{{kanjiraw}}</div>
            </div>
        </div>
      </div>
      <div v-else-if="(kanji == null || kanji == '') && kanjiraw" class="kanji sub_box">
        <div class="kanji_bottom">
          <div class="sub_title"><i class="fas fa-book"></i></div>
          <div v-for="(kanjiraw,index) in kanjiraw.replaceAll(/\s/g,'').replace(/[{()}]/g, '').split('')" :key="index">
              <div class="kanji_button" @click="showDrawKanji(kanjiraw, 'Tracé de')">{{kanjiraw}}</div>
            </div>
        </div>
      </div>
      <div v-if="kana" class="kana sub_box">
        <div class="sub_title"><i class="fas fa-book-open"></i></div>
        <span v-html="kana"></span>
      </div>
      <div v-if="roomaji" class="roomaji sub_box">
        <div class="sub_title"><i class="fas fa-bookmark"></i></div>
        <span v-html="roomaji"></span>
      </div>
      <div v-if="trad" class="trad sub_box">
        <div class="sub_title"><i class="fas fa-audio-description"></i></div>
        <span v-html="trad"></span>
      </div>
      <br v-if="trad || roomaji || kana || kanji || kanjiraw">
      <div v-if="explication" class="explication sub_box">
        <div class="sub_title"><i class="fas fa-question-circle"></i></div>
        <span v-html="explication"></span>
      </div>
    </div>

    <KanjiDraw v-if="kanjiDrawCurrent" :title="kanjiDrawCurrentTitle" :kanji="kanjiDrawCurrent" @endKanjiDraw="endPopupKanjiDraw"/>

  </div>
</template>

<script>
import KanjiDraw from "@/components/Composants/KanjiDraw.vue";

export default {
  name: "BoxExample",
  props: ["kanji","kanjiraw", "kana", "roomaji", "trad", "explication"],
  data(){
    return{
      positionGrid: false,
      kanjiDrawCurrent: '',
      kanjiDrawCurrentTitle: '',
    }
  },
  components: {
    KanjiDraw
  },
  methods:{
    // KanjiDraw Method
    showDrawKanji(kanji, title){
      this.kanjiDrawCurrent = kanji;
      this.kanjiDrawCurrentTitle = title;
    },
    // event
    endPopupKanjiDraw(){
      this.kanjiDrawCurrent = ''
    },
  }
};
</script>

<style scoped lang="scss">
.box_example{
  margin: 5px 10px 5px 0px;
  //border: 1px solid #8fb3e0;
  border: solid 1px #ddd;
  padding: 0px;
  //background: #edf1f7;
  border-radius: 5px;
}
.sub_title{
  color: var(--first-color);
}

/* Position GRID */
.box_grid{
  .kanji{
    padding: 3px 5px;
  }
  .sub_title{
    margin-right: 10px;
    width: 20px;
    float: left;
  }
  .sub_box{
    //background-color: #FFFFFF;
    border-radius: 5px;
    margin: 3px 0px;
    padding: 3px 5px;
  }
}

/* Position List */
.box_list{
  .kanji{
    padding: 3px 5px;
  }
  .sub_title{
    margin-right: 5px;
    float: left;
  }
  .sub_box{
    //background-color: #FFFFFF;
    border-radius: 5px;
    margin: 3px 0px;
    padding: 0px 5px;
    display: inline-block;
    &.explication{
      padding: 0px 5px;
      margin: 0px;
    }
  }
}

/* kanji draw button*/
.kanji_bottom{
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.kanji_button{
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 1px;
  &:hover{
    opacity: 0.75;
    background: rgb(221 221 221 / 50%);
  }
}
</style>