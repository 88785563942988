<template>
  <div>
      <h1>Lire l'heure</h1>

      <h3 class="title_subcontent">Accès rapide</h3>
      <Button1 :text="'Heures'" :link="'#heures'" />
      <Button1 :text="'Minutes'" :link="'#minutes'" />
      <Button1 :text="'Secondes'" :link="'#secondes'" />
      <Button1 :text="'Donner une heure'" :link="'#donner_heure'" />

      <h3 class="title_subcontent" id="heures">Heures</h3>
      <p class="text_no_margin">
        <span class="kanji_button" @click="showDrawKanji('時', 'Tracé')">時</span> = とき (toki) lecture purement japonais ou ジ (ji) lecture sino-japonaise.<br>
        Ce kanji signifie l'heure en lecture sino-japonaise et aussi l'idée de temps en général, en lecture purement japonaise.<br>
        Le Japon utilise le même système d'heure que les anglophones, avec des paliers de 12h matin ou 12h midi.<br>
        Pour donner une heure on va préciser si c'est avant midi ou après midi, puis donner l'heure en combinant le chiffre et le kanji de l'heure 時 (ji).<br>
        Les différents moments de journé<br>
        <span class="text_li">- Avant midi:  
          <span class="kanji_button" @click="showDrawKanji('午', 'Tracé')">午</span>
          <span class="kanji_button" @click="showDrawKanji('前', 'Tracé')">前</span> = ゴゼン (gosen)
        </span><br>
        <span class="text_li">- Après midi:  
          <span class="kanji_button" @click="showDrawKanji('午', 'Tracé')">午</span>
          <span class="kanji_button" @click="showDrawKanji('後', 'Tracé')">後</span> = ゴゴ (gogo)
        </span><br>
        Les exceptions de prononciation<br>
        <span class="text_li">- Pour 12h, il n'y a pas besoin de préciser 午前 (gosen) ou 午後 (gogo)</span><br>
        <span class="text_li">- Pour 0h, 午前 (gosen) est optionel</span><br>
        Autres vocabulaires<br>
        <span class="text_li">- midi: 
          <span class="kanji_button" @click="showDrawKanji('正', 'Tracé')">正</span>
          <span class="kanji_button" @click="showDrawKanji('午', 'Tracé')">午</span> = ショウゴ (shougo)
        </span><br>
        <span class="text_li">- minuit: 
          <span class="kanji_button" @click="showDrawKanji('真', 'Tracé')">真</span>
          <span class="kanji_button" @click="showDrawKanji('夜', 'Tracé')">夜</span>
          <span class="kanji_button" @click="showDrawKanji('中', 'Tracé')">中</span> = まよなか (mayonaka)
        </span><br>
      </p>
      <table class="content-table nombres" id="nombres">
        <thead>
          <tr>
            <th>Heure</th>
            <th>Kanji</th>
            <th>Furigana</th>
            <th>Roomaji</th>
          </tr>
        </thead>
        <tr>
          <th>1h</th>
          <td><span class="blue_text">午前</span><span class="red_text">一</span>時</td>
          <td><span class="blue_text">ゴゼン</span><span class="red_text">イチ</span>ジ</td>
          <td><span class="blue_text">gozen</span> <span class="red_text">ichi</span>ji</td>
        </tr>
        <tr>
          <th>2h</th>
          <td><span class="blue_text">午前</span><span class="red_text">ニ</span>時</td>
          <td><span class="blue_text">ゴゼン</span><span class="red_text">ニ</span>ジ</td>
          <td><span class="blue_text">gozen</span> <span class="red_text">ni</span>ji</td>
        </tr>
        <tr>
          <th>3h</th>
          <td><span class="blue_text">午前</span><span class="red_text">三</span>時</td>
          <td><span class="blue_text">ゴゼン</span><span class="red_text">サン</span>ジ</td>
          <td><span class="blue_text">gozen</span> <span class="red_text">san</span>ji</td>
        </tr>
        <tr>
          <th>4h</th>
          <td><span class="blue_text">午前</span><span class="red_text">四</span>時</td>
          <td><span class="blue_text">ゴゼン</span><span class="red_text">よ</span>ジ</td>
          <td><span class="blue_text">gozen</span> <span class="red_text">yo</span>ji</td>
        </tr>
        <tr>
          <th>5h</th>
          <td><span class="blue_text">午前</span><span class="red_text">五</span>時</td>
          <td><span class="blue_text">ゴゼン</span><span class="red_text">ゴ</span>ジ</td>
          <td><span class="blue_text">gozen</span> <span class="red_text">go</span>ji</td>
        </tr>
        <tr>
          <th>6h</th>
          <td><span class="blue_text">午前</span><span class="red_text">六</span>時</td>
          <td><span class="blue_text">ゴゼン</span><span class="red_text">ロク</span>ジ</td>
          <td><span class="blue_text">gozen</span> <span class="red_text">roku</span>ji</td>
        </tr>
        <tr>
          <th>7h</th>
          <td><span class="blue_text">午前</span><span class="red_text">七</span>時</td>
          <td><span class="blue_text">ゴゼン</span><span class="red_text">シチ</span>ジ</td>
          <td><span class="blue_text">gozen</span> <span class="red_text">shichi</span>ji</td>
        </tr>
        <tr>
          <th>8h</th>
          <td><span class="blue_text">午前</span><span class="red_text">八</span>時</td>
          <td><span class="blue_text">ゴゼン</span><span class="red_text">ハチ</span>ジ</td>
          <td><span class="blue_text">gozen</span> <span class="red_text">hachi</span>ji</td>
        </tr>
        <tr>
          <th>9h</th>
          <td><span class="blue_text">午前</span><span class="red_text">九</span>時</td>
          <td><span class="blue_text">ゴゼン</span><span class="red_text">ク</span>ジ</td>
          <td><span class="blue_text">gozen</span> <span class="red_text">ku</span>ji</td>
        </tr>
        <tr>
          <th>10h</th>
          <td><span class="blue_text">午前</span><span class="red_text">十</span>時</td>
          <td><span class="blue_text">ゴゼン</span><span class="red_text">ジュウ</span>ジ</td>
          <td><span class="blue_text">gozen</span> <span class="red_text">juu</span>ji</td>
        </tr>
        <tr>
          <th>11h</th>
          <td><span class="blue_text">午前</span><span class="red_text">十一</span>時</td>
          <td><span class="blue_text">ゴゼン</span><span class="red_text">ジュウイチ</span>ジ</td>
          <td><span class="blue_text">gozen</span> <span class="red_text">juuichi</span>ji</td>
        </tr>
        <tr>
          <th>12h</th>
          <td><span class="red_text">十二</span>時</td>
          <td><span class="red_text">ジュウニ</span>ジ</td>
          <td><span class="red_text">juuni</span>ji</td>
        </tr>
        <tr>
          <th>13h</th>
          <td><span class="blue_text">午後</span><span class="red_text">一</span>時</td>
          <td><span class="blue_text">ゴゴ</span><span class="red_text">イチ</span>ジ</td>
          <td><span class="blue_text">gogo</span> <span class="red_text">ichi</span>ji</td>
        </tr>
        <tr>
          <th>14h</th>
          <td><span class="blue_text">午後</span><span class="red_text">二</span>時</td>
          <td><span class="blue_text">ゴゴ</span><span class="red_text">ニ</span>ジ</td>
          <td><span class="blue_text">gogo</span> <span class="red_text">ni</span>ji</td>
        </tr>
        <tr>
          <th>15h</th>
          <td><span class="blue_text">午後</span><span class="red_text">三</span>時</td>
          <td><span class="blue_text">ゴゴ</span><span class="red_text">サン</span>ジ</td>
          <td><span class="blue_text">gogo</span> <span class="red_text">san</span>ji</td>
        </tr>
        <tr>
          <th>16h</th>
          <td><span class="blue_text">午後</span><span class="red_text">四</span>時</td>
          <td><span class="blue_text">ゴゴ</span><span class="red_text">よ</span>ジ</td>
          <td><span class="blue_text">gogo</span> <span class="red_text">yo</span>ji</td>
        </tr>
        <tr>
          <th>17h</th>
          <td><span class="blue_text">午後</span><span class="red_text">五</span>時</td>
          <td><span class="blue_text">ゴゴ</span><span class="red_text">ゴ</span>ジ</td>
          <td><span class="blue_text">gogo</span> <span class="red_text">go</span>ji</td>
        </tr>
        <tr>
          <th>18h</th>
          <td><span class="blue_text">午後</span><span class="red_text">六</span>時</td>
          <td><span class="blue_text">ゴゴ</span><span class="red_text">ロク</span>ジ</td>
          <td><span class="blue_text">gogo</span> <span class="red_text">roku</span>ji</td>
        </tr>
        <tr>
          <th>19h</th>
          <td><span class="blue_text">午後</span><span class="red_text">七</span>時</td>
          <td><span class="blue_text">ゴゴ</span><span class="red_text">シチ</span>ジ</td>
          <td><span class="blue_text">gogo</span> <span class="red_text">shichi</span>ji</td>
        </tr>
        <tr>
          <th>20h</th>
          <td><span class="blue_text">午後</span><span class="red_text">八</span>時</td>
          <td><span class="blue_text">ゴゴ</span><span class="red_text">ハチ</span>ジ</td>
          <td><span class="blue_text">gogo</span> <span class="red_text">hachi</span>ji</td>
        </tr>
        <tr>
          <th>21h</th>
          <td><span class="blue_text">午後</span><span class="red_text">九</span>時</td>
          <td><span class="blue_text">ゴゴ</span><span class="red_text">ク</span>ジ</td>
          <td><span class="blue_text">gogo</span> <span class="red_text">ku</span>ji</td>
        </tr>
        <tr>
          <th>22h</th>
          <td><span class="blue_text">午後</span><span class="red_text">十</span>時</td>
          <td><span class="blue_text">ゴゴ</span><span class="red_text">ジュウ</span>ジ</td>
          <td><span class="blue_text">gogo</span> <span class="red_text">juu</span>ji</td>
        </tr>
        <tr>
          <th>23h</th>
          <td><span class="blue_text">午後</span><span class="red_text">十一</span>時</td>
          <td><span class="blue_text">ゴゴ</span><span class="red_text">ジュウイチ</span>ジ</td>
          <td><span class="blue_text">gogo</span> <span class="red_text">juuichi</span>ji</td>
        </tr>
        <tr>
          <th>0h</th>
          <td><span class="blue_text">(午前)</span><span class="red_text">霊</span>時</td>
          <td><span class="blue_text">(ゴゼン)</span><span class="red_text">レイ</span>ジ</td>
          <td><span class="blue_text">(gozen)</span> <span class="red_text">rei</span>ji</td>
        </tr>
      </table>

      <h3 class="title_subcontent" id="minutes">Minutes</h3>
      <p class="text_no_margin">
        Le kanji des Minutes est <span class="kanji_button" @click="showDrawKanji('分', 'Tracé')">分</span> dont la lecture sino-japonaise est フン (fun) ou プン (pun) suivant le nombre qui précède.<br>
        Liste des prononciations des minutes suivant leur terminaison.<br>
        En rouge correspond à toutes les terminaisons des minutes en プン (pun) et en bleu en フン (fun).<br>
        <span class="text_li red_text">- 1 min: 一分 - イップン (ippun)</span><br>
        <span class="text_li blue_text">- 2 min: ニ分 - ニフン (nifun)</span><br>
        <span class="text_li red_text">- 3 min: 三分 - サンプン (sanpun)</span><br>
        <span class="text_li red_text">- 4 min: 四分 - よんプン (yonpun)</span><br>
        <span class="text_li blue_text">- 5 min: 五分 - ゴフン (gofun)</span><br>
        <span class="text_li red_text">- 6 min: 六分 - ロップン (roppun)</span><br>
        <span class="text_li blue_text">- 7 min: 七分 - ななフン (nanafun)</span><br>
        <span class="text_li red_text">- 8 min: 八分 - ハップン (happun)</span><br>
        <span class="text_li blue_text">- 9 min: 九分 - キュウフン (kyuufun)</span><br>
        <span class="text_li red_text">- 10 min: 十分 - ジュップン (juppun)</span><br>
        Pour 30min on peut utiliser le kanji 半 - ハン (han) qui veut dire "et demie"<br>
      </p>
      <!--<p class="box_example">
        <span class="blue_text">午後</span><span class="red_text">四</span>時<span class="green_text">三十分</span> = <span class="blue_text">gogo</span> <span class="red_text">yo</span>ji <span class="green_text">sanjuupun</span> = <span class="red_text">16</span>h<span class="green_text">30</span><br>
        <span class="blue_text">午後</span><span class="red_text">四</span>時<span class="green_text">半</span> = <span class="blue_text">gogo</span> <span class="red_text">yo</span>ji <span class="green_text">han</span> = <span class="red_text">16</span>h<span class="green_text"> et demie</span><br>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'blue_text\'>午後</span><span class=\'red_text\'>四</span>時<span class=\'green_text\'>三十分</span>'"
        :kanjiraw="'午後四時三十分'"
        :roomaji="'<span class=\'blue_text\'>gogo</span> <span class=\'red_text\'>yo</span>ji <span class=\'green_text\'>sanjuupun</span>'"
        :trad="'<span class=\'red_text\'>16</span>h<span class=\'green_text\'>30</span>'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'<span class=\'blue_text\'>午後</span><span class=\'red_text\'>四</span>時<span class=\'green_text\'>半</span>'"
        :kanjiraw="'午後四時半'"
        :roomaji="'<span class=\'blue_text\'>gogo</span> <span class=\'red_text\'>yo</span>ji <span class=\'green_text\'>han</span>'"
        :trad="'<span class=\'red_text\'>16</span>h<span class=\'green_text\'> et demie</span>'" 
        :explication="''"
      />

      <h3 class="title_subcontent" id="secondes">Secondes</h3>
      <p class="text_no_margin">
        Le kanji des Secondes est <span class="kanji_button" @click="showDrawKanji('秒', 'Tracé')">秒</span> dont la lecture sino-japonaise est ビョウ (byou).<br>
      </p>
      <!--<p class="box_example">
        <span class="green_text">午前7時</span><span class="blue_text">46分</span><span class="red_text">53秒</span>  = <span class="green_text">午前七時</span><span class="blue_text">四十六分</span><span class="red_text">五十三秒</span> = <span class="green_text">gozen shichiji</span> <span class="blue_text">yonjuuroppun</span> <span class="red_text">gojuusanbyou</span> = <span class="green_text">7h</span> <span class="blue_text">46min</span> <span class="red_text">53s</span><br>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'green_text\'>午前7時</span><span class=\'blue_text\'>46分</span><span class=\'red_text\'>53秒</span> OU <span class=\'green_text\'>午前七時</span><span class=\'blue_text\'>四十六分</span><span class=\'red_text\'>五十三秒</span>'"
        :kanjiraw="'午前七時四十六分五十三秒'"
        :roomaji="'<span class=\'green_text\'>gozen shichiji</span> <span class=\'blue_text\'>yonjuuroppun</span> <span class=\'red_text\'>gojuusanbyou</span>'"
        :trad="'<span class=\'green_text\'>7h</span> <span class=\'blue_text\'>46min</span> <span class=\'red_text\'>53s</span>'" 
        :explication="''"
      />

      <h3 class="title_subcontent" id="donner_heure">Donner une heure</h3>
      <p class="text_no_margin">
        Pour donner une heure, on peut utiliser les kansūji ou nombre arabe.<br>
        Les japonais utilise plein de systèmes différents : <br>
        <span class="text_li">午後4時30分</span><br>
        <span class="text_li">午後四時三十分</span><br>
        <span class="text_li">16h30</span><br>
        <span class="text_li">16:30</span><br>
        <span class="text_li">PM4:30</span><br>
        À l'oral les japonais utiliseront plus souvent le système en 12h.<br>
        À l'écrit et surtout sur tous les supports digitale on aura souvent le système écrit en 24h.<br>
      </p>
      <!--<p class="box_example">
        <span class="blue_text">午後</span><span class="red_text">4</span>時<span class="green_text">30分</span> = <span class="blue_text">午後</span><span class="red_text">四</span>時<span class="green_text">三十分</span> = <span class="blue_text">gogo</span> <span class="red_text">yo</span>ji <span class="green_text">sanjuupun</span> = <span class="red_text">16</span>h<span class="green_text">30</span> = <span class="red_text">16</span>:<span class="green_text">30</span> = <span class="blue_text">PM</span><span class="red_text">4</span>:<span class="green_text">30</span><br>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'blue_text\'>午後</span><span class=\'red_text\'>4</span>時<span class=\'green_text\'>30分</span> OU <span class=\'blue_text\'>午後</span><span class=\'red_text\'>四</span>時<span class=\'green_text\'>三十分</span>'"
        :kanjiraw="'午後四時三十分'"
        :roomaji="'<span class=\'blue_text\'>gogo</span> <span class=\'red_text\'>yo</span>ji <span class=\'green_text\'>sanjuupun</span>'"
        :trad="'<span class=\'red_text\'>16</span>h<span class=\'green_text\'>30</span> OU <span class=\'red_text\'>16</span>:<span class=\'green_text\'>30</span> OU <span class=\'blue_text\'>PM</span><span class=\'red_text\'>4</span>:<span class=\'green_text\'>30</span>'" 
        :explication="''"
      />
      <p class="text_no_margin">
        Comme en français on peut faire une soustraction dans une heure.<br>
        Mais ceci est beaucoup moins utilisé que la première méthode en donnant l'heure exact, car on peut confondre 前 (mae) par "un petit peu avant" ou "moins".<br>
      </p>
      <!--<p class="box_example">
        <span class="green_text">9時</span><span class="blue_text">10分</span><span class="red_text">前</span> = <span class="green_text">kuji</span> <span class="blue_text">juupun</span> <span class="red_text">mae</span> = <span class="green_text">9h</span> <span class="red_text">moins</span> <span class="blue_text">10</span>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'green_text\'>9時</span><span class=\'blue_text\'>10分</span><span class=\'red_text\'>前</span>'"
        :kanjiraw="'9時10分前'"
        :roomaji="'<span class=\'green_text\'>kuji</span> <span class=\'blue_text\'>juupun</span> <span class=\'red_text\'>mae</span>'"
        :trad="'<span class=\'green_text\'>9h</span> <span class=\'red_text\'>moins</span> <span class=\'blue_text\'>10</span>'" 
        :explication="''"
      />
      <p class="text_no_margin">
        Anecdote:<br>
        Parfois il peut arriver au japon que l'on trouve des journées allant au-dessus de 24h.<br>
        Exemple de restaurant "fermant à 26h". Qui correspond en faite au prolongement d'une journée sur le jours suivant. Donc le restaurant fermerai à 2h du matin le jours suivant de sont ouverture.<br>
        On peut aller au maximum jusqu'a 30h qui correspond à 6h du matin du jours suivant.<br>
      </p>

      <KanjiDraw v-if="kanjiDrawCurrent" :title="kanjiDrawCurrentTitle" :kanji="kanjiDrawCurrent" @endKanjiDraw="endPopupKanjiDraw"/>
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";
import KanjiDraw from "@/components/Composants/KanjiDraw.vue";

export default {
  name: 'Times',
  components: {
    Button1,
    BoxExample,
    KanjiDraw
  },
  data() {
    return {
      kanjiDrawCurrent: '',
      kanjiDrawCurrentTitle: '',
    };
  },
  methods:{
    // KanjiDraw Method
    showDrawKanji(kanji, title){
      this.kanjiDrawCurrent = kanji;
      this.kanjiDrawCurrentTitle = title;
    },
    // event
    endPopupKanjiDraw(){
      this.kanjiDrawCurrent = ''
    },
  }
}
</script>

<style lang="scss" scoped>
/*section*/
.section{
  display: inline-block;
  margin: 0 15px;
    .inactif_kanji{
      opacity: 0.4;
  }
}

.section .search,
.section .select{
  float: right;
  margin-top: 9px;
  min-height: 40px;
}

.section .select{
  margin-right: 5px;
}
/*form*/
input, select{
  padding: 10px 6px;
  box-shadow: border-box;
  border: 1px solid #ddd;
  color: #555;
}

/*table*/
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.nombres th {
  background-color: #009879;
  color: #ffffff;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

/* kanji draw button*/
.kanji_bottom{
  display: flex;
  justify-content: center;
}
.kanji_button{
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 1px;
  &:hover{
    opacity: 0.75;
    background: rgb(221 221 221 / 50%);
  }
}
</style>