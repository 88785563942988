<template>
  <div class="home_card">
    <div v-if="link">
      <router-link :to="{ name: link }" class="href_card">
        <i :class="'fas ' + img + ' card_icon'"></i>
        <h1 class="card_title">
          {{ title }}
        </h1>
        <div class="card_description">
          {{ description }}
        </div>
      </router-link>
    </div>
    <div v-else>
      <div :to="{ name: link }" class="href_card">
        <i :class="'fas ' + img + ' card_icon'"></i>
        <h1 class="card_title">
          {{ title }}
        </h1>
        <div class="card_description">
          {{ description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeCard",
  props: ["title", "description", "img", "link"],
};
</script>

<style lang="scss" scoped>
.home_card {
  .card_icon{
    display: inline-block;
    color: deepskyblue;
    font-size: 1.5em;
    margin-right: 5px;
  }
  .card_title{
    display: inline-block;
    font-size: 1.5em;
  }
  .href_card{
    display: inline-block;
    border: solid 1px #ddd;
    border-radius: 10px;
    padding: 10px 10px;
    margin: 5px;
    box-shadow: 0 3px 18px -10px #4a74c9;
    transition: linear 0.25s;
  }
  &:hover{
    opacity: 0.7;
    transition: linear 0.25s;
  }
}
</style>