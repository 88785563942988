import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  // data
  state: {
    isProduction: false, 
    apiUrl: 'https://japonais-recap.com/data/',
    apiKanji: 'https://japonais-recap.com/kanji/'
  },
  // mutation synchrone in application
  mutations: {
    setCurrentProductionState(state, payload){
      state.isProduction = payload;    
      if(payload == true){
        state.apiUrl = 'https://japonais-recap.com/data/'
        state.apiKanji = 'https://japonais-recap.com/kanji/'
      }
      else{
        state.apiUrl = 'http://localhost:3000/'
        state.apiKanji = 'https://japonais-recap.com/kanji/'
      }
    }
  },
  // mutation asynchrone from other website (api)
  actions: {
  },
  // others
  modules: {
  },
  // Getter Data
  getters: {
    getCurrentEnv: state => state.isProduction,
    getCurrentApiUrl: state => state.apiUrl,
    getCurrentApiKanji: state => state.apiKanji,
  }
})
