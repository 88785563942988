<template>
  <div>
    <h1>Nombre & Compter</h1>

    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Kansūji'" :link="'#kansuji'" />
    <Button1 :text="'Tableaux des Nombres'" :link="'#nombres'" />
    <Button1 :text="'Les formes alternatives des nombres japonais'" :link="'#forme_alternatives'" />
    <Button1 :text="'Prononciations des nombres à virgules'" :link="'#prononciations'" />
    <Button1 :text="'Compter la monnaie'" :link="'#monnaie'" />

    <h3 class="title_subcontent" id="kansuji">Kansūji</h3>
    <p class="text_no_margin">
      - "kan" pour Kanji et "sūji" pour Nombre. Ceci désigne les nombres japonais en kanji.<br>
      - Quelques particularités, le nombre "4" se prononce "shi" comme la "mort" en japonais. Du coup ce chiffre porte malheur. Vous ne verrez pas de siège "4", ni de chambre n°4 dans un hôtel, ni d'étage 4, etc.<br> 
      On préfèrera dire "yon" la lecture purement japonaise.<br>
      - Pour ne pas confondre "7" (shichi) et "8" (hachi), on va utiliser la lecture purement japonaise de "7" (nana). De plus "shichi" est aussi un homonyme de "terre de mort" ce qui rejoint un peu la superstition du "4".<br>
      - le "9" possède deux lectures sino-japonaise "ku" et "kyuu". Étant donné que "ku" est aussi un homonyme avec le mot "souffrance", nous allons utiliser l'autre lecture sino-japonaise "kyuu".<br>
      - Pour compter on utilise la lecture sino-japonaise sauf pour "4" et "7" qui utilise la lecture purement japonaise.<br>
      - <span class="green_text">Les nombres en vert</span> dans le tableau correspond aux nombres dont la prononciation diffère de la règle habituelle car c'est plus simple à prononcer (ex : 300 = san + hyaku mais on prononcera san + byaku).<br>
    </p>
    <p class="text_no_margin">
      - Pour les nombres entre 11 et 9999 nous allons additionner <span class="red_text">les nombres uniques en rouge</span> et multiplier par les <span class="blue_text">nombres ronds en bleu</span> dans le tableau, pour obtenir la somme voulue.<br>
    </p>
    <p class="box_example">
      10 = 1 x 10 = 十一<br>
      20 = 2 x 10 = ニ十<br>
      26 = 2 x 10 + 6 = ニ十六<br>
      126 = 100 + 2 x 10 + 6 = 百ニ十六<br>
      236 = 2 x 100 + 3 x 10 + 6 = ニ百三十六<br>
    </p>
    <p class="text_no_margin">
      - En japonais on compte par paquets de 4 chiffres, tous les paquets de 4 chiffre au-dessus, il y aura donc un nouveau kansūji<br>
      <span class="text_li">(10 000) <span class="red_text">1</span> 0000 (le paquet des "man"): 万 (man). (il faudra dire ici, ichiman pour <span class="red_text">1</span> 0000 car on a "1" dans le paquet des "man")</span><br>
      <span class="text_li">(100 millions) <span class="blue_text">1</span> 0000 0000 (le paquet des "oku"): 万 (oku). (il faudra dire ici, ichioku pour <span class="blue_text">1</span> 0000 0000 car on a "1" dans le paquet des "oku")</span><br>
      <span class="text_li">(1 billion) <span class="green_text">1</span> 0000 0000 0000 (le paquet des "chou"): 兆 (chou). (il faudra dire ici, ichichou pour <span class="green_text">1</span> 0000 0000 0000 car on a "1" dans le paquet des "chou")</span><br>
      <span class="text_li">(10<sup>16</sup>) <span class="red_text">1</span> 0000 0000 0000 0000 (le paquet des "kei"): 京 (kei). (il faudra dire ici, ichikei pour <span class="red_text">1</span> 0000 0000 0000 0000 car on a "1" dans le paquet des "kei")</span><br>
      <span class="text_li">(10<sup>20</sup>) <span class="blue_text">1</span> 0000 0000 0000 0000 0000 (le paquet des "gai"): 垓 (gai). (il faudra dire ici, ichigai pour <span class="blue_text">1</span> 0000 0000 0000 0000 0000 car on a "1" dans le paquet des "gai")</span><br>
    </p>
    <p class="box_example">
      10 000 = <span class="red_text">1</span> 0000 = <span class="red_text">一万</span> (ichiman) (on a "1" dans le paquet des "man")<br>
      23 456 = <span class="red_text">2</span> 3456 = <span class="red_text">ニ万</span>三千四百五十六 (<span class="red_text">niman</span> sanzen yonhyaku gojuu roku) (on a "2" dans le paquet des "man")<br>
      1 000 000 = <span class="red_text">100</span> 0000 = <span class="red_text">百万</span> (on a "100" dans le paquet des "man")<br>
      94 562 236 = <span class="red_text">9456</span> 2236 = <span class="red_text">九千四百五十六万</span>　二千二百三十六 (on a "9456" dans le paquet des "man")<br>
      100 millions = <span class="blue_text">1</span> <span class="red_text">0000</span> 0000 = <span class="blue_text">一億</span> (on a "1" dans le paquet des "oku")<br>
      1 billion = <span class="green_text">1</span>  <span class="blue_text">0000</span>  <span class="red_text">0000</span> 0000  = <span class="green_text">一兆</span> (on a "1" dans le paquet des "chou")<br>
      10<sup>16</sup> = <span class="red_text">1</span> <span class="green_text">0000</span> <span class="blue_text">0000</span>  <span class="red_text">0000</span> 0000  = <span class="red_text">一京</span> (on a "1" dans le paquet des "kei")<br>
      10<sup>20</sup> = <span class="blue_text">1</span> <span class="red_text">0000</span> <span class="green_text">0000</span> <span class="blue_text">0000</span> <span class="red_text">0000</span> 0000  = <span class="red_text">一垓</span> (on a "1" dans le paquet des "gai")<br>
    </p>
    <p> 
      Liste complet des chiffres jusqu'a 10<sup>68</sup> : <Button1 :text="'Numération japonaise - wiki'" :target="'_blank'" :link="'https://fr.wikipedia.org/wiki/Numération_japonaise'" /><br>
    </p>
    <p class="text_no_margin">
      - A faire attention, pour 1000 c'est bien "sen" et pas "ichisen". on mets "ichi" seulement à partir du kansūji 10 000 (man) et au-dessus. 
    </p>

    <TableNombre/>

    <h3 class="title_subcontent" id="forme_alternatives">Les formes alternatives des nombres japonais</h3>
    <p class="text_no_margin">
      Les formes Légaux, ce sont les nombres utilisés pour tous ce qui est légal, billet, contrat, ce qui évite de falsifier les nombres "un" (一),"deux" (ニ) et "trois" (三) qui correspondent chacun à un nombre de trait. Voici une liste des nombres dont leurs écritures diffèrent :<br>
      <span class="text_li">- 1: 壱</span><br>
      <span class="text_li">- 2: 弐</span><br>
      <span class="text_li">- 3: 参</span><br>
      <span class="text_li">- 10: 拾</span><br>
      <span class="text_li">- 10 000: 万 ou 萬</span><br>
    </p>
    <p class="box_example">
      23 = 二十三 =  弐拾参<br>
    </p>
    <p class="text_no_margin">
      Petite particularité pour 1000 et 100 si on utilise la forme légale, il faut préfixé par "un" (壱) à contrario du comptage normal.
    </p>
    <p class="box_example">
      100 = 百 (comptage normal) =  壱百 (comptage légal) (hyaku)<br>
      1000 = 千 (comptage normal) =  壱千 (comptage légal) (sen)<br>
    </p>
    <p class="text_no_margin">
      Il peut arriver qu'on utilise les kansūji comme des chiffres arabes dans certains cas, comme pour la date d'une année.<br> 
    </p>
    <p class="box_example">
      203 = ニ〇三 (on utilise littéralement à la suite le kansuji du deux, du zéro et du trois)<br>
      2021 = ニ〇ニ一 (la date 2021)<br>
      6,050円 = 六,〇五〇円 (prix affiché dans certains restaurants)<br>
    </p>

    <h3 class="title_subcontent" id="prononciations">Prononciations des nombres à virgules</h3>
    <p class="text_no_margin">
      pour la prononciation des nombres à virgule. les nombres décimaux avant la virgule se lisent normalement et les nombres après la virgule se lisent un par un (cas par cas).
    </p>
    <p class="box_example">
      <span class="red_text">23</span><span class="blue_text">,</span><span class="green_text">52</span> = <span class="red_text">nijuusan</span> <span class="blue_text">ten</span> <span class="green_text">go ni</span> (<span class="red_text">vingt-trois</span> <span class="blue_text">virgule</span> <span class="green_text">cinq deux</span>)
    </p>

    <h3 class="title_subcontent" id="monnaie">Compter la monnaie</h3>
    <p class="text_no_margin">
      - le kanji de la monnaie est 円 (エン) "en" dont le symbole est ¥ (et en économie "JPY)<br>
      - les autres monnaies :<br>
      <span class="text_li">- € (euro) ユーロ (yuuro) (et en économie "EUR")</span><br>
      <span class="text_li">- $ (dollars) ドル (doru) (et en économie "USD")</span><br>
      - Pour simplifier et faire une conversion rapide, on peut dire que 100 JPY = 1 EUR, même si dans la réalité par rapport à la bourse le yen est plus fort, 100 JPY est plus proche de 0.75 EUR en moyenne.<br>
      Nous pouvons donc ajouter ou enlever 1/4 de différence avec le comptage simplifié fait en amont.<br> 
      - Au Japon on trouvera très souvent le prix indiqué comme ceci en arabe, "6,050円" la virgule étant là seulement pour faire une séparation. Le prix est de 6050¥.<br>
      - Dans certains restaurants on peut retrouver le prix en kansuji avec la lecture arabe, 6,050円 = 六,〇五〇円 (en écriture horizontale ou verticale).<br>
      - Dans de très rares restaurants on peut retrouver le prix en kansuji, mais c'est beaucoup plus rare 6,050円 = 六千五十円.<br>
    </p>
    <p class="box_example">
      1 000¥ = 7.5€ ou 10€ en comptage simplifié<br> 
      10 000¥ = 75€ ou 100€ en comptage simplifié<br>
    </p>

  </div>
</template>

<script>
import TableNombre from "@/components/Tableau/TableNombre.vue";
import Button1 from "@/components/Buttons/Button1.vue";

export default {
    name: 'Compter',
    components: {
      TableNombre,
      Button1
    }
}
</script>

<style>

</style>