<template>
  <div>
      <h1>Classificateurs numéraux</h1>
      <p class="text_no_margin">
        Les classificateurs numéraux permettent de quantifier différentes catégories de choses.<br>
        Pour compter des choses en japonais, il ne suffit pas d'utiliser les nombres, il faut spécifier à quelles catégories appartiennent les choses que l'on compte.<br>
        Par exemple pour compter 2 chats, je ne dois pas juste dire 2 et chat, mais spécifier la catégorie à laquelle correspond les chats, qui est ici les petit animaux.<br>
        Toutes ces catégories de comptage correspond aux Classificateurs numéraux.<br>
      </p>

      <h3 class="title_subcontent">Accès rapide</h3>
      <Button1 :text="'Principaux classificateurs numéraux'" :link="'#classificateurs_principaux'" />
      <Button1 :text="'Utilisation de 何 (nan) avec les classificateurs numéraux dont poser une question'" :link="'#utilisation_de_nan'" />
      <Button1 :text="'Transformer les classificateurs numéraux en nombre ordinal (1er, 2ème, 3ème, etc)'" :link="'#nombre_ordinal'" />
      <Button1 :text="'La prononciation'" :link="'#prononciation'" />

      <h3 class="title_subcontent" id="classificateurs_principaux">Les principaux classificateurs numéraux</h3>
      <p class="text_no_margin">
        Il existe énormément de classificateurs numéraux, mais voici une liste non-exhaustive des plus utilisés.<br>
        <span class="text_li">- 人 (ニン (nin)): Les êtres humains.</span><br>
        <span class="text_li">- 匹 (ヒキ (hiki)): Les petits animaux (chien, chat, poisson, etc). Si on peut porter dans nos bras un animal alors c'est un petit animal</span>.<br>
        <span class="text_li">- 頭 (トウ (tou)): Les gros animaux (vache, lion, éléphant, etc). Si on ne peut pas porter dans nos bras un animal alors c'est un gros animal</span>.<br>
        <span class="text_li">- 羽 (ワ (wa)): Les animaux ailés et les lapins (historiquement tous les animaux dans 羽 (ワ) se mange à contrario de 匹 (ヒキ). Du coup le bouddhisme à mit les lapins dans la catégorie des animaux ailés pour pouvoir les manger).</span><br>
        <span class="text_li">- 枚 (マイ (mai)): Les objets plats et fins (feuille de papier, cd, pizza, etc).</span><br>
        <span class="text_li">- 本 (ホン (hon)): Les objets longs et cylindriques (crayon, bouteille, baguette de pain, etc).</span><br>
        <span class="text_li">- 冊 (サツ (satsu)): Les objets reliés (cahier, livre, dictionnaire, etc).</span><br>
        <span class="text_li">- 台 (ダイ (dai)): Les machines, produits industriels, véhicules (pc, voiture, etc).</span><br>
        <span class="text_li">- 杯 (ハイ (hai)): Classer le contenu d'un verre, d'un bol, d'une cuillère, tout ce qui est contenue par un récipient.</span><br>
        <span class="text_li">- 個 (コ (ko)): Les choses de petites et de moyennes tailles, de formes variées et qui ne rentrent dans aucun autre classificateur numéral (un truc, pomme, morceau de sucre, montre, etc).</span><br>
        <span class="text_li">- つ (tsu): Classer les mêmes types d'objets que 個 (コ (ko)), des choses concrètes ou abstraites, difficiles à classer.</span><br>
        On peut utiliser le classificateur つ (tsu) et 個 (コ (ko)) lorsqu'on ne sait pas quel classificateur utiliser.<br>
        La lecture utilisée est sino-japonaise car on va les combiner avec des nombres.<br>
        Pour utiliser un classificateur numéral, je viens mettre mon nombre en premier suivi du classificateur numéral.<br>
        Les classificateurs s'utilisent comme des adverbes de quantité.<br>
      </p>
      <!--<p class="box_example">
        香はタオルを買う。= kaori wa taoru o kau。 = Kaori achète une serviette (phrase normal)<br>
        香はタオルを<span class="red_text">2枚</span>買う。= kaori wa taoru o <span class="red_text">ni mai</span> kau。= Kaori achète <span class="red_text">deux</span> serviette (on à ajouté "2枚" comme un adverbe de quantité et "枚" pour la serviette qui est un objets plat et fin)<br>
        香は<span class="red_text">2枚</span><span class="blue_text">の</span>タオルを買う。= kaori wa <span class="red_text">ni mai</span> <span class="blue_text">no</span> taoru o kau。 = Kaori achète les <span class="red_text">deux</span> serviette (on peut utiliser la particule "の" (no) pour définir entre le nom et notre classificateur numéral)<br>
        猫がいる。= neko ga iru = il y a un chats.<br>
        猫が<span class="red_text">2匹</span>いる。= neko ga <span class="red_text">ni hiki</span> iru = il y a <span class="red_text">deux</span> chat.<br>
        <span class="red_text">2匹</span><span class="blue_text">の</span>猫がいる。= <span class="red_text">ni hiki</span> <span class="blue_text">no</span> neko ga iru = il y a <span class="red_text">deux</span> chat.<br>
      </p>-->
      <BoxExample 
        :kanji="'香はタオルを買う。'"
        :kanjiraw="'香はタオルを買う。'"
        :kana="''" 
        :roomaji="'kaori wa taoru o kau。'"
        :trad="'Kaori achète une serviette.'" 
        :explication="'phrase normal'"
      />
      <BoxExample 
        :kanji="'香はタオルを<span class=\'red_text\'>2枚</span>買う。'"
        :kanjiraw="'香はタオルを2枚買う。'"
        :kana="''" 
        :roomaji="'kaori wa taoru o <span class=\'red_text\'>ni mai</span> kau。'"
        :trad="'Kaori achète <span class=\'red_text\'>deux</span> serviettes.'" 
        :explication="'On a ajouté “2枚” comme un adverbe de quantité et “枚” pour la serviette qui est un objet plat et fin.'"
      />
      <BoxExample 
        :kanji="'香は<span class=\'red_text\'>2枚</span><span class=\'blue_text\'>の</span>タオルを買う。'"
        :kanjiraw="'香は2枚のタオルを買う。'"
        :kana="''" 
        :roomaji="'kaori wa <span class=\'red_text\'>ni mai</span> <span class=\'blue_text\'>no</span> taoru o kau。'"
        :trad="'Kaori achète les <span class=\'red_text\'>deux</span> serviettes.'" 
        :explication="'On peut utiliser la particule “の” (no) pour définir entre le nom et notre classificateur numéral.'"
      />
      <BoxExample 
        :kanji="'猫がいる。'"
        :kanjiraw="'猫がいる。'"
        :kana="''" 
        :roomaji="'neko ga iru.'"
        :trad="'il y a un chats.'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'猫が<span class=\'red_text\'>2匹</span>いる。'"
        :kanjiraw="'猫が2匹いる。'"
        :kana="''" 
        :roomaji="'neko ga <span class=\'red_text\'>ni hiki</span> iru.'"
        :trad="'il y a <span class=\'red_text\'>deux</span> chat.'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'<span class=\'red_text\'>2匹</span><span class=\'blue_text\'>の</span>猫がいる。'"
        :kanjiraw="'2匹の猫がいる。'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>ni hiki</span> <span class=\'blue_text\'>no</span> neko ga iru.'"
        :trad="'il y a <span class=\'red_text\'>deux</span> chat.'" 
        :explication="''"
      />
      <p class="text_no_margin">
        Quand le classificateur numéral est utilisé juste avant le verbe, il ne peut s'appliquer qu'au COD (sujet du verbe).<br>
        Quand le classificateur numéral détermine le nom avec la particule の (no), il peut s'appliquer à n'importe quel complément du verbe.<br>
      </p>

      <h3 class="title_subcontent" id="utilisation_de_nan">Utilisation de 何 (nan) avec les classificateurs numéraux, dont poser une question</h3>
      <h4 class="title_no_bottom">Poser une question avec 何 (nani)</h4>
      <p class="text_no_margin">
        Pour poser une question avec les classificateurs numéraux sur une quantité (combien) on va utiliser 何 (nan) + classificateur numéral juste avant le verbe.<br>
        On prononce 何 "nani" par "nan" lorsqu'il est combiné aux classificateurs numéraux.<br>
      </p>
      <!--<p class="box_example">
        空には鳥が<span class="red_text">何羽</span>いる? = sora ni wa tori ga <span class="red_text">nanwa</span> iru ? = <span class="red_text">Combien</span> y a-t-il d'oiseaux dans le ciel ?<br>
        空には<span class="red_text">何羽</span>の鳥がいる? = sora ni wa <span class="red_text">nanwa</span> no tori ga iru ? = <span class="red_text">Combien</span> d'oiseaux y a-t-il dans le ciel ?<br>
      </p>-->
      <BoxExample 
        :kanji="'空には鳥が<span class=\'red_text\'>何羽</span>いる?'"
        :kanjiraw="'空には鳥が何羽いる? '"
        :kana="''" 
        :roomaji="'sora ni wa tori ga <span class=\'red_text\'>nanwa</span> iru ?'"
        :trad="'<span class=\'red_text\'>Combien</span> y a-t-il d\'oiseaux dans le ciel ?'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'空には<span class=\'red_text\'>何羽</span>の鳥がいる?'"
        :kanjiraw="'空には何羽の鳥がいる?'"
        :kana="''" 
        :roomaji="'sora ni wa <span class=\'red_text\'>nanwa</span> no tori ga iru ?'"
        :trad="'<span class=\'red_text\'>Combien</span> d\'oiseaux y a-t-il dans le ciel ?'" 
        :explication="''"
      />
      <h4 class="title_no_bottom">Créer de nouveaux mots avec 何 (nani)</h4>
      <p class="text_no_margin">
        On peut créer de nouveaux mots en combinant 何 (nan) + classificateur numéral + terminaison en -か (ka) ou -も (mo) ou -でも (demo).<br>
      </p>
      <!--<p class="box_example">
        何羽か = nanwaka = un certain nombre d'oiseaux<br>
        何羽も = nanwamo = de nombreux oiseaux<br>
        何羽でも = nanwademo = n'importe quel nombre d'oiseaux<br>
      </p>-->
      <BoxExample 
        :kanji="'何羽か'"
        :kanjiraw="'何羽か'"
        :kana="''" 
        :roomaji="'nanwaka'"
        :trad="'un certain nombre d\'oiseaux'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'何羽も'"
        :kanjiraw="'何羽も'"
        :kana="''" 
        :roomaji="'nanwamo'"
        :trad="'de nombreux oiseaux'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'何羽でも'"
        :kanjiraw="'何羽でも'"
        :kana="''" 
        :roomaji="'nanwademo'"
        :trad="'n\'importe quel nombre d\'oiseaux'" 
        :explication="''"
      />
      <p class="text_no_margin">
        Pour créer une négative absolue, on va dire "il n'y en a pas un seul", on va compter 1 ou 0 chose et mettre à la forme négative la phrase.<br>
      </p>
      <!--<p class="box_example">
        空には鳥が<span class="red_text">1羽も</span>いない。= sora ni wa tori ga <span class="red_text">ichi wa mo</span> inai = il n'y a (pas un seul) <span class="red_text">aucun</span> oiseau dans le ciel.
      </p>-->
      <BoxExample 
        :kanji="'空には鳥が<span class=\'red_text\'>1羽も</span>いない。'"
        :kanjiraw="'空には鳥が1羽もいない。'"
        :kana="''" 
        :roomaji="'sora ni wa tori ga <span class=\'red_text\'>ichi wa mo</span> inai.'"
        :trad="'Il n\'y a (pas un seul) <span class=\'red_text\'>aucun</span> oiseau dans le ciel.'" 
        :explication="''"
      />

      <h3 class="title_subcontent" id="nombre_ordinal">Transformer les classificateurs numéraux en nombre ordinal (1er, 2ème, 3ème, etc)</h3>
      <h4 class="title_no_bottom">Utilisation de 目 (me)</h4>
      <p class="text_no_margin">
        Pour cela on peut ajouter le suffixe 目 (me) après le classificateur numéral.<br>
      </p>
      <!--<p class="box_example">
        右から<span class="red_text">3頭目</span>の馬だ。= migi kara <span class="red_text">san toume</span> no uma da。= c'est le <span class="red_text">troisième</span> cheval en partant de la droite. 
      </p>-->
      <BoxExample 
        :kanji="'右から<span class=\'red_text\'>3頭目</span>の馬だ。'"
        :kanjiraw="'右から3頭目の馬だ。'"
        :kana="''" 
        :roomaji="'migi kara <span class=\'red_text\'>san toume</span> no uma da。'"
        :trad="'C\'est le <span class=\'red_text\'>troisième</span> cheval en partant de la droite.'" 
        :explication="''"
      />
      <h4 class="title_no_bottom">Utilisation de 第 (dai)</h4>
      <p class="text_no_margin">
        On peut sinon ajouter le préfixe 第 (dai), que l'on va mettre directement devant le nombre sans classificateur numéral.<br>
        On ne précise pas la catégorie d'objet, il faut donc préciser le nom de l'objet avec la particule の (no).<br>
      </p>
      <!--<p class="box_example">
        <span class="red_text">3第</span>の馬 = <span class="red_text">dai san</span> no uma = le <span class="red_text">troisième</span> cheval<br>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'>3第</span>の馬。'"
        :kanjiraw="'3第の馬。'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>dai san</span> no uma.'"
        :trad="'le <span class=\'red_text\'>troisième</span> cheval'" 
        :explication="''"
      />

      <h4 class="title_no_bottom" id="prononciation">Différence entre 目 (me) et 第 (dai)</h4>
      <p class="text_no_margin">
        目 (me) correspond à un nombre ordinal dans un ordre que l'on créer sois même en partant de ce que l'on veut<br>
        第 (dai) indique un ordre officiel dans une liste clairement établi<br>
      </p>
      <p class="box_example">
        Dans un contexte où les chevaux sont numérotés de 1 à 7 de gauche à droite, qui est l'ordre officiel<br>
        <!--<span class="red_text">3第</span>の馬 = <span class="red_text">dai san</span> no uma = le <span class="red_text">troisième</span> cheval (3第 correspond au 3ème cheval en partant de l'ordre officiel, de gauche à droite)<br>
        右から<span class="red_text">3頭目</span>の馬だ。= migi kara <span class="red_text">san toume</span> no uma da。= c'est le <span class="red_text">troisième</span> cheval en partant de la droite. (3頭目 correspond au 3ème cheval en partant de la droite, donc ça sera le 5ème cheval dans ordre officiel. Ici on a créer notre propre ordre)-->
      
      <BoxExample 
        :kanji="'<span class=\'red_text\'>3第</span>の馬。'"
        :kanjiraw="'3第の馬。'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>dai san</span> no uma.'"
        :trad="'le <span class=\'red_text\'>troisième</span> cheval.'" 
        :explication="'3第 correspond au 3ème cheval en partant de l\'ordre officiel, de gauche à droite.'"
      />
      <BoxExample 
        :kanji="'右から<span class=\'red_text\'>3頭目</span>の馬だ。'"
        :kanjiraw="'右から3頭目の馬だ。'"
        :kana="''" 
        :roomaji="'migi kara <span class=\'red_text\'>san toume</span> no uma da。'"
        :trad="'c\'est le <span class=\'red_text\'>troisième</span> cheval en partant de la droite.'" 
        :explication="'3頭目 correspond au 3ème cheval en partant de la droite, donc ça sera le 5ème cheval dans ordre officiel. Ici on a créé notre propre ordre.'"
      />
      </p>
      <p class="text_no_margin">
        On peut aussi poser une question sur l'ordre avec 何 (nani).<br>
      </p>
      <!--<p class="box_example">
        何頭目 = dans quel odre non officiel<br>
        第何 = dans quel ordre officiel
      </p>-->
      <BoxExample 
        :kanji="'何頭目'"
        :kanjiraw="'何頭目'"
        :kana="''" 
        :roomaji="''"
        :trad="'dans quel odre non officiel'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'第何'"
        :kanjiraw="'第何'"
        :kana="''" 
        :roomaji="''"
        :trad="'dans quel ordre officiel'" 
        :explication="''"
      />

      <h3 class="title_subcontent">La prononciation</h3>
      <p class="text_no_margin">
        À gauche (k, s, t et h) correspond aux classificateurs numéraux qui commencent par cette lettre.<br>
        Exemple pour 頭 (トウ (tou)), ça sera la ligne "t" dont il faudra regarder car "tou" commence par un "t".<br>
        <span class="txt_grey">Les cellules en Gris</span> correspondent aux combinaisons avec des nombres japonais pour lequel on a des particularités de prononciation (la particularité est souvent une pause).<br>
        <span class="blue_text">En bleu</span> correspond à ce qui ne change pas pour tous les classificateurs numéraux qui commencent par cette première lettre de la ligne.<br>
        <span>En noir</span> correspond à ce qui est propre à l'exemple utilisé pour chaque ligne du tableau, on retrouve le mot utilisé comme exemple entre parenthèse.<br>
        Il y a une petite exception pour le classificateur 人 (nin) et つ (tsu).<br>
      </p>
      <table class="content-table combinations" id="combinations">
        <thead>
          <tr>
            <th></th>
            <th>一 (ichi)</th>
            <th>二 (ni)</th>
            <th>三 (san)</th>
            <th>四 (yon)</th>
            <th>五 (go)</th>
            <th>六 (roku)</th>
            <th>七 (nana)</th>
            <th>八 (hachi)</th>
            <th>九 (kyuu)</th>
            <th>十 (juu)</th>
            <th>何 (nani)</th>
          </tr>
        </thead>
        <tr>
          <th>k (個 "コ")</th>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">イッ</span>コ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ニ</span>コ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">サン</span>コ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">よん</span>コ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ゴ</span>コ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">ロッ</span>コ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">なな</span>コ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">ハッ</span>コ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">キュウ</span>コ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">ジュッ</span>コ/<span class="blue_text">ジッ</span>コ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">なん</span>コ</span></td>
        </tr>
        <tr>
          <th>s (冊 "サツ")</th>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">イッ</span>サツ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ニ</span>サツ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">サン</span>サツ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">よん</span>サツ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ゴ</span>サツ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ロク</span>サツ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">なな</span>サツ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">ハッ</span>サツ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">キュウ</span>サツ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">ジュッ</span>サツ/<span class="blue_text">ジッ</span>サツ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">なん</span>サツ</span></td>
        </tr>
        <tr>
          <th>t (頭 "トウ")</th>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">イッ</span>トウ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ニ</span>トウ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">サン</span>トウ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">よん</span>トウ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ゴ</span>トウ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ロク</span>トウ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">なな</span>トウ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">ハッ</span>トウ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">キュウ</span>トウ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">ジュッ</span>トウ/<span class="blue_text">ジッ</span>トウ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">なん</span>トウ</span></td>
        </tr>
        <tr>
          <th>h (匹 "ヒキ")</th>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">イッ</span>ピキ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ニ</span>ヒキ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">サン</span>ビキ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">よん</span>ヒキ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ゴ</span>ヒキ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">ロッ</span>ピキ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">なな</span>ヒキ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">ハッ</span>ピキ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">キュウ</span>ヒキ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">ジュッ</span>ピキ/<span class="blue_text">ジッ</span>ピキ</span></td>
          <td class="bg_kana"><span class="kana_classificateur"><span class="blue_text">なん</span>ビキ</span></td>
        </tr>
        <tr>
          <th>w (羽 "ワ")</th>
          <td><span class="kana_classificateur"><span class="blue_text">イチ</span>ワ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ニ</span>ワ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">サン</span>ワ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">よん</span>ワ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ゴ</span>ワ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ロク</span>ワ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">なな</span>ワ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ハチ</span>ワ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">キュウ</span>ワ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">ジュウ</span>ワ</span></td>
          <td><span class="kana_classificateur"><span class="blue_text">なん</span>ワ</span></td>
        </tr>
        <tr>
          <th>人 (nin)</th>
          <td><span class="kana_classificateur">ひとり</span></td>
          <td><span class="kana_classificateur">ふたり</span></td>
          <td><span class="kana_classificateur">サンニン</span></td>
          <td><span class="kana_classificateur">よニン</span></td>
          <td><span class="kana_classificateur">ゴニン</span></td>
          <td><span class="kana_classificateur">ロクニン</span></td>
          <td><span class="kana_classificateur">ななニン</span></td>
          <td><span class="kana_classificateur">ハチニン</span></td>
          <td><span class="kana_classificateur">キュウニン</span></td>
          <td><span class="kana_classificateur">ジュウニン</span></td>
          <td><span class="kana_classificateur">なんニン</span></td>
        </tr>
        <tr>
          <th>つ</th>
          <td><span class="kana_classificateur">ひとつ</span></td>
          <td><span class="kana_classificateur">ふたつ</span></td>
          <td><span class="kana_classificateur">みっつ</span></td>
          <td><span class="kana_classificateur">よっつ</span></td>
          <td><span class="kana_classificateur">いつつ</span></td>
          <td><span class="kana_classificateur">むっつ</span></td>
          <td><span class="kana_classificateur">ななつ</span></td>
          <td><span class="kana_classificateur">やつ</span></td>
          <td><span class="kana_classificateur">ここのつ</span></td>
          <td><span class="kana_classificateur">とお</span></td>
          <td><span class="kana_classificateur">いくつ</span></td>
        </tr>
      </table>
      <p class="text_no_margin">
        si on ajoute la particule "デ" (de) après le classificateur numeral 人 (nin), on pourra dire "tout seul"
      </p>
      <!--<p class="box_example">
        一人デ = hitori de = tout seul<br>
        ニ人デ = futari de = à deux<br>
      </p>-->
      <BoxExample 
        :kanji="'一人デ'"
        :kanjiraw="'一人デ'"
        :kana="''" 
        :roomaji="'hitori de'"
        :trad="'tout seul'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'ニ人デ'"
        :kanjiraw="'ニ人デ'"
        :kana="''" 
        :roomaji="'futari de'"
        :trad="'à deux'" 
        :explication="''"
      />
      <p class="text_no_margin">
        Petite information, il ne faut pas utiliser "いくつ" pour compter l'argent. Pour argent il faut utiliser いくら<br>
      </p>
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  name: 'Classificateurs',
  components: {
    Button1,
    BoxExample
  },
}
</script>

<style lang="scss" scoped>
.bg_kana{
  background: #ddd;
}
.txt_grey{
  color: grey;
}
/*section*/
.section{
  display: inline-block;
  margin: 0 15px;
    .inactif_kanji{
      opacity: 0.4;
  }
}

.section .search,
.section .select{
  float: right;
  margin-top: 9px;
  min-height: 40px;
}

.section .select{
  margin-right: 5px;
}
/*form*/
input, select{
  padding: 10px 6px;
  box-shadow: border-box;
  border: 1px solid #ddd;
  color: #555;
}

/*table*/
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.combinations th {
  background-color: #009879;
  color: #ffffff;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
</style>