<template>
  <div class="table_vocabulaire">
    <h2 class="vocab_h">
      <div class="vocab_title">{{title ? title : 'Filtre'}}</div>

      <input
        class="search"
        type="search"
        v-model="search"
        placeholder="Recherche occurrence"
        @keyup="searchInTable"
      />

      <select class="select" v-model="size" @change="onChange">
        <option value="0.625rem">0.625rem</option>
        <option value="0.75rem">0.75rem</option>
        <option value="0.875rem">0.875rem</option>
        <option selected="selected" value="1rem">1rem</option>
        <option value="1.125rem">1.125rem</option>
        <option value="1.25rem">1.25rem</option>
        <option value="1.5rem">1.5rem</option>
        <option value="1.875rem">1.875rem</option>
        <option value="2rem">2rem</option>
        <option value="2.25rem">2.25rem</option>
        <option value="2.5rem">2.5rem</option>
        <option value="2.75rem">2.75rem</option>
      </select>

      <div class="numb_occurence vocab_title">
        ({{numberActu}}/{{numberTotal}})
      </div>
    </h2>
    <table class="content-table" id="table_vocabulaire" ref="vocab">
      <thead>
        <tr>
          <th>Kanji</th>
          <th>Kana</th>
          <th>Rōmaji / Prononciation</th>
          <th>Catégories</th>
          <th>Groupe</th>
          <th>Traduction</th>
          <th>Dictionnaire</th>
          <th>Conjugaison</th>
          <th>Audio</th>
        </tr>
      </thead>
      <tr v-for="item in vocabulaire" :key="item.kanji" class="tr_vocab">
        <td>
          <!--<a class="switch_dark--light_black--white" target="_blank" :href="'https://jitenon.com/kanji/' + item.kanji">{{ item.kanji }}</a>-->
          <div class="kanji_top">
            {{ item.kanji }}
          </div>
          <div class="kanji_bottom">
            <div v-for="(kanji,index) in item.kanji.replaceAll(/\s/g,'').replace(/[{()}]/g, '').split('')" :key="index">
              <div class="kanji_button" @click="showDrawKanji(kanji, 'Tracé')">{{kanji}}</div>
            </div>
          </div>
        </td>
        <td>{{ item.furigana }}</td>
        <td>
          <div>{{ item.romaji }}</div>
          <div class="intonation" v-if="item.intonation">
            <div v-for="(kanji,index) in item.intonation" :key="index">
              <span class='red_text' v-if="kanji == '＼' || kanji == '／' || kanji == '￣'">{{kanji}}</span>
              <span class="space_span" v-else-if="kanji == ' '">&nbsp;</span>
              <span v-else>{{kanji}}</span>
            </div>
          </div>
        </td>
        <td>{{ item.categorie }}</td>
        <td>{{ item.groupe }}</td>
        <td>
          <div>{{ item.translate }}</div>
        </td>
        <td>
          <a target="_blank" :href="'https://jisho.org/search/' + item.kanji">
            <i class="fas fa-atlas"></i>
          </a>
        </td>
        <!--<td>
              <iframe :src="'https://translate.google.com.vn/translate_tts?ie=UTF-8&q='+ item.kanji +'&tl=ja&client=tw-ob'" frameborder="0" width="150px" height="60px" ></iframe>
            </td>-->
        <td>
          <a
            target="_blank"
            :href="'https://jlearn.net/dictionary/' + item.kanji"
          >
            <i class="fas fa-bookmark"></i>
          </a>
        </td>
        <!--<td>
              <a target="_blank" :href="'https://translate.google.com/?sl=ja&tl=en&text='+item.kanji+'&op=translate'">
                <i class="fas fa-language"></i>
              </a>
            </td>-->
        <td class="volume" @click="listenAudio(item.kanji)">
          <i class="fas fa-volume-up"></i>
        </td>
      </tr>
    </table>

    <KanjiDraw v-if="kanjiDrawCurrent" :title="kanjiDrawCurrentTitle" :kanji="kanjiDrawCurrent" @endKanjiDraw="endPopupKanjiDraw"/>

  </div>
</template>

<script>
import KanjiDraw from "@/components/Composants/KanjiDraw.vue";

export default {
  props: ["vocabulaire","title"],
  name: "TableVocabulaire",
  components: {
    KanjiDraw
  },
  data() {
    return {
      size: "1rem",
      search: "",
      numberTotal: null,
      numberActu: null,
      kanjiDrawCurrent: '',
      kanjiDrawCurrentTitle: '',
    };
  },
  methods: {
    searchInTable() {
      if (this.search.length > 0) {
        var tr = this.$refs.vocab.getElementsByClassName("tr_vocab");
        tr.forEach((l) => l.classList.add("inactif_vocab"));
        tr.forEach((element) => {
          if (element.innerText.includes(this.search)) {
            element.classList.remove("inactif_vocab");
          }
        });
        this.countActu();
      } else {
        var tr = this.$refs.vocab.getElementsByClassName("tr_vocab");
        tr.forEach((l) => l.classList.remove("inactif_vocab"));
        this.countActu();
      }
    },
    onChange() {
      this.$refs.vocab.style.fontSize = this.size;
    },
    countTotal(){
      this.numberTotal = this.$refs.vocab.getElementsByClassName("tr_vocab").length
    },
    countActu(){
      this.numberActu = this.numberTotal - this.$refs.vocab.getElementsByClassName("tr_vocab inactif_vocab").length
    },
    listenAudio(kanji){
      responsiveVoice.speak(kanji, "Japanese Male");
    },
    // KanjiDraw Method
    showDrawKanji(kanji, title){
      this.kanjiDrawCurrent = kanji;
      this.kanjiDrawCurrentTitle = title;
    },
    // event
    endPopupKanjiDraw(){
      this.kanjiDrawCurrent = ''
    },
  },
  // after the component is create and before data
  mounted() {
    this.onChange();
    this.countTotal();
    this.countActu();
  },
  // Watch props "vocabulaire" change
  watch: { 
    vocabulaire: function(newVal, oldVal) {
      this.countTotal();
      this.countActu();
    }
  }
};
</script>

<style lang="scss" scoped>
/*icones*/
.volume{
  cursor: pointer;
}

/*section*/
.table_vocabulaire {
  display: inline-block;
  margin: 0 15px 0 0px;
  .inactif_kanji {
    opacity: 0.4;
  }
}

.table_vocabulaire .search,
.table_vocabulaire .select {
  float: right;
  margin-top: 9px;
  min-height: 40px;
  margin-bottom: 10px;
}

.table_vocabulaire .select {
  margin-right: 5px;
}
/*form*/
input,
select {
  padding: 10px 6px;
  box-shadow: border-box;
  border: 1px solid #ddd;
  color: #555;
}

.inactif_vocab {
  display: none;
}
.vocab_h {
  margin: 0;
}
.vocab_title {
  float: left;
  margin-top: 10px;
}

.numb_occurence{
  margin-left: 5px;
}

/*table*/
.content-table .kanji {
  /*font-family: "noto sans cjk";*/
}
.content-table .romaji {
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  width: 100%;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
/* kanji draw button*/
.kanji_bottom{
  display: flex;
  justify-content: center;
}
.kanji_button{
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 1px;
  &:hover{
    opacity: 0.75;
    background: rgb(221 221 221 / 50%);
  }
}
/* intonation */
.intonation{
  display: flex;
  justify-content: center;
  .space_span{
    padding: 0 5px;
  }
}
</style>