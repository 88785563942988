<template>
  <div>
      <h1>Adverbes</h1>

      <h3 class="title_subcontent">A propos des adverbes japonais</h3>
      <p class="text_no_margin">
        L'adverbe sert à nuancer un verbe, adjectif, phrase, repère spatial ou temporel, etc. Il va donner un détail, préciser comment se passe une action.<br>
        L'adverbe se place juste avant, ceux sur quoi elle apporte une précision.<br>
        Quand l'adverbe porte sur toute la phrase, elle sera mise au début de la phrase, après le thème et "は" (wa). Ainsi adverbe peut passer devant les compléments de temps (ex : 7h du matin, demain, etc).<br>
        L'adverbe n'a pas obligatoirement besoin de particule pour être placé dans une phrase, il peut être mise n'importe où, après le thème et "は" (wa).<br>
      </p>

      <h3 class="title_subcontent">Liste des adverbes</h3>

      <h4 class="title_no_bottom">Les adverbes de manière</h4>
      <p class="text_no_margin">
        Elles permettent de préciser comment est faite une action.<br>
        <span class="text_li">- ゆっくり (yukkuri) (lentement)</span><br>
        <span class="text_li">- しっかり (shikkari) (fermement)</span><br>
        <span class="text_li">- きちんと (kichinto) (soigneusement)</span><br>
      </p>
      <!--<p class="box_example">
        ポールは<span class="red_text">ゆっくり</span>話す。= pooru wa <span class="red_text">yukkuri</span> hanasu = paul parle <span class="red_text">lentement</span> (on peut placer adverbe juste avant le verbe pour appliquer la nuance sur le verbe parler)<br>
      </p>-->
      <BoxExample 
        :kanji="'ポールは<span class=\'red_text\'>ゆっくり</span>話す。'"
        :kanjiraw="'ポールはゆっくり話す。'"
        :kana="''" 
        :roomaji="'pooru wa <span class=\'red_text\'>yukkuri</span> hanasu.'"
        :trad="'paul parle <span class=\'red_text\'>lentement</span>.'" 
        :explication="'On peut placer adverbe juste avant le verbe pour appliquer la nuance sur ce verbe.'"
      />

      <h4 class="title_no_bottom">Les adverbes de degrès</h4>
      <p class="text_no_margin">
        <span class="text_li">- とても (totemo) (très)</span><br>
        <span class="text_li">- もっと (motto) (encore plus)</span><br>
        <span class="text_li">- かなり (kanari) (assez)</span><br>
      </p>
      <!--<p class="box_example">
        ポールは<span class="red_text">とても</span>ゆっくり話す。= pooru wa <span class="red_text">totemo</span> yukkuri hanasu = paul parle <span class="red_text">très</span> lentement (on peut placer adverbe juste avant le précédent adverbe pour donner un degrès à cet adverbe)<br>
      </p>-->
      <BoxExample 
        :kanji="'ポールは<span class=\'red_text\'>とても</span>ゆっくり話す。'"
        :kanjiraw="'ポールはとてもゆっくり話す。'"
        :kana="''" 
        :roomaji="'pooru wa <span class=\'red_text\'>totemo</span> yukkuri hanasu.'"
        :trad="'paul parle <span class=\'red_text\'>très</span> lentement.'" 
        :explication="'On peut placer adverbe juste avant le précédent adverbe pour donner un degrès à cet adverbe.'"
      />

      <h4 class="title_no_bottom">Les adverbes de quantité</h4>
      <p class="text_no_margin">
        Pour donner son avis sur la quantité d'une action.<br>
        <span class="text_li">- たくさん (takusan) (beauccoup)</span><br>
        <span class="text_li">- いっぱい (ippai) (plein)</span><br>
        <span class="text_li">- 少し (sukoshi) (un peu)</span><br>
      </p>
      <!--<p class="box_example">
        ポールは漫画を<span class="red_text">たくせん</span>読む。= pooru wa manga o <span class="red_text">takusan</span> yomu。= paul lit <span class="red_text">beaucoup</span> de mangas.<br>
        ポールは<span class="red_text">たくせん</span>の漫画を読む。= pooru wa <span class="red_text">takusan</span> no manga o yomu。= paul lit <span class="red_text">beaucoup</span> de mangas. (le sens de la phrase n'as pas changé, on peut dire aussi "takusan no manga")<br>
        <span class="red_text">少し</span>右 = <span class="red_text">sukoshi</span> migi = <span class="red_text">un peu</span> à droite<br>
      </p>-->
      <BoxExample 
        :kanji="'ポールは漫画を<span class=\'red_text\'>たくせん</span>読む。'"
        :kanjiraw="'ポールは漫画をたくせん読む。'"
        :kana="''" 
        :roomaji="'pooru wa manga o <span class=\'red_text\'>takusan</span> yomu。'"
        :trad="'paul lit <span class=\'red_text\'>beaucoup</span> de mangas.'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'ポールは<span class=\'red_text\'>たくせん</span>の漫画を読む。'"
        :kanjiraw="'ポールはたくせんの漫画を読む。'"
        :kana="''" 
        :roomaji="'pooru wa <span class=\'red_text\'>takusan</span> no manga o yomu。'"
        :trad="'paul lit <span class=\'red_text\'>beaucoup</span> de mangas.'" 
        :explication="'le sens de la phrase n\'as pas changé, on peut dire aussi “takusan no manga”.'"
      />
      <BoxExample 
        :kanji="'<span class=\'red_text\'>少し</span>右。'"
        :kanjiraw="'少し右。'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>sukoshi</span> migi.'"
        :trad="'<span class=\'red_text\'>un peu</span> à droite.'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">Les adverbes de temps</h4>
      <p class="text_no_margin">
        Pour donner son avis sur le temps d'une action.<br>
        <span class="text_li">- ずっと (zutto) (constamment)</span><br>
        <span class="text_li">- すぐ (sugu) (immédiatement)</span><br>
        <span class="text_li">- 間もなく (mamonaku) (sous peu)</span><br>
      </p>
      <!--<p class="box_example">
        ポールは<span class="red_text">ずっと</span>トムのそばにいる = pooru wa <span class="red_text">zutto</span> tomu no sobani iru = paul est <span class="red_text">constamment</span> aux côtés de tom (on peut placer adverbe en début de phrase pour nuancer toute la phrase)<br>
      </p>-->
      <BoxExample 
        :kanji="'ポールは<span class=\'red_text\'>ずっと</span>トムのそばにいる。'"
        :kanjiraw="'ポールはずっとトムのそばにいる。'"
        :kana="''" 
        :roomaji="'pooru wa <span class=\'red_text\'>zutto</span> tomu no sobani iru.'"
        :trad="'paul est <span class=\'red_text\'>constamment</span> aux côtés de tom.'" 
        :explication="'On peut placer adverbe en début de phrase pour nuancer toute la phrase.'"
      />

      <h4 class="title_no_bottom">Les adverbes de fréquence</h4>
      <p class="text_no_margin">
        Pour donner son avis sur la fréquence d'une action.<br>
        <span class="text_li">- 時々 (tokidoki) (de temps en temps)</span><br>
        <span class="text_li">- よく (yoku) (souvent)</span><br>
        <span class="text_li">- いつも (itsumo) (toujours)</span><br>
      </p>
      <!--<p class="box_example">
        ポールは<span class="red_text">時々</span>コーヒーを飲む。= pooru wa <span class="red_text">tokidoki</span> koohii o nomu = paul boit de <span class="red_text">temps en temps</span> du café<br>
      </p>-->
      <BoxExample 
        :kanji="'ポールは<span class=\'red_text\'>時々</span>コーヒーを飲む。'"
        :kanjiraw="'ポールは時々コーヒーを飲む。'"
        :kana="''" 
        :roomaji="'pooru wa <span class=\'red_text\'>tokidoki</span> koohii o nomu.'"
        :trad="'paul boit de <span class=\'red_text\'>temps en temps</span> du café.'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">Les adverbes d'aspect</h4>
      <p class="text_no_margin">
        Pour connaître l'état d'avancement d'une action.<br>
        <span class="text_li">- もう (mou) (déjà)</span><br>
        <span class="text_li">- ついに (tsuini) (enfin)</span><br>
        <span class="text_li">- まだ (mada) (encore)</span><br>
      </p>
      <!--<p class="box_example">
        今日は<span class="red_text">もう</span>バカンスだ = kyou wa <span class="red_text">mou</span> bakansu da = Aujourd'hui, c'est <span class="red_text">déjà</span> les vacances
      </p>-->
      <BoxExample 
        :kanji="'今日は<span class=\'red_text\'>もう</span>バカンスだ。'"
        :kanjiraw="'今日はもうバカンスだ。'"
        :kana="''" 
        :roomaji="'kyou wa <span class=\'red_text\'>mou</span> bakansu da.'"
        :trad="'Aujourd\'hui, c\'est <span class=\'red_text\'>déjà</span> les vacances.'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">Les adverbes de supposition</h4>
      <p class="text_no_margin">
        Pour donner son avis sur la probabilité d'une action.<br>
        <span class="text_li">- 多分 (tabun) (peut-être)</span><br>
        <span class="text_li">- 確かに (tashikani) (certainement)</span><br>
        <span class="text_li">- 必ず (kanarazu) (à coup sùr)</span><br>
      </p>
      <!--<p class="box_example">
        ポールは<span class="red_text">多分</span>トイレにいる。= pooru wa <span class="red_text">tabun</span> toire ni iru = paul est <span class="red_text">peut-être</span> aux toilettes
      </p>-->
      <BoxExample 
        :kanji="'ポールは<span class=\'red_text\'>多分</span>トイレにいる。'"
        :kanjiraw="'ポールは多分トイレにいる。'"
        :kana="''" 
        :roomaji="'pooru wa <span class=\'red_text\'>tabun</span> toire ni iru.'"
        :trad="'paul est <span class=\'red_text\'>peut-être</span> aux toilettes.'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">Les adverbes de jugement</h4>
      <p class="text_no_margin">
        Pour donner son avis sur l'action.<br>
        <span class="text_li">- もちろん (mochiron) (bien entendu)</span><br>
        <span class="text_li">- 本当に (hontouni) (vraiment)</span><br>
        <span class="text_li">- やはり (yahari) ou やっぱり (yappari) (comme on pouvait s'y attendre)</span><br>
      </p>
      <!--<p class="box_example">
        ポールは<span class="red_text">もちろん</span>毎日トイレにいく = pooru wa <span class="red_text">mochiron</span> mainichi toire ni iku = Paul va <span class="red_text">bien entendu</span> aux toilettes tous les jours. (les adverbes peuvent se placer avant les compléments de temps, comme "mainichi")
      </p>-->
      <BoxExample 
        :kanji="'ポールは<span class=\'red_text\'>もちろん</span>毎日トイレにいく。'"
        :kanjiraw="'ポールはもちろん毎日トイレにいく。'"
        :kana="''" 
        :roomaji="'pooru wa <span class=\'red_text\'>mochiron</span> mainichi toire ni iku.'"
        :trad="'Paul va <span class=\'red_text\'>bien entendu</span> aux toilettes tous les jours.'" 
        :explication="'Les adverbes peuvent se placer avant les compléments de temps, comme “mainichi”.'"
      />

      <h4 class="title_no_bottom">Différence entre また (mata) et まだ (mada)</h4>
      <p class="text_no_margin">
        また (mata) pour quelque chose qui se répète encore.<br>
      </p>
      <!--<p class="box_example">
        犬は<span class="red_text">また</span>庭にいる = inu wa <span class="red_text">mata</span> niwa ni iru = le chien est <span class="red_text">à nouveau</span> dans le jardin (le chien était là hier,il est rentrer chez lui, il est revenue à nouveau le lendemain)<br>
      </p>-->
      <BoxExample 
        :kanji="'犬は<span class=\'red_text\'>また</span>庭にいる。'"
        :kanjiraw="'犬はまた庭にいる。'"
        :kana="''" 
        :roomaji="'inu wa <span class=\'red_text\'>mata</span> niwa ni iru.'"
        :trad="'le chien est <span class=\'red_text\'>à nouveau</span> dans le jardin.'" 
        :explication="'Le chien était là hier, il est rentrer chez lui, il est revenue à nouveau le lendemain.'"
      />
      <p class="text_no_margin">
        まだ (mada) pour quelque chose qui dure dans le temps.<br>
      </p>
      <!--<p class="box_example">
        犬は<span class="red_text">まだ</span>庭にいる = inu wa <span class="red_text">mada</span> niwa ni iru = le chien est <span class="red_text">toujours</span> dans le jardin (le chien est toujours dans le jardin depuis hier, il n'as pas bougé)<br>
      </p>-->
      <BoxExample 
        :kanji="'犬は<span class=\'red_text\'>まだ</span>庭にいる。'"
        :kanjiraw="'犬はまだ庭にいる。'"
        :kana="''" 
        :roomaji="'inu wa <span class=\'red_text\'>mada</span> niwa ni iru.'"
        :trad="'le chien est <span class=\'red_text\'>toujours</span> dans le jardin.'" 
        :explication="'Le chien est toujours dans le jardin depuis hier, il n\'as pas bougé.'"
      />
  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>