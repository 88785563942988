import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '../views/Home.vue'
import Introduction from '../views/Introduction.vue'
import Particule from '../views/Particule.vue'
import Adjectifs from '../views/Adjectifs.vue'
import Vocabulaires from '../views/Vocabulaires.vue'
import Link from '../views/Link.vue'
import Verbes from '../views/Verbes.vue'
import Compter from '../views/Compter.vue'
import Clavier from '../views/Clavier.vue'
import Classificateurs from '../views/Classificateurs.vue'
import Datejap from '../views/Date.vue'
import NotFound from '../views/NotFound.vue'
import Grammaire from '../views/Grammaire.vue'
import Times from '../views/Times.vue'
import Converter from '../views/Converter.vue'
import Adverbe from '../views/Adverbe.vue'
import PoserQuestion from '../views/PoserQuestion.vue'
import ParticuleAutre from '../views/ParticuleAutre.vue'
import Prefixes from '../views/Prefixes.vue'
import Connecteurs from '../views/Connecteurs.vue'
import PronomsPersonnels from '../views/PronomsPersonnels.vue'
import PlurielAdjectifsPos from '../views/PlurielAdjectifsPos.vue'
import SuffixesHonorifiques from '../views/SuffixesHonorifiques.vue'
import UnitMesure from '../views/UnitMesure.vue'
import SpecificiteJap from '../views/SpecificiteJap.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/introduction',
    name: 'Introduction',
    component: Introduction
  },
  {
    path: '/particules/:id?',
    name: 'Particule',
    component: Particule
  },
  {
    path: '/adjectifs/:id?',
    name: 'Adjectifs',
    component: Adjectifs
  },
  {
    path: '/vocabulaires',
    name: 'Vocabulaires',
    component: Vocabulaires
  },
  {
    path: '/verbes',
    name: 'Verbes',
    component: Verbes
  },
  {
    path: '/links',
    name: 'Link',
    component: Link
  },
  {
    path: '/compter',
    name: 'Compter',
    component: Compter
  },
  {
    path: '/clavier',
    name: 'Clavier',
    component: Clavier
  },
  {
    path: '/classificateurs',
    name: 'Classificateurs',
    component: Classificateurs
  },
  {
    path: '/date',
    name: 'Date',
    component: Datejap
  },
  {
    path: '/grammaire',
    name: 'Grammaire',
    component: Grammaire
  },
  {
    path: '/converter',
    name: 'Converter',
    component: Converter
  },
  {
    path: '/times',
    name: 'Times',
    component: Times
  },
  {
    path: '/adverbes',
    name: 'Adverbe',
    component: Adverbe
  },
  {
    path: '/particulesautres/:id?',
    name: 'ParticuleAutre',
    component: ParticuleAutre
  },
  {
    path: '/poserquestion',
    name: 'PoserQuestion',
    component: PoserQuestion
  },
  {
    path: '/prefixes',
    name: 'Prefixes',
    component: Prefixes
  },
  {
    path: '/connecteurs',
    name: 'Connecteurs',
    component: Connecteurs
  },
  {
    path: '/pronomspersonnels',
    name: 'PronomsPersonnels',
    component: PronomsPersonnels
  },
  {
    path: '/plurieladjectifs',
    name: 'PlurielAdjectifsPos',
    component: PlurielAdjectifsPos
  },
  {
    path: '/suffixeshonorifiques',
    name: 'SuffixesHonorifiques',
    component: SuffixesHonorifiques
  },
  {
    path: '/unitesmesure',
    name: 'UnitMesure',
    component: UnitMesure
  },
  {
    path: '/specificites_langue_japonaise',
    name: 'SpecificiteJap',
    component: SpecificiteJap
  },
  // catchall 404, all route not matching route
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior (to, from, savedPosition) {
    // Automatique scroll to top if no params id
    if(!to.params.id){
      return { x: 0, y: 0 }
    }
  }
})

export default router
