<template>
  <div>
    <h1>Les Suffixes et Titres Honorifiques</h1>
    <p class="text_no_margin">
      Les suffixes honorifiques, sont les suffixes que l'on met après le nom d'une personne quand on s'adresse ou mentionne cette personne.<br>
      Ceci a pour but d'indiquer la position, relation du locuteur vis à vis de cette personne. Qui sera souvent du respect.<br>
      Les suffixes honorifiques peuvent être mis autant sur un nom que prénom japonais ou étranger.<br> 
      Ne pas mettre un suffixe honorifique est un manque de respect, il faut utiliser le suffixe honorifique approprié à la personne à qui on s'adresse. Sauf si on est très proche de cette personne (ami, épouse, etc).<br>
      Très important! Les suffixes honorifiques ne s'utilisent pas sur sois même, ça fait extrêmement prétentieux. même en français on ne s'introduit pas avec Monsieur.<br>
      Lorsqu'on fait partie d'un groupe (famille, entreprise) et que l'on parle à une personne extérieur à ce groupe, on ne met pas de suffixe honorifique sur les membres de notre groupe. c'est de la modestie, ceci permet de ne pas élever quelqu'un de notre propre groupe.<br>
    </p>

    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Suffixes honorifiques'" :link="'#suffixes_honorifiques'" />
    <Button1 :text="'Titres honorifiques'" :link="'#titres_honorifiques'" />
    <Button1 :text="'Titres non-honorifiques'" :link="'#titres_non_honorifiques'" />
    <Button1 :text="'Différence entre titre honorifique et non-honorifique'" :link="'#difference_honorifiques'" />
    <Button1 :text="'Conclusion'" :link="'#conclusion'" />

    <h3 class="title_subcontent" id="suffixes_honorifiques">Suffixes honorifiques</h3>
    <h4 class="title_no_bottom">suffixe さん (san)</h4>
    <p class="text_no_margin">
      さん (san) permet de marquer le respect, c'est le plus répandu. l'équivalent est "Monsieur (M.), Madame (Mme)".<br>
      On va le mettre après le nom d'une personne quand on s'adresse à cette personne ou la mentionne.<br>
    </p>
    <!--<p class="box_example">
      鳥山<span class="red_text">さん</span> = toriyama <span class="red_text">san</span> = Monsieur Toriyama (nom)<br>
      明<span class="red_text">さん</span> = akira <span class="red_text">san</span> = Monsieur Akira (prénom)<br>
      鳥山 明<span class="red_text">さん</span> = toriyama akira <span class="red_text">san</span> = Monsieur Toriyama Akira (en japonais le nom de famille se met en premier contrairement en occidental ou c'est inversé)<br>
      鳥山<span class="red_text">さん</span>、お元気ですか。= toriyama <span class="red_text">san</span> o genki desu ka = Comment allez-vous, M.Toriyama ?<br>
      鳥山<span class="red_text">さん</span>はどこにいるの?= toriyama <span class="red_text">san</span> wa doko ni iru no ? = Où est M.Toriyama ?<br>
    </p>-->
    <BoxExample 
      :kanji="'鳥山<span class=\'red_text\'>さん</span>。'"
      :kanjiraw="'鳥山さん。'"
      :kana="''" 
      :roomaji="'toriyama <span class=\'red_text\'>san</span>.'"
      :trad="'Monsieur Toriyama (nom).'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'明<span class=\'red_text\'>さん</span>。'"
      :kanjiraw="'明さん。'"
      :kana="''" 
      :roomaji="'akira <span class=\'red_text\'>san</span>.'"
      :trad="'Monsieur Akira (prénom).'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'鳥山 明<span class=\'red_text\'>さん</span>。'"
      :kanjiraw="'鳥山 明さん。'"
      :kana="''" 
      :roomaji="'toriyama akira <span class=\'red_text\'>san</span>.'"
      :trad="'Monsieur Toriyama Akira.'" 
      :explication="'En japonais le nom de famille se met en premier contrairement en occidental ou c\'est inversé.'"
    />
    <BoxExample 
      :kanji="'鳥山<span class=\'red_text\'>さん</span>、お元気ですか。'"
      :kanjiraw="'鳥山さん、お元気ですか。'"
      :kana="''" 
      :roomaji="'toriyama <span class=\'red_text\'>san</span> o genki desu ka.'"
      :trad="'Comment allez-vous, M.Toriyama ?'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'鳥山<span class=\'red_text\'>さん</span>はどこにいるの?'"
      :kanjiraw="'鳥山さんはどこにいるの?'"
      :kana="''" 
      :roomaji="'toriyama <span class=\'red_text\'>san</span> wa doko ni iru no ?'"
      :trad="'Où est M.Toriyama ?'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Il arrive qu'on puisse mettre les suffixes sur des noms commun, des métiers ou des noms qui renvoie à des personnes.<br>
    </p>
    <!--<p class="box_example">
      猫<span class="red_text">さん</span> = neko <span class="red_text">san</span> = Monsieur chat<br>
      本屋<span class="red_text">さん</span> = honya <span class="red_text">san</span> = Monsieur le libraire<br>
      作家<span class="red_text">さん</span> = sakka <span class="red_text">san</span> = Monsieur l'écricain<br>
      皆<span class="red_text">さん</span> = mina<span class="red_text">san</span> = Tous le monde<br>
    </p>-->
    <BoxExample 
      :kanji="'猫<span class=\'red_text\'>さん</span>。'"
      :kanjiraw="'猫さん。'"
      :kana="''" 
      :roomaji="'neko <span class=\'red_text\'>san</span>。'"
      :trad="'Monsieur chat。'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'本屋<span class=\'red_text\'>さん</span>。'"
      :kanjiraw="'本屋さん。'"
      :kana="''" 
      :roomaji="'honya <span class=\'red_text\'>san</span>.'"
      :trad="'Monsieur le libraire.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'作家<span class=\'red_text\'>さん</span>。'"
      :kanjiraw="'作家さん。'"
      :kana="''" 
      :roomaji="'sakka <span class=\'red_text\'>san</span>.'"
      :trad="'Monsieur l\'écricain.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'皆<span class=\'red_text\'>さん</span>。'"
      :kanjiraw="'皆さん。'"
      :kana="''" 
      :roomaji="'mina<span class=\'red_text\'>san</span>.'"
      :trad="'Tous le monde.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">suffixe 様 - さま (sama)</h4>
    <p class="text_no_margin">
      様 (sama) permet de marquer un grand respect envers une personne bien plus haut placée que soi, que l'on admire, des invités importants ou des clients.<br>
    </p>
    <!--<p class="box_example">
      神<span class="red_text">様</span> = kami <span class="red_text">sama</span> = les dieux<br>
      俺<span class="red_text">様</span> = ore sama = Moi (en très prétentieux)<br>
    </p>-->
    <BoxExample 
      :kanji="'神<span class=\'red_text\'>様</span>。'"
      :kanjiraw="'神様。'"
      :kana="''" 
      :roomaji="'kami <span class=\'red_text\'>sama</span>.'"
      :trad="'les dieux.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'俺<span class=\'red_text\'>様</span>。'"
      :kanjiraw="'俺様。'"
      :kana="''" 
      :roomaji="'ore sama'"
      :trad="'Moi (en très prétentieux).'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">suffixe 君 - クン (kun)</h4>
    <p class="text_no_margin">
      君 - クン (kun) est utiliser envers les hommes de statut inférieur, un jeune homme, un garçon, un homme pour qui on a de l'affection.<br>
    </p>
    <!--<p class="box_example">
      ポール<span class="red_text">君</span> = pooru <span class="red_text">kun</span> = Paul<br>
    </p>-->
    <BoxExample 
      :kanji="'ポール<span class=\'red_text\'>君</span>。'"
      :kanjiraw="'ポール君。'"
      :kana="''" 
      :roomaji="'pooru <span class=\'red_text\'>kun</span>.'"
      :trad="'Paul'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">suffixe ちゃん (chan) et たん (tan)</h4>
    <p class="text_no_margin">
      ちゃん (chan) est utiliser envers les femmes, une fille attachante, mignonne. Il est aussi possible de l'utiliser sur des bébés en général.<br>
    </p>
    <!--<p class="box_example">
      赤<span class="red_text">ちゃん</span> = aka <span class="red_text">chan</span> = bébé<br>
    </p>-->
    <BoxExample 
      :kanji="'赤<span class=\'red_text\'>ちゃん</span>。'"
      :kanjiraw="'赤ちゃん。'"
      :kana="''" 
      :roomaji="'aka <span class=\'red_text\'>chan</span>.'"
      :trad="'bébé'" 
      :explication="''"
    />
    <p class="text_no_margin">
      たん (tan) est un dérivé de ちゃん (chan), en version encore plus mignonne.<br>
      Comme si ちゃん (chan) etait mal prononcé par un enfant.<br>
    </p>

    <h4 class="title_no_bottom">suffixe 氏 - シ (shi)</h4>
    <p class="text_no_margin">
      氏 - シ (shi) permet de marquer le respect (Monsieur, Madame) mais envers une personne que l'on ne connaît pas direcctement.<br>
      Quand on mentionne quelqu'un, dans des discours formels, infos, documents légaux.<br>
    </p>

    <h3 class="title_subcontent" id="titres_honorifiques">Titres honorifiques</h3>
    <p class="text_no_margin">
      Il est courant d'utiliser le titre d'une personne au lieu d'un suffixe honorifique après sont nom.<br>
      La différence entre les titres et suffixes honorifiques, c'est que les titres peuvent être utilisés seuls, sans nom avant.<br>
      Les titres honorifique peuvent être une alternative à あなた (anata) en l'appelant par son titre honorique.<br>
    </p>

    <h4 class="title_no_bottom">先生 - センセイ (sensei)</h4>
    <p class="text_no_margin">
      Ce titre est utilisé pour quelqu'un qui est spécialiste dans son domaine (un maître, professeur, docteur, etc).
    </p>
    <!--<p class="box_example">
      鳥山<span class="red_text">先生</span> = toriyama <span class="red_text">sensei</span> = Maître Toriyama<br>
      鳥山<span class="red_text">先生</span>、お元気ですか。= toriyama <span class="red_text">sensei</span> o genki desu ka = Comment allez-vous, Maître Toriyama ?<br>
      鳥山<span class="red_text">先生</span>はどこにいるの?= toriyama <span class="red_text">sensei</span> wa doko ni iru no ? = Où est Maître Toriyama ?<br>
    </p>-->
    <BoxExample 
      :kanji="'鳥山<span class=\'red_text\'>先生</span>。'"
      :kanjiraw="'鳥山先生。'"
      :kana="''" 
      :roomaji="'toriyama <span class=\'red_text\'>sensei</span>.'"
      :trad="'Maître Toriyama'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'鳥山<span class=\'red_text\'>先生</span>、お元気ですか。'"
      :kanjiraw="'鳥山先生、お元気ですか。'"
      :kana="''" 
      :roomaji="'toriyama <span class=\'red_text\'>sensei</span> o genki desu ka.'"
      :trad="'Comment allez-vous, Maître Toriyama ?'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'鳥山<span class=\'red_text\'>先生</span>はどこにいるの?'"
      :kanjiraw="'鳥山先生はどこにいるの?'"
      :kana="''" 
      :roomaji="'toriyama <span class=\'red_text\'>sensei</span> wa doko ni iru no ?'"
      :trad="'Où est Maître Toriyama ?'" 
      :explication="''"
    />
    <p class="text_no_margin">
      On peut aussi utiliser sensei et même tous les titres honorifiques sans mentionner le prénom ou nom.
    </p>
    <!--<p class="box_example">
      <span class="red_text">先生</span>、お元気ですか。= <span class="red_text">sensei</span> o genki desu ka = Comment allez-vous, Maître ?<br>
      <span class="red_text">先生</span>はどこにいるの?= <span class="red_text">sensei</span> wa doko ni iru no ? = Où est le Maître ?<br>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>先生</span>、お元気ですか。'"
      :kanjiraw="'先生、お元気ですか。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>sensei</span> o genki desu ka.'"
      :trad="'Comment allez-vous, Maître ?'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'red_text\'>先生</span>はどこにいるの?'"
      :kanjiraw="'先生はどこにいるの?'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>sensei</span> wa doko ni iru no ?'"
      :trad="'Où est le Maître ?'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Alternative à あなたの (anata no) pour la possession.
    </p>
    <!--<p class="box_example">
      <span class="red_text">先生</span>の机 = <span class="red_text">sensei</span> no tsukue = le bureau du maître<br>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>先生</span>の机。'"
      :kanjiraw="'先生の机。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>sensei</span> no tsukue'"
      :trad="'le bureau du maître'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">博士 - はかせ (hakase)</h4>
    <p class="text_no_margin">
      Ce titre est utilisé pour quelqu'un qui est diplomée d'un doctorat (un docteur, scientifique, savant).
    </p>
    <!--<p class="box_example">
      オーキド<span class="red_text">博士</span> = <span class="red_text">hookido</span> sensei = professeur chen (dans pokémon)<br>
    </p>-->
    <BoxExample 
      :kanji="'オーキド<span class=\'red_text\'>博士</span>。'"
      :kanjiraw="'オーキド博士。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>hookido</span> sensei.'"
      :trad="'professeur chen (dans pokémon).'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">先輩 - センパイ (senpai)</h4>
    <p class="text_no_margin">
      Ce titre désigne une personne plus agée, un ainée, une personne de niveau supérieur dans la même catégorie que la sienne (école, club, travail).<br>
    </p>
    <!--<p class="box_example">
      ポール<span class="red_text">先輩</span> = pooru <span class="red_text">senpai</span> = paul (ainée)<br>
    </p>-->
    <BoxExample 
      :kanji="'ポール<span class=\'red_text\'>先輩</span>。'"
      :kanjiraw="'ポール先輩。'"
      :kana="''" 
      :roomaji="'pooru <span class=\'red_text\'>senpai</span>.'"
      :trad="'paul (ainée)'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">後輩 - コウハイ (kouhai)</h4>
    <p class="text_no_margin">
      Ce titre désigne une personne plus jeune, un cadet, une personne de niveau inférieur dans la même catégorie que la sienne (école, club, travail).<br>
      Kouhai sera moins utilisé car il est un peu rabaissant, on utilisera plus les suffixe honorifique -kun, -chan.<br>
    </p>

    <h4 class="title_no_bottom">陛下 - ヘイカ (heika)</h4>
    <p class="text_no_margin">
      Ce titre désigne un monarque souverain (roi, empreur, majesté).
    </p>

    <h3 class="title_subcontent" id="titres_non_honorifiques">Titres non-honorifiques</h3>
    <h4 class="title_no_bottom">殿 - との (tono) ou どの (dono)</h4>
    <p class="text_no_margin">
      Ce titre non-honorifique est plus masculin et désigne un seigneur, ça fait très Moyen Age, donne un style grandiose (plus utilisé dans les jeux vidéo).<br>
    </p>

    <h4 class="title_no_bottom">坊 - ボウ (bou)</h4>
    <p class="text_no_margin">
      Ce titre non-honorifique est plus masculin et désigne un jeune garçon pour qui on a de la tendresse, un bébé (utilisation plus rare aujourd'hui, on utilise plus kun).<br>
    </p>
    <!--<p class="box_example">
      赤ん<span class="red_text">坊</span> = akan <span class="red_text">bou</span> = bébé (terme plus utilisé dans le passé ou personne agée)<br>
    </p>-->
    <BoxExample 
      :kanji="'赤ん<span class=\'red_text\'>坊</span>。'"
      :kanjiraw="'赤ん坊。'"
      :kana="''" 
      :roomaji="'akan <span class=\'red_text\'>bou</span>.'"
      :trad="'bébé'" 
      :explication="'Terme plus utilisé dans le passé ou par des personnes agées.'"
    />

    <h4 class="title_no_bottom">姫 - ひね (hime)</h4>
    <p class="text_no_margin">
      Ce titre non-honorifique est plus féminin et désigne une princesse, fille de famille noble.<br>
    </p>
    <!--<p class="box_example">
      もののけ<span class="red_text">姫</span> = mononoke <span class="red_text">hime</span> = Princesse Mononoké<br>
    </p>-->
    <BoxExample 
      :kanji="'もののけ<span class=\'red_text\'>姫</span>。'"
      :kanjiraw="'もののけ姫。'"
      :kana="''" 
      :roomaji="'mononoke <span class=\'red_text\'>hime</span>.'"
      :trad="'Princesse Mononoké'" 
      :explication="''"
    />
    <p class="text_no_margin">
      La particularité entre 姫 - ひね (hime) et 王女 - オウジョ (oujo) c'est que "oujo" désigne la fille du roi et "hime" désigne une fille de famille noble, c'est plus large que "oujo".
    </p>

    <h4 class="title_no_bottom">Autre titre non-honorifique noble</h4>
    <!--<p class="text_no_margin">
      国王 = コクオウ = kokuou = Roi<br>
　　　女王 = ジョオウ = jouu = Reine<br>
　　　王子 = オウジ = ouji = Prince<br>
　　　王女 = オウジョ = oujo = Princesse<br>
    </p>-->
    <BoxExample 
      :kanji="'国王'"
      :kanjiraw="'国王'"
      :kana="'コクオウ'" 
      :roomaji="'kokuou'"
      :trad="'Roi'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'女王'"
      :kanjiraw="'女王'"
      :kana="'ジョオウ'" 
      :roomaji="'jouu'"
      :trad="'Reine'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'王子'"
      :kanjiraw="'王子'"
      :kana="'オウジ'" 
      :roomaji="'ouji'"
      :trad="'Prince'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'王女'"
      :kanjiraw="'王女'"
      :kana="'オウジョ'" 
      :roomaji="'oujo'"
      :trad="'Princesse'" 
      :explication="''"
    />

    <h3 class="title_subcontent" id="difference_honorifiques">Différence entre titre honorifique et non-honorifique</h3>
    <p class="text_no_margin">
      L'utilisation est la même, on peut les utiliser après un nom ou seul.<br>
      Mais quand le titre <span class="red_text">non-honorifique est utilisé seul</span>, il a lui même <span class="blue_text">besoin d'un suffixe honorifique</span> ou d'un <span class="green_text">titre honorifique</span> après lui, pour avoir cet aspect honorifique qu'il ne porte pas de base.<br>
    </p>
    <!--<p class="box_example">
      ゼルダ<span class="red_text">姫</span> = zeruda <span class="red_text">hime</span> = princesse Zelda (utilisation de "hime" après un nom)<br>
      <span class="red_text">姫</span><span class="blue_text">様</span> = <span class="red_text">hime</span> <span class="blue_text">sama</span> = Princesse (utilisation de "hime" tout seul, il faut utiliser un suffixe ou titre honorifique)<br>
      エリザベス2世<span class="red_text">女王</span> = erisabesu 2sei <span class="red_text">jouu</span> = <span class="red_text">la reine</span> Élisabeth II (utilisation de "jouu" après un nom)<br>
      <span class="red_text">女王</span><span class="blue_text">陛下</span> = <span class="red_text">jouu</span> <span class="blue_text">heika</span> = <span class="blue_text">Sa Majesté</span> <span class="red_text">la reine</span> (utilisation de "jouu" tout seul, il faut utiliser un suffixe ou titre honorifique)
    </p>-->
    <BoxExample 
      :kanji="'ゼルダ<span class=\'red_text\'>姫</span>。'"
      :kanjiraw="'ゼルダ姫。'"
      :kana="''" 
      :roomaji="'zeruda <span class=\'red_text\'>hime</span>.'"
      :trad="'princesse Zelda'" 
      :explication="'Utilisation de “hime” après un nom.'"
    />
    <BoxExample 
      :kanji="'<span class=\'red_text\'>姫</span><span class=\'blue_text\'>様</span>。'"
      :kanjiraw="'姫様。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>hime</span> <span class=\'blue_text\'>sama</span>.'"
      :trad="'Princesse'" 
      :explication="'Utilisation de “hime” tout seul, il faut utiliser un suffixe ou titre honorifique.'"
    />
    <BoxExample 
      :kanji="'エリザベス2世<span class=\'red_text\'>女王</span>。'"
      :kanjiraw="'エリザベス2世女王。'"
      :kana="''" 
      :roomaji="'erisabesu 2sei <span class=\'red_text\'>jouu</span>.'"
      :trad="'<span class=\'red_text\'>la reine</span> Élisabeth II.'" 
      :explication="'Utilisation de “jouu” après un nom.'"
    />
    <BoxExample 
      :kanji="'<span class=\'red_text\'>女王</span><span class=\'blue_text\'>陛下</span>。'"
      :kanjiraw="'女王陛下。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>jouu</span> <span class=\'blue_text\'>heika</span>.'"
      :trad="'<span class=\'blue_text\'>Sa Majesté</span> <span class=\'red_text\'>la reine</span>.'" 
      :explication="'Utilisation de “jouu” tout seul, il faut utiliser un suffixe ou titre honorifique.'"
    />

    <h3 class="title_subcontent" id="conclusion">Conclusion</h3>
    <p class="text_no_margin">
      - Le suffixe honorifique on peut le mettre qu'après un nom.<br>
      - Le titre honorifique on peut le mettre après un nom ou l'utiliser seul.<br>
      - Le titre non honorifique on peut le mettre après un nom et également l'utiliser seul mais avec un suffixe honorifique ou d'un titre honorifique.<br>
      Si on veut juste appeler quelqu'un par Monsieurs : おじさん<br>
      Si on veut juste appeler quelqu'un par Madame : おばさん<br>
    </p>
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  name: 'Classificateurs',
  components: {
    Button1,
    BoxExample
  },
}
</script>

<style>

</style>s