<template>
  <div id="wa">
    <h3 class="title_subcontent">Particule : wa (は)</h3>
    <p class="text_no_margin">Cette particule permet d'indiquer le thème de la phrase.<br>
    Elle s'écrit avec la particule "ha" mais se prononce bien "wa" avec le roomaji "wa" (cette exception est dû à des habitudes de prononciation au fil des siècles).<br>
    On peut traduire "wa" par "<b>Quant à…</b>", "<b>À propos de…</b>", "<b>En ce qui concerne</b>" en français.<br>
    Le thème de la phrase c'est un nom ou groupe nominal qui est toujours en début de phrase et qui annonce l'élément principal dont on va parler dans la phrase.<br>
    C'est ce sur quoi gravite toute la phrase. Il peut donc y avoir plusieurs sujets dans une phrase mais seulement un seul thème, dont les sujets gravitent autour de celle-ci.<br>
    On peut marquer une pause après "wa" dans la prononciation de la phrase, contrairement à "ga" où la phrase se dit d'une traite.<br>
    <span class="blue_text">Thème + wa </span></p>
    <!--<p class="box_example">
        <span class="blue_text">猫</span><span class="red_text">は</span> = <span class="blue_text">neko</span> <span class="red_text">wa</span> = <span class="red_text">En ce qui concerne le <span class="blue_text">chat</span></span> <br>
        <span class="blue_text">ジョン</span><span class="red_text">は</span>学生ですか = <span class="blue_text">john</span> <span class="red_text">wa</span> gakusei desu ka = Est-ce que <span class="blue_text">john</span> est étudiant ? <span class="red_text">(En ce qui concerne <span class="blue_text">john</span>)</span> <br>
        <span class="blue_text">トイレ</span><span class="red_text">は</span>どこですか = <span class="blue_text">toire</span> <span class="red_text">wa</span> doko desu ka = Où sont les <span class="blue_text">toilettes</span> ? <span class="red_text">(En ce qui concerne les <span class="blue_text">toilettes</span>)</span> <br>
        <span class="blue_text">私</span><span class="red_text">は</span>日本が大好き = <span class="blue_text">watashi</span> <span class="red_text">wa</span> nihon ga daisuki = <span class="blue_text">J</span>'adore le japon! <span class="red_text">(En ce qui <span class="blue_text">me</span> concerne)</span> <br>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'blue_text\'>猫</span><span class=\'red_text\'>は</span>。'"
      :kanjiraw="'猫は。'"
      :kana="''" 
      :roomaji="'<span class=\'blue_text\'>neko</span> <span class=\'red_text\'>wa</span>.'"
      :trad="'<span class=\'red_text\'>En ce qui concerne le <span class=\'blue_text\'>chat</span></span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'blue_text\'>ジョン</span><span class=\'red_text\'>は</span>学生ですか。'"
      :kanjiraw="'ジョンは学生ですか。'"
      :kana="''" 
      :roomaji="'<span class=\'blue_text\'>john</span> <span class=\'red_text\'>wa</span> gakusei desu ka.'"
      :trad="'Est-ce que <span class=\'blue_text\'>john</span> est étudiant ? <span class=\'red_text\'>(En ce qui concerne <span class=\'blue_text\'>john</span>)</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'blue_text\'>トイレ</span><span class=\'red_text\'>は</span>どこですか。'"
      :kanjiraw="'トイレはどこですか。'"
      :kana="''" 
      :roomaji="'<span class=\'blue_text\'>toire</span> <span class=\'red_text\'>wa</span> doko desu ka.'"
      :trad="'Où sont les <span class=\'blue_text\'>toilettes</span> ? <span class=\'red_text\'>(En ce qui concerne les <span class=\'blue_text\'>toilettes</span>)</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'blue_text\'>私</span><span class=\'red_text\'>は</span>日本が大好き。'"
      :kanjiraw="'私は日本が大好き。'"
      :kana="''" 
      :roomaji="'<span class=\'blue_text\'>watashi</span> <span class=\'red_text\'>wa</span> nihon ga daisuki.'"
      :trad="'<span class=\'blue_text\'>J</span>\'adore le japon! <span class=\'red_text\'>(En ce qui <span class=\'blue_text\'>me</span> concerne)</span>.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Lorsqu'on a plusieurs phrases à la suite et que le thème ne change pas, il n'est pas utile de re-mentionner le thème.<br>
    </p>
    <!--<p class="box_example">
      <span class="blue_text">ゼルダ</span><span class="red_text">は</span>王女だ。リンクの友達だ。剣士だ = <span class="blue_text">zeruda</span> <span class="red_text">wa</span> ojoo da. rinku no tomodachi da. kenshi da. = <span class="blue_text">zelda</span> <span class="red_text">est</span> une princesse. <span class="blue_text">zelda</span> <span class="red_text">est</span> l'amie de link. <span class="blue_text">zelda</span> <span class="red_text">est</span> une escrimeuse.
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'blue_text\'>ゼルダ</span><span class=\'red_text\'>は</span>王女だ。リンクの友達だ。剣士だ。'"
      :kanjiraw="'ゼルダは王女だ。リンクの友達だ。剣士だ。'"
      :kana="''" 
      :roomaji="'<span class=\'blue_text\'>zeruda</span> <span class=\'red_text\'>wa</span> ojoo da. rinku no tomodachi da. kenshi da.'"
      :trad="'<span class=\'blue_text\'>zelda</span> <span class=\'red_text\'>est</span> une princesse. <span class=\'blue_text\'>zelda</span> <span class=\'red_text\'>est</span> l\'amie de link. <span class=\'blue_text\'>zelda</span> <span class=\'red_text\'>est</span> une escrimeuse.'" 
      :explication="''"
    />

  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>