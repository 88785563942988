<template>
      <div class="section">
      <h4>
        <div class="section_title">
          Les Combinaisons Katakana
        </div>
      </h4>

      <table class="content-table combinations" id="combinations">
        <thead>
          <tr>
            <th></th>
            <th>キ (ki)</th>
            <th>ギ (gi)</th>
            <th>シ (shi)</th>
            <th>ジ (ji)</th>
            <th>チ (chi)</th>
            <th>ニ (ni)</th>
            <th>ヒ (hi)</th>
            <th>ビ (bi)</th>
            <th>ピ (pi)</th>
            <th>ミ (mi)</th>
            <th>リ (ri)</th>
          </tr>
        </thead>
        <tr>
          <th>ャ (ya)</th>
          <td>キャ (kya)</td>
          <td>ギャ (gya)</td>
          <td>シャ (sha)</td>
          <td>ジャ (ja)</td>
          <td>チャ (cha)</td>
          <td>ニャ (nya)</td>
          <td>ヒャ (hya)</td>
          <td>ビャ (bya)</td>
          <td>ピャ (pya)</td>
          <td>ミャ (mya)</td>
          <td>リャ (rya)</td>
        </tr>
        <tr>
          <th>ュ (yu)</th>
          <td>キュ (kyu)</td>
          <td>ギュ (gyu)</td>
          <td>シュ (shu)</td>
          <td>ジュ (ju)</td>
          <td>チュ (chu)</td>
          <td>ニュ (nyu)</td>
          <td>ヒュ (hyu)</td>
          <td>ビュ (byu)</td>
          <td>ピュ (pyu)</td>
          <td>ミュ (myu)</td>
          <td>リュ (ryu)</td>
        </tr>
        <tr>
          <th>ョ (yo)</th>
          <td>キョ (kyo)</td>
          <td>ギョ (gyo)</td>
          <td>ショ (sho)</td>
          <td>ジョ (jo)</td>
          <td>チョ (cho)</td>
          <td>ニョ (nyo)</td>
          <td>ヒョ (hyo)</td>
          <td>ビョ (byo)</td>
          <td>ピョ (pyo)</td>
          <td>ミョ (myo)</td>
          <td>リョ (ryo)</td>
        </tr>
      </table>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
/*section*/
.section{
  display: inline-block;
  margin: 0 15px;
    .inactif_kanji{
      opacity: 0.4;
  }
}

.section .search,
.section .select{
  float: right;
  margin-top: 9px;
  min-height: 40px;
}

.section .select{
  margin-right: 5px;
}
/*form*/
input, select{
  padding: 10px 6px;
  box-shadow: border-box;
  border: 1px solid #ddd;
  color: #555;
}

/*table*/
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.combinations th {
  background-color: #009879;
  color: #ffffff;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
</style>