<template>
      <div class="section">
      <h4>
        <div class="section_title">
          Les nouvelles Combinaisons Katakana
        </div>
      </h4>

      <table class="content-table combinations" id="combinations">
        <thead>
          <tr>
            <th></th>
            <th>ァ (a)</th>
            <th>ィ (i)</th>
            <th>ゥ (u)</th>
            <th>ェ (e)</th>
            <th>ォ (o)</th>
          </tr>
        </thead>
        <tr>
          <th>フ (f)</th>
          <td>ファ (fa)</td>
          <td>フィ (fi)</td>
          <td>フ (fu)</td>
          <td>フェ (fe)</td>
          <td>フォ (fo)</td>
        </tr>
        <tr>
          <th>ヴ (v)</th>
          <td>ヴァ (va)</td>
          <td>ヴィ (vi)</td>
          <td>ヴ (vu)</td>
          <td>ヴェ (ve)</td>
          <td>ヴォ (vo)</td>
        </tr>
        <tr>
          <th>ウ (w)</th>
          <td>ワ (wa)</td>
          <td>ウィ (wi)</td>
          <td>-</td>
          <td>ウェ (we)</td>
          <td>ウォ (wo (prononciation anglaise))</td>
        </tr>
        <tr>
          <th>テ/ト (t)</th>
          <td>-</td>
          <td>ティ (ti)</td>
          <td>トゥ (tu)</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <th>デ/ド (d)</th>
          <td>-</td>
          <td>ディ (di)</td>
          <td>ドゥ (du)</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <th>シ (sh)</th>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>シェ (she)</td>
          <td>-</td>
        </tr>
        <tr>
          <th>ジ (j)</th>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>ジェ (je)</td>
          <td>-</td>
        </tr>
        <tr>
          <th>チ (ch)</th>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>チェ (che)</td>
          <td>-</td>
        </tr>
        <tr>
          <th>ツ (ts)</th>
          <td>ツァ (tsa)</td>
          <td>ツィ (tsi)</td>
          <td>ツ (tsu)</td>
          <td>ツェ (tse)</td>
          <td>ツォ (tso)</td>
        </tr>
        <tr>
          <th>ス (s)</th>
          <td>-</td>
          <td>スィ (si)</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <th>ス (dy)</th>
          <td>-</td>
          <td>-</td>
          <td>デュ (dyu)</td>
          <td>-</td>
          <td>-</td>
        </tr>
        <tr>
          <th>ク (kw)</th>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>-</td>
          <td>クォ (kwo)</td>
        </tr>
      </table>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
/*section*/
.section{
  display: inline-block;
  margin: 0 15px;
    .inactif_kanji{
      opacity: 0.4;
  }
}

.section .search,
.section .select{
  float: right;
  margin-top: 9px;
  min-height: 40px;
}

.section .select{
  margin-right: 5px;
}
/*form*/
input, select{
  padding: 10px 6px;
  box-shadow: border-box;
  border: 1px solid #ddd;
  color: #555;
}

/*table*/
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.combinations th {
  background-color: #009879;
  color: #ffffff;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
</style>