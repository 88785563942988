<template>
  <div>
    <h1>Adjectifs</h1>

    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Introduction aux adjecctifs en japonais'" :link="'#introduction'" />
    <Button1 :text="'Adjectifs en i'" :link="'#i'" />
    <Button1 :text="'Adjectifs en Na'" :link="'#na'" />

    <h3 class="title_subcontent" id="introduction">Introduction aux adjecctifs en japonais</h3>
    <p class="text_no_margin">
      Les adjectifs servent à décrire les choses (objets, personnes, opinions, ...).
    </p>
    <h4 class="title_no_bottom">Deux groupe Adjectifs</h4>
    <p class="text_no_margin">
      Le premier groupe <span class="red_text">"i"</span> correspond aux adjectifs se terminant par <span class="red_text">-ai -ii -ui -oi</span>, à l’exception de kirai qui est dans "na".<br>
      Le deuxième groupe <span class="red_text">"na"</span> correspond à <span class="red_text">tout le reste</span>, tous ceux qui ne sont pas dans le groupe "i".
    </p>

    <h4 class="title_no_bottom">Intonation</h4>
    <p class="text_no_margin">En donnant de l'intonation on peut modifier la force de l'adjectif.</p>
    <p class="box_example">
      takai = cher <br>
      takai<span class="red_text">!</span> = C'est cher!<br>
      takai<span class="red_text">!!</span> = C'est ultra cher! <br>
    </p>

    <h4 class="title_no_bottom">Mecha</h4>
    <p class="text_no_margin">En prefixant par Mecha on accentue la force de l'adjectif.</p>
    <p class="box_example">
      <span class="red_text">mecha</span> takai! = cher très cher!<br>
    </p>

    <h4 class="title_no_bottom">Desu</h4>
    <p class="text_no_margin">En suffixant par desu, on rend polie la phrase.</p>
    <p class="box_example">
      takai <span class="red_text">desu</span> = c'est cher<br>
    </p>

    <!-- adjectif i -->
    <AdjectifI/>
  
    <!-- adjectif na -->
    <AdjectifNa/>

  </div>
</template>

<script>
import TableVocabulaire from "@/components/Tableau/TableVocabulaire.vue";
import Button1 from "@/components/Buttons/Button1.vue";
import AdjectifI from "@/components/Adjectifs/AdjectifI.vue";
import AdjectifNa from "@/components/Adjectifs/AdjectifNa.vue";

import autoscrollMixin from "@/mixins/autoscrollMixins";

export default {
  name: "Adjectifs",
  data() {
    return {
      id: this.$route.params.id,
    };
  },
  components: {
    TableVocabulaire,
    Button1,
    AdjectifI,
    AdjectifNa
  },
  mixins:[autoscrollMixin]
};
</script>

<style>
</style>