<template>
  <div>
    <h1>Particules</h1>
    <p>Les particules constituent les règles de grammaire de la langue japonaise.</p>

    <h3 class="title_subcontent">Accès rapide</h3>

    <Button1 :text="'Particule No (の)'" :link="'#no'" />
    <Button1 :text="'Particule To (と)'" :link="'#to'" />
    <Button1 :text="'Particule Ya (や)'" :link="'#ya'" />
    <Button1 :text="'Particule Wa (は)'" :link="'#wa'" />
    <Button1 :text="'Particule Ni (に)'" :link="'#ni'" />
    <Button1 :text="'Particule De (で)'" :link="'#de'" />
    <Button1 :text="'Particule E (へ)'" :link="'#e'" />
    <Button1 :text="'Particule O (を)'" :link="'#wo'" />
    <Button1 :text="'Particule Kara et Made (から et　まで)'" :link="'#kara_made'" />
    <Button1 :text="'Particule Ga (が)'" :link="'#ga'" />
    <Button1 :text="'Particule Mo (も)'" :link="'#mo'" />
    <Button1 :text="'Particule Yori (より)'" :link="'#yori'" />
    <Button1 :text="'Particule Ka (か)'" :link="'#ka'" />
    <!--<Button1 :text="'Liste des particules sur Wiki'" :target="'_blank'" :link="'https://fr.wikipedia.org/wiki/Particule_en_japonais'" />-->

    <h3 class="title_subcontent">Par où commencer</h3>
    <p class="text_no_margin">
      Il est conseillé de voir les Particules ici présents dans l'ordre chronologique car chaque nouvelle particule introduite, peut être retrouvée en exemple dans les particules suivantes, pour faire des phrases plus complexes au fur et à mesure.<br>
    </p>

    <ParticuleNo/>
    <ParticuleTo/>
    <ParticuleYa/>
    <ParticuleWa/>
    <ParticuleNi/>
    <ParticuleDe/>
    <ParticuleE/>
    <ParticuleWo/>
    <ParticuleKaraMade/>
    <ParticuleGa/>
    <ParticuleMo/>
    <ParticuleYori/>
    
    <ParticuleKa/>
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import ParticuleWo from "@/components/Particules/ParticuleWo.vue";
import ParticuleNi from "@/components/Particules/ParticuleNi.vue";
import ParticuleE from "@/components/Particules/ParticuleE.vue";
import ParticuleNo from "@/components/Particules/ParticuleNo.vue";
import ParticuleTo from "@/components/Particules/ParticuleTo.vue";
import ParticuleMo from "@/components/Particules/ParticuleMo.vue";
import ParticuleYa from "@/components/Particules/ParticuleYa.vue";
import ParticuleKa from "@/components/Particules/ParticuleKa.vue";
import ParticuleWa from "@/components/Particules/ParticuleWa.vue";
import ParticuleGa from "@/components/Particules/ParticuleGa.vue";
import ParticuleDe from "@/components/Particules/ParticuleDe.vue";
import ParticuleYori from "@/components/Particules/ParticuleYori.vue";
import ParticuleKaraMade from "@/components/Particules/ParticuleKaraMade.vue";

import autoscrollMixin from "@/mixins/autoscrollMixins";

export default {
  name: "Particule",
  components: {
    Button1,
    ParticuleWo,
    ParticuleNi,
    ParticuleE,
    ParticuleNo,
    ParticuleTo,
    ParticuleMo,
    ParticuleYa,
    ParticuleKa,
    ParticuleWa,
    ParticuleGa,
    ParticuleDe,
    ParticuleKaraMade,
    ParticuleYori
  },
  mixins:[autoscrollMixin]
};
</script>

<style>
</style>