<template>
  <div id="ga">
    <h3 class="title_subcontent">Particule : ga (が)</h3>
    <p class="text_no_margin">
      Cette particule est la particule du sujet du verbe et elle s'utilise de différentes façons.<br>
    </p>

    <h4 class="title_no_bottom">Lorsque dans une phrase, le thème de la phrase sera différent du sujet du verbe</h4>
    <p class="text_no_margin">
      Lorsque dans une phrase, le sujet du verbe est différent du thème.<br>
    </p>
    <!--<p class="box_example">
      熊は日本で猿を食べる。= kuma wa nihon de saru o taberu = les ours mangent des signes au japon (phrase normal avec wa seulement)<br>
      日本では、<span class="red_text">熊<b>が</b></span>猿を食べる = nihon de wa, <span class="red_text">kuma <b>ga</b></span> saru o taberu = Au japon, les ours mangent des singes. (au japon lieu de l'action (utilisation de la particule "de") puis de "wa" pour indiquer le japon comme thème. Et ensuite "ga" pour indiquer que ce sont les ours (le sujet) qui mangent (le verbe). Le sujet du verbe manger est donc Ours. notre thème et sujet sont donc différent mais tous se rapport au japon (le thème))<br>
      ミルクは<span class="red_text">虎<b>が</b></span>毎朝飲む = miruku wa <span class="red_text">tora <b>ga</b></span> maiasa nomu = En ce qui concerne le lait, les tigres en boivent tous les matins. (le thème c'est le lait. Le sujet du verbe boire sont les tigres)<br>
    </p>-->
    <BoxExample 
      :kanji="'熊は日本で猿を食べる。'"
      :kanjiraw="'熊は日本で猿を食べる。'"
      :kana="''" 
      :roomaji="'kuma wa nihon de saru o taberu.'"
      :trad="'les ours mangent des singes au Japon.'" 
      :explication="'Phrase normal avec wa seulement.'"
    />
    <BoxExample 
      :kanji="'日本では、<span class=\'red_text\'>熊<b>が</b></span>猿を食べる。'"
      :kanjiraw="'日本では、熊が猿を食べる。'"
      :kana="''" 
      :roomaji="'nihon de wa, <span class=\'red_text\'>kuma <b>ga</b></span> saru o taberu.'"
      :trad="'Au japon, les ours mangent des singes.'" 
      :explication="'“au japon” est le lieu de l\'action (utilisation de la particule “de”) puis de “wa” pour indiquer le japon comme thème. Et ensuite “ga” pour indiquer que ce sont les ours (le sujet) qui mangent (le verbe). Le sujet du verbe manger est donc Ours. notre thème et sujet sont donc différents mais tous se rapportent au Japon (le thème).'"
    />
    <BoxExample 
      :kanji="'ミルクは<span class=\'red_text\'>虎<b>が</b></span>毎朝飲む。'"
      :kanjiraw="'ミルクは虎が毎朝飲む。'"
      :kana="''" 
      :roomaji="'miruku wa <span class=\'red_text\'>tora <b>ga</b></span> maiasa nomu.'"
      :trad="'En ce qui concerne le lait, les tigres en boivent tous les matins.'" 
      :explication="'Le thème c\'est le lait. Le sujet du verbe boire sont les tigres.'"
    />

    <h4 class="title_no_bottom">Dans une phrase sans thème pour insister sur le sujet du verbe ou pour donner une toute nouvelle information</h4>
    <p class="text_no_margin">
      On va remplacer le thème "wa" par "ga" pour insister sur le sujet du verbe et lui donner une plus haute importance au sujet.<br>
    </p>
    <!--<p class="box_example">
      <span class="blue_text">サン<b>は</b></span>もののけ姫だ。= <span class="blue_text">san <b>wa</b></span> mononoke hime da = San est la princesse Mononoké <br>
      <span class="red_text">サン<b>が</b></span>もののけ姫だ。= <span class="red_text">san <b>ga</b></span> mononoke hime da = C'est San, qui est la princesse Mononoké  (en transformant le thème en sujet, on insiste et met de l'importance sur le sujet du verbe.)<br> 
      Si on nous demande qui est la princesse monoké ? on répondra avec "が" (ga) pour insister, que c'est San !<br>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'blue_text\'>サン<b>は</b></span>もののけ姫だ。'"
      :kanjiraw="'サンはもののけ姫だ。'"
      :kana="''" 
      :roomaji="'<span class=\'blue_text\'>san <b>wa</b></span> mononoke hime da.'"
      :trad="'San est la princesse Mononoké.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'red_text\'>サン<b>が</b></span>もののけ姫だ。'"
      :kanjiraw="'サンがもののけ姫だ。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>san <b>ga</b></span> mononoke hime da.'"
      :trad="'C\'est San, qui est la princesse Mononoké.'" 
      :explication="'En transformant le thème en sujet, on insiste et met de l\'importance sur le sujet du verbe. <br>Si on nous demande qui est la princesse monoké ? on répondra avec “が” (ga) pour insister, que c\'est San !'"
    />
    <p class="text_no_margin">
      Pour donner une toute nouvelle information<br>
    </p>
    <!--<p class="box_example">
      <span class="blue_text">イノシシ<b>は</b></span>来る = En ce qui concerne les sangliers, ils viennent (dans notre conversation, on sait déjà que l'on parle de sanglier)<br>
      <span class="red_text">イノシシ<b>が</b></span>来る! = Des sangliers viennent! (on porte ici une nouvelle information, dans notre conversation on ne parlait pas de sanglier à la base. En ne mettant pas de thème, on comprend que l'information est entièrement nouvelle).<br>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'blue_text\'>イノシシ<b>は</b></span>来る。'"
      :kanjiraw="'イノシシは来る。'"
      :kana="''" 
      :roomaji="'<span class=\'blue_text\'>inoshishi <b>wa</b></span> kuru.'"
      :trad="'En ce qui concerne les sangliers, ils viennent.'" 
      :explication="'Dans notre conversation, on sait déjà que l\'on parle de sanglier.'"
    />
    <BoxExample 
      :kanji="'<span class=\'red_text\'>イノシシ<b>が</b></span>来る!'"
      :kanjiraw="'イノシシが来る! '"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>inoshishi <b>ga</b></span> kuru!'"
      :trad="'Des sangliers viennent!'" 
      :explication="'On porte ici une nouvelle information, dans notre conversation on ne parlait pas de sanglier à la base. En ne mettant pas de thème, on comprend que l\'information est entièrement nouvelle.'"
    />

    <h4 class="title_no_bottom">Exprimer une présence "il y a"</h4>
    <p class="text_no_margin">
      la particule est utilisée avec les verbes "ある" (aru) et "いる" (iru) pour indiquer une présence. « <b>il y a</b> » en français.<br>
      <span class="blue_text">ga + aru ou iru</span></p>
    <!--<p class="box_example">
      公園に<span class="red_text">子供<b>が</b></span>いる = Kouen ni <span class="red_text">kodomo <b>ga</b></span> iru = <span class="red_text"><b>Il y a</b> des enfants</span> dans le parc.<br>
      木の前には<span class="red_text">ライオン<b>が</b></span>いる = ki no mae ni wa <span class="red_text">raion <b>ga</b></span> iru = Devant l'arbre, <span class="red_text"><b>il y a</b> un lion</span>.<br>
      <span class="red_text">ライオン<b>が</b></span>いる = <span class="red_text">raion <b>ga</b></span> iru = <span class="red_text"><b>il y a</b> un lion</span><br>
    </p>-->
    <BoxExample 
      :kanji="'公園に<span class=\'red_text\'>子供<b>が</b></span>いる。'"
      :kanjiraw="'公園に子供がいる。'"
      :kana="''" 
      :roomaji="'Kouen ni <span class=\'red_text\'>kodomo <b>ga</b></span> iru.'"
      :trad="'<span class=\'red_text\'><b>Il y a</b> des enfants</span> dans le parc.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'木の前には<span class=\'red_text\'>ライオン<b>が</b></span>いる。'"
      :kanjiraw="'木の前にはライオンがいる。'"
      :kana="''" 
      :roomaji="'ki no mae ni wa <span class=\'red_text\'>raion <b>ga</b></span> iru.'"
      :trad="'Devant l\'arbre, <span class=\'red_text\'><b>il y a</b> un lion</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'red_text\'>ライオン<b>が</b></span>いる。'"
      :kanjiraw="'ライオンがいる。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>raion <b>ga</b></span> iru.'"
      :trad="'<span class=\'red_text\'><b>il y a</b> un lion</span>'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Exprimer le verbe "avoir"</h4>
    <p class="text_no_margin">
      En japonais il n'existe pas de verbe "avoir" en tant que tel, on va utiliser la particule "が" (ga) pour l'exprimer
    </p>
    <!--<p class="box_example">
      キリンは<span class="red_text">角<b>が</b></span>ある。= kirin wa <span class="red_text">tsuno <b>ga</b></span> aru = En ce qui concerne la girafe, <span class="red_text"><b>il y a</b> des cornes</span>. (que l'on va interprété par "la girafe a des cornes")<br> 
      私は<span class="red_text">妹<b>が</b></span>いる = watashi wa <span class="red_text">imouto <b>ga</b></span> iru = en ce qui me concerne, <span class="red_text"><b>il y a</b> une soeur</span> = <span class="red_text"><b>j'ai</b> une petite soeur</span>.
    </p>-->
    <BoxExample 
      :kanji="'キリンは<span class=\'red_text\'>角<b>が</b></span>ある。'"
      :kanjiraw="'キリンは角がある。'"
      :kana="''" 
      :roomaji="'kirin wa <span class=\'red_text\'>tsuno <b>ga</b></span> aru.'"
      :trad="'En ce qui concerne la girafe, <span class=\'red_text\'><b>il y a</b> des cornes</span>. (que l\'on va interprété par “la girafe a des cornes”).'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'私は<span class=\'red_text\'>妹<b>が</b></span>いる。'"
      :kanjiraw="'私は妹がいる。'"
      :kana="''" 
      :roomaji="'watashi wa <span class=\'red_text\'>imouto <b>ga</b></span> iru.'"
      :trad="'En ce qui me concerne, <span class=\'red_text\'><b>il y a</b> une soeur</span> = <span class=\'red_text\'><b>j\'ai</b> une petite soeur</span>.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">En complément d'objet direct avec des ajectifs</h4>
    <p class="text_no_margin">En COD avec des ajectifs pouvant exprimer la préférence, la compétence, le désir, etc <br>
    <span class="blue_text">ga + adjectif</span></p>
    <!--<p class="box_example">
      <span class="red_text">バナナ<b>が</b></span>大好き = <span class="red_text">banana <b>ga</b></span> daisuki = j'adore les banane (ga + adjectif "大好き" (daisuki (aimer)))<br>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>バナナ<b>が</b></span>大好き。'"
      :kanjiraw="'バナナが大好き。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>banana <b>ga</b></span> daisuki.'"
      :trad="'j\'adore les banane.'" 
      :explication="'ga + adjectif “大好き” (daisuki (aimer)).'"
    />
  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>