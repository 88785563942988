<template>
  <div id="ya">
    <h3 class="title_subcontent">Particule : ya (や)</h3>
    <p class="text_no_margin">Cette particule permet d'exprimer une énumération non finie (non-exhaustive) contrairement à "to" qui est fini.<br>
    Elle peut être comparé à <b>"etc"</b> ou <b>"il y a entre autres"</b> en français avec une liste non-exhaustive.<br>
    Cette particule se place du coup à chaque fois entre deux noms comme pour "to".<br>
    <span class="blue_text">nom + ya + nom</span></p>
    <!--<p class="box_example">
        sakana <span class="red_text">ya</span> niku = <span class="red_text">il y a entre autres</span> de la viande <span class="red_text">et</span> du poisson<br>
        sakana <span class="red_text">ya</span> niku <span class="red_text">ya</span> yasai = de la viande <span class="red_text">et</span> du poisson <span class="red_text">et</span> des légumes, <span class="red_text">etc</span><br>
    </p>-->
    <BoxExample 
      :kanji="'魚<span class=\'red_text\'>や</span>肉。'"
      :kanjiraw="'魚や肉。'"
      :kana="''" 
      :roomaji="'sakana <span class=\'red_text\'>ya</span> niku.'"
      :trad="'<span class=\'red_text\'>il y a entre autres</span> de la viande <span class=\'red_text\'>et</span> du poisson.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'魚<span class=\'red_text\'>や</span>肉<span class=\'red_text\'>や</span>野菜。'"
      :kanjiraw="'魚や肉や野菜。'"
      :kana="''" 
      :roomaji="'sakana <span class=\'red_text\'>ya</span> niku <span class=\'red_text\'>ya</span> yasai.'"
      :trad="'de la viande <span class=\'red_text\'>et</span> du poisson <span class=\'red_text\'>et</span> des légumes, <span class=\'red_text\'>etc</span>.'" 
      :explication="''"
    />

  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>