<template>
  <div id="yori">
    <h3 class="title_subcontent">Particule : yori (より)</h3>
    <p class="text_no_margin">Cette particule s'utilise de deux façons différentes</p>

    <h4 class="title_no_bottom">Indiquer un point de départ comme Kara (en plus poli)</h4>
    <p class="text_no_margin">
        La particule s'utilise exactement comme "から" (kara), on vient même remplacer la particule par "より" (yori).<br>
        La particule sera plus utilisé dans une phrase de style soutenu, plus formel, pour la rendre plus polie (programme tv, présentation télé, etc).<br>
        En général dans la vie de tous les jours "から" (kara) sera plus utilisé.<br>
    </p>
    <!--<p class="box_example">
       映画は<span class="red_text">6時<b>から</b></span>始まる = eiga wa <span class="red_text">6ji <b>kara</b></span> hajimaru = le film commence <span class="red_text"><b>à</b> 6h</span> (utilisation de kara)<br>
       映画は<span class="red_text">6時<b>より</b></span>始まる = eiga wa <span class="red_text">6ji <b>yori</b></span> hajimaru = le film commence <span class="red_text"><b>à</b> 6h</span> (la phrase est juste plus poli, plus formel)<br>
       私はバスで<span class="red_text">京都<b>から</b></span>東京までいく = watashi wa basu de <span class="red_text">kyoto <b>kara</b></span> tokyo made iku = je vais <span class="red_text"><b>de</b> Kyoto</span> à Tokyo en bus (utilisation de kara et made)<br>
       私はバスで<span class="red_text">京都<b>よち</b></span>東京までいく = watashi wa basu de <span class="red_text">kyoto <b>yori</b></span> tokyo made iku = je vais <span class="red_text"><b>de</b> Kyoto</span> à Tokyo en bus (utilisation de yori et made, même chose mais avec une phrase plus poli)<br>
    </p>-->
    <BoxExample 
      :kanji="'映画は<span class=\'red_text\'>6時<b>から</b></span>始まる。'"
      :kanjiraw="'映画は6時から始まる。'"
      :kana="''" 
      :roomaji="'eiga wa <span class=\'red_text\'>6ji <b>kara</b></span> hajimaru.'"
      :trad="'le film commence <span class=\'red_text\'><b>à</b> 6h</span>.'" 
      :explication="'Utilisation de kara.'"
    />
    <BoxExample 
      :kanji="'映画は<span class=\'red_text\'>6時<b>より</b></span>始まる。'"
      :kanjiraw="'映画は6時より始まる。'"
      :kana="''" 
      :roomaji="'eiga wa <span class=\'red_text\'>6ji <b>yori</b></span> hajimaru.'"
      :trad="'le film commence <span class=\'red_text\'><b>à</b> 6h</span>.'" 
      :explication="'La phrase est juste plus polie, plus formel.'"
    />
    <BoxExample 
      :kanji="'私はバスで<span class=\'red_text\'>京都<b>から</b></span>東京までいく。'"
      :kanjiraw="'私はバスで京都から東京までいく。'"
      :kana="''" 
      :roomaji="'watashi wa basu de <span class=\'red_text\'>kyoto <b>kara</b></span> tokyo made iku.'"
      :trad="'je vais <span class=\'red_text\'><b>de</b> Kyoto</span> à Tokyo en bus.'" 
      :explication="'Utilisation de kara et made.'"
    />
    <BoxExample 
      :kanji="'私はバスで<span class=\'red_text\'>京都<b>よち</b></span>東京までいく。'"
      :kanjiraw="'私はバスで京都よち東京までいく。'"
      :kana="''" 
      :roomaji="'watashi wa basu de <span class=\'red_text\'>kyoto <b>yori</b></span> tokyo made iku.'"
      :trad="'je vais <span class=\'red_text\'><b>de</b> Kyoto</span> à Tokyo en bus.'" 
      :explication="'Utilisation de yori et made, même chose mais avec une phrase plus polie.'"
    />

    <h4 class="title_no_bottom">Indiquer la base, pour une comparaison</h4>
    <p class="text_no_margin">
      La particule va servir de base de comparaison.<br>
      En français on peut comparer la particule "より" (yori) à "<b>par rapport à</b>", "<b>comparer à</b>", "<b>plutôt que</b>".<br>
      On place la particule "より" (yori) après le sujet de comparaison.<br>
    </p>
    <!--<p class="box_example">
      馬は<span class="red_text">肉<b>よち</b></span>花を食べる。= uma wa <span class="red_text">niku <b>yori</b></span> hana o taberu = les chevaux mange des fleurs <span class="red_text"><b>plutôt</b> que de la viande</span> (Dans le contexte on sait que l'on compare la viande et les fleurs).<br>
      馬は<span class="red_text">牛<b>より</b></span>花を食べる。= uma wa <span class="red_text">ushi <b>yori</b></span> hana o taberu = les chevaux mangent des fleurs <span class="red_text"><b>plutôt</b> que les vaches</span> (Dans le contexte on sait que l'on compare des chevaux et des vaches, quand bien même la phrase de base n'as pas changé)<br>
    </p>-->
    <BoxExample 
      :kanji="'馬は<span class=\'red_text\'>肉<b>よち</b></span>花を食べる。'"
      :kanjiraw="'馬は肉よち花を食べる。'"
      :kana="''" 
      :roomaji="'uma wa <span class=\'red_text\'>niku <b>yori</b></span> hana o taberu.'"
      :trad="'les chevaux mange des fleurs <span class=\'red_text\'><b>plutôt</b> que de la viande</span>.'" 
      :explication="'Dans le contexte on sait que l\'on compare la viande et les fleurs.'"
    />
    <BoxExample 
      :kanji="'馬は<span class=\'red_text\'>牛<b>より</b></span>花を食べる。'"
      :kanjiraw="'馬は牛より花を食べる。'"
      :kana="''" 
      :roomaji="'uma wa <span class=\'red_text\'>ushi <b>yori</b></span> hana o taberu.'"
      :trad="'les chevaux mangent des fleurs <span class=\'red_text\'><b>plutôt</b> que les vaches</span>.'" 
      :explication="'Dans le contexte on sait que l\'on compare des chevaux et des vaches, quand bien même la phrase de base n\'as pas changé.'"
    />
    <p class="text_no_margin">
      Pour indiquer la fréquence, quantité dans notre comparaison on va utiliser des adverbes et adjectifs comme "よく" (yoku (très souvent)).<br>
      Ces adverbes de fréquence, quantités seront placés juste avant le verbe.<br>
    </p>
    <!--<p class="box_example">
      馬は<span class="red_text">牛<b>より</b></span>花を<span class="blue_text">よく</span>食べる。= uma wa <span class="red_text">ushi <b>yori</b></span> hana o <span class="blue_text">yoku</span> taberu = les chevaux mangent <span class="blue_text">plus</span> des fleurs <span class="red_text"><b>plutôt</b> que les vaches</span> (on a ajouté yoku avant le verbe pour indiquer une quantité).<br>
    </p>-->
    <BoxExample 
      :kanji="'馬は<span class=\'red_text\'>牛<b>より</b></span>花を<span class=\'blue_text\'>よく</span>食べる。'"
      :kanjiraw="'馬は牛より花をよく食べる。'"
      :kana="''" 
      :roomaji="'uma wa <span class=\'red_text\'>ushi <b>yori</b></span> hana o <span class=\'blue_text\'>yoku</span> taberu.'"
      :trad="'les chevaux mangent <span class=\'blue_text\'>plus</span> des fleurs <span class=\'red_text\'><b>plutôt</b> que les vaches</span>.'" 
      :explication="'On a ajouté yoku avant le verbe pour indiquer une quantité.'"
    />
    <p class="text_no_margin">
      Parfois il peut y avoir des ambiguïtés de comparaison. Dans ce cas pour palier à ces problèmes d'ambiguïté on peut ajouter "の方" (nohou) qui va indiquer dans la phrase le comparé.<br>
      "の方" (nohou) va mettre l'accent sur ce qu'il y a en plus ou en moins.<br>
    </p>
    <!--<p class="box_example">
      ワニは<span class="red_text">蛇<b>より</b></span>くもを<span class="blue_text">よく</span>食べる。= wani wa <span class="red_text">ebi <b>yori</b></span> kumo o <span class="blue_text">yoku</span> taberu = les crocodiles mangent <span class="blue_text">plus</span> d'araignées <span class="red_text">que les serpents</span>. <br>
      il y a ici une ambiguïté, on ne sait pas si "les crocodiles mangent plus d'araignées que de serpents" ou "les crocodiles mangent plus d'araignées que les serpents n'en mangents" <br>
    </p>-->
    <BoxExample 
      :kanji="'ワニは<span class=\'red_text\'>蛇<b>より</b></span>くもを<span class=\'blue_text\'>よく</span>食べる。'"
      :kanjiraw="'ワニは蛇よりくもをよく食べる。'"
      :kana="''" 
      :roomaji="'wani wa <span class=\'red_text\'>ebi <b>yori</b></span> kumo o <span class=\'blue_text\'>yoku</span> taberu.'"
      :trad="'les crocodiles mangent <span class=\'blue_text\'>plus</span> d\'araignées <span class=\'red_text\'>que les serpents</span>.'" 
      :explication="'Il y a ici une ambiguïté, on ne sait pas si “les crocodiles mangent plus d\'araignées que de serpents” ou “les crocodiles mangent plus d\'araignées que les serpents n\'en mangents”.'"
    />
    <!--<p class="box_example">
      <span class="green_text">ワニ<b>の方</b>が</span><span class="red_text">蛇<b>より</b></span>くもを<span class="blue_text">よく</span>食べる。= <span class="green_text">wani <b>no hou</b> ga</span> <span class="red_text">ebi <b>yori</b></span> kumo o <span class="blue_text">yoku</span> taberu = <span class="green_text">les crocodiles</span> mangent <span class="blue_text">plus</span> d'araignées <span class="red_text">que les serpents</span>. <br>
      on à ajouté "の方" (nohou) aux crocodiles pour être le comparé par rapport au serpent et on va remplacer "は"(wa) par "が"(ga) pour insister sur le sujet comparé. ici on aura "les crocodiles mangent plus d'araignées que les serpents n'en mangents"<br>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'green_text\'>ワニ<b>の方</b>が</span><span class=\'red_text\'>蛇<b>より</b></span>くもを<span class=\'blue_text\'>よく</span>食べる。'"
      :kanjiraw="'ワニの方が蛇よりくもをよく食べる。'"
      :kana="''" 
      :roomaji="'<span class=\'green_text\'>wani <b>no hou</b> ga</span> <span class=\'red_text\'>ebi <b>yori</b></span> kumo o <span class=\'blue_text\'>yoku</span> taberu.'"
      :trad="'<span class=\'green_text\'>les crocodiles</span> mangent <span class=\'blue_text\'>plus</span> d\'araignées <span class=\'red_text\'>que les serpents</span>.'" 
      :explication="'On à ajouté “の方” (nohou) aux crocodiles pour être le comparé par rapport au serpent et on va remplacer “は”(wa) par “が”(ga) pour insister sur le sujet comparé. ici on aura “les crocodiles mangent plus d\'araignées que les serpents n\'en mangents”.'"
    />
    <!--<p class="box_example">
      ワニは<span class="red_text">蛇<b>より</b></span><span class="green_text">くも<b>の方</b>を</span><span class="blue_text">よく</span>食べる。= wani wa <span class="red_text">ebi <b>yori</b></span> <span class="green_text">kumo <b>no hou</b> o</span> <span class="blue_text">yoku</span> taberu = les crocodiles mangent <span class="blue_text">plus</span> <span class="green_text">d'araignées</span> <span class="red_text">que les serpents</span>. <br>
      on à ajouté "の方" (nohou) à l'araigné pour être le comparé. ici on aura "les crocodiles mangent plus d'araignées que de serpents"<br>
    </p>-->
    <BoxExample 
      :kanji="'ワニは<span class=\'red_text\'>蛇<b>より</b></span><span class=\'green_text\'>くも<b>の方</b>を</span><span class=\'blue_text\'>よく</span>食べる。'"
      :kanjiraw="'ワニは蛇よりくもの方をよく食べる。'"
      :kana="''" 
      :roomaji="'wani wa <span class=\'red_text\'>ebi <b>yori</b></span> <span class=\'green_text\'>kumo <b>no hou</b> o</span> <span class=\'blue_text\'>yoku</span> taberu.'"
      :trad="'les crocodiles mangent <span class=\'blue_text\'>plus</span> <span class=\'green_text\'>d\'araignées</span> <span class=\'red_text\'>que les serpents</span>.'" 
      :explication="'On à ajouté “の方” (nohou) à l\'araigné pour être le comparé. ici on aura “les crocodiles mangent plus d\'araignées que de serpents”.'"
    />
    <p class="text_no_margin">
      En japonais, 方 (hou) indique entre autres la direction, le côté. C'est pourquoi on utilise の方 pour indiquer le comparé dans le cas d'une comparaison ambiguë.<br>
    </p>
    <Button1 :text="'Vidéo explicatif plus détaillé sur la comparaison'" :target="'_blank'" :link="'https://www.youtube.com/watch?v=s1m91QGqiOU'" />
    
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
    name: 'ParticuleYori',
    components: {
        Button1,
        BoxExample
    },
}
</script>

<style>

</style>