<template>
  <div>
    <h1>Écriture et Lecture Japonais</h1>

    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Introduction générale à l\'écritures de la langue'" :link="'#intro_general'" />
    <Button1 :text="'Explication des différentes écritures de la langue'" :link="'#explica_detaille'" />
    <Button1 :text="'Les Tracés'" :link="'#trace'" />
    <Button1 :text="'Tableaux des Hiragana et Katakana'" :link="'#kana_table'" />
    <Button1 :text="'Règle de Prononciation'" :link="'#prononciation'" />
    <Button1 :text="'Règle de la Pause'" :link="'#pause'" />
    <Button1 :text="'Règle des Allongements'" :link="'#allongements'" />
    <Button1 :text="'Règle et tableaux des Combinaisons'" :link="'#combinaisons'" />
    <Button1 :text="'Kanji, utilisation et prononciation particulière'" :link="'#kanji_utilisation'" />
    <Button1 :text="'Kanji, Les cas particuliers'" :link="'#kanji_cas_particuliers'" />
    <Button1 :text="'Les prénom et noms japonais'" :link="'#prenom_nom'" />
    <Button1 :text="'La Ponctuation japonaise'" :link="'#ponctuation'" />

    <h3 class="title_subcontent" id="intro_general">Introduction générale à l'écritures de la langue</h3>

    <h4 class="title_no_bottom">Hiragana, Katakana et Kanji</h4>
    <p class="text_no_margin">
      - L'alphabet japonais est un alphabet de syllabes (syllabique) ayant pour base 5 Voyelles italiennes "a", "i", "u", "e", "o" et des combinaisons avec d'autre Consonnes anglaises "k", "s", ...<br>
      - L'alphabet japonais possède de base 3 systèmes d'écriture, les Hiragana, les Katakana et les Kanji. Ces trois-là se mélangent et peuvent s'utiliser dans une même phrase.<br> 
      - Les Hiragana et Katakana constituent les "kana" qui représentent des lettres et les kanji constituent les logogrammes, idéogrammes et pictogrammes qui représentent des choses (objet, idée, ...).<br>
      - Les phrases s'écrivent sans espace. Étant donné que dans les 3 systèmes d'écritures, la forme des kana et kanji sont différents, on arrive à les différencier lorsque les trois systèmes sont utilisés ensemble dans une même phrase.<br>
      - Historiquement les Hiragana et Katakana sont issus de Kanji qui ont été simplifié avec le temps.<br>
    </p>
    
    <h4 class="title_no_bottom">Sens d'écriture</h4>
    <p class="text_no_margin">
      - Il existe deux sens écritures (les deux sont énormément utilisés) : <br>
      <span class="text_li">- horizontale : venu de l'occident et dont le sens de lecture des caractères est de gauche à droite puis des lignes de haut en bas comme en français.</span><br>
      <span class="text_li">- verticale : le sens traditionnel, d'origine japonais dont les caractères se lit de haut en bas puis des colonnes de droite à gauche. (Sens qu'on retrouve dans les mangas, les romans, les journaux japonais).</span><br>
      - On remarquera, que l'écriture japonais prend du coup, beaucoup moins de caractères pour dire la même chose en français.
    </p><br>
    <!--<p class="box_example">
      sens horizontale: lecture des caractères de gauche à droite puis des lignes de haut en bas (12 caractères en japonais, 47 caractères en français): <br>
      庭で美味しい魚を食べた。 = niwa de oishii sakana o tabeta。= j'ai mangé de délicieux poisson dans le jardin.<br>
      <br>
      sens verticale: lecture des caractères de haut en bas puis des colonnes de droite à gauche: <br>
      <span style="writing-mode: vertical-rl;text-orientation: upright;">庭で美味しい<br>魚を食べた。</span> = <span style="writing-mode: vertical-rl;text-orientation: upright;">niwa de oishii<br> sakana o tabeta。</span> = j'ai mangé de délicieux poisson dans le jardin.<br>
    </p>-->
    Sens horizontale : lecture des caractères de gauche à droite puis des lignes de haut en bas (12 caractères en japonais, 47 caractères en français) :<br>
    <BoxExample 
      :kanji="'庭で美味しい魚を食べた。'"
      :kanjiraw="'庭で美味しい魚を食べた。'"
      :roomaji="'niwa de oishii sakana o tabeta。'"
      :trad="'j\'ai mangé de délicieux poisson dans le jardin.'" 
      :explication="''"
    />
    Sens verticale : lecture des caractères de haut en bas puis des colonnes de droite à gauche : <br>
    <BoxExample 
      :kanji="'<span style=\'writing-mode: vertical-rl;text-orientation: upright;\'>庭で美味しい<br>魚を食べた。</span>'"
      :kana="''" 
      :roomaji="'<span style=\'writing-mode: vertical-rl;text-orientation: upright;\'>niwa de oishii<br> sakana o tabeta。</span>'"
      :trad="'j\'ai mangé de délicieux poisson dans le jardin.'" 
      :explication="''"
    />
    <div class="legend">
      <span class="legend_title">Légendes des icônes : </span>
      <i class="fas fa-book legend_icon"></i> Kanji,  
      <i class="fas fa-book-open legend_icon"></i> Kana,
      <i class="fas fa-bookmark legend_icon"></i> Rōmaji,
      <i class="fas fa-audio-description legend_icon"></i> Traduction,
      <i class="fas fa-question-circle legend_icon"></i> Explication
    </div>

    <h3 class="title_subcontent" id="explica_detaille">Explication détaillés des différentes écritures de la langue</h3>

    <h4 class="title_no_bottom">Hiragana (ひらがな)</h4>
    <p class="text_no_margin">
      - Les hiragana sont utilisés pour les mots issus de la langue japonaise et chinoise.<br>
      - Ils se distinguent par leur forme simplifiée et arrondi.<br>
    </p>
    <!--<p class="box_example">
      ここに   = koko ni (ici)<br>
    </p>-->
    <BoxExample 
      :kanji="''"
      :kana="'ここに'" 
      :roomaji="'koko ni'"
      :trad="'ici'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Katakana (カタカナ)</h4>
    <p class="text_no_margin">
      - Les katakana sont utilisés pour les mots, prénom et pays d'origine étranger, leur écriture et prononciation en katakana se base seulement sur la phonétique du mot étranger.<br>
      - ils sont également utilisés comme onomatopée dans les mangas.<br>
      - Les katakana se distinguent par leur forme simplifiée et carré.<br>
    </p>
    <!--<p class="box_example">
      カメラ = kamera (Camera)<br>
      フルーツ = furuutsu (Fruit)<br>
      フランス = furansu (France)<br>
      アンリ・ベール = anri・beeru (Henri beyle)(prénom nom avec le nakaten au milieu pour différencier le prénom et nom)<br>
      ジャン=ジャック = jan=jakku (Jean-Jaques)(le tiret correspond à un égale en japonais pour le prénom et nom)
      ビッグ = biggu (Big)<br>
      クリスタル = kurisutaru (crystal)<br>
      タクシー = takushii (taxi)
    </p>-->
    <BoxExample 
      :kanji="''"
      :kana="'カメラ'" 
      :roomaji="'kamera'"
      :trad="'Caméra'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="''"
      :kana="'フルーツ'" 
      :roomaji="'furuutsu'"
      :trad="'Fruit'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="''"
      :kana="'フランス'" 
      :roomaji="'furansu'"
      :trad="'France'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="''"
      :kana="'アンリ・ベール'" 
      :roomaji="'anri・beeru'"
      :trad="'Henri beyle'" 
      :explication="'Prénom et nom avec le nakaten au milieu pour différencier le prénom du nom.'"
    />
    <BoxExample 
      :kanji="''"
      :kana="'ジャン=ジャック'" 
      :roomaji="'jan=jakku'"
      :trad="'Jean-Jaques'" 
      :explication="'Le tiret correspond à un égal en japonais pour le prénom et nom.'"
    />
    <BoxExample 
      :kanji="''"
      :kana="'ビッグ'" 
      :roomaji="'biggu'"
      :trad="'Big'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="''"
      :kana="'クリスタル'" 
      :roomaji="'kurisutaru'"
      :trad="'Crystal'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="''"
      :kana="'タクシー'" 
      :roomaji="'takushii'"
      :trad="'Taxi'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Kanji (漢字)</h4>
    <p class="text_no_margin">
      - Les kanjis sont des logogrammes (idéogramme, pictogramme, ...) issus à la base des caractères chinois et qui représentent des choses (objet, idée, ...) contrairement au kana (hiragana et katakana) qui représente des lettres.<br>
    </p>
    <!--<p class="box_example">
      火 (hi) = feu (le kanji à la forme d'un feu de camp. il est proche d'un pictogramme)<br>
      猫 (neko) = chat (le kanji est plus proche d'un idéogramme ici)<br>
    </p>-->
    <BoxExample 
      :kanji="''"
      :kanjiraw="'火'"
      :roomaji="'hi'"
      :trad="'feu'" 
      :explication="'Le kanji à la forme d\'un feu de camp. Il est proche d\'un pictogramme.'"
    />
    <BoxExample 
      :kanji="''"
      :kanjiraw="'猫'"
      :roomaji="'neko'"
      :trad="'chat'" 
      :explication="'Le kanji est plus proche d\'un idéogramme ici.'"
    />
    <p class="text_no_margin">
      - Les kanjis sont majoritairement utilisés car ils permettent de comprendre plus rapidement le sens de la phrase et de repérer rapidement les séparations de mots.<br>
      - Ils se font remarquer par un nombre de trait généralement plus élevé que les kana (hiragana et katakana) et leur forme plus complexe.<br>
    </p>
    <!--<p class="box_example">
      ねこがいますか = nekogaimasuka = estcequilyaunchat? (sans kanji) <br>
      猫が居ますか = neko ga imasu ka = Est-ce qu'il y a un chat? (avec kanji)<br>
    </p>-->
    <BoxExample 
      :kanji="''"
      :kanjiraw="''"
      :kana="'ねこがいますか'" 
      :roomaji="'nekogaimasuka'"
      :trad="'estcequilyaunchat?'" 
      :explication="'Sans kanji, on repère plus difficilement la délimitation des différents mots.'"
    />
    <BoxExample 
      :kanji="'猫が居ますか'"
      :kanjiraw="'猫が居ますか'"
      :kana="''" 
      :roomaji="'neko ga imasu ka'"
      :trad="'Est-ce qu\'il y a un chat ?'" 
      :explication="'Avec kanji, on repère mieux les différents mots'"
    />
    <p class="text_no_margin">
      - Les kanjis peuvent être combiné pour donner une autre idée (kanji de Tokyo = kanji capitale + kanji de l'est).<br>
    </p>
    <!--<p class="box_example">
      東京 = Tokyo (東 = Est et 京 = Capitale (Capitale de Est) )<br>
    </p>-->
    <BoxExample 
      :kanji="'東京'"
      :kanjiraw="'東京'"
      :kana="''" 
      :roomaji="''"
      :trad="'Tokyo'" 
      :explication="'東 = Est et 京 = Capitale. Combiné ceci donne la capitale de l\'est.'"
    />
    <p class="text_no_margin">
      - Il existe à peu près 2 000 Kanji utiles pour la vie courante, 5 000 Kanji pour les professeurs université et ils existent plus de 50 000 Kanji différents.<br>
      - Les kanji viennent originalement du chinois, dont les japonais ont associé leur mot, vocabulaire à ces kanjis par la suite, ce qui a créé 2 lectures : <br>
      <span class="text_li">- Kun yomi : la lecture du kanji venant du japonais (purement japonaise). Que l'on retrouve traduit en Hiragana dans le dictionnaire.</span><br>
      <span class="text_li">- On yomi : la lecture du kanji venant du chinois (sino-japonaise). Que l'on retrouve traduit en Katakana dans le dictionnaire.</span><br>
      - Règle d'utilisation du Kanji : <br>
      <span class="text_li">- Lorsqu'on utilise un Kanji seul pour créer un mot, on le lit dans sa lecture purement japonaise.</span><br>
      <span class="text_li">- Lorsqu'on utilise une combinaison de Kanji pour créer un mot, on les lit dans leur lecture chinoise (sino-japonaise).</span><br>
    </p>
    <BoxExample 
      :kanji="''"
      :kanjiraw="'山'"
      :kana="''" 
      :roomaji="'yama'"
      :trad="'Montagne'" 
      :explication="'(purement japonais : やま) yama OU (sino-japonais : サン) san. Kanji seul on utilise la lecture purement japonais.'"
    />
    <p class="box_example">
      <!--山 = (purement japonais : やま) yama OU (sino-japonais : サン) san = Montagne<br>-->
      白 (shiro) (purement japonais) = Blanc<br> 
      鳥 (tori) (purement japonais) = Oiseau<br> 
      白鳥 (hakuchou = haku (sino-japonais) + chou (sino-japonais)) = Cygne <br>
    </p>
    <p class="text_no_margin">
    - Il arrive parfois qu'un Kanji possède seulement une lecture sino-japonaise, car le mot n'existait pas en japonais à l'origine.<br>
    </p>
    <p class="box_example">
      週 = sino-japonais : しゅう (shuu) = Semaine<br>
    </p>
    <p class="text_no_margin">
    - Il arrive souvent qu'un kanji possède plusieurs lectures Kunyomi et Onyomi, correspondant à plusieurs nuances de représentation du kanji.<br>
    </p>
    <p class="box_example">
      月 = sino-japonais : ゲツ (getsu) = lune ou ガツ (gatsu) = mois (car le mois fait référence au calendrier lunaire)<br>
    </p>
    <p class="text_no_margin">
    - Il arrive qu'un kanji possède plusieurs interprétations.<br>
    </p>
    <p class="box_example">
      本 = purement japonais: もと (moto) = origine et sino-japonais: ホン (livre) <br>
    </p>
    <p class="text_no_margin">
    - Les okurigana sont des hiragana qui servent de terminaison à des kanjis.<br>
    </p>
    <!--<p class="box_example">
      短い = みじかい (mijikai (purement japonais)) (ici le "i" fait office de okurigana) = court<br>
      食べる = たべる (taberu (purement japonais)) (ici le "be" et "ru" fait office de okurigana) = manger
    </p>-->
    <BoxExample 
      :kanji="'短い'"
      :kanjiraw="'短い'"
      :kana="'みじかい'" 
      :roomaji="'mijikai (purement japonais)'"
      :trad="'court'" 
      :explication="'Ici le \'i\' fait office de okurigana.'"
    />
    <BoxExample 
      :kanji="'食べる'"
      :kanjiraw="'食べる'"
      :kana="'たべる'" 
      :roomaji="'taberu (purement japonais'"
      :trad="'manger'" 
      :explication="'Ici le \'be\' et \'ru\' fait office de okurigana).'"
    />
    <p class="text_no_margin">
    - Les clés chez les kanjis correspondent à des parties qui vont former le kanji, chaque kanji peut donc être constitué de clés<br>
    - On retrouve ces mêmes clés sur une majorité de kanji différents. En connaissant ces clés, c'est savoir tracer et interpréter une partie du kanji.<br>
    - Il y a, à peu près 82 clés important pour pouvoir savoir tracer les kanjis les plus important.<br>
    - Certaines clés sont issus d'une simplification de kanji simple (ex : le kanji de l'homme "人", la clé est "亻")<br>
    </p>
    <p class="box_example">
      人 (hito) = homme, la clé de homme est = 亻.<br>
      体 (jintai) = corps humain, on retrouve la clé de l’homme 亻au début du kanji et qui indique une référence à l'homme.<br>
      囗 = la clé de l'enceinte, la boite.<br>
      国 (kuni) = le pays, on retrouve la clé de la boite tout autour, entourant le kanji.<br>
      図 (zu) = la carte, plan, on retrouve la clé de la boite tout autour, entourant le kanji.<br>
    </p>

    <h4 class="title_no_bottom">Rōmaji (ローマ字)</h4>
    <p class="text_no_margin">
      - Le Rōmaji est l'alphabet romain. Ils sont utilisés dans des noms de marque, logo, sites internet, etc car ça fait plus cool qu'en japonais.<br>
      - Ils peuvent également être utilisés comme retranscription des phrases japonais dans notre alphabet romain, pour nous aider dans notre compréhension et prononciation.<br>
      - Dans de très rare cas, il est utilisé dans la langue japonaise (email, t-shirt).
      - Le Rōmaji utilise actuellement 23 lettres de notre alphabet romain. il n'y a pas de "L","Q" et "X".<br>
      - Le Rōmaji peut aussi s'appeler le système "Hepburn" en hommage à James Curtis qui est un américain qui à créé le tout premier dictionnaire Anglais Japonais en 1886.<br>
      - Il existe un autre système s'appelant, le "Kunrei", qui est une version un peu différente du Rōmaji et un peu moins utilisé.<br>
      - Par rapport au kunrei, le rōmaji est une écriture plus proche des syllabes.<br>
      - les suffixes honorifiques pour les prénoms "-kun", "-san", "-chan", etc s'écrivent la plupart du temps de cette manière "Ayaka-chan" en Rōmaji, bien qu'en japonais ils s'écrivent tout d'une traite, sans trait "あやかちゃん". Car c'est plus simple de compréhension.
    </p>
    <!--<p class="box_example">
      Eメール = Email<br>
      Tシャツ = Tshirt
    </p>-->
    <BoxExample 
      :kanji="'Eメール'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'Emeeru'"
      :trad="'e-mail'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'Tシャツ'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'tshatsu'"
      :trad="'T-shirt'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Kunrei</h4>
    <p class="text_no_margin">
      - Le Kunrei est une version alternative du Rōmaji dont l'écriture est plus proche de la prononciation orale.<br>
    </p>
    <p class="box_example">
      しゃち = shachi (Rōmaji) ou syati (Kunrei) = Orque ("syati", c'est ce qu'on entendra de plus proche à l'oral)<br>
      じゅうじ = juuji (Rōmaji) ou zyuuzi (Kunrei) = Dix<br>
    </p>

    <h4 class="title_no_bottom">Furigana</h4>
    <p class="text_no_margin">
      - Les Furigana servent à retranscrire un kanji en katakana ou hiragana pour nous aider dans sa prononciation.<br>
    </p>
    <p class="box_example">
      <span class="red_text">買</span>う = <span class="red_text">か</span>う<br>
    </p>

    <h4 class="title_no_bottom">arabiasūji</h4>
    <p class="text_no_margin">
      - Les arabiasūji sont les chiffres arabes de 1 à 9.<br>
      - ils sont utilisé pour les prix, pour compter, en science, etc ... ils remplacent les chiffres kanji là où c'est le plus pratique.
    </p>
    <p class="box_example">
      88245 = 八万八千二百四十五 (Comme on peut le voir ici, ce n'est pas pratique d'utiliser les chiffres kanji.)
    </p>

    <h3 class="title_subcontent" id="trace">Les Tracés</h3>
    <p class="text_no_margin">
      - Les Hiragana, Katakana et Kanji se tracent de Gauche à Droite et de Haut en Bas.<br>
      - Lors d'un tracé les Hiragana, Katakana et les Kanji rentrent tous dans un carré de même dimension, il n'y a pas de kana, kanji plus grand qu'un autre. Ils font donc tous la même taille.<br>
    </p>

    <h3 class="title_subcontent">Comprendre le tableau des Kana</h3>
    <p class="text_no_margin">
      - La zone blanche (gojūonzu) correspond à l'alphabet principal japonais dans l'ordre chronologique que l'on peut retrouver dans les bibliothèques (en partant de gauche à droite et de haut en bas).<br>
      - La zone bleue correspond à l'alphabet des dakuten (guillemet : ") et handakuten (rond : °).<br>
    </p>
    <p class="text_no_margin">
      Mnémotechnique :<br>
      <span class="text_li">Pour ne pas confondre en hiragana さ (sa) et ち (chi). Penser au prénom "SaChi", retenez l'ordre "sa" en 1er et "chi" en 2nd puis que les boucles du kana sont sur les extérieurs du mot.</span><br>
      <span class="text_li">Pour ne pas confondre en hiragana せ (se) et サ (sa). On peut penser à "c'est ça" = "se sa", retenez l'ordre "se" en 1er "sa" en 2nd puis que la barre verticale la plus grande est sur les extérieurs.</span><br>
      <span class="text_li">Pour ne pas confondre ぬ (nu) ね (ne) め (me) et れ (re). La boucle se forme seulement sur la ligne des "N" donc ぬ (nu) et ね (ne).</span><br>
    </p>

    <div class="kana_table" id="kana_table">
      <Hiragana />
      <Katakana />
    </div>
    
    <h3 class="title_subcontent" id="prononciation">Règle de Prononciation</h3>
    <p class="text_no_margin">
      - Chaque Hiragana et Katakana se prononce et possède le même temps de prononciation, même le "n" possède le même temps de prononciation.<br>
    </p>
    <p class="box_example">
      katakana = ka ta ka na (en 4 temps)<br>
      femme = onna = o n na (en 3 temps)<br>
    </p>
    Petite vidéo habituellement montré au 1er cours de japonais en université, sur la prononciation des Kana: <Button1 :text="'あいうえおのうた (Myu sings AIUEO)'" :target="'_blank'" :link="'https://www.youtube.com/watch?v=LrXHizFaol0'" /><br>

    <h3 class="title_subcontent" id="pause">Règle de la Pause</h3>
    <p class="text_no_margin">
      - - La pause correspond à la consonne d'après qui est doublé, elle prend un temps aussi. La pause est souvent utilisée pour les consonnes occlusive "t","k" et "p" et consonne fricative "s".<br>
      <span class="text_li">- Pauses hiragana : correspond à un petit tsu (っ)</span><br>
      <span class="text_li">- Pauses katakana : correspond à un petit tsu (ッ)</span><br>
      - Il arrive qu'on tombe sur ce type d’onomatopée "あっ", ceci indique un "a" très bref, qui s'estompe vite.<br>
    </p>
    <p class="box_example">
      (intrument musique) が<span class="red_text">っ</span>き  = gakki = ga <span class="red_text">k</span> ki (en 3 temps avec la pause tsu (っ) qui double le k de ki)<br>
      (billet) き<span class="red_text">っ</span>ぷ  = kippu = ki <span class="red_text">p</span> pu (en 3 temps avec la pause tsu (っ) qui double le p de pu)<br>
      (magasin de café) き<span class="red_text">っ</span>さてん  = kissaten = ki <span class="red_text">s</span> sa te n (en 5 temps avec la pause tsu (っ) qui prolonge le son s de sa)<br>
      sonic = ソニ<span class="red_text">ッ</span>ク = sonikku = so ni <span class="red_text">k</span> ku (en 4 temps avec la pause tsu (ッ) qui double le k de ku)<br>
    </p>

    <h3 class="title_subcontent" id="allongements">Règle des Allongements</h3>
    <p class="text_no_margin">
      - Les allongements correspondent à la dernière voyelle qui est allongé "a", "e", "i", "o" et "u" et elles prennent un temps aussi.<br>
      - Les allongements ont été créés à l'origine pour traduire la phonétique des langues étrangères dont le chinois et leur idéogramme en premier (lorsque les chinois ont apporté leur kanji au japon).<br>
      <span class="text_li">- Allongements hiragana :</span><br>
      <span class="text_lix2">- en a correspond au あ</span><br>
      <span class="text_lix2">- en i correspond au い</span><br>
      <span class="text_lix2">- en u correspond au う</span><br>
      <span class="text_lix2">- en e correspond au い et dans de rare exception au え</span><br>
      <span class="text_lix2">- en o correspond au う et dans de rare exception au お</span><br>
      <span class="text_li">- Allongements katakana : correspond à un trait (ー)</span><br>
      - En Rōmaji les allongements peuvent être écrit de deux manières différentes : <br>
      <span class="text_li">- En mettant un allongement, un trait au-dessus de la lettre "Rōmaji".</span><br>
      <span class="text_li">- Ou en ajoutant la voyelle correspondante, vue ci-dessus "Roomaji".</span><br>
    </p>
    <p class="box_example">
      (maman) おか<span class="red_text">あ</span>さん = okaasan = o ka <span class="red_text">a</span> sa n (en 5 temps avec la pause en (あ) qui allonge le a de ka)<br>
      (grand frère) おに<span class="red_text">い</span>さん = oniisan = o ni <span class="red_text">i</span> sa n (en 5 temps avec la pause en (い) qui allonge le i de ni)<br>
      (ciel) てんく<span class="red_text">う</span> = tenkuu = te n ku <span class="red_text">u</span> (en 4 temps avec la pause en (う) qui allonge le u de ku)<br>
      (professeur) せんせ<span class="red_text">い</span> = sensei = se n se <span class="red_text">i</span> (en 4 temps avec la pause en (い) qui allonge le e de se)<br>
      (grande soeur) おね<span class="red_text">え</span>さん = oneesan = o ne <span class="red_text">e</span> sa n (en 5 temps avec la pause en (え) qui allonge le e de ne)<br>
      (sucre) さと<span class="red_text">う</span> = satou = sa to <span class="red_text">u</span> (en 3 temps avec la pause en (う) qui allonge le o de to)<br>
      (loup) お<span class="red_text">お</span>かみ = o<span class="red_text">o</span>kami = o o ka mi (en 4 temps avec la pause en (お) qui allonge le o de o)<br>
      (rōmaji) ロ<span class="red_text">ー</span>マ字 = rōmaji = ro <span class="red_text">o</span> ma ji (en 4 temps avec la pause en trait (ー) qui allonge le o de ro)<br>
    </p>

    <h3 class="title_subcontent" id="combinaisons">Règle et tableaux des Combinaisons</h3>
    <p class="text_no_margin">
      - Les Combinaisons correspondent à la combinaison de deux types de Kana, les grand Kana de la colonne des i, à savoir "ki", "gi", "shi", "ji", "chi", "ni", "hi", "bi", "pi", "mi" et "ri" et un petit kana "ya", "yu", "yo", etc.<br>
      - La Combinaison ne prend qu'un temps.
    </p>
    <p class="box_example">
      (conclusion) けっ<span class="red_text">きょ</span>く = kekkyoku = ke k <span class="red_text">kyo</span> ku (en 4 temps)<br>
      (judo) <span class="red_text">じゅ</span>うどう = juudoo = <span class="red_text">ju</span> u do o (en 4 temps)<br>
    </p>
    <TableCombinations /><br>
    <TableCombinationsKatakana /><br>
    <TableNewCombinations /><br>

    <h3 class="title_subcontent" id="kanji_utilisation">Kanji, leur règles d'utilisation et de prononciation particulière</h3>
    <p class="text_no_margin">
      - Première règle<br>
      <span class="text_li">- il arrive parfois que le 1er kana du 2nd Kanji prend un accent, pour des raisons de prononciation plus facile</span>
    </p>
    <p class="box_example">
      火山 = 火 (ka) + 山 (san), sauf que kasan est difficile à prononcer. On aura donc un accent sur le "sa" = かざん (kazan).
    </p>
    <p class="text_no_margin">
      - Seconde règle<br>
      <span class="text_li">- il arrive parfois que le 2nd kana du 1er Kanji deviennent une pause, pour des raisons de prononciation plus facile. Cette pause arrive plus souvent sur les kanjis commençants par "tsu" ce qui explique pourquoi historiquement, le petit "tsu" ai été utilisé comme modèle de kana de pause.</span>
    </p>
    <p class="box_example">
      楽器 = 楽 (gaku) + 器 (ki), sauf que GAKUKI est difficile à prononcer. On aura donc une pause à la place du "ku" = がっき (GAKKI).
    </p>
    <p class="text_no_margin">
      - Il arrive que les deux règles soient utilisées en même temps.<br>
    </p>
    <p class="box_example">
      発表  = 発 (ハツ) (hatsu) + 表 (ヒヨウ) (hyou) = sauf que hatsuhyou est difficile à prononcer. On aura donc une pause à la place du "tsu" et un accent sur le "hyo" = はっぴょう (happyou).
    </p>

    <h3 class="title_subcontent" id="kanji_cas_particuliers">Kanji, Les cas particuliers</h3>
    <p class="text_no_margin">
      - Il peut arriver sur des combinaisons de kanji, de devoir utiliser la lecture purement japonaise et non sino-japonaise. Car le mot existait originalement en japonais et il a été décomposé sur deux kanji.<br>
    </p>
    <p class="box_example">
      名前 = 名 (na (purement japonais)) + 前 (mae (purement japonais)) = prénom<br>
    </p>
    <p class="text_no_margin">
      - Il peut arriver sur des combinaisons de kanji, de devoir utiliser la lecture purement japonaise et non sino-japonaise. Car le 1er kanji est un préfixe ou le dernier kanji est un suffixe.<br>
    </p>
    <p class="box_example">
      小山 = 小 (ko (purement japonais)) + 山 (yama (purement japonais)) = petite montagne<br>
    </p>
    <p class="text_no_margin">
      - Parfois mais plus rarement on va combiner la lecture purement japonaise et sino-japonaise.<br>
    </p>
    <p class="box_example">
      曜日 = 曜 (you (sino-japonais)) + 日 (bi (purement japonais)) = jour de la semaine<br>
    </p>
    <p class="text_no_margin">
      - On peut combiner des Katakana avec des Kanji de cette façon-là (exemple), lorsque qu'on parle de chose étranger et que ces mêmes combinaisons en japonais marchent aussi.<br>
    </p>
    <p class="box_example">
      日本人 = 日本 (nihon (japon (sino-japonais)) + 人 (jin (personne (sino-japonais))) = personne japonaise<br>
      フランス人 = フランス (furansu (france (katakana))) + 人 (jin (personne (sino-japonais))) = personne française<br>
      フランス語 = フランス (furansu (france (katakana))) + 語 (go (langue (sino-japonais))) = français la langue<br>
      ローマ字 = ローマ (roma (rome)) + 字 (ji (lettre)) = Rōmaji
    </p>
    <p class="text_no_margin">
      - Ce kanji 々 est le kanji de la répétition, c'est un kanji utilisé lorsqu'une combinaison de kanji se répète à la suite.<br>
    </p>
    <p class="box_example">
      時時 = 時々 = tokidoki (purement japonais) = de temps en temps (on utilise le kanji 時 deux fois de suite)<br>
      少少 = 少々 = shoushou (sino-japonais) = un tout petit peu
    </p>
    <p class="text_no_margin">
      - On peut avoir des kanjis avec la même lecture mais pas le même sens qui sera précisé par idéogramme du kanji.<br>
      - A l'oral la prononciation est différente, certaines syllabes auront une intonation plus appuyée que d'autres.<br>
    </p>
    <p class="box_example">
      神 = kami ↘ = dieu (on descend l'intonation sur la fin de "mi")<br>
      紙 = kami ↗ = papier (on appuie l'intonation sur la fin de "mi")<br>
      髪 = kami ↗ = les cheveux (on appuie l'intonation sur la fin de "mi)<br>
      Suivant le contexte de discussion on sait de quel "kami" on parle
    </p>
    <p class="text_no_margin">
      - il peut arriver, mais plus rarement, qu'une combinaison de kanji possède sa propre lecture et n'utilise pas le “purement japonais” ou “sino-japonais”. Car les japonais avaient une prononciation pour ce mot dont ils l'ont associé à une combinaison de kanji.<br>
    </p>
    <p class="box_example">
      大人 = otona (purement japonais) = adulte<br>
    </p>
    <p class="text_no_margin">
      - Plus rare mais existant, certains kanjis sont utilisés pour leur phonétique comme des kana de lecture, cependant ceci à tendance à disparaître au fur et à mesure.<br>
    </p>
    <p class="box_example">
      出来る = 出 (de) + 来(ki) + る(ru) = dekiru (purement japonais) = pouvoir faire<br>
    </p>
    <p class="text_no_margin">
      - Il peut arriver qu'un mot possède deux écritures par son origine chinoise et japonaise.<br>
    </p>
    <p class="box_example">
      卵 (origine chinois) = tamago = oeuf de poule<br>
      玉子 (origine japonais) = 玉 (tama) + (子) go = oeuf de poule<br>
    </p>

    <h3 class="title_subcontent" id="prenom_nom">Les prénoms et noms japonais</h3>
    <p class="text_no_margin">
      - Les prénoms et noms japonais s'écrivent en japonais.<br>
      - Dans la majorité des cas, les noms japonais s'écrivent avec une combinaison de deux Kanji et se lisent en purement japonais (il y a cependant des cas plus rares de lecture en sino-japonais comme Endo ou Satou).<br>
      - Historiquement les premiers noms de famille ont été donnés aux personnes selon leur situations géologique, là où elles habitaient.<br>
    </p>
    <p class="box_example">
      田中 = 田 (ta (purement japonais)) rizière + 中 (naka (purement japonais)) milieu = tanaka (historiquement ceci a été donnée à une famille qui vivait au milieu de la rizière)<br>
      川島 = 川 (kawa (purement japonais)) rivière + 島 (shima (purement japonais)) île = kawashima (famille qui vivait près d'une île et d'une rivière)<br>
      佐藤 = 佐 (sa (sino-japonais)) 藤 (tou (sino-japonais)) = satou<br>
    </p>

    <h3 class="title_subcontent" id="ponctuation">La Ponctuation japonaise</h3>
    <p class="text_no_margin">
      Listes complètes des ponctuations : <Button1 :text="'List of Japanese typographic symbols - wiki'" :target="'_blank'" :link="'https://en.wikipedia.org/wiki/List_of_Japanese_typographic_symbols'" /><br>
      Les ponctuations prennent l'orientation du texte. Si un texte est écrit en verticale, les points et les traits seront allongés à la verticale. De même pour un texte horizontal, les points et les traits seront à l'horizontal.<br>
      Les différentes ponctuations en japonais : <br>
    </p>
    <span class="text_li">- <span class="blue_text">maru (。) (le point)</span>: Il s'utilise pour clôturer une phrase comme en français.</span><br>
    <p class="box_example">
      猫が大好きだ。= neko ga daisuki da。= j'aime les chats.<br>
    </p>
    <span class="text_li">- <span class="blue_text">ten (、) (la virgule)</span>: Permet de créer une séparation et pause dans la phrase comme en français.</span><br>
    <p class="box_example">
      そうか、私も。= sou, watashi mo = ok, moi aussi<br>
    </p>
    <span class="text_li">- <span class="blue_text">nakaten (・) (point de séparation)</span>: Il s'utilise pour séparer le nom et prénom.</span><br>
    <p class="box_example">
      アンリ・ベール = anri・beeru = Henri beyle<br>
    </p>
    <span class="text_li">- <span class="blue_text">piriodo (.) (point de date)</span>: Il s'utilise pour les dates.</span><br>
    <p class="box_example">
      2021. 11. 23 = 23 novembre 2021<br>
    </p>
    <span class="text_li">- <span class="blue_text">tensen (•••) (point de suspension)</span>: Il s'utilise pour marquer une phrase incomplète comme en français, Ils sont composés de trois petits points à mi-hauteur. Ils sont souvent utilisés par 6.</span><br>
    <p class="box_example">
      猫が•••••• = neko ga •••••• = le chat ...<br>
    </p>
    <span class="text_li">- <span class="blue_text">kantanfu (!) (point d'exclamation)</span>: Souvent utilisé dans les mangas, il sert comme en français, à mettre de l'intonation à la phrase.</span><br>
    <p class="box_example">
      猫が居ます! = neko ga imasu! = il y a un chat!<br>
    </p>
    <span class="text_li">- <span class="blue_text">gimonfu (?) (point d'interrogation)</span>: Il s'utilise à la fin d'une phrase interrogative comme en français.</span><br>
    <p class="box_example">
      どこにいるの? = doko ni iru no? = Où es-tu?<br>
    </p>
    <span class="text_li">- <span class="blue_text">nakasen (ー) (trait d'allongement)</span>: Il s'utilise pour créer un allongement, plus il y a de trait, plus la durée de suspension est longue.</span><br>
    <p class="box_example">
      えっとーー = e ttoーー = euhhhhhh (politesse)<br>
    </p>
    <span class="text_li">- <span class="blue_text">nammigata (~) (tilde)</span>: Il s’utilise de deux façons. Comme pour le trait d'allongement, marquer une suspension de doute ou marquer la durée d'une date "jusqu’à".</span><br>
    <p class="box_example">
      うわ~~ = uwa~~~~ = euhhhhh (doute)<br>
      月曜日~火曜日 = Getsuyōbi~Kayōbi = Lundi jusqu'a Mardi<br>
    </p>
    <span class="text_li">- <span class="blue_text">kakko (「」) (crochets simple)</span>: Il s'utilise souvent pour mettre en relief quelque chose, faire porter son attention sur un mot.</span><br>
    <p class="box_example">
      「猫」が居ます = 「neko」ga imasu = il y a un 'chat' <br>
    </p>
    <span class="text_li">- <span class="blue_text">kakko (『』) (crochets double)</span>: Il s'utilise souvent pour mettre une citation comme le double crochet en français.</span><br>
    <p class="box_example">
      『猫が居ます』 = 『neko ga imasu』 = "il y a un chat"<br>
    </p>
    <span class="text_li">- <span class="blue_text">wakisen (__) (soulignement)</span>: Permet de souligner une phrase comme en français.</span><br>
    <p class="box_example">
      <span style="text-decoration: underline;">猫が居ます</span> = <span style="text-decoration: underline;">neko ga imasu</span>  = <span style="text-decoration: underline;">il y a un chat</span> <br>
    </p>
    <span class="text_li">- <span class="blue_text">(+ - = /) (règle mathématique)</span>: Les mêmes signes pour les règles mathématiques comme en français, les japonais utilisent les nombres arabes en mathématique.</span><br>
    <p class="box_example">
      2+2 = 4
    </p>
    <span class="text_li">- <span class="blue_text">daburu haifun (=) (tiret)</span>: Il s'utilise pour séparer les prénoms composés comme le tiret en français.</span><br>
    <p class="box_example">
      ジャン=ジャック = jan=jakku = Jean-Jaques<br>
    </p>
    <span class="text_li">- <span class="blue_text">kurikaeshi (ゝ) (caractère de répétion)</span>: Il s'utilise lorsqu'un caractère hiragana est répété, mais de nos jours il n'est plus utilisé.</span><br>
    <p class="box_example">
      ここ = こゝ = koko = ici<br>
    </p>
    <span class="text_li">- <span class="blue_text">kurikaeshi dakuten (ゞ) (caractère de répétion)</span>: Il s'utilise lorsqu'un caractère hiragana dakuten est répété, mais de nos jours il n'est plus utilisé.</span><br>
    <p class="box_example">
      ただいま = たゞいま = tadaima = bon retour<br>
    </p>
    <span class="text_li">- <span class="blue_text">kurikaeshi (ヽ) (caractère de répétion)</span>: Il s'utilise lorsqu'un caractère katakana est répété, mais de nos jours il n'est plus utilisé.</span><br>
    <p class="box_example">
      バナナ = バナヽ= banana = banane <br>
    </p>
    <span class="text_li">- <span class="blue_text">kurikaeshi dakuten (ヾ) (caractère de répétion)</span>: Il s'utilise lorsqu'un caractère katakana dakuten est répété, mais de nos jours il n'est plus utilisé.</span><br>
    <p class="box_example">
      ババロア = バヾロア = babaroa = Bavarois <br>
    </p>
    <span class="text_li">- <span class="blue_text">kurikaeshi (々) (caractère de répétion)</span>: Ilil s'utilise lorsqu'un kanji est répété, celui-ci est encore utilisé.</span><br>
    <p class="box_example">
      時時 = 時々 = tokidoki = le temps<br>
    </p>
    <span class="text_li">- <span class="blue_text">nonojiten (〃) (caractère de répétion)</span>: Il s'utilise lorsqu'on répète le même mot dans une case comme en français avec la double apostrophe."</span><br>
    <p class="box_example">
      <table style="border:solid 1px;">
        <tr>
          <td style="border:solid 1px;">Chat 1</td>
          <td style="border:solid 1px;">猫 1</td>
        </tr>
        <tr>
          <td style="border:solid 1px;">Chat 2</td>
          <td style="border:solid 1px;">〃 2</td>
        </tr>
        <tr>
          <td style="border:solid 1px;">Chat 3</td>
          <td style="border:solid 1px;">〃 3</td>
        </tr>
      </table>
    </p>
    
  </div>
</template>

<script>
// @ is an alias to /src
import Hiragana from "@/components/Tableau/Hiragana.vue";
import Katakana from "@/components/Tableau/Katakana.vue";
import Button1 from "@/components/Buttons/Button1.vue";
import TableCombinations from "@/components/Tableau/TableCombinations.vue";
import TableCombinationsKatakana from "@/components/Tableau/TableCombinationsKatakana.vue";
import TableNewCombinations from "@/components/Tableau/TableNewCombinations.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  name: "Introduction",
  components: {
    Hiragana,
    Katakana,
    Button1,
    TableCombinations,
    TableCombinationsKatakana,
    TableNewCombinations,
    BoxExample
  },
};
</script>

<style lang="scss" scoped>
.kana_table {
  text-align: center;
  margin-top: 15px;
}
.legend_icon{
  color: var(--first-color);
}
</style>