<template>
  <div>
    <h3 id="na" class="title_subcontent">Adjectifs en Na</h3>
    <h4 class="title_no_bottom">Exemple utilisation</h4>
    <p class="text_no_margin">L'adjectif se place toujours avant le nom, et il faut rajouter "na" à la fin.</p>
    <p class="box_example">
        <span class="red_text">un beau</span> manga = <span class="red_text">kirei na</span> manga <br>
        un manga <span class="red_text">bizarre</span> = <span class="red_text">hen na</span> manga <br>
    </p>

    <h4 class="title_no_bottom">Conjugaison</h4>
    <p class="text_no_margin">Leur base ne change pas, on vient modifier le suffixe desu.</p>
    <p class="box_example">
        suki <span class="red_text">desu</span> = aimé <br>
    </p>
    <p class="text_no_margin">Négatif avec <span class="blue_text">janai</span></p>
    <p class="box_example">
        suki <span class="red_text">janai</span> = j'aime <span class="red_text">pas</span><br>
    </p>
    <p class="text_no_margin">Passé avec <span class="blue_text">datta</span></p>
    <p class="box_example">
        suki <span class="red_text">datta</span> = <span class="red_text">j'ai</span> aimé<br>
    </p>
    <p class="text_no_margin">Négatif Passé avec <span class="blue_text">janakatta</span></p>
    <p class="box_example">
        suki <span class="red_text">janakatta</span> = <span class="red_text">je n'ai pas</span> aimé <br>
    </p>
    <p class="text_no_margin">Impression Positif avec <span class="blue_text">sou</span></p>
    <p class="box_example">
        suki<span class="red_text">sou</span> = <span class="red_text">tu as à l'air</span> d'aimé <br>
    </p>
    <p class="text_no_margin">Transformation en adverbe avec <span class="blue_text">ni</span></p>
    <p class="box_example">
        jozu <span class="red_text">ni</span> = habile<span class="red_text">ment</span><br>
    </p>
    
    <div v-if="adjectifna.length">
      <TableVocabulaire :vocabulaire="adjectifna" />
    </div>
    <div v-else>
      <Chargement :title="'Loading Adjectif Na ...'" />
    </div>
  </div>
</template>

<script>
import TableVocabulaire from "@/components/Tableau/TableVocabulaire.vue";
import Chargement from "@/components/Composants/Chargement.vue";

export default {
  name: "AdjectifNa",
  data() {
    return {
      id: this.$route.params.id,
      adjectifna: [],
    };
  },
  methods:{
    getEnv(){
      return this.$store.getters.getCurrentApiUrl
    }
  },
  mounted(){
    fetch(this.getEnv() + 'adjectifsna')
      .then(res => res.json())
      .then(data => this.adjectifna = data)
      .catch(err => console.log(err.message))
  },
  components: {
    TableVocabulaire,
    Chargement
  },
};
</script>

<style>
</style>