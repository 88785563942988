<template>
  <div>
    <h1>Vocabulaire</h1>
    <h3 class="title_subcontent">Liste des thèmes de vocabulaire</h3>
    <Button1 :text="'Premiers mots en kanji'" @click.native="kanji" :link="'#kanji'" />
    <Button1 :text="'Formules de politesse'" @click.native="formulespolitesse" :link="'#formulespolitesse'" />
    <Button1 :text="'Matériels domestique'" @click.native="materielsdomestique" :link="'#materielsdomestique'" />

    <h4 class="title_sub_sub">Grammaire, Conjugaison, Verbe, Adjectfis, etc</h4>
    <Button1 :text="'Verbes - Partie 1'" @click.native="verbe" :link="'#verbes'" />
    <Button1 :text="'Adjectifs en I'" @click.native="adjectifEnI" :link="'#adjectifEnI'" />
    <Button1 :text="'Adjectifs en Na'" @click.native="adjectifEnNa" :link="'#adjectifEnNa'" />

    <h3 class="title_subcontent">Vocabulaire</h3>
    <div v-if="tablevocab.length">
      <div class="list_button">
      <Button1 :text="'Entrainement Français vers Japonais'" @click.native="startExoFrToJp" />
      <Button1 :text="'Entrainement Japonais vers Français'" @click.native="startExoJpToFr" />
      </div>

      <p class="subtitle">
        <i class="fas fa-info-circle"></i> Pour plus informations sur l'utilisation du tableau, il y a une zone information en bas de la page
      </p>

      <TableVocabulaire :vocabulaire="tablevocab" />

      <p class="subtitle">
        <span class="subtitle_title"><i class="fas fa-info-circle"></i> Informations sur le tableau</span><br>
        - Dans la colonne "Kanji", les kanji sont cliquables pour avoir leur tracé.<br>
        - La colonne "Dictionnaire" fait une redirection vers "Jisho", permettant d'aller voir plus en profondeur les Kanji comme leur lecture Kun et On.<br>
        - La colonne "Conjugaison" fait une redirection vers "jlearn" permettant de voir l'utilisation du mot dans différents exemples de phrases ou situation, ainsi que toutes ses différentes formes de conjugaison si c'est un verbe.<br>
        - La colonne "Audio" permet d'écouter la prononciation d'un mot.<br>
        - Au-dessus du tableau de vocabulaire, il y a des exercices de vocabulaire français vers japonais et inversement, suivant différents thèmes pour apprendre efficacement un thème en particulier.<br>
        - La lecture de la colonne Kanji est très souvent ce que vous retrouvez d'écrit en japonais, il faudra savoir écrire ou reconnaître les mots dans cette colonne.<br>
      </p>

      <p>
        Voix audio alimenté par <Button1 :text="'ResponsiveVoice'" :target="'_blank'" :link="'https://responsivevoice.org'" />
      </p>

      <p>
        Bibliothèque SVG des tracés des Kanjis <Button1 :text="'KanjiVG'" :target="'_blank'" :link="'https://kanjivg.tagaini.net/index.html'" />
      </p>

      <Findword v-if="isFindwordPlaying" :type="exoType" :title="popuptitle" :vocabulaire="tablevocab" @end="endPopup"/>
    </div>
    <div v-else>
      <Chargement :title="'Loading vocabulaire ...'" />
    </div>

  </div>
</template>

<script>
import TableVocabulaire from "@/components/Tableau/TableVocabulaire.vue";
import Chargement from "@/components/Composants/Chargement.vue";
import Findword from "@/components/Composants/Findword.vue";
import Button1 from "@/components/Buttons/Button1.vue";

export default {
  name: 'Vocabulaires',
  data() {
    return {
      tablevocab: [],
      isFindwordPlaying: false,
      popuptitle: '',
      popupid: '',
      exoType: ''
    };
  },
  methods:{
    // event
    endPopup(){
      this.isFindwordPlaying = false
    },

    // API Fetch
    getEnv(){
      return this.$store.getters.getCurrentApiUrl
    },
    getTableVocab(tableid){
      fetch(this.getEnv() + tableid)
        .then(res => res.json())
        .then(data => this.tablevocab = data)
        .catch(err => console.log(err.message))
    },

    // Start Exo
    startExoFrToJp(){
      this.exoType = 'frtojp'
      this.isFindwordPlaying = true
    },
    startExoJpToFr(){
      this.exoType = 'jptofr'
      this.isFindwordPlaying = true
    },

    // Thème
    formulespolitesse(){
      this.getTableVocab('formulespolitesse');
      this.popupid = 'formulespolitesse';
      this.popuptitle = 'Formules de politesse';
    },
    materielsdomestique(){
      this.getTableVocab('materielsdomestique');
      this.popupid = 'materielsdomestique';
      this.popuptitle = 'Matériels domestique';
    },
    kanji(){
      this.getTableVocab('kanjiprincipaux');
      this.popupid = 'kanji';
      this.popuptitle = 'Premiers mots en kanji';
    },
    verbe(){
      this.getTableVocab('verbes');
      this.popupid = 'verbes';
      this.popuptitle = 'Verbes';
    },
    adjectifEnI(){
      this.getTableVocab('adjectifsi');
      this.popupid = 'adjectifsi';
      this.popuptitle = 'Adjectifs en I';
    },
    adjectifEnNa(){
      this.getTableVocab('adjectifsna');
      this.popupid = 'adjectifsna';
      this.popuptitle = 'Adjectifs en Na';
    }
  },
  mounted(){
    this.formulespolitesse()
  },
  components: {
    TableVocabulaire,
    Chargement,
    Button1,
    Findword
  },
}
</script>

<style lang="scss" scoped>
.list_button{
  display: block;
}
.subtitle_title{
  font-size: 1.1em;
}
.title_sub_sub{
  margin: 0;
}
</style>