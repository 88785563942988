<template>
      <div class="section">
      <h3>
        <div class="section_title">
          Tableau Récapitulatif
        </div>
        <input class="search" type="search" v-model="search" placeholder="search occurence" @keyup="searchInTable"/>

		<select class="select" v-model="size" @change="onChange">
          <option value="0.625rem">0.625rem</option>
          <option value="0.75rem">0.75rem</option>
          <option value="0.875rem">0.875rem</option>
          <option selected="selected" value="1rem">1rem</option>
          <option value="1.125rem">1.125rem</option>
          <option value="1.25rem">1.25rem</option>
          <option value="1.5rem">1.5rem</option>
          <option value="1.875rem">1.875rem</option>
          <option value="2rem">2rem</option>
		  </select>
      </h3>

      <table class="content-table verbe" id="verbe">
        <thead>
          <tr>
            <th>Verbes</th>
            <th>-masu</th>
            <th>-mashou</th>
            <th>-tai</th>
            <th>-nai</th>
            <th>-ou</th>
            <th colspan="2">Capacité</th>
            <th>-te</th>
            <th>-ta</th>
          </tr>
        </thead>
        <tr>
          <th>1er Grp</th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <th>
            <div class="first">買う</div>
            <div class="second">Ka<span class="red_text">u</span></div>
          </th>
          <td class="kanji_container">Ka<span class="red_text">imasu</span></td>
          <td class="kanji_container">Ka<span class="red_text">imashou</span></td>
          <td class="kanji_container">Ka<span class="red_text">itai</span></td>
          <td class="kanji_container">Ka<span class="red_text">wanai</span></td>
          <td class="kanji_container">Ka<span class="red_text">ou</span></td>
          <td class="kanji_container">Ka<span class="red_text">eru</span></td>
          <td class="kanji_container">Ka<span class="red_text">emasu</span></td>
          <td class="kanji_container">Ka<span class="red_text">tte</span></td>
          <td class="kanji_container">Ka<span class="red_text">tta</span></td>
        </tr>
        <tr>
          <th>
            <div class="first">聞く</div>
            <div class="second">Ki<span class="red_text">ku</span></div>
          </th>
          <td class="kanji_container">Ki<span class="red_text">kimasu</span></td>
          <td class="kanji_container">Ki<span class="red_text">kimashou</span></td>
          <td class="kanji_container">Ki<span class="red_text">kitai</span></td>
          <td class="kanji_container">Ki<span class="red_text">kanai</span></td>
          <td class="kanji_container">Ki<span class="red_text">kou</span></td>
          <td class="kanji_container">Ki<span class="red_text">keru</span></td>
          <td class="kanji_container">Ki<span class="red_text">kemasu</span></td>
          <td class="kanji_container">Ki<span class="red_text">ite</span></td>
          <td class="kanji_container">Ki<span class="red_text">ita</span></td>
        </tr>
        <tr>
          <th>
            <div class="first">脱ぐ</div>
            <div class="second">Nu<span class="red_text">gu</span></div>
          </th>
          <td class="kanji_container">Nu<span class="red_text">gimasu</span></td>
          <td class="kanji_container">Nu<span class="red_text">gimashou</span></td>
          <td class="kanji_container">Nu<span class="red_text">gitai</span></td>
          <td class="kanji_container">Nu<span class="red_text">ganai</span></td>
          <td class="kanji_container">Nu<span class="red_text">gou</span></td>
          <td class="kanji_container">Nu<span class="red_text">geru</span></td>
          <td class="kanji_container">Nu<span class="red_text">gemasu</span></td>
          <td class="kanji_container">Nu<span class="red_text">ide</span></td>
          <td class="kanji_container">Nu<span class="red_text">ida</span></td>
        </tr>
        <tr>
          <th>
            <div class="first">遊ぶ</div>
            <div class="second">Aso<span class="red_text">bu</span></div>
          </th>
          <td class="kanji_container">Aso<span class="red_text">bimasu</span></td>
          <td class="kanji_container">Aso<span class="red_text">bimashou</span></td>
          <td class="kanji_container">Aso<span class="red_text">bitai</span></td>
          <td class="kanji_container">Aso<span class="red_text">banai</span></td>
          <td class="kanji_container">Aso<span class="red_text">bou</span></td>
          <td class="kanji_container">Aso<span class="red_text">beru</span></td>
          <td class="kanji_container">Aso<span class="red_text">bemasu</span></td>
          <td class="kanji_container">Aso<span class="red_text">nde</span></td>
          <td class="kanji_container">Aso<span class="red_text">nda</span></td>
        </tr>
        <tr>
          <th>
            <div class="first">読む</div>
            <div class="second">Yo<span class="red_text">mu</span></div>
          </th>
          <td class="kanji_container">Yo<span class="red_text">mimasu</span></td>
          <td class="kanji_container">Yo<span class="red_text">mimashou</span></td>
          <td class="kanji_container">Yo<span class="red_text">mitai</span></td>
          <td class="kanji_container">Yo<span class="red_text">manai</span></td>
          <td class="kanji_container">Yo<span class="red_text">mou</span></td>
          <td class="kanji_container">Yo<span class="red_text">meru</span></td>
          <td class="kanji_container">Yo<span class="red_text">memasu</span></td>
          <td class="kanji_container">Yo<span class="red_text">nde</span></td>
          <td class="kanji_container">Yo<span class="red_text">nda</span></td>
        </tr>
        <tr>
          <th>
            <div class="first">話す</div>
            <div class="second">Hana<span class="red_text">su</span></div>
          </th>
          <td class="kanji_container">Hana<span class="red_text">shimasu</span></td>
          <td class="kanji_container">Hana<span class="red_text">shimashou</span></td>
          <td class="kanji_container">Hana<span class="red_text">shitai</span></td>
          <td class="kanji_container">Hana<span class="red_text">sanai</span></td>
          <td class="kanji_container">Hana<span class="red_text">sou</span></td>
          <td class="kanji_container">Hana<span class="red_text">seru</span></td>
          <td class="kanji_container">Hana<span class="red_text">semasu</span></td>
          <td class="kanji_container">Hana<span class="red_text">shite</span></td>
          <td class="kanji_container">Hana<span class="red_text">shita</span></td>
        </tr>
        <tr>
          <th>
            <div class="first">待つ</div>
            <div class="second">Ma<span class="red_text">tsu</span></div>
          </th>
          <td class="kanji_container">Ma<span class="red_text">chimasu</span></td>
          <td class="kanji_container">Ma<span class="red_text">chimashou</span></td>
          <td class="kanji_container">Ma<span class="red_text">chitai</span></td>
          <td class="kanji_container">Ma<span class="red_text">tanai</span></td>
          <td class="kanji_container">Ma<span class="red_text">tou</span></td>
          <td class="kanji_container">Ma<span class="red_text">teru</span></td>
          <td class="kanji_container">Ma<span class="red_text">temasu</span></td>
          <td class="kanji_container">Ma<span class="red_text">tte</span></td>
          <td class="kanji_container">Ma<span class="red_text">tta</span></td>
        </tr>
        <tr>
          <th>
            <div class="first">死ぬ</div>
            <div class="second">Shi<span class="red_text">nu</span></div>
          </th>
          <td class="kanji_container">Shi<span class="red_text">nimasu</span></td>
          <td class="kanji_container">Shi<span class="red_text">nimashou</span></td>
          <td class="kanji_container">Shi<span class="red_text">nitai</span></td>
          <td class="kanji_container">Shi<span class="red_text">nanai</span></td>
          <td class="kanji_container">Shi<span class="red_text">nou</span></td>
          <td class="kanji_container">Shi<span class="red_text">neru</span></td>
          <td class="kanji_container">Shi<span class="red_text">nemasu</span></td>
          <td class="kanji_container">Shi<span class="red_text">nde</span></td>
          <td class="kanji_container">Shi<span class="red_text">nda</span></td>
        </tr>
        <tr>
          <th>
            <div class="first">帰る</div>
            <div class="second">Kae<span class="red_text">ru</span></div>
          </th>
          <td class="kanji_container">Kae<span class="red_text">rimasu</span></td>
          <td class="kanji_container">Kae<span class="red_text">rimashou</span></td>
          <td class="kanji_container">Kae<span class="red_text">ritai</span></td>
          <td class="kanji_container">Kae<span class="red_text">ranai</span></td>
          <td class="kanji_container">Kae<span class="red_text">rou</span></td>
          <td class="kanji_container">Kae<span class="red_text">reru</span></td>
          <td class="kanji_container">Kae<span class="red_text">remasu</span></td>
          <td class="kanji_container">Kae<span class="red_text">tte</span></td>
          <td class="kanji_container">Kae<span class="red_text">tta</span></td>
        </tr>
        <tr>
          <th>2nd Grp</th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <th>
            <div class="first">食べる</div>
            <div class="second">Tabe<span class="red_text">ru</span></div>
          </th>
          <td class="kanji_container">Tabe<span class="red_text">masu</span></td>
          <td class="kanji_container">Tabe<span class="red_text">mashou</span></td>
          <td class="kanji_container">Tabe<span class="red_text">tai</span></td>
          <td class="kanji_container">Tabe<span class="red_text">nai</span></td>
          <td class="kanji_container">Tabe<span class="red_text">you</span></td>
          <td class="kanji_container">Tabe<span class="red_text">rareru</span></td>
          <td class="kanji_container">Tabe<span class="red_text">raremasu</span></td>
          <td class="kanji_container">Tabe<span class="red_text">te</span></td>
          <td class="kanji_container">Tabe<span class="red_text">ta</span></td>
        </tr>
        <tr>
          <th>
            <div class="first">見る</div>
            <div class="second">Mi<span class="red_text">ru</span></div>
          </th>
          <td class="kanji_container">Mi<span class="red_text">masu</span></td>
          <td class="kanji_container">Mi<span class="red_text">mashou</span></td>
          <td class="kanji_container">Mi<span class="red_text">tai</span></td>
          <td class="kanji_container">Mi<span class="red_text">nai</span></td>
          <td class="kanji_container">Mi<span class="red_text">you</span></td>
          <td class="kanji_container">Mi<span class="red_text">rareru</span></td>
          <td class="kanji_container">Mi<span class="red_text">raremasu</span></td>
          <td class="kanji_container">Mi<span class="red_text">te</span></td>
          <td class="kanji_container">Mi<span class="red_text">ta</span></td>
        </tr>
        <tr>
          <th>3em Grp</th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <th>
            <div class="first">為る</div>
            <div class="second"><span class="red_text">Suru</span></div>
          </th>
          <td class="kanji_container"><span class="red_text">Shimasu</span></td>
          <td class="kanji_container"><span class="red_text">Shimashou</span></td>
          <td class="kanji_container"><span class="red_text">Shitai</span></td>
          <td class="kanji_container"><span class="red_text">Shinai</span></td>
          <td class="kanji_container"><span class="red_text">Shiyou</span></td>
          <td class="kanji_container"><span class="red_text">Dekiru</span></td>
          <td class="kanji_container"><span class="red_text">Dekimasu</span></td>
          <td class="kanji_container"><span class="red_text">Shite</span></td>
          <td class="kanji_container"><span class="red_text">Shita</span></td>
        </tr>
        <tr>
          <th>
            <div class="first">来る</div>
            <div class="second"><span class="red_text">Kuru</span></div>
          </th>
          <td class="kanji_container"><span class="red_text">Kimasu</span></td>
          <td class="kanji_container"><span class="red_text">Kimashou</span></td>
          <td class="kanji_container"><span class="red_text">Kitai</span></td>
          <td class="kanji_container"><span class="red_text">Konai</span></td>
          <td class="kanji_container"><span class="red_text">Koyou</span></td>
          <td class="kanji_container"><span class="red_text">Korareru</span></td>
          <td class="kanji_container"><span class="red_text">Koraremasu</span></td>
          <td class="kanji_container"><span class="red_text">Kite</span></td>
          <td class="kanji_container"><span class="red_text">Kita</span></td>
        </tr>
      </table>
    </div>
</template>

<script>
export default {
    data(){
        return{
            size : '1rem',
            search: '',
        }
    },
    methods: {
        searchInTable(){
            if(this.search.length > 0){
                var kanjis = document.getElementById("verbe").getElementsByClassName("kanji_container")
                kanjis.forEach((l) => l.classList.add("inactif_kanji"));
                kanjis.forEach(element => {
                    if(element.innerText.includes(this.search)){
                        element.classList.remove("inactif_kanji")
                    }
                });
            } else {
                var kanjis = document.getElementById("verbe").getElementsByClassName("kanji_container")
                kanjis.forEach((l) => l.classList.remove("inactif_kanji"));
            }
        },
        onChange(){
            document.getElementById("verbe").style.fontSize = this.size
        }
    },
    mounted(){
        this.onChange()
    }
}
</script>

<style lang="scss" scoped>
/*section*/
.section{
  display: inline-block;
  margin: 0 15px 0 0;
    .inactif_kanji{
      opacity: 0.4;
  }
}

.section_title{
    float: left;
    margin-top: 13px;
}

.section .search,
.section .select{
  float: right;
  margin-top: 9px;
  min-height: 40px;
  margin-bottom: 5px;
}

.section .select{
  margin-right: 5px;
}
/*form*/
input, select{
  padding: 10px 6px;
  box-shadow: border-box;
  border: 1px solid #ddd;
  color: #555;
}

/*table*/
.content-table .kanji{
  /*font-family: "noto sans cjk";*/
}
.content-table .romaji{
  
}
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.verbe th {
  background-color: #009879;
  color: #ffffff;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
</style>