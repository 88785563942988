<template>
  <div id="wo">
    <h3 class="title_subcontent">Particule : o (を)</h3>
    <p class="text_no_margin">
      Cette particule s'utilise de deux façons différentes.<br>
      Cette particule est jamais utilisé avec les verbes de description だ (da) et de présence いる (iru) et ある (aru), il est impossible de les lier ensemble.<br> 
    </p>

    <h4 class="title_no_bottom">En Complément d'objet direct</h4>
    <p class="text_no_margin">
      Cette particule sert de COD (complément d'objet direct), elle sert donc à accompagner un mot ou groupe de mot à un verbe (c'est ce qui dans une phrase subit l'action du verbe).<br>
      La particule "wo" est très rarement utilisé dans des mots. On la verra donc très souvent utilisé comme particule.<br>
      On peut reconnaître le COD en se posant la question "<b>qui ?</b>" ou "<b>quoi ?</b>".<br>
      <span class="blue_text">COD + o + verbe</span><br>
    </p>
    <!--<p class="box_example">
        mainichi <span class="red_text">manga <b>o</b></span> yomimasu = Je lis <span class="red_text"><b>des</b> mangas</span> tous les jours (je lis quoi ? Des mangas) <br>
        ashita <span class="red_text">terebi <b>o</b></span> mimasu = Demain je regarderai <span class="red_text"><b>la</b> télévision</span> (je regarde quoi ? la télévision) <br>
        ポールはレストランの中でトムと箸で<span class="red_text">パスタと野菜<b>を</b></span>食べる。= pooru wa resutoran no naka de tomu to hashi de <span class="red_text">pasuta to yasai <b>o</b></span> taberu = paul mange <span class="red_text">des pâtes et des légumes</span> avec des baguettes avec tom dans le restaurant (paul mangue quoi ? des pates et des légumes)<br>
    </p>-->
    <BoxExample 
      :kanji="''"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'mainichi <span class=\'red_text\'>manga <b>o</b></span> yomimasu.'"
      :trad="'Je lis <span class=\'red_text\'><b>des</b> mangas</span> tous les jours.'" 
      :explication="'je lis quoi ? Des mangas.'"
    />
    <BoxExample 
      :kanji="''"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'ashita <span class=\'red_text\'>terebi <b>o</b></span> mimasu.'"
      :trad="'Demain je regarderai <span class=\'red_text\'><b>la</b> télévision</span>.'" 
      :explication="'je regarde quoi ? la télévision.'"
    />
    <BoxExample 
      :kanji="'ポールはレストランの中でトムと箸で<span class=\'red_text\'>パスタと野菜<b>を</b></span>食べる。'"
      :kanjiraw="'ポールはレストランの中でトムと箸でパスタと野菜を食べる。'"
      :kana="''" 
      :roomaji="'pooru wa resutoran no naka de tomu to hashi de <span class=\'red_text\'>pasuta to yasai <b>o</b></span> taberu.'"
      :trad="'paul mange <span class=\'red_text\'>des pâtes et des légumes</span> avec des baguettes avec Tom dans le restaurant.'" 
      :explication="'paul mangue quoi ? des pâtes et des légumes.'"
    />
    <p class="text_no_margin">
      Quelques exceptions d'utilisation du COD.<br>
      <span class="text_li">- avec le verbe 会う (au (rencontrer)): on utilise toujours に (ni)</span><br>
      <span class="text_li">- avec les verbes exprimant un changement on utilise toujours に (ni) :</span><br>
      <span class="text_lix2">- なる (naru (devenir))</span><br>
      <span class="text_lix2">- 変わる (kawaru (se changer en))</span><br>
    </p>
    <!--<p class="box_example">
      ピッコロはセル<span class="red_text"><b>に</b>会う</span> = pikkoro wa seru <span class="red_text"><b>ni</b> au</span> = piccolo <span class="red_text">rencontre</span> cell.<br>
      孫悟空はスーパーサイヤ人<span class="red_text"><b>に</b>なる</span> = Songokuu wa suupaaa saiyajin <span class="red_text"><b>ni</b> naru</span> = Son goku <span class="red_text">devient</span> un super saiyan.<br>
      ナメク星は火山<span class="red_text"><b>に</b>変わる</span> = namekusei wa kazan <span class="red_text"><b>ni</b> kawaru</span> = la planète namek <span class="red_text">se change</span> en volcan<br>
    </p>-->
    <BoxExample 
      :kanji="'ピッコロはセル<span class=\'red_text\'><b>に</b>会う</span>。'"
      :kanjiraw="'ピッコロはセルに会う。'"
      :kana="''" 
      :roomaji="'pikkoro wa seru <span class=\'red_text\'><b>ni</b> au</span>.'"
      :trad="'piccolo <span class=\'red_text\'>rencontre</span> cell.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'孫悟空はスーパーサイヤ人<span class=\'red_text\'><b>に</b>なる</span>。'"
      :kanjiraw="'孫悟空はスーパーサイヤ人になる。'"
      :kana="''" 
      :roomaji="'Songokuu wa suupaaa saiyajin <span class=\'red_text\'><b>ni</b> naru</span>.'"
      :trad="'Son goku <span class=\'red_text\'>devient</span> un super saiyan.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'ナメク星は火山<span class=\'red_text\'><b>に</b>変わる</span>。'"
      :kanjiraw="'ナメク星は火山に変わる。'"
      :kana="''" 
      :roomaji="'namekusei wa kazan <span class=\'red_text\'><b>ni</b> kawaru</span>.'"
      :trad="'la planète namek <span class=\'red_text\'>se change</span> en volcan.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Indiquer un lieu mais dans l'idée d'un parcours non précis</h4>
    <p class="text_no_margin">
      En utilisant les verbes de mouvement, on peut indiquer un parcours non précis contrairement à "で" (de) qui indique un parcours précis.<br>
      <span class="blue_text">COD + o + verbe état de déplacement non précis</span><br>
    </p>
    <!--<p class="box_example">
      鳥は空<span class="red_text">を飛ぶ</span> = tori wa sora <span class="red_text">o tobu</span> = l'oiseau vole dans le ciel (l'oiseau se déplace mais pas dans une direction précis)<br>
      私は公園<span class="red_text">を歩く</span> = watashi wa kouen <span class="red_text">o aruku</span> = je marche dans le parc (je marche dans le parc mais pas dans une direction précis)<br>
    </p>-->
    <BoxExample 
      :kanji="'鳥は空<span class=\'red_text\'>を飛ぶ</span>。'"
      :kanjiraw="'鳥は空を飛ぶ。'"
      :kana="''" 
      :roomaji="'tori wa sora <span class=\'red_text\'>o tobu</span>.'"
      :trad="'l\'oiseau vole dans le ciel.'" 
      :explication="'L\'oiseau se déplace mais pas dans une direction précis.'"
    />
    <BoxExample 
      :kanji="'私は公園<span class=\'red_text\'>を歩く</span>。'"
      :kanjiraw="'私は公園を歩く。'"
      :kana="''" 
      :roomaji="'watashi wa kouen <span class=\'red_text\'>o aruku</span>.'"
      :trad="'je marche dans le parc.'" 
      :explication="'Je marche dans le parc mais pas dans une direction précis.'"
    />
  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>