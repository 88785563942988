<template>
      <div class="section">
      <h1>
        Hiragana
        <input class="search" type="search" v-model="search" placeholder="search occurence" @keyup="searchInTable"/>

		<select class="select" v-model="size" @change="onChange">
          <option value="0.625rem">0.625rem</option>
          <option value="0.75rem">0.75rem</option>
          <option value="0.875rem">0.875rem</option>
          <option selected="selected" value="1rem">1rem</option>
          <option value="1.125rem">1.125rem</option>
          <option value="1.25rem">1.25rem</option>
          <option value="1.5rem">1.5rem</option>
          <option value="1.875rem">1.875rem</option>
          <option value="2rem">2rem</option>
		  </select>
      </h1>

      <table class="content-table hiragana" id="hiragana">
        <thead>
          <tr>
            <th></th>
            <th>A</th>
            <th>I</th>
            <th>U</th>
            <th>E</th>
            <th>O</th>
          </tr>
        </thead>
        <tr>
          <th></th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('あ', 'Tracé de あ (a)')">あ</span>
              <span class="romaji">(a)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('い', 'Tracé de い (i)')">い</span>
              <span class="romaji">(i)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('う', 'Tracé de う (u)')">う</span>
              <span class="romaji">(u)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('え', 'Tracé de え (e)')">え</span>
              <span class="romaji">(e)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('お', 'Tracé de お (o)')">お</span>
              <span class="romaji">(o)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>K</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('か', 'Tracé de か (ka)')">か</span>
              <span class="romaji">(ka)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('き', 'Tracé de き (ki)')">き</span>
              <span class="romaji">(ki)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('く', 'Tracé de く (ku)')">く</span>
              <span class="romaji">(ku)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('け', 'Tracé de け (ke)')">け</span>
              <span class="romaji">(ke)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('こ', 'Tracé de こ (ko)')">こ</span>
              <span class="romaji">(ko)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>S</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('さ', 'Tracé de さ (sa)')">さ</span>
              <span class="romaji">(sa)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('し', 'Tracé de し (shi)')">し</span>
              <span class="romaji">(shi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('す', 'Tracé de す (su)')">す</span>
              <span class="romaji">(su)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('せ', 'Tracé de せ (se)')">せ</span>
              <span class="romaji">(se)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('そ', 'Tracé de そ (i)')">そ</span>
              <span class="romaji">(so)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>T</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('た', 'Tracé de た (ta)')">た</span>
              <span class="romaji">(ta)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ち', 'Tracé de ち (chi)')">ち</span>
              <span class="romaji">(chi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('つ', 'Tracé de つ (tsu)')">つ</span>
              <span class="romaji">(tsu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('て', 'Tracé de て (te)')">て</span>
              <span class="romaji">(te)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('と', 'Tracé de と (to)')">と</span>
              <span class="romaji">(to)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>N</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('な', 'Tracé de な (na)')">な</span>
              <span class="romaji">(na)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('に', 'Tracé de に (ni)')">に</span>
              <span class="romaji">(ni)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぬ', 'Tracé de ぬ (nu)')">ぬ</span>
              <span class="romaji">(nu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ね', 'Tracé de ね (ne)')">ね</span>
              <span class="romaji">(ne)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('の', 'Tracé de の (no)')">の</span>
              <span class="romaji">(no)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>H</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('は', 'Tracé de は (ha)')">は</span>
              <span class="romaji">(ha)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ひ', 'Tracé de ひ (hi)')">ひ</span>
              <span class="romaji">(hi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ふ', 'Tracé de ふ (fu)')">ふ</span>
              <span class="romaji">(fu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('へ', 'Tracé de へ (he)')">へ</span>
              <span class="romaji">(he)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ほ', 'Tracé de ほ (ho)')">ほ</span>
              <span class="romaji">(ho)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>M</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ま', 'Tracé de ま (ma)')">ま</span>
              <span class="romaji">(ma)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('み', 'Tracé de み (mi)')">み</span>
              <span class="romaji">(mi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('む', 'Tracé de む (mu)')">む</span>
              <span class="romaji">(mu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('め', 'Tracé de め (me)')">め</span>
              <span class="romaji">(me)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('も', 'Tracé de も (mo)')">も</span>
              <span class="romaji">(mo)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>Y</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('や', 'Tracé de や (ya)')">や</span>
              <span class="romaji">(ya)</span>
            </div>
          </td>
          <td></td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ゆ', 'Tracé de ゆ (yu)')">ゆ</span>
              <span class="romaji">(yu)</span>
            </div>
          </td>
          <td></td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('よ', 'Tracé de よ (yo)')">よ</span>
              <span class="romaji">(yo)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>R</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ら', 'Tracé de ら (ra)')">ら</span>
              <span class="romaji">(ra)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('り', 'Tracé de り (ri)')">り</span>
              <span class="romaji">(ri)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('る', 'Tracé de る (ru)')">る</span>
              <span class="romaji">(ru)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('れ', 'Tracé de れ (re)')">れ</span>
              <span class="romaji">(re)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ろ', 'Tracé de ろ (ro)')">ろ</span>
              <span class="romaji">(ro)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>W</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('わ', 'Tracé de わ (wa)')">わ</span>
              <span class="romaji">(wa)</span>
            </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('を', 'Tracé de を (wo)')">を</span>
              <span class="romaji">(wo)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>N</th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ん', 'Tracé de ん (n)')">ん</span>
              <span class="romaji">(n)</span>
            </div>
          </td>
        </tr>
        <tr class="table_light_blue">
          <th>G</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('が', 'Tracé de が (ga)')">が</span>
              <span class="romaji">(ga)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぎ', 'Tracé de ぎ (gi)')">ぎ</span>
              <span class="romaji">(gi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぐ', 'Tracé de ぐ (gu)')">ぐ</span>
              <span class="romaji">(gu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('げ', 'Tracé de げ (ge)')">げ</span>
              <span class="romaji">(ge)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ご', 'Tracé de ご (go)')">ご</span>
              <span class="romaji">(go)</span>
            </div>
          </td>
        </tr>
        <tr class="table_light_blue">
          <th>Z</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ざ', 'Tracé de ざ (za)')">ざ</span>
              <span class="romaji">(za)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('じ', 'Tracé de じ (ji)')">じ</span>
              <span class="romaji">(ji)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ず', 'Tracé de ず (zu)')">ず</span>
              <span class="romaji">(zu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぜ', 'Tracé de ぜ (ze)')">ぜ</span>
              <span class="romaji">(ze)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぞ', 'Tracé de ぞ (zo)')">ぞ</span>
              <span class="romaji">(zo)</span>
            </div>
          </td>
        </tr>
        <tr class="table_light_blue">
          <th>D</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('だ', 'Tracé de だ (da)')">だ</span>
              <span class="romaji">(da)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぢ', 'Tracé de ぢ (dji)')">ぢ</span>
              <span class="romaji">(dji)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('づ', 'Tracé de づ (dzu)')">づ</span>
              <span class="romaji">(dzu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('で', 'Tracé de で (de)')">で</span>
              <span class="romaji">(de)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ど', 'Tracé de ど (do)')">ど</span>
              <span class="romaji">(do)</span>
            </div>
          </td>
        </tr>
        <tr class="table_light_blue">
          <th>B</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ば', 'Tracé de ば (ba)')">ば</span>
              <span class="romaji">(ba)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('び', 'Tracé de び (bi)')">び</span>
              <span class="romaji">(bi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぶ', 'Tracé de ぶ (bu)')">ぶ</span>
              <span class="romaji">(bu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('べ', 'Tracé de べ (be)')">べ</span>
              <span class="romaji">(be)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぼ', 'Tracé de ぼ (bo)')">ぼ</span>
              <span class="romaji">(bo)</span>
            </div>
          </td>
        </tr>
        <tr class="table_light_blue">
          <th>P</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぱ', 'Tracé de ぱ (pa)')">ぱ</span>
              <span class="romaji">(pa)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぴ', 'Tracé de ぴ (pi)')">ぴ</span>
              <span class="romaji">(pi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぷ', 'Tracé de ぷ (pu)')">ぷ</span>
              <span class="romaji">(pu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぺ', 'Tracé de ぺ (pe)')">ぺ</span>
              <span class="romaji">(pe)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ぽ', 'Tracé de ぽ (po)')">ぽ</span>
              <span class="romaji">(po)</span>
            </div>
          </td>
        </tr>
      </table>

      <KanjiDraw v-if="kanjiDrawCurrent" :title="kanjiDrawCurrentTitle" :kanji="kanjiDrawCurrent" @endKanjiDraw="endPopupKanjiDraw"/>

    </div>
</template>

<script>
import KanjiDraw from "@/components/Composants/KanjiDraw.vue";

export default {
    data(){
      return{
        size : '1rem',
        search: '',
        kanjiDrawCurrent: '',
        kanjiDrawCurrentTitle: '',
      }
    },
    components: {
      KanjiDraw
    },
    methods: {
      searchInTable(){
        if(this.search.length > 0){
          var kanjis = document.getElementById("hiragana").getElementsByClassName("kanji_container")
          kanjis.forEach((l) => l.classList.add("inactif_kanji"));
          kanjis.forEach(element => {
            if(element.innerText.includes(this.search)){
                element.classList.remove("inactif_kanji")
            }
          });
        } else {
          var kanjis = document.getElementById("hiragana").getElementsByClassName("kanji_container")
          kanjis.forEach((l) => l.classList.remove("inactif_kanji"));
        }
      },
      onChange(){
          document.getElementById("hiragana").style.fontSize = this.size
      },
      // KanjiDraw Method
      showDrawKanji(kanji, title){
        this.kanjiDrawCurrent = kanji;
        this.kanjiDrawCurrentTitle = title;
      },
      // event
      endPopupKanjiDraw(){
        this.kanjiDrawCurrent = ''
      },
    },
    mounted(){
        this.onChange()
    }
}
</script>

<style lang="scss" scoped>
/*section*/
.section{
  display: inline-block;
  margin: 0 15px;
    .inactif_kanji{
      opacity: 0.4;
  }
}

.section .search,
.section .select{
  float: right;
  margin-top: 9px;
  min-height: 40px;
}

.section .select{
  margin-right: 5px;
}
/*form*/
input, select{
  padding: 10px 6px;
  box-shadow: border-box;
  border: 1px solid #ddd;
  color: #555;
}

/*table*/
.content-table .kanji{
  /*font-family: "noto sans cjk";*/
}
.content-table .romaji{
  
}
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.hiragana th {
  background-color: #009879;
  color: #ffffff;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

/* kanji draw button*/
.kanji_bottom{
  display: flex;
  justify-content: center;
}
.kanji_button{
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 1px;
  &:hover{
    opacity: 0.75;
    background: rgb(221 221 221 / 50%);
  }
}
</style>