<template>
  <div class="home">
    <div class="introduction">
      <img alt="Vue logo" src="../assets/logo_japanese.png">
      <h1 class="intro_title">Japonais Récap</h1>
    </div>
    <p>
      Japonais Récap est une plateforme condensée et résumé de la langue Japonaise, pour tous ceux qui se lancent dans l'apprentissage du japonais. Vous pourrez retrouver rapidement les différentes notions de japonais et utiliser la plateforme en complément avec d'autres références de cours, pour un apprentissage optimal.<br>
      Pour résumer, Japonais Récap permet d'apprendre et de se remémorer rapidement les grandes règles de la langue japonaise.<br>
      Ce site est entièrement gratuit et vise à partager cette passion commune pour l'apprentissage de cette langue!<br>
    </p>

    <!--<h3 class="title_subcontent">Par où commencer pour les débutants ?</h3>
    <p class="text_no_margin">
      Voici une liste de référence en ligne pour apprendre le japonais de façon optimal en autodidacte:<br>
      <span class="text_li">- les cours de japonais de julien fontanier sur youtube
        <Button1 :text="'Cours de japonais'" :target="'_blank'" :link="'https://www.youtube.com/c/CoursdejaponaisFR/featured'" />
      </span><br>
      <span class="text_li">- les cours de japonais sur japan activator (application mobile android et web) 
        <Button1 :text="'Japan Activator'" :target="'_blank'" :link="'https://www.japan-activator.com/fr'" />
      </span><br>
    </p>-->

    <h3 class="title_subcontent">Par où commencer pour les débutants ?</h3>
    <p class="text_no_margin">
      Pour apprendre le japonais de façon optimale, je vous recommande fortement de suivre cette référence de cours en ligne, sur lequel "Japonais Récap" se base énormément.
      <Button1 :text="'Cours de Japonais par julien fontanier'" :target="'_blank'" :link="'https://www.youtube.com/c/CoursdejaponaisFR/featured'" /><br>
      Pour apprendre le japonais sur "Japonais Récap", suivez les thèmes principaux dans l'ordre chronologique présenté, en commençant par leur sous thèmes (De gauche à droite).<br>
      <span class="text_li">- Comprendre l'écriture et la prononciation de la langue japonaise</span><br>
      <!--<span class="text_li">- (Facultatif) Clavier Japonais (utile pour utiliser le clavier japonais sur PC)</span><br>-->
      <span class="text_li">- Comprendre la grammaire japonaise</span><br>
      <span class="text_li">- Comprendre la conjugaison et les adjectifs</span><br>
      N'hésitez pas à faire un tour dans "Vocabulaires" pour apprendre de nouveaux mots de vocabulaire par thème. Vous aurez des exercices, la possibilité de voir le tracé de chaque Kanji et Kana, écouter la prononciation, etc.<br>
      Ainsi que l'annexe avec des liens externes vers d'autres applications, jlpt, flashcard, etc utile à votre apprentissage.<br>
      <Button1 :text="'Vocabulaire par thèmes'" :link="'Vocabulaires'" :isInterneBp="true" />
      <Button1 :text="'Annexes - Liens externes'" :link="'Link'" :isInterneBp="true" /><br>
      <!--Vous pouvez également télécharger Japonais récap sur votre smartphone<br>
      <Button1 :text="'Téléchargez l\'application'" :link="'#'" @click.native="triggerPopupPwa" />-->
    </p>

    <h3 class="title_subcontent">
      Liste des cours
      <div class="cours_choose">
        <div class="cours_choose_bp" v-if="isGrid" @click="toggleGrid">Switch Liste <i class="fas fa-list"></i></div>
        <div class="cours_choose_bp" v-else @click="toggleGrid">Switch Grid <i class="fas fa-th-large"></i></div>
      </div>
    </h3>

    <div class="legend">
      <span class="legend_title">Légendes des icônes : </span>
      <i class="fas fa-book legend_icon"></i> Kanji,  
      <i class="fas fa-book-open legend_icon"></i> Kana,
      <i class="fas fa-bookmark legend_icon"></i> Rōmaji,
      <i class="fas fa-audio-description legend_icon"></i> Traduction,
      <i class="fas fa-question-circle legend_icon"></i> Explication
    </div>

    <div v-if="isGrid">
      <h4 class="title_no_bottom">Comprendre l'écriture et la prononciation de la langue japonaise</h4>
      <HomeCard :title="'Écriture & Lecture'" :description="'Cours sur l\'introduction à la langue japonaise avec les Kana (Hiragana et Katakana), Kanji, Rōmaji, etc'" :img="'fa-font'" :link="'Introduction'"/>
      <HomeCard :title="'Nombre & Compter'" :description="'Cours sur l\'utilisation des nombres et comment compter'" :img="'fa-sort-numeric-down'" :link="'Compter'"/>
      <HomeCard :title="'Dates'" :description="'Cours sur la compréhension et l\'utilisation des dates ainsi que des différentes ères japonais'" :img="'fa-calendar-alt'" :link="'Date'"/>
      <HomeCard :title="'Lire l\'heure'" :description="'Cours sur l\'utilisation des heures'" :img="'fa-history'" :link="'Times'"/>
      
      <h4 class="title_no_bottom">Comprendre la grammaire japonaise</h4>
      <HomeCard :title="'Grammaire'" :description="'Cours sur l\'introduction à la grammaire japonaise'" :img="'fa-pencil-ruler'" :link="'Grammaire'"/>
      <HomeCard :title="'Particules Principaux'" :description="'Cours sur l\'utilisation des principaux particules'" :img="'fa-scroll'" :link="'Particule'"/>
      <HomeCard :title="'Autres Particules'" :description="'Cours sur l\'utilisation des particules adverbiales, d\'emphase, de fin de phrase et de noda'" :img="'fa-scroll'" :link="'ParticuleAutre'"/>
      <HomeCard :title="'Adverbes'" :description="'Cours sur l\'utilisation des adverbes'" :img="'fa-pen-alt'" :link="'Adverbe'"/>
      <HomeCard :title="'Mots interrogatifs'" :description="'Cours sur comment utiliser les mots interrogatifs et poser une question'" :img="'fa-question'" :link="'PoserQuestion'"/>
      <HomeCard :title="'Préfixes'" :description="'Cours sur l\'utilisation des préfixes こ・そ・あ・ど et お de politesse et honorifique'" :img="'fa-angle-double-left'" :link="'Prefixes'"/>
      <HomeCard :title="'Classificateurs'" :description="'Cours sur l\'utilisation des classificateurs (compter et classer les choses)'" :img="'fa-list-ol'" :link="'Classificateurs'"/>
      <HomeCard :title="'Unités de mesure'" :description="'Cours sur l\'utilisation des unités de mesure'" :img="'fa-balance-scale'" :link="'UnitMesure'"/>
      <HomeCard :title="'Connecteurs logiques'" :description="'Cours sur l\'utilisation des connecteurs logiques entre plusieurs phrase'" :img="'fa-external-link-alt'" :link="'Connecteurs'"/>
      <HomeCard :title="'Spécificités Japonais'" :description="'Cours sur les spécificités de la langue japonaise'" :img="'fa-book-reader'" :link="'SpecificiteJap'"/>
      <HomeCard :title="'Pronoms Personnels'" :description="'Cours sur l\'utilisation des pronoms personnels'" :img="'fa-user-friends'" :link="'PronomsPersonnels'"/>
      <HomeCard :title="'Pluriels & Adjectif possessifs'" :description="'Cours sur l\'utilisation des pluriels et adjectifs possessifs'" :img="'fa-users'" :link="'PlurielAdjectifsPos'"/>
      <HomeCard :title="'Suffixes & Titres Honorifiques'" :description="'Cours sur l\'utilisation des suffixes et des titres honorifiques'" :img="'fa-hat-cowboy'" :link="'SuffixesHonorifiques'"/>

      <h4 class="title_no_bottom">Comprendre la conjugaison et les adjectifs</h4>
      <HomeCard :title="'Adjectifs'" :description="'Cours sur l\'utilisation des adjectifs'" :img="'fa-paste'" :link="'Adjectifs'"/>
      <HomeCard :title="'Verbes'" :description="'Cours sur l\'utilisation des verbes'" :img="'fa-book'" :link="'Verbes'"/>

      <h4 class="title_no_bottom">Annexes</h4>
      <HomeCard :title="'Vocabulaire'" :description="'Liste de vocabulaire non-exhaustive catégorisé par thème'" :img="'fa-globe-asia'" :link="'Vocabulaires'"/>
      <HomeCard :title="'Clavier Japonais'" :description="'Installation et tutoriel sur l\'utilisation d\'un clavier japonais sur windows'" :img="'fa-language'" :link="'Clavier'"/>
      <HomeCard :title="'Liens externes'" :description="'Liste non exhaustive de liens utiles à l\'apprentissage du japonais'" :img="'fa-link'" :link="'Link'"/>
      <HomeCard :title="'Convertir Rōmaji & Kana'" :description="'Outils pour convertir le Rōmaji vers Kana et inversement'" :img="'fa-keyboard'" :link="'Converter'"/>
      <!--<HomeCard :title="'Application Japonais-récap'" :description="'Téléchargez Japonais récap sur votre smartphone'" :img="'fa-mobile-alt'" @click.native="triggerPopupPwa" />-->
    </div>
    <div v-else>
      <h4 class="title_no_bottom">Comprendre l'écriture et la prononciation de la langue japonaise</h4>
      <HomeListCard :title="'Écriture & Lecture'" :description="'Cours sur l\'introduction à la langue japonaise avec les Kana (Hiragana et Katakana), Kanji, Rōmaji, etc'" :img="'fa-font'" :link="'Introduction'"/>
      <HomeListCard :title="'Nombre & Compter'" :description="'Cours sur l\'utilisation des nombres et comment compter'" :img="'fa-sort-numeric-down'" :link="'Compter'"/>
      <HomeListCard :title="'Dates'" :description="'Cours sur la compréhension et l\'utilisation des dates ainsi que des différentes ères japonais'" :img="'fa-calendar-alt'" :link="'Date'"/>
      <HomeListCard :title="'Lire l\'heure'" :description="'Cours sur l\'utilisation des heures'" :img="'fa-history'" :link="'Times'"/>
      
      <h4 class="title_no_bottom">Comprendre la grammaire de la Langue japonaises</h4>
      <HomeListCard :title="'Grammaire'" :description="'Cours sur l\'introduction à la grammaire japonaise'" :img="'fa-pencil-ruler'" :link="'Grammaire'"/>
      <HomeListCard :title="'Particules Principaux'" :description="'Cours sur l\'utilisation des principaux particules'" :img="'fa-scroll'" :link="'Particule'"/>
      <HomeListCard :title="'Autres Particules'" :description="'Cours sur l\'utilisation des particules adverbiales, d\'emphase, de fin de phrase et de noda'" :img="'fa-scroll'" :link="'ParticuleAutre'"/>
      <HomeListCard :title="'Adverbes'" :description="'Cours sur l\'utilisation des adverbes'" :img="'fa-pen-alt'" :link="'Adverbe'"/>
      <HomeListCard :title="'Mots interrogatifs'" :description="'Cours sur comment utiliser les mots interrogatifs et poser une question'" :img="'fa-question'" :link="'PoserQuestion'"/>
      <HomeListCard :title="'Préfixes'" :description="'Cours sur l\'utilisation des préfixes こ・そ・あ・ど et お de politesse et honorifique'" :img="'fa-angle-double-left'" :link="'Prefixes'"/>
      <HomeListCard :title="'Classificateurs'" :description="'Cours sur l\'utilisation des classificateurs (compter et classer les choses)'" :img="'fa-list-ol'" :link="'Classificateurs'"/>
      <HomeListCard :title="'Unités de mesure'" :description="'Cours sur l\'utilisation des unités de mesure'" :img="'fa-balance-scale'" :link="'UnitMesure'"/>
      <HomeListCard :title="'Connecteurs logiques'" :description="'Cours sur l\'utilisation des connecteurs logiques entre plusieurs phrase'" :img="'fa-external-link-alt'" :link="'Connecteurs'"/>
      <HomeListCard :title="'Spécificités Japonais'" :description="'Cours sur les spécificités de la langue japonaise'" :img="'fa-book-reader'" :link="'SpecificiteJap'"/>
      <HomeListCard :title="'Pronoms Personnels'" :description="'Cours sur l\'utilisation des pronoms personnels'" :img="'fa-user-friends'" :link="'PronomsPersonnels'"/>
      <HomeListCard :title="'Pluriels & Adjectif possessifs'" :description="'Cours sur l\'utilisation des pluriels et adjectifs possessifs'" :img="'fa-users'" :link="'PlurielAdjectifsPos'"/>
      <HomeListCard :title="'Suffixes & Titres Honorifiques'" :description="'Cours sur l\'utilisation des suffixes et des titres honorifiques'" :img="'fa-hat-cowboy'" :link="'SuffixesHonorifiques'"/>

      <h4 class="title_no_bottom">Comprendre la conjugaison et les adjectifs</h4>
      <HomeListCard :title="'Adjectifs'" :description="'Cours sur l\'utilisation des adjectifs'" :img="'fa-paste'" :link="'Adjectifs'"/>
      <HomeListCard :title="'Verbes'" :description="'Cours sur l\'utilisation des verbes'" :img="'fa-book'" :link="'Verbes'"/>

      <h4 class="title_no_bottom">Annexes</h4>
      <HomeListCard :title="'Vocabulaire'" :description="'Liste de vocabulaire non-exhaustive catégorisé par thème'" :img="'fa-globe-asia'" :link="'Vocabulaires'"/>
      <HomeListCard :title="'Clavier Japonais'" :description="'Installation et tutoriel sur l\'utilisation d\'un clavier japonais sur windows'" :img="'fa-language'" :link="'Clavier'"/>
      <HomeListCard :title="'Liens externes'" :description="'Liste non exhaustive de liens utiles à l\'apprentissage du japonais'" :img="'fa-link'" :link="'Link'"/>
      <HomeListCard :title="'Convertir Rōmaji & Kana'" :description="'Outils pour convertir le Rōmaji vers Kana et inversement'" :img="'fa-keyboard'" :link="'Converter'"/>
      <!--<HomeCard :title="'Application Japonais-récap'" :description="'Téléchargez Japonais récap sur votre smartphone'" :img="'fa-mobile-alt'" @click.native="triggerPopupPwa" />-->
    </div>
  
    <!-- PWA popup -->
    <div v-if="deferredPrompt" class="pwa_popup">
      <div class="pwa_description">
        Téléchargez notre application gratuitement. Il ne prendra pas de place sur votre téléphone et les cours fonctionnent également hors ligne! 
      </div>
      <div class="pwa_buttons">
        <button class="pwa_bp" @click="dismiss">Annuler</button>
        <button class="pwa_bp" @click="install">Installer</button>
      </div>
    </div>

  </div>
</template>

<script>
import HomeCard from "@/components/Cards/HomeCard.vue";
import HomeListCard from "@/components/Cards/HomeListCard.vue";
import Button1 from "@/components/Buttons/Button1.vue";

export default {
  name: 'Home',
  components: {
    HomeCard,
    HomeListCard,
    Button1
  },
  data() {
    return {
      deferredPrompt: null,
      isGrid: true
    };
  },
  methods: {
    // PWA - https://medium.com/js-dojo/promoting-your-vue-js-pwa-installation-6bd112342c19
    async dismiss() {
      this.deferredPrompt = null;
    },
    async install() {
      this.deferredPrompt.prompt();
    },
    triggerPopupPwa(){
      window.addEventListener("beforeinstallprompt", e => {
        e.preventDefault();
        // Stash the event so it can be triggered later.
        this.deferredPrompt = e;
      });
    },
    // Toggle Grid
    toggleGrid() {
      if(this.isGrid)
        this.isGrid = false;
      else
        this.isGrid = true;
    },
  },
  created() {
    // PWA - https://medium.com/js-dojo/promoting-your-vue-js-pwa-installation-6bd112342c19
    /*window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
    });*/
    window.addEventListener("appinstalled", () => {
      this.deferredPrompt = null;
    });
  }
}
</script>

<style scoped lang="scss">
.home{
  img{
    max-width: 50%;
    max-height: 5rem;
  }
  .introduction{
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    .intro_title{
      align-self: center;
      margin-left: 10px;
    }
  }
}

.cours_choose{
  float: right;
  .cours_choose_bp{
    cursor: pointer;
    //color: var(--first-color);
    border: solid 1px var(--first-color);
    border-radius: 5px;
    padding: 0px 8px 0px 8px;
    &:hover{
      opacity: 0.8;
    }
  }
}

.legend_icon{
  color: var(--first-color);
}

/*=== PWA ===*/
.pwa_popup{
  background: var(--bg-color);
  color: #fff;
  font-size: 15px;
  left: 0;
  position: fixed;
  box-sizing: content-box;
  z-index: 150;
  text-align: center;
  padding: 10px 0 10px 0;
  margin: auto;
  width: 100%;
  bottom: 0;
  .pwa_buttons{
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .pwa_bp{
    cursor: pointer;
    line-height: 1.2;
    font-size: 16px;
    text-decoration: none;
    padding: 5px 25px;
    margin: 5px 20px;
    border-radius: 5px;
    box-shadow: 0px 5px 5px -3px rgb(33 33 33 / 50%);
    background: white;
    &:hover {
      opacity: 0.8;
    }
  }
}
</style>
