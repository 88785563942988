<template>
  <div class="home_card">
    <div v-if="link">
      <router-link :to="{ name: link }" class="href_card">
        <i :class="'fas ' + img + ' card_icon'"></i>
        <h1 class="card_title">
          {{ title }}
        </h1>
        <div class="card_description">
          {{ description }}
        </div>
      </router-link>
    </div>
    <div v-else>
      <div :to="{ name: link }" class="href_card">
        <i :class="'fas ' + img + ' card_icon'"></i>
        <h1 class="card_title">
          {{ title }}
        </h1>
        <div class="card_description">
          {{ description }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeCard",
  props: ["title", "description", "img", "link"],
};
</script>

<style lang="scss" scoped>
.home_card {
  margin: 15px;
  display: inline-block;
  cursor: pointer;
  .href_card{
    display: inline-block;
    text-align: center;
    max-width: 320px;
    border: solid 1px #ddd;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 6px 30px -10px #4a74c9;
    transition: linear 0.25s;
  }
  .card_icon{
    color: deepskyblue;
    font-size: 4rem;
  }
  &:hover{
    opacity: 0.7;
    transition: linear 0.25s;
  }
}
</style>