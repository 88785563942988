<template>
  <div>
    <h1>Pluriel et adjectifs possessifs japonais</h1>
    <p class="text_no_margin">
      Les adjectifs possessifs correspond à "mon", "ton", "son", etc. C'est différent des mots adjectifs.<br>
    </p>

    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Les Suffixes de pluriel'" :link="'#suffixes_pluriel'" />
    <Button1 :text="'Les Adjectifs possessifs'" :link="'#adjectifs_possessifs'" />

    <h3 class="title_subcontent" id="suffixes_pluriel">Les Suffixes de pluriel</h3>
    <h4 class="title_no_bottom">達 - たち (tachi)</h4>
    <p class="text_no_margin">
      <span class="red_text">たち (tachi)</span> est un kanji que l'on va mettre après des pronoms personnels pour signifier qu'ils sont au pluriels.<br>
      - première personne du pluriel (nous): 私<span class="red_text">たち</span>  (watashi<span class="red_text">tachi</span>), 僕<span class="red_text">たち</span> (boku<span class="red_text">tachi</span>), on peut combiner à tous les pronoms personnels "je".<br>
      - deuxième personne du pluriel (vous): あなた<span class="red_text">たち</span> (anata<span class="red_text">tachi</span>), 君<span class="red_text">たち</span> (kimi<span class="red_text">tachi</span>), on peut combiner à tous les pronoms personnels "tu".<br>
      - troisième personne du pluriel (ils, elles): 彼<span class="red_text">たち</span> (kare<span class="red_text">tachi</span>), 彼女<span class="red_text">たち</span> (kanojo<span class="red_text">tachi</span>), on peut combiner à tous les pronoms personnels "il et elle".<br>
      Lorsque dans un groupe il y a des hommes et des femmes on peut utiliser "ils" ou "elles" ou de manière général この人<span class="red_text">たち</span> (konohito<span class="red_text">tachi</span>) pour parler de ces personnes sans préciser de genre.<br>
    </p>
    <p class="box_example">
      この人<span class="red_text">たち</span> (konohito<span class="red_text">tachi</span>) = ces personnes
    </p>
    <p class="text_no_margin">
      On peut aussi utiliser le suffixe 達 (tachi) sur des noms désignant des êtres humains.<br>
    </p>
    <p class="box_example">
      戦士<span class="red_text">たち</span> (senchi<span class="red_text">tachi</span>) = les guerriers
    </p>
    <p class="text_no_margin">
      On peut aussi utiliser le suffixe 達 (tachi) sur des noms propres pour dire "et compagnie, et les autres".
    </p>
    <p class="box_example">
      ギギ<span class="red_text">たち</span> (guigui<span class="red_text">tachi</span>) = Guigui et compagnie<br>
    </p>
    <p class="text_no_margin">
      Concernant 友達 (tomodachi), le mot à été créer comme ça et permet de dire un ou plusieurs amis.<br>
    </p>

    <h4 class="title_no_bottom">等 - ら (ra)</h4>
    <p class="text_no_margin">
      <span class="red_text">ら (ra)</span> est un suffixe de pluriel rabaissant, il rabaise la personne désigner par le pronom personnel.<br>
      On va l'utiliser avec des pronoms personnels informels et des mots hostiles.<br>
    </p>
    <p class="box_example">
      貴様<span class="red_text">ら</span> (kisama<span class="red_text">ra</span>) = vous (péjoratif)<br>
    </p>
    <p class="text_no_margin">
      Mais utiliser ce suffixe sur soi-même ou son groupe, sa famille fait humble et créer une modestie.<br>
      Rappel: Appliquer ce qui est dégradant sur autrui, c'est méchant. Mais appliquer sur soi-même c'est humble, car on se fait tout petit (il y a une idée de se manque un peu de respect pour valoriser l'interlocuteur).<br>
    </p>
    <p class="box_example">
      僕<span class="red_text">ら</span> (boku<span class="red_text">ra</span>) = nous (modestie)
    </p>
    <p class="text_no_margin">
      On peut mettre ら (ra) après des préfixes démonstratifs こ・そ・あ・ど pour désigner des choses au pluriel.<br>
    </p>
    <p class="box_example">
      これ<span class="red_text">ら</span> (kore<span class="red_text">ra</span>) = ces objets, ces choses<br>
      こいつ<span class="red_text">ら</span> (koitsu<span class="red_text">ra</span>) = ces types là<br>
      ここ<span class="red_text">ら</span> (koko<span class="red_text">ra</span>) = ces environs<br>
    </p>

    <h4 class="title_no_bottom">共 - とも (tomo) ou ども (domo)</h4>
    <p class="text_no_margin">
      <span class="red_text">とも (tomo) ou ども (domo)</span> est un suffixe de pluriel rabaissant, il s'utilise avec "watashi" ou "watakushi" et des noms désignant des personnes inférieur dans la hiérarchie.<br>
      Rappel: Appliquer ce qui est dégradant sur autrui, c'est méchant. Mais appliquer sur sois même c'est humble.
    </p>
    <p class="box_example">
      私<span class="red_text">ども</span> (watashi<span class="red_text">domo</span>) = nous (modestie)<br>
      後輩<span class="red_text">ども</span> (kouhai<span class="red_text">domo</span>) = les cadets (péjoratif)<br>
      野郎<span class="red_text">ども</span> (yarou<span class="red_text">domo</span>) = les gars (péjoratif)<br>
    </p>
    <p class="text_no_margin">
      Autre utilisation de とも (tomo) avec un nombre de personnes, pour parler du groupe<br>
    </p>
    <p class="box_example">
      2人<span class="red_text">とも</span> (futari<span class="red_text">tomo</span>) = tous les deux<br>
      <!--私<span class="red_text">たち</span>は2人<span class="blue_text">とも</span>フランス人だ。= watashi<span class="red_text">tachi</span> wa futari<span class="blue_text">tomo</span> furansujin da。= <span class="red_text">Nous</span> sommes <span class="blue_text">tous les deux</span> des français<br>-->
    </p>
    <BoxExample 
      :kanji="'私<span class=\'red_text\'>たち</span>は2人<span class=\'blue_text\'>とも</span>フランス人だ。'"
      :kanjiraw="'私たちは2人ともフランス人だ。'"
      :kana="''" 
      :roomaji="'watashi<span class=\'red_text\'>tachi</span> wa futari<span class=\'blue_text\'>tomo</span> furansujin da。'"
      :trad="'<span class=\'red_text\'>Nous</span> sommes <span class=\'blue_text\'>tous les deux</span> des français'" 
      :explication="''"
    />


    <h4 class="title_no_bottom">方 - かた (kata) ou がた (gata)</h4>
    <p class="text_no_margin">
      <span class="red_text">かた (kata) ou がた (gata)</span> est un suffixe de pluriel très formel, il s'utilise avec "anata" ou sur des mots désignant des personnes.<br>
    </p>
    <p class="box_example">
      あんた<span class="red_text">がた</span> (anata<span class="red_text">gata</span>) = vous (très formel, poli)<br>
      先生<span class="red_text">がた</span> (sensei<span class="red_text">gata</span>) = des professeurs (très formel)<br>
      お客様<span class="red_text">がた</span> (okyakusama<span class="red_text">gata</span>) = des clients (très formel)<br>
    </p>

    <h4 class="title_no_bottom" id="adjectifs_possessifs">kanji de répétion 々</h4>
    <p class="text_no_margin">
      Parfois la répétition d'un kanji avec <span class="red_text">々</span> permet de mettre un mot au pluriel.<br>
    </p>
    <p class="box_example">
      神<span class="red_text">々</span> (kami<span class="red_text">gami</span>) = les dieux<br>
      人<span class="red_text">々</span> (hito<span class="red_text">bito</span>) = les personnes, les gens<br>
      方<span class="red_text">々</span> (kata<span class="red_text">gata</span>) = les personnes (en plus poli que hitobito)<br>
      我<span class="red_text">々</span> (ware<span class="red_text">ware</span>) = nous (très formel)<br>
    </p>
    <p class="text_no_margin">
      Tous n'est pas forcément au pluriel.
    </p>
    <p class="box_example">
      日<span class="red_text">々</span> (hi<span class="red_text">bi</span>) = les jours, jour après jour<br>
      時<span class="red_text">々</span> (toki<span class="red_text">doki</span>) = de temps en temps<br>
    </p>

    <h3 class="title_subcontent">Les Adjectifs possessifs</h3>
    <p class="text_no_margin">
      Tous les pronoms personnels sont des noms, on peut donc les utiliser avec la particule の (no) pour exprimer une possession.<br>
    </p>
    <p class="box_example">
      トムの馬 (tomu no uma) = le cheval de Tom<br>
      <span class="red_text">私の</span>馬 (<span class="red_text">watashi no</span> uma) = le cheval de moi = Mon cheval<br>
      <span class="red_text">我が</span>馬 (<span class="red_text">waga</span> uma) = le cheval de moi = Mon cheval (petite formulation propre à "我" (ware) qui utilise "が (ga) au lieu de "の" (no) et qui fait un peu ancien, littéraire, un peu fier)<br>
      <span class="red_text">あなたの</span>馬 (<span class="red_text">anata no</span> uma) = le cheval de toi = Ton cheval<br>
      <span class="red_text">彼の</span>馬 (<span class="red_text">kare no</span> uma) = le cheval de lui = Son cheval<br>
      <span class="red_text">彼女の</span>馬 (<span class="red_text">kanojo no</span> uma) = le cheval de d'elle = Son cheval<br>
      <span class="red_text">わたくしどもの</span>馬 (<span class="red_text">watakushidomo no</span> uma) = le cheval de nous = Notre cheval<br>
      <span class="red_text">君たちの</span>馬 (<span class="red_text">kimitachi no</span> uma) = le cheval de vous = Votre cheval<br>
      <span class="red_text">奴らの</span>馬 (<span class="red_text">yatsura no</span> uma) = le cheval d'eux = Leur cheval<br>
    </p>
    <p class="text_no_margin">
      Attention à l'odre des mots. quand on a un adjectif possessif au sein d'une possession.<br>
      Il faut bien inverser en français et Japonais.<br>
    </p>
    <p class="box_example">
      <span class="red_text">私の</span>友達の馬 (<span class="red_text">watashi no</span> tomodachi no uma) = le cheval de l'ami <span class="red_text">de moi</span> = le cheval de <span class="red_text">mon</span> ami<br>
    </p>
    <p class="text_no_margin">
      En japonais il n'y a pas de soucis à répéter plusieurs fois le même pronom personnel dans une même phrase.<br>
    </p>
    <p class="box_example">
      私は<span class="red_text">私の</span>馬を売る。(watashi wa <span class="red_text">watashi no</span> uma o uru) = je vend <span class="red_text">mon</span> cheval
    </p>
    <p class="text_no_margin">
      Petit information sur <span class="red_text">我が</span> (waga) qui a créer un nouveau mot de vocabulaire <span class="red_text">我がまま</span> (wagamama) qui veut dire "dans l'état, tel que les choses sont, égoisme".
    </p>
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  name: 'Classificateurs',
  components: {
    Button1,
    BoxExample
  },
}
</script>

<style>

</style>