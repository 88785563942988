<template>
  <div id="e">
    <h3 class="title_subcontent">Particule : e (へ)</h3>
    <p class="text_no_margin">Cette particule permet d'indiquer une direction, contrairement à "に" (ni) qui indique un lieu, point d'arrivée, "e" (へ) va plus insister sur la manière de se déplacer, ce vers quoi on se dirige.<br>
    Pour indiquer cette direction, on va utiliser des verbes de mouvement directionnel.<br>
    La notion de direction est important, le verbe "marcher" ne marche pas ici car elle n'indique pas une direction mais un état de marche.<br>
    <span class="text_li">- 行く (いく): aller</span><br>
    <span class="text_li">- 来る　(くる): venir</span><br>
    <span class="text_li">- 帰る　(かえる): rentrer chez soi</span><br>
    <span class="text_li">- 戻る　(もどる): revenir, retourner</span><br>
    <span class="text_li">- 向かう　(むかう): se diriger vers</span><br>
    <span class="text_li">- 進む　(すすむ): avancer, progresser</span><br>
    <span class="blue_text">Lieu + e + verbe de déplacement</span><br>
    </p>
    <!--<p class="box_example">
        <span class="red_text">フランス<b>へ</b></span>帰る = <span class="red_text">furansu <b>e</b></span> kaeru = Rentrer <span class="red_text">en france</span><br>
        <span class="red_text">レストラン<b>へ</b></span>いく = <span class="red_text">resutoran <b>e</b></span> iku = aller <span class="red_text">en direction du restaurant</span><br>
        トムは<span class="red_text">レストラン<b>へ</b></span>行く = tomu wa <span class="red_text">resutoran <b>e</b></span> iku = Tom va <span class="red_text">en direction du restaurant</span><br>
        ポールはバイクで<span class="red_text">西の都<b>へ</b></span>いく = pooru wa baiku de <span class="red_text">nishi no miyako <b>e</b></span> iku = Paul va <span class="red_text">en direction de la capitale de l'ouest</span> en moto<br>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>フランス<b>へ</b></span>帰る。'"
      :kanjiraw="'フランスへ帰る。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>furansu <b>e</b></span> kaeru.'"
      :trad="'Rentrer <span class=\'red_text\'>en france</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'red_text\'>レストラン<b>へ</b></span>いく。'"
      :kanjiraw="'レストランへいく。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>resutoran <b>e</b></span> iku.'"
      :trad="'aller <span class=\'red_text\'>en direction du restaurant</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'トムは<span class=\'red_text\'>レストラン<b>へ</b></span>行く。'"
      :kanjiraw="'トムはレストランへ行く。'"
      :kana="''" 
      :roomaji="'tomu wa <span class=\'red_text\'>resutoran <b>e</b></span> iku.'"
      :trad="'Tom va <span class=\'red_text\'>en direction du restaurant</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'ポールはバイクで<span class=\'red_text\'>西の都<b>へ</b></span>いく。'"
      :kanjiraw="'ポールはバイクで西の都へいく。'"
      :kana="''" 
      :roomaji="'pooru wa baiku de <span class=\'red_text\'>nishi no miyako <b>e</b></span> iku.'"
      :trad="'Paul va <span class=\'red_text\'>en direction de la capitale de l\'ouest</span> en moto.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Globalement dans la vie courante, la particule "e" est moins utilisé que "ni" car on indique très souvent le point d'arrivée et pas la manière d'y arriver.
    </p>
    <!--<p class="box_example">
      トムはレストラン<span class="red_text">に</span>行く = tomu wa resutoran <span class="red_text">ni</span> iku = tom va <span class="red_text">au</span> restaurant<br>
      トムはレストラン<span class="red_text">へ</span>行く = tomu wa resutoran <span class="red_text">e</span> iku = tom va <span class="red_text">en direction</span> du restaurant<br>
    </p>-->
    <BoxExample 
      :kanji="'トムはレストラン<span class=\'red_text\'>に</span>行く。'"
      :kanjiraw="'トムはレストランに行く。'"
      :kana="''" 
      :roomaji="'tomu wa resutoran <span class=\'red_text\'>ni</span> iku.'"
      :trad="'tom va <span class=\'red_text\'>au</span> restaurant.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'トムはレストラン<span class=\'red_text\'>へ</span>行く。'"
      :kanjiraw="'トムはレストランへ行く。'"
      :kana="''" 
      :roomaji="'tomu wa resutoran <span class=\'red_text\'>e</span> iku.'"
      :trad="'tom va <span class=\'red_text\'>en direction</span> du restaurant.'" 
      :explication="''"
    />
  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>