<template>
  <div id="app">
    <!-- Sidebar -->
    <Sidebar />
    <!-- Router View -->
    <transition 
      name="fade" 
      enter-active-class="animate__animated animate__fadeInRight"
      leave-active-class="animate__animated animate__fadeOutLeft">
      <router-view class="page_router" />
    </transition>
    <!-- Button Hide Sidebar -->
    <button @click="toggleSidebarDisplay" class="button_fix_top bp_top">
      <i class="fas fa-expand-alt"></i>
    </button>
    <!--<span ref="titleBpTopRight" class="txt_bp_sidebar">Cacher Sidebar</span>-->
    <!-- Button Scroll To Top -->
    <button @click="scrollToTop" class="button_fix_top bp_bottom">
      <i class="fas fa-chevron-up"></i>
    </button>
    <!-- Footer -->
    <!--<div class="footer_space"></div>-->
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Sidebar from "@/components/Sidebar.vue";
import Footer from "@/components/Footer.vue";

export default {
  data() {
    return {
      isSidebarShow: false,
    };
  },
  components: {
    Sidebar,
    Footer,
  },
  methods: {
    // Scroll
    scrollToTop() {
      window.scroll({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    },
    // Sidbar show or Hide
    toggleSidebarDisplay(){
      if(this.isSidebarShow){
        document.getElementById("navbar").classList.toggle("hide_sidebar");
        document.getElementById("body-pd").classList.toggle("fullwidth_app");
        this.isSidebarShow = false;
        //this.$refs.titleBpTopRight.innerHTML="Cacher Sidebar"
      } else {
        document.getElementById("navbar").classList.toggle("hide_sidebar");
        document.getElementById("body-pd").classList.toggle("fullwidth_app");
        this.isSidebarShow = true;
        //this.$refs.titleBpTopRight.innerHTML="Afficher Sidebar"
      }
    }
  },
  created() {
    // Set Prod or Dev Env
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1"){
      this.$store.commit('setCurrentProductionState', false)
    }
    else {
      this.$store.commit('setCurrentProductionState', true)
    }
  },
  mounted(){
    // add CDN WanaKana to VueJS (https://www.npmjs.com/package/wanakana)
    if (document.getElementById('WanaKana')) 
      return; // was already loaded
    var scriptTag = document.createElement("script");
    scriptTag.src = "https://unpkg.com/wanakana@4.0.2/umd/wanakana.min.js";
    scriptTag.id = "WanaKana";
    document.getElementsByTagName('head')[0].appendChild(scriptTag);

    // add CDN responsive voice to VueJS (https://responsivevoice.org)
    /*if (document.getElementById('ResponsiveVoice')) 
      return; // was already loaded
    var scriptTag2 = document.createElement("script");
    scriptTag2.src = "https://code.responsivevoice.org/responsivevoice.js?key=SHuzKBAu";
    scriptTag2.id = "ResponsiveVoice";
    document.getElementsByTagName('head')[0].appendChild(scriptTag2);*/

    // when the page is load (from another page)
    if(this.$route.params.id){
      if(document.getElementById(this.$route.params.id)){
        document.getElementById(this.$route.params.id).scrollIntoView({
          behavior: "smooth",
        });
      }
    }
  },
  watch: {
    // react to route changes... (same page we click on another submenu)
    $route(to, from) {
      if (to !== from) {
        if (this.$route.params.id) {
          if(document.getElementById(this.$route.params.id)){
            document.getElementById(this.$route.params.id).scrollIntoView({
              behavior: "smooth",
            });
          }
        }
      }
    },
  },
};
</script>

<style lang="scss">
/*===== Import =====*/
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/animate.css/4.1.1/animate.min.css");

/*===== VARIABLES CSS =====*/
:root {
  --nav-width: 92px;

  /*===== Colores =====*/
  --first-color: #1462ff;
  --bg-color: #12192c;
  --sub-color: #b6cefc;
  --white-color: #fff;

  /*===== Fuente y tipografia =====*/
  --body-font: "Poppins", sans-serif;
  --normal-font-size: 1rem;
  --small-font-size: 0.875rem;

  /*===== z index =====*/
  --z-fixed: 100;
}

/*===== BASE =====*/
*,
::before,
::after {
  box-sizing: border-box;
}
body {
  position: relative;
  margin: 0;
  padding: 2rem 15px 0 6.75rem;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  transition: 0.5s;
}
h1 {
  margin: 0;
}
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
a {
  text-decoration: none;
}
b{
  font-size: 1.1em;
}

/*===== Footer ====*/
.footer_space {
  height: 40px;
}

/*==== TEXT ====*/
.title_no_bottom {
  margin-bottom: 0;
}
.text_no_margin {
  margin: 0;
}
.box_example {
  border: solid 1px #ddd;
  padding: 5px;
  margin: 5px 10px 5px 0px;
  border-radius: 5px;
}
.text_li{
  margin-left: 1rem;
}
.text_lix2{
  margin-left: 2rem;
}

.red_text {
  color: red;
}
.blue_text {
  color: deepskyblue;
}
.green_text {
  color: green;
}
.orange_text{
  color: #FFA500;
}

.bold_text{
  font-weight: bold;
}

/*==== TABLE ====*/
.table_light_blue{
  background-color: rgb(0 136 255 / 25%);
}

/*==== BUTTON ==== */
.txt_bp_sidebar{
  position: fixed;
  right: 100px;
  top: 33px;
}
.button_fix_top {
  cursor: pointer;
  position: fixed;
  right: 25px;
  height: 40px;
  width: 40px;
  background: var(--first-color);
  border-radius: 5px;
  box-shadow: 0 6px 30px -10px #4a74c9;
  color: white;
  border: none;
  opacity: 0.7;
  .btn_scroll_icon {
    padding: 8px;
  }
  &:hover{
    opacity: 1;
  }
  &.bp_bottom{
    bottom: 50px;
  }
  &.bp_top{
    top: 25px;
  }
}

/*==== THEME ====*/
.darkmode{
  color: white;
  background-color: black;
  .table_vocabulaire .fas, .table_vocabulaire .far {
    color: white;
  }
  .home_card a{
    color: white;
  }
  .switch_dark--light_black--white{
    color: white;
  }
}
.lightmode{
  color: black;
  background-color: white;
  .table_vocabulaire .fas, .table_vocabulaire .far {
    color: black;
  }
  .home_card a{
    color: black;
  }
  .switch_dark--light_black--white{
    color: black;
  }
}

/* When sidebar is hide */
.fullwidth_app{
  padding: 2rem 15px 0 15px;
  transition: 0.5s;
}

/*==== SEPARATEUR ====*/
hr{
  border: 0;
  border-bottom: 2px dashed #eee;
  background: #999;
  margin: 20px 10px 20px 0px;
}

/*==== Content ====*/
.title_subcontent{
  margin-bottom: 0.5em;
  border-bottom: solid 2px var(--first-color);
}
.box_content{
  border: solid 1px var(--first-color);
  padding: 5px;
  margin: 5px 10px 5px 0px;
  border-radius: 5px;
}

/*=== Transition ===*/
// https://www.youtube.com/watch?v=L77Uq93XXzk (vue3)
// https://www.youtube.com/watch?v=0aG2hhvS1sM (vue2)
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-out;
}
.page_router{
  position: absolute;
  padding-bottom: 40px;
}
</style>
