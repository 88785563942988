<template>
  <div id="ka">
    <h3 class="title_subcontent">Particule : ka (か)</h3>
    <p class="text_no_margin">Cette particule s'utilise de différentes façons.</p>

    <h4 class="title_no_bottom">Une question</h4>
    <p class="text_no_margin">Cette particule permet d'indiquer une question.<br><span class="blue_text">phrase + ka</span></p>
    <!--<p class="box_example">
        furansugo hanasemasu <span class="red_text">ka</span> = Parlez-vous français <span class="red_text">?</span><br>
        genki desu <span class="red_text">ka</span> = ça va <span class="red_text">?</span><br>
    </p>-->
    <BoxExample 
      :kanji="'フランス語話せます<span class=\'red_text\'>か</span>。'"
      :kanjiraw="'フランス語話せますか。'"
      :kana="''" 
      :roomaji="'furansugo hanasemasu <span class=\'red_text\'>ka</span>.'"
      :trad="'Parlez-vous français <span class=\'red_text\'>?</span>'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'元気です<span class=\'red_text\'>か</span>。'"
      :kanjiraw="'元気ですか。'"
      :kana="''" 
      :roomaji="'genki desu <span class=\'red_text\'>ka</span>.'"
      :trad="'ça va <span class=\'red_text\'>?</span>'" 
      :explication="''"
    />
    <Button1 :text="'Voir en profondeur dans \'Autre particule\' section \'Particules de fin de phrase\' '" :link="'ParticuleAutre'"  :isInterneBp="true" :params="'ka'" />
    <Button1 :text="'Voir en profondeur dans \'Poser une question\' '" :link="'PoserQuestion'" :isInterneBp="true" />

    <h4 class="title_no_bottom">Un choix</h4>
    <p class="text_no_margin">De demander un choix à faire.<br><span class="blue_text">choix + ka + choix</span></p>
    <!--<p class="box_example">
        Getsuyoubi <span class="red_text">ka</span> kayoubi = Lundi <span class="red_text">ou</span> Mardi ?<br>
    </p>-->
    <BoxExample 
      :kanji="'月曜日<span class=\'red_text\'>か</span>火曜日。'"
      :kanjiraw="'月曜日か火曜日。'"
      :kana="''" 
      :roomaji="'Getsuyoubi <span class=\'red_text\'>ka</span> kayoubi.'"
      :trad="'Lundi <span class=\'red_text\'>ou</span> Mardi ?'" 
      :explication="''"
    />
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
      Button1,
      BoxExample
  },
}
</script>

<style>

</style>