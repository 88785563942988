<template>
  <div>
    <h1 style="margin-top: 10px" id="particuleadverbiale">Particules Adverbiales</h1>

    <h3 class="title_subcontent">A propos des particules adverbiales</h3>
    <p class="text_no_margin">
        Ce sont des particules qui nuance l'utilisation des noms auquel elle sont rattaché.<br>
        Ces particules se place après un nom et nuance l'utilisation de ce nom.<br>
        Ces particules ne remplace pas les particules habituellement présent は (wa), を (o), etc.
    </p>

    <h3 class="title_subcontent">Liste des particules adverbiales</h3>
    <h4 class="title_no_bottom">particule だけ (dake) et のみ (nomi) - caractère unique</h4>
    <p class="text_no_margin">
      Cette particule souligne le caractère unique du nom.<br>
      だけ (dake) et のみ (nomi) on exactement la même utilisation, sauf que のみ (nomi) sera plus poli, plus formel, il sera plus utilisé pour s'adresser à un public, dans la vie courante il n'est pas très utilisé.<br>
    </p>
    <!--<p class="box_example">
      パンダは竹<span class="red_text">だけ</span>を食べる。= panda wa take <span class="red_text">dake</span> o taberu = les pandas mangent <span class="red_text">uniquement</span> du bambou (on se concentre sur le bambou uniquement)<br>
    </p>-->
    <BoxExample 
      :kanji="'パンダは竹<span class=\'red_text\'>だけ</span>を食べる。'"
      :kanjiraw="'パンダは竹だけを食べる。'"
      :kana="''" 
      :roomaji="'panda wa take <span class=\'red_text\'>dake</span> o taberu.'"
      :trad="'les pandas mangent <span class=\'red_text\'>uniquement</span> du bambou.'" 
      :explication="'On se concentre sur le bambou uniquement.'"
    />

    <h4 class="title_no_bottom">particule しか (shika) - tournure négative</h4>
    <p class="text_no_margin">
      Cette particule à le même sens que だけ (dake) mais elle s'utilise seulement avec des tournures négatives.<br>
      Elle correspond en français à "ne", "que".<br>
      Après しか (shika) on n'utilise pas de particule, car しか (shika) est aussi une particule emphase (voir plus bas). Elle remplace le COD.<br>
    </p>
    <!--<p class="box_example">
      パンダは竹<span class="red_text">しか</span>食べない。= panda wa take <span class="red_text">shika</span> tabenai = les pandas <span class="red_text">ne</span> mangent <span class="red_text">que</span> du bambou.<br>
      la phrase veut dire la même chose que dans exemple au-dessus mais avec une tournure négative. Ici on n'exclut tout, sauf le bambou<br>
      la particule à remplacé le を (o) du cod, donc on ne dira pas "shika o tabenai"<br>
    </p>-->
    <BoxExample 
      :kanji="'パンダは竹<span class=\'red_text\'>しか</span>食べない。'"
      :kanjiraw="'パンダは竹しか食べない。'"
      :kana="''" 
      :roomaji="'panda wa take <span class=\'red_text\'>shika</span> tabenai.'"
      :trad="'les pandas <span class=\'red_text\'>ne</span> mangent <span class=\'red_text\'>que</span> du bambou.'" 
      :explication="'La phrase veut dire la même chose que dans exemple au-dessus mais avec une tournure négative. Ici on n\'exclut tout, sauf le bambou<br>La particule à remplacé le を (o) du cod, donc on ne dira pas “shika o tabenai”.'"
    />

    <h4 class="title_no_bottom">particule ばかり (bakari) - caractère unique péjorative</h4>
    <p class="text_no_margin">
      Cette particule souligne le caractère unique du nom mais avec une connotation péjorative.<br>
    </p>
    <!--<p class="box_example">
      ポールは漫画<span class="red_text">ばかり</span>を読む = pooru wa manga <span class="red_text">bakari</span> o nomu = paul <span class="red_text">ne</span> regarde <span class="red_text">que</span> des mangas (on se concentre sur les mangas de manière péjorative)
    </p>-->
    <BoxExample 
      :kanji="'ポールは漫画<span class=\'red_text\'>ばかり</span>を読む。'"
      :kanjiraw="'ポールは漫画ばかりを読む。'"
      :kana="''" 
      :roomaji="'pooru wa manga <span class=\'red_text\'>bakari</span> o nomu.'"
      :trad="'paul <span class=\'red_text\'>ne</span> regarde <span class=\'red_text\'>que</span> des mangas.'" 
      :explication="'On se concentre sur les mangas de manière péjorative.'"
    />

    <h4 class="title_no_bottom">particule くらい (kurai) et ころ (koro) - Approximation</h4>
    <p class="text_no_margin">
      La particule de くらい (kurai) permet de faire tout sorte d'approximation, dont sur la durée.<br>
      La particule de ころ (koro) permet de faire une approximation sur un point fixe dans le temps.<br>
    </p>
    <!--<p class="box_example">
      ポールは毎日6時間<span class="red_text">くらい</span>漫画を読む。= pooru wa mainichi 6ji kan <span class="red_text">kurai</span> manga o yomu = paul lit des mangas <span class="red_text">environ</span> six heures tous les jours ("kan" veut dire la durée, sur 6h. on peut donc utiliser kurai "environ durée")<br>
      授業は8じ<span class="red_text">ころ</span>に始まる = jugyoo wa 8ji <span class="red_text">koro</span> ni hajimaru = le cours commence <span class="red_text">environ</span> à 8h (le cours commence à peu près à 8h, c'est un point dans le temps, pas une durée)<br>
    </p>-->
    <BoxExample 
      :kanji="'ポールは毎日6時間<span class=\'red_text\'>くらい</span>漫画を読む。'"
      :kanjiraw="'ポールは毎日6時間くらい漫画を読む。'"
      :kana="''" 
      :roomaji="'pooru wa mainichi 6ji kan <span class=\'red_text\'>kurai</span> manga o yomu.'"
      :trad="'paul lit des mangas <span class=\'red_text\'>environ</span> six heures tous les jours.'" 
      :explication="'“kan” veut dire la durée, sur 6h. on peut donc utiliser kurai “environ durée”.'"
    />
    <BoxExample 
      :kanji="'授業は8じ<span class=\'red_text\'>ころ</span>に始まる。'"
      :kanjiraw="'授業は8じころに始まる。'"
      :kana="''" 
      :roomaji="'jugyoo wa 8ji <span class=\'red_text\'>koro</span> ni hajimaru.'"
      :trad="'Le cours commence <span class=\'red_text\'>environ</span> à 8h.'" 
      :explication="'Le cours commence à peu près à 8h, c\'est un point dans le temps, pas une durée.'"
    />
    <p class="text_no_margin">
      Pour que la prononciation soit plus facile, les japonais diront plus souvent ぐらい (gurai) et  ごろ (goro). Leur signification reste la même.
    </p>
    <!--<p class="box_example">
      ポールは毎日6時間<span class="red_text">ぐらい</span>漫画を読む。= pooru wa mainichi 6ji kan <span class="red_text">gurai</span> manga o yomu = paul lit des mangas <span class="red_text">environ</span> six heures tous les jours ("kan" veut dire la durée, sur 6h. on peut donc utiliser kurai "environ durée")<br>
      授業は8じ<span class="red_text">ごろ</span>に始まる = jugyoo wa 8ji <span class="red_text">goro</span> ni hajimaru = le cours commence <span class="red_text">environ</span> à 8h (le cours commence à peu près à 8h, c'est un point dans le temps, pas une durée)<br>
    </p>-->
    <BoxExample 
      :kanji="'ポールは毎日6時間<span class=\'red_text\'>ぐらい</span>漫画を読む。'"
      :kanjiraw="'ポールは毎日6時間ぐらい漫画を読む。'"
      :kana="''" 
      :roomaji="'pooru wa mainichi 6ji kan <span class=\'red_text\'>gurai</span> manga o yomu.'"
      :trad="'paul lit des mangas <span class=\'red_text\'>environ</span> six heures tous les jours.'" 
      :explication="'“kan” veut dire la durée, sur 6h. on peut donc utiliser kurai “environ durée”.'"
    />
    <BoxExample 
      :kanji="'授業は8じ<span class=\'red_text\'>ごろ</span>に始まる。'"
      :kanjiraw="'授業は8じごろに始まる。'"
      :kana="''" 
      :roomaji="'jugyoo wa 8ji <span class=\'red_text\'>goro</span> ni hajimaru.'"
      :trad="'Le cours commence <span class=\'red_text\'>environ</span> à 8h.'" 
      :explication="'Le cours commence à peu près à 8h, c\'est un point dans le temps, pas une durée.'"
    />
  
    <h4 class="title_no_bottom">particule ほど (hodo) - Comparaison</h4>
    <p class="text_no_margin">
      La particule ほど (hodo) va servir de comparaison.<br>
      En français équivalent, ça serait "autant que".<br>
    </p>
    <!--<p class="box_example">
      ヤシ<span class="red_text">ほど</span>の大きさだ = yashi <span class="red_text">hodo</span> no okisa da = c'est grand <span class="red_text">comme</span> un palmier (autant qu'un palmier)
    </p>-->
    <BoxExample 
      :kanji="'ヤシ<span class=\'red_text\'>ほど</span>の大きさだ。'"
      :kanjiraw="'ヤシほどの大きさだ。'"
      :kana="''" 
      :roomaji="'yashi <span class=\'red_text\'>hodo</span> no okisa da.'"
      :trad="'c\'est grand <span class=\'red_text\'>comme</span> un palmier.'" 
      :explication="'autant qu\'un palmier.'"
    />

    <h4 class="title_no_bottom">particule など (nado) et なんか (nanka) - non-exhaustivité</h4>
    <p class="text_no_margin">
      La particule など (nado) va être utilisé pour appuyer la particule や (ya).<br>
      La particule peut aussi être utilisé avec un seul terme. on peut citer un seul objet et pas obligatoirement deux au minimum dans une phrase.<br>
      La paraticule なんか (nanka) s'utilise exactement de la même façon que など (nado), mais elle est plus familier, moins poli.
    </p>
    <!--<p class="box_example">
      ポールはミルクやコーヒー<span class="red_text">など</span>おのむ = pooru wa miruku ya koohii <span class="red_text">nado</span> o nomu = Paul boit du lait, du café, <span class="red_text">etc</span> (avec など (nado) on vient appuyé encore plus sur le "etc")
    </p>-->
    <BoxExample 
      :kanji="'ポールはミルクやコーヒー<span class=\'red_text\'>など</span>おのむ。'"
      :kanjiraw="'ポールはミルクやコーヒーなどおのむ。'"
      :kana="''" 
      :roomaji="'pooru wa miruku ya koohii <span class=\'red_text\'>nado</span> o nomu.'"
      :trad="'Paul boit du lait, du café, <span class=\'red_text\'>etc</span>'" 
      :explication="'Avec など (nado) on vient appuyé encore plus sur le “etc”.'"
    />
  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>