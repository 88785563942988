<template>
  <div>
      <h1>Écrire avec le clavier japonais sur ordinateur</h1>

      <h3 class="title_subcontent">Accès rapide</h3>
      <Button1 :text="'Installer le clavier japonais sur Windows 10'" :link="'#installer'" />
      <Button1 :text="'Les différents types de clavier'" :link="'#type_clavier'" />
      <Button1 :text="'Les raccourcis d\'utilisation'" :link="'#raccourcis'" />

      <h3 class="title_subcontent" id="installer">Installer le clavier japonais sur Windows 10</h3>
      <p class="text_no_margin">
        - Ouvrir le "menu" principal de windows. <br>
        - Aller dans "Langue".<br>
        <img src="../assets/langue_menu_window.png" alt="langue_menu_clavier" class="preview_img">
        - Cliquer sur "Ajouter une langue"<br>
        <img src="../assets/langue_menu_window2.png" alt="langue_menu_clavier_2" class="preview_img">
        - Choisir "Japonais"<br>
        <img src="../assets/langue_menu_window3.png" alt="langue_menu_clavier_3" class="preview_img">
      </p>

      <h3 class="title_subcontent" id="type_clavier">Les différents types de clavier</h3>
      <p class="text_no_margin">
        Le clavier détecte et marche avec les deux systèmes de Rōmaji, "Hepburn" et "Kunrei".<br>
        Information: Chaque Kanji, kana prend la taille d' une case et chaque case possède la même dimension.<br>
        - Hiragana: Utilise le système Rōmaji Hiragana.<br>
        - Full-width Katakana: Utilise le système Rōmaji Katakana et chaque caractère prendra la taille d'une case.<br>
        - Full-width Alphanumeric: Utilise le système Romain, notre alphabet et chaque caractère prendra la taille d'une case.<br>
        - Half-width Katakana: Utilise le système Rōmaji Katakana et chaque caractère sera collés, ils ne rentreront pas dans une case.<br>
        - Half-width Alphanumeric: Utilise le système Romain, notre alphabet et chaque caractère seront collé, ils ne rentrerons pas dans une case.<br>
        - IME Pad: Permet de tracer des traits dans un carré de dessin pour avoir une liste de propositions de kanji associé à votre dessin. Utile pour retrouver un kanji.<br>
      </p>

      <h3 class="title_subcontent" id="raccourcis">Les raccourcis d'utilisation</h3>
      <p class="text_no_margin">
        - Pour switcher la langue du clavier en FR, EN ou JP suivant la liste de langue ajouter sur votre windows, taper "maj + alt"<br>
      </p>
      <p class="text_no_margin">
        - Pour switcher un mot en hiragana en katakana, taper "f7" lorsque le mot est encore en cours de modification<br>
        - Pour switcher un mot en katakana en hiragana, taper "f6" lorsque le mot est encore en cours de modification<br>
        - Pour switcher le clavier d'une écriture de type hiragana à katakana ou inversement, taper "alt + ²"
      </p>
      <p class="text_no_margin">
        - Pour avoir les Kanji, il faut écrire le mot en Rōmaji <br>
        <span class="text_li">- puis appuyer 1 fois sur la barre d'espace pour convertir le kana en kanji</span><br>
        <span class="text_li">- puis appuyer 2 fois sur la barre d'espace pour avoir une liste de proposition de kanji</span>
      </p>
      <p class="text_no_margin">
        - faire le petit tsu (っ), petit ya (ゃ), petit etc: il faut préfixer par "l" suivie du kana.<br>
      </p>
      <p class="box_example">
        lya = ゃ<br>
        ltsu = っ<br>
      </p>
      <p class="text_no_margin">
        - pour faire la ponctuation (comparaison avec notre clavier AZERTY): <br>
        <span class="text_li">- maru (。) = touche azerty ":"</span><br>
        <span class="text_li">- ten (、) = touche azerty ";"</span><br>
        <span class="text_li">- kantanfu (!) = touche azerty "maj + &"</span><br>
        <span class="text_li">- gimonfu (?) = touche azerty "maj + !"</span><br>
        <span class="text_li">- nakasen (ー) = touche azerty ")"</span><br>
        <span class="text_li">- nakaten (・) = touche azerty "!"</span><br>
        <span class="text_li">- daburu haifun (=) = touche azerty "="</span><br>
        <span class="text_li">- kakko (「) = touche azerty "^"</span><br>
        <span class="text_li">- kakko (」) = touche azerty "$"</span><br>
        <span class="text_li">- kakko (『) = touche azerty "^" puis double espace pour avoir une liste de proposition</span><br>
        <span class="text_li">- kakko (』) = touche azerty "$" puis double espace pour avoir une liste de proposition</span><br>
      </p>
      <p class="text_no_margin">
        - pour avoir une liste de tous les symboles ASCII existant, taper "kigou" puis double espace<br>
      </p>
      <p class="text_no_margin">
        - pour avoir une liste de tous les caractères de répétition comme le kurikaeshi (々), taper "onaji" puis double espace<br>
      </p>
      <p class="text_no_margin">
        - pour avoir une liste de tous les emojis ASCII (kaomoji) de visage comme ça (*'▽'), taper "kao" puis double espace<br>
      </p>
      <p class="text_no_margin">
        - pour avoir un nombre arabe convertis en japonais, taper par exemple un nombre "15483" puis double espace pour avoir une liste de propositions dont la convertion "一万五千四百八十三"<br>
      </p>
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
export default {
  name: 'Clavier',
  components: {
    Button1
  },
}
</script>

<style lang="scss" scoped>
.preview_img{
  max-height: 400px;
  display: block;
  max-width: 100%;
}
</style>