<template>
  <div>
    <h1>Convertir Rōmaji & Kana</h1>

    <h3 class="title_subcontent">Convertisseur</h3> 

    <p>Changer la taille du texte dans le textarea
      <select class="select" v-model="size" @change="onChange">
        <option value="0.625rem">0.625rem</option>
        <option value="0.75rem">0.75rem</option>
        <option value="0.875rem">0.875rem</option>
        <option selected="selected" value="1rem">1rem</option>
        <option value="1.125rem">1.125rem</option>
        <option value="1.25rem">1.25rem</option>
        <option value="1.5rem">1.5rem</option>
        <option value="1.875rem">1.875rem</option>
        <option value="2rem">2rem</option>
        <option value="2.25rem">2.25rem</option>
        <option value="2.5rem">2.5rem</option>
        <option value="2.75rem">2.75rem</option>
      </select>
    </p>

    <div class="form">
      <div class="col1">
        <h4 class="text_no_margin">Rōmaji</h4>
        <textarea name="" id="" cols="30" rows="10" v-model="entryRoomaji" v-on:keyup="entryRoomajiChange"></textarea>
      </div>
      <div class="col2">
        <h4 class="text_no_margin">Kana</h4>
        <textarea name="" id="" cols="30" rows="10" v-model="entryKana" v-on:keyup="entryKanaChange"></textarea>
      </div>
    </div>

    <p class="text_no_margin">
      <i class="far fa-question-circle"></i> Information d'utilisation<br>
      <span class="text_li">- Écrire en Katakana = Écrire en Majuscule (Rōmaji)</span><br>
      <span class="text_li">- Écrire en Hiragana = Écrire en Minuscule (Rōmaji)</span><br>
    </p>
    <p>
       <i class="far fa-question-circle"></i> Ponctuations<br>
       <span class="text_li">- ten (、) (la virgule) = touche "," (virgule)</span><br>
      <span class="text_li">- nakasen (ー) (trait d'allongement) = touche "-" (tiret du 6)</span><br>
    </p>
    <p>
      Conversion par <Button1 :text="'WanaKana.js'" :target="'_blank'" :link="'https://wanakana.com'" />
    </p>
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";

export default {
  name: 'Converter',
  components: {
      Button1,
  },
  data() {
    return{
      size: "1rem",
      entryRoomaji: '',
      entryKana: ''
    }
  },
  methods:{
    entryRoomajiChange(){
      this.entryKana = wanakana.toKana(this.entryRoomaji);
    },
    entryKanaChange(){
      this.entryRoomaji = wanakana.toRomaji(this.entryKana);
    },
    onChange() {
      document.querySelectorAll("textarea").forEach(el => {
          el.style.fontSize = this.size;
      });
    },
  }
}
</script>

<style scoped lang="scss">
.form{
  display: inline-block;
  width: 100%;
  textarea{
    width: 100%;
  }
  .col1{
    width: 50%;
    float: left;
  }
  .col2{
    width: 50%;
    float: right;
  }
}
</style>