<template>
  <div>
    <h1>Grammaire</h1>

    <h3 class="title_subcontent" id="semaine">La formation d'une phrase japonaise</h3>
    <h4 class="title_no_bottom">Différence entre Français et Japonais</h4>
    <p class="text_no_margin">
      - les principales règles dans la formation d'une phrase (énormément de chose sont déterminé par le contexte de conversation) :<br>
      <span class="text_li">- Pas de genre (masculin, féminin)</span><br>
      <span class="text_li">- Pas de nombre (singulier, pluriel)</span><br>
    </p>
    <!--<p class="box_example">
      犬 (inu (chien)) = le chien, la chienne, les chiens, les chiennes (le simple kanji peut dire les quatres)<br>
    </p>-->
    <BoxExample 
      :kanji="''"
      :kanjiraw="'犬'"
      :kana="''" 
      :roomaji="'inu'"
      :trad="'chien'" 
      :explication="'le chien, la chienne, les chiens, les chiennes (le simple kanji peut dire les quatres)'"
    />
    <p class="text_no_margin">
        <span class="text_li">- pas d'articles définis et indéfinis (le, un, la, une, les, des)</span><br>
    </p>
    <!--<p class="box_example">
      犬 (inu) = le chien, un chien, la chienne, une chienne...
    </p>-->
    <BoxExample 
      :kanji="''"
      :kanjiraw="'犬'"
      :kana="''" 
      :roomaji="'inu'"
      :trad="'chien'" 
      :explication="'le chien, un chien, la chienne, une chienne...'"
    />
    <p class="text_no_margin">
        <span class="text_li">- pas d'adjectifs possessifs (mon,ton,son, ma,ta,sa, mes,tes,ses, notre,votre,leur, nos,vos,leurs)</span><br>
        <span class="text_li">- pas de conjugaison</span><br>
        <span class="text_li">- pas de temps</span><br>
        <span class="text_li">- pas de modes (indicatif, conditionnel, subjonctif, ...)</span><br>
    </p>

    <h4 class="title_no_bottom">la traduction entre français et japonais</h4>
    <p class="text_no_margin">
        - Pour traduire du français au japonais ou inversement, il faut traduire l'idée global, le sens de la phrase et non traduire mot à mot les mots dans la phrase.<br>
    </p>
    <p class="box_example">
        "je prend une petite douche en deux-deux!" => il ne faut pas traduire ici le verbe "prendre" au sens litéral de prendre, car on ne prend pas la douche en elle même, pareil pour "petite" et "deux-deux" qui détermine plus la durée de la douche.<br>
    </p>
    <p class="text_no_margin">
        - Ils existent deux notions à savoir en traduction<br>
        <span class="text_li">- le thème: consiste à traduire une phrase de sa propre langue à une langue étrangère (français vers japonais)</span><br>
        <span class="text_li">- la version: consiste à traduire une phrase de la langue étrangère à sa propre langue (japonais vers français)</span><br>
    </p>

    <h4 class="title_no_bottom">Verbe</h4>
    <p class="text_no_margin">
        - Un verbe n'a pas forcément besoin de sujet, ce qui rend une phrase neutre.<br>
        - En japonais ils existent différents verbes êtres :<br>
        <span class="text_li">- être de description (pour décrire une personne ou chose): "だ" (da (forme neutre)).</span><br>
        <span class="text_li">- être de présence (pour signifier la présence de quelqu'un ou quelque chose): いる (iru) et ある (aru).</span><br>
        <span class="text_lix2">- いる (iru) à utiliser pour tous les êtres vivants (animaux et humains).</span><br>
        <span class="text_lix2">- ある (aru) à utiliser pour toutes les choses non vivants (objets) ou inanimés.</span><br>
        <span class="text_li">- être de présence plus spécifique à une situation.</span><br>
        <span class="text_lix2">- 残る (nokoru) qui veut dire "rester", lorsqu'on est quelque part et qu'on y est encore.</span><br>
        <span class="text_lix2">- 住む (sumu) qui veut dire "habiter", lorsqu'on habite quelque part et qu'on y reste de manière sédentaire.</span><br>
        <span class="text_lix2">- 泊まる (tomaru) qui veut dire "passer une nuit", lorsqu'on est quelque part juste pour une nuit.</span><br>
    </p>
    <p class="box_example">
      - un homme: iru (vivant humain)<br>
      - un chien: iru (vivant animal)<br>
      - un arbre: aru (c'est inanimé)<br>
      - un stylo: aru (c'est un objet)<br>
      - un robot: aru (c'est un objet)<br>
      - une cellule biologique: aru (car c'est trop petit)<br>
      - un taxi: iru (car on parle du chauffeur et pas de la voiture)<br>
      - un poisson: iru (si il est vivant), aru (si il est mort et cuisiné)<br>
    </p>

    <h4 class="title_no_bottom">Formation d'une phrase simple</h4>
    <p class="text_no_margin">
        - En japonais, le verbe se met toujours à la fin de la phrase.<br>
        - La construction d'une phrase est donc très souvent inversé par rapport au français.<br>
        <span class="text_li">- En français on commence par le verbe puis finis par le thème.</span><br>
        <span class="text_li">- En japonais on commence par le thème puis finis par le verbe.</span><br>
    </p>
    <!--<p class="box_example">
      <span class="red_text">犬</span><span class="blue_text">だ</span> = <span class="blue_text">c'est un</span> <span class="red_text">chien</span><br>
      <span class="red_text">猫</span><span class="blue_text">だ</span> = <span class="blue_text">c'est un</span> <span class="red_text">chat</span><br>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>犬</span><span class=\'blue_text\'>だ</span>。'"
      :kanjiraw="'犬だ。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>inu</span> <span class=\'blue_text\'>da</span>.'"
      :trad="'<span class=\'blue_text\'>c\'est un</span> <span class=\'red_text\'>chien</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'red_text\'>猫</span><span class=\'blue_text\'>だ</span>。'"
      :kanjiraw="'猫だ。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>neko</span> <span class=\'blue_text\'>da</span>.'"
      :trad="'<span class=\'blue_text\'>c\'est un</span> <span class=\'red_text\'>chat</span>.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Les Particules Grammaticales</h4>
    <p class="text_no_margin">
        - Les particules sont des hiragana qui vont permettre d'agencer la phrase, de lier les mots, phrases, etc entre elles, elle correspondent à des fonctions grammaticales.<br>
        - Voici une liste des cours sur toutes les particules grammaticales existantes :<br>
      <Button1 :text="'Particule No (の)'" :link="'Particule'" :params="'no'" :isInterneBp="true" />
      <Button1 :text="'Particule To (と)'" :link="'Particule'" :params="'to'" :isInterneBp="true" />
      <Button1 :text="'Particule Ya (や)'" :link="'Particule'" :params="'ya'" :isInterneBp="true" />
      <Button1 :text="'Particule Wa (は)'" :link="'Particule'" :params="'wa'" :isInterneBp="true" />
      <Button1 :text="'Particule Ni (に)'" :link="'Particule'" :params="'ni'" :isInterneBp="true" />
      <Button1 :text="'Particule De (で)'" :link="'Particule'" :params="'de'" :isInterneBp="true" />
      <Button1 :text="'Particule E (へ)'" :link="'Particule'" :params="'e'" :isInterneBp="true" />
      <Button1 :text="'Particule O (を)'" :link="'Particule'" :params="'wo'" :isInterneBp="true" />
      <Button1 :text="'Particule Kara et Made (から et　まで)'" :link="'Particule'" :params="'kara_made'" :isInterneBp="true" />
      <Button1 :text="'Particule Ga (が)'" :link="'Particule'" :params="'ga'" :isInterneBp="true" />
      <Button1 :text="'Particule Mo (も)'" :link="'Particule'" :params="'mo'" :isInterneBp="true" />
      <Button1 :text="'Particule Yori (より)'" :link="'Particule'" :params="'yori'" :isInterneBp="true" />
      <Button1 :text="'Particule Ka (か)'" :link="'Particule'" :params="'ka'" :isInterneBp="true" />
    </p>

    <h4 class="title_no_bottom">Formation d'une phrase de façon simplifié</h4>
    <p class="text_no_margin">
        - La construction d'une phrase est souvent agencé ainsi à la forme déclarative : <br>
        <span class="red_text">thème de la conversation</span> + <span class="green_text">particule wa (は)</span> + informations complémentaire sur le thème (cod, coi, ...) + <span class="blue_text">verbe</span>.<br>
        - Le thème reste toujours en premier et la phrase s'organise autour du verbe qui reste toujours à la fin.<br>
        - Plus les différentes informations complémentaire sont liée aux verbes, plus elle seront proche du verbe.<br>
        - On remarquera donc que après le thème, très souvent la phrase se construit à l'envers du français.<br>
    </p>
    <!--<p class="box_example">
        <span class="red_text">私わたし</span>  <span class="green_text">は</span>  日本人 <span class="blue_text">です</span>。 = <span class="red_text">Watashi</span> <span class="green_text">wa</span> nihonjin <span class="blue_text">desu</span>. = <span class="red_text">je</span> <span class="blue_text">suis</span> japonais<br>
        <span class="green_text">サスキとナルト<b>は</b></span> <span class="green_text"></span>　キバの犬<b>と</b>　<span class="red_text">木ノ葉の病院<b>に</b></span>　<span class="blue_text">いる</span>。= <span class="green_text">sasuke to naruto <b>wa</b></span> kiba no inu <b>to</b> <span class="red_text">konoha no byouin <b>ni</b></span> <span class="blue_text">iru</span> = <span class="green_text">sasuke et naruto</span> <span class="blue_text">sont</span> <span class="red_text">à l'hôpital de konoha</span> avec le chien de kiba.
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>私わたし</span>  <span class=\'green_text\'>は</span>  日本人 <span class=\'blue_text\'>です</span>。'"
      :kanjiraw="'私わたし は 日本人 です。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>Watashi</span> <span class=\'green_text\'>wa</span> nihonjin <span class=\'blue_text\'>desu</span>.'"
      :trad="'<span class=\'red_text\'>Je</span> <span class=\'blue_text\'>suis</span> japonais.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'green_text\'>サスキとナルト<b>は</b></span> <span class=\'green_text\'></span>キバの犬<b>と</b><span class=\'red_text\'>木ノ葉の病院<b>に</b></span><span class=\'blue_text\'>いる</span>。'"
      :kanjiraw="'サスキとナルトは キバの犬と木ノ葉の病院にいる。'"
      :kana="''" 
      :roomaji="'<span class=\'green_text\'>sasuke to naruto <b>wa</b></span> kiba no inu <b>to</b> <span class=\'red_text\'>konoha no byouin <b>ni</b></span> <span class=\'blue_text\'>iru</span>.'"
      :trad="'<span class=\'green_text\'>sasuke et naruto</span> <span class=\'blue_text\'>sont</span> <span class=\'red_text\'>à l\'hôpital de konoha</span> avec le chien de kiba.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Prononciation d'une phrase en entier</h4>
    <p class="text_no_margin">
      Entre chaque particule important (en gras) qui vient marquer un changement, on vient marquer une petite pause dans la prononciation de la phrase.<br>
    </p>
    <!--<p class="box_example">
      <span class="green_text">私<b>は</b> </span>サクラ<b>と</b><span class="red_text">映画館<b>に</b> </span><span class="blue_text">いる</span> = <span class="green_text">watashi <b>wa</b></span> sakura <b>to</b> <span class="red_text">eigakan <b>ni</b></span> <span class="blue_text">iru</span> = <span class="green_text">je</span> <span class="blue_text">suis</span> <span class="red_text">au cinéma</span> avec sakura<br>
      <span class="green_text">サスキとナルト<b>は</b></span> <span class="green_text"></span>　キバの犬<b>と</b>　<span class="red_text">木ノ葉の病院<b>に</b></span>　<span class="blue_text">いる</span>。= <span class="green_text">sasuke to naruto <b>wa</b></span> kiba no inu <b>to</b> <span class="red_text">konoha no byouin <b>ni</b></span> <span class="blue_text">iru</span> = <span class="green_text">sasuke et naruto</span> <span class="blue_text">sont</span> <span class="red_text">à l'hôpital de konoha</span> avec le chien de kiba.
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'green_text\'>私<b>は</b> </span>サクラ<b>と</b><span class=\'red_text\'>映画館<b>に</b> </span><span class=\'blue_text\'>いる</span>。'"
      :kanjiraw="'私は サクラと映画館に いる。'"
      :kana="''" 
      :roomaji="'<span class=\'green_text\'>watashi <b>wa</b></span> sakura <b>to</b> <span class=\'red_text\'>eigakan <b>ni</b></span> <span class=\'blue_text\'>iru</span>.'"
      :trad="'<span class=\'green_text\'>je</span> <span class=\'blue_text\'>suis</span> <span class=\'red_text\'>au cinéma</span> avec sakura.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'green_text\'>サスキとナルト<b>は</b></span> <span class=\'green_text\'></span>キバの犬<b>と</b><span class=\'red_text\'>木ノ葉の病院<b>に</b></span><span class=\'blue_text\'>いる</span>。'"
      :kanjiraw="'サスキとナルトは キバの犬と木ノ葉の病院にいる。'"
      :kana="''" 
      :roomaji="'<span class=\'green_text\'>sasuke to naruto <b>wa</b></span> kiba no inu <b>to</b> <span class=\'red_text\'>konoha no byouin <b>ni</b></span> <span class=\'blue_text\'>iru</span>.'"
      :trad="'<span class=\'green_text\'>sasuke et naruto</span> <span class=\'blue_text\'>sont</span> <span class=\'red_text\'>à l\'hôpital de konoha</span> avec le chien de kiba.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Conclusion à la fin de l'apprentisage de la grammaire</h4>
    <p class="text_no_margin">
      On verra à la toute fin de apprentisage que les phrases peuvent être ainsi complexe.<br>
      <span class="red_text">thème de la conversation</span> + <span class="green_text">particule wa (は) ou ga (が) si accentuation ou particule emphase ou mo (も) si cest "aussi"</span> + Point dans le temps (avec Ni (に) si c'est précis)  + <span class="red_text">COI</span> + COD + <span class="blue_text">verbe</span> + Particule de fin phrase.<br>
      <span class="text_li">- Avec les adverbe se mettant n'importe où devant le mot dont elle apporte une précision.</span><br>
      <span class="text_li">- Avec les particules adverbiales se mettant après un nom pour nuancer l'utilisation de ce nom.</span><br>
      Le japonais est une langue qui se base énormément sur le contexte, qui aime aller à l'essentiel. On aime dire les choses le plus simple possible, même mettre le moins de mot possible dans notre phrase (exemple avec la poésie japonaise, les haiku).<br>
      Ce qui fait qu'il est possible omettre des particules et des compléments lorsque le contexte et la prononciation (en faissant des pauses aux endroits où il y a des particule grammatices), le permet.<br>
    </p>

  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
    name: 'Grammaire',
    components: {
        Button1,
        BoxExample
    },
}
</script>

<style>

</style>