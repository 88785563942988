<template>
  <div>
    <!-- Autres Particules -->
    <!--<h1 style="margin-top: 10px" id="particulefinphrase">Les autres Particules</h1>-->
    <h3 class="title_subcontent" id="noda">La Particule のだ (noda) - la tournure explicative</h3>
    <p class="text_no_margin">
      Cette tournure explicative permet de demander une explication ou de donner une explication.<br>
      Pour mettre une phrase à la tournure explicative il faut mettre のだ (noda) à la fin de la phrase, après le verbe.<br>
      La particule s'utilise dans un contexte demandant une explication.<br>
      En français on peut la comparer à "C'est que", "parce que".<br>
      Extension d'utilisation de のだ (noda) :<br>
      <span class="text_li">- のだ (noda) permet également de souligner un propos ou question, de l'accentuer, de rendre le propos plus important qu'une phrase normal.</span><br>
      <span class="text_li">- Une phrase à la forme interrogative か (ka), si on baisse l'intonation, elle permet d'exprimer la découverte, la compréhension d'un nouveau fait.</span><br>
      <span class="text_li">- À l'oral il est possible d'abréger le の (no) en ん (n).</span><br>
      <span class="text_li">- Les femmes enlève parfois le だ (da) en fin de phrase, ça fait mignon, un peu enfantin.</span><br>
    </p>
    <!--<p class="box_example">
      ティボは庭にいる。= tibo wa niwa ni iru。= Tibo est dans le jardin. (phrase informative, qui donne juste une information)<br>
      ティボは庭にいる<span class="red_text">のだ</span>。= tibo wa niwa ni iru <span class="red_text">noda</span>。= <span class="red_text">c'est que</span>, Tibo est dans le jardin. (phrase à la tournure explicative, si on nous demande une explication)<br>
      Exemple, si on nous demande "pourquoi le chien aboie ?", on répondra par une phrase à la tournure explicative. "C'est parce qu'il y a tibo dans le jardin"<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボは庭にいる。'"
      :kanjiraw="'ティボは庭にいる。'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru。'"
      :trad="'Tibo est dans le jardin.'" 
      :explication="'Phrase informative, qui donne juste une information.'"
    />
    <BoxExample 
      :kanji="'ティボは庭にいる<span class=\'red_text\'>のだ</span>。'"
      :kanjiraw="'ティボは庭にいるのだ。'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru <span class=\'red_text\'>noda</span>。'"
      :trad="'<span class=\'red_text\'>c\'est que</span>, Tibo est dans le jardin.'" 
      :explication="'Phrase à la tournure explicative, si on nous demande une explication.'"
    />
    <BoxExample 
      :explication="'Exemple, si on nous demande “pourquoi le chien aboie ?”, on répondra par une phrase à la tournure explicative. “C\'est parce qu\'il y a tibo dans le jardin”'"
    />
    <p class="text_no_margin">
      À chaque fois qu'une phrase se termine par だ (da) et que la particule suivante commence par の (no), on va remplacer だ (da) par な (na)<br>
    </p>
    <!--<p class="box_example">
      ポールは馬鹿だ。= pooru wa baka da。= Paul est un idiot. (phrase informative) <br>
      ポールは馬鹿<span class="red_text">なのだ</span>。= pooru wa baka <span class="red_text">na noda</span>。= Paul est un idiot. (phrase explicative)<br>
    </p>-->
    <BoxExample 
      :kanji="'ポールは馬鹿だ。'"
      :kanjiraw="'ポールは馬鹿だ。'"
      :kana="''" 
      :roomaji="'pooru wa baka da。'"
      :trad="'Paul est un idiot.'" 
      :explication="'Phrase informative.'"
    />
    <BoxExample 
      :kanji="'ポールは馬鹿<span class=\'red_text\'>なのだ</span>。'"
      :kanjiraw="'ポールは馬鹿なのだ。'"
      :kana="''" 
      :roomaji="'pooru wa baka <span class=\'red_text\'>na noda</span>。'"
      :trad="'Paul est un idiot.'" 
      :explication="'Phrase explicative.'"
    />
    <p class="text_no_margin">
      On ne peut pas mettre de particule de fin de phrase entre le verbe et のだ (noda).<br>
      Pour résumer, on peut mettre des particules de fin de phrase, mais seulement après la particule のだ (noda).<br>
    </p>
    <!--<p class="box_example">
      ティボは庭にいる<span class="red_text">のだよ</span>。= tibo wa niwa ni iru <span class="red_text">noda yo</span>。= <span class="red_text">c'est que</span>, Tibo est dans le jardin ! (phrase à la tournure explicative のだ (noda) suivi de la particule よ (yo) pour accentuer)<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボは庭にいる<span class=\'red_text\'>のだよ</span>。'"
      :kanjiraw="'ティボは庭にいるのだよ。'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru <span class=\'red_text\'>noda yo</span>。'"
      :trad="'<span class=\'red_text\'>c\'est que</span>, Tibo est dans le jardin !'" 
      :explication="'Phrase à la tournure explicative のだ (noda) suivi de la particule よ (yo) pour accentuer.'"
    />
    <p class="text_no_margin">
      Forme explicative interrogative avec のだ (noda) + か (ka).<br>
      Lorsqu'on fait une phrase à la tournure explicative, on répond par une phrase à la tournure explicative.<br>
      Rappel Particule か (ka): Si la phrase se termine par "だ" (da), alors la particule か (ka) remplace cette particule "だ" (da).<br>
    </p>
    <!--<p class="box_example">
      ティボは庭にいるか。= tibo wa niwa ni iru ka。= Tibo est-il dans le jardin ? (On demande juste une information)<br>
      ティボは庭にいる<span class="red_text">のか</span>。= tibo wa niwa ni iru <span class="red_text">noka</span>。= <span class="red_text">Est-ce que</span> Tibo est dans le jardin ? (On demande une explication, on remplace "だ" (da) par la particule か (ka))<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボは庭にいるか。'"
      :kanjiraw="'ティボは庭にいるか。'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru ka。'"
      :trad="'Tibo est-il dans le jardin ?'" 
      :explication="'On demande juste une information.'"
    />
    <BoxExample 
      :kanji="'ティボは庭にいる<span class=\'red_text\'>のか</span>。'"
      :kanjiraw="'ティボは庭にいるのか。'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru <span class=\'red_text\'>noka</span>。'"
      :trad="'<span class=\'red_text\'>Est-ce que</span> Tibo est dans le jardin ?'" 
      :explication="'On demande une explication, on remplace “だ” (da) par la particule か (ka).'"
    />
    <p class="text_no_margin">
      On peut omettre la particule か (ka), si on monte l'intonation en fin de phrase.<br>
      Attention の (no) n'est donc pas une particule de fin de phrase, c'est bien la particule のだ (noda) + か (ka), dont on a enlevé "da" et "ka".
    </p>
    <!--<p class="box_example">
      ティボは庭にいる?= tibo wa niwa ni iru ?= Tibo est-il dans le jardin ? (On monte intonation sur la fin de phrase)<br>
      ティボは庭にいる<span class="red_text">の</span>? ↗ = tibo wa niwa ni iru <span class="red_text">no</span>? ↗ = <span class="red_text">Est-ce que</span> Tibo est dans le jardin ? (On monte intonation sur la fin de phrase)<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボは庭にいる?'"
      :kanjiraw="'ティボは庭にいる?'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru ?'"
      :trad="'Tibo est-il dans le jardin ?'" 
      :explication="'On monte intonation sur la fin de phrase.'"
    />
    <BoxExample 
      :kanji="'ティボは庭にいる<span class=\'red_text\'>の</span>? ↗'"
      :kanjiraw="'ティボは庭にいるの?'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru <span class=\'red_text\'>no</span>? ↗'"
      :trad="'<span class=\'red_text\'>Est-ce que</span> Tibo est dans le jardin ?'" 
      :explication="'On monte intonation sur la fin de phrase.'"
    />
    <p class="text_no_margin">
      Pour exprimer la découverte, la compréhension d'un nouveau fait. Pour ça il faut baisser l'intonation en fin de phrase.
    </p>
    <!--<p class="box_example">
      ティボは庭にいる<span class="red_text">のか</span>。↘ = tibo wa niwa ni iru <span class="red_text">noka</span>。↘ = hmm, je vois que tibo est dans le jardin (On baisse l'intonation en fin de phrase, on exprime la découverte)<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボは庭にいる<span class=\'red_text\'>のか</span>。↘'"
      :kanjiraw="'ティボは庭にいるのか。'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru <span class=\'red_text\'>noka</span>。↘'"
      :trad="'hmm, je vois que tibo est dans le jardin.'" 
      :explication="'On baisse l\'intonation en fin de phrase, on exprime la découverte.'"
    />
    <p class="text_no_margin">
      À l'oral il est possible d'abréger le の (no) en ん (n)<br>
      Cette forme à l'oral s'utilise plus souvent pour les phrases à la forme déclarative. La forme interrogative est moins utilisé, elle fait plus dialecte d'Osaka.<br>
    </p>
    <!--<p class="box_example">
      ティボは庭にいる<span class="red_text">んだ</span>。= tibo wa niwa ni iru <span class="red_text">n da</span>。= <span class="red_text">c'est que</span>, Tibo est dans le jardin. (on prononcera "niwa ni irun da")<br>
      ティボは馬鹿な<span class="red_text">んだ</span>。= tibo wa baka na <span class="red_text">n da</span> = tibo est un idiot <br>
      ティボは庭にいる<span class="red_text">んか</span>。= tibo wa niwa ni iru <span class="red_text">n ka</span>。= <span class="red_text">est-ce que</span> Tibo est dans le jardin ?<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボは庭にいる<span class=\'red_text\'>んだ</span>。'"
      :kanjiraw="'ティボは庭にいるんだ。'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru <span class=\'red_text\'>n da</span>。'"
      :trad="'<span class=\'red_text\'>c\'est que</span>, Tibo est dans le jardin.'" 
      :explication="'On prononcera “niwa ni irun da”.'"
    />
    <BoxExample 
      :kanji="'ティボは馬鹿な<span class=\'red_text\'>んだ</span>。'"
      :kanjiraw="'ティボは馬鹿なんだ。'"
      :kana="''" 
      :roomaji="'tibo wa baka na <span class=\'red_text\'>n da</span>'"
      :trad="'tibo est un idiot'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'ティボは庭にいる<span class=\'red_text\'>んか</span>。'"
      :kanjiraw="'ティボは庭にいるんか。'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru <span class=\'red_text\'>n ka</span>。'"
      :trad="'<span class=\'red_text\'>est-ce que</span> Tibo est dans le jardin ?'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Les femmes enlèvent parfois le だ (da) en fin de phrase, ça fait mignon, un peu enfantin.
    </p>
    <!--<p class="box_example">
      ティボは庭にいる<span class="red_text">の</span>。= tibo wa niwa ni iru <span class="red_text">no</span>。= <span class="red_text">c'est que</span>, Tibo est dans le jardin. (en enlevant だ (da) et allongeant un peu le の (no) ça fera un peu enfantin)<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボは庭にいる<span class=\'red_text\'>の</span>。'"
      :kanjiraw="'ティボは庭にいるの。'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru <span class=\'red_text\'>no</span>。'"
      :trad="'<span class=\'red_text\'>c\'est que</span>, Tibo est dans le jardin.'" 
      :explication="'En enlevant だ (da) et allongeant un peu le の (no) ça fera un peu enfantin.'"
    />

  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>
<style>

</style>