<template>
  <div id="kanjidraw" class="popup_kanjidraw" @click.self="quit">
    <div class="card" v-if="kanjiUrl">

      <h4 class="kanji_draw_title"><!--{{title}}-->Tracé de {{kanji}} - 
        <a class="button_jisho" target="_blank" :href="'https://jisho.org/search/' + kanji">
          <i class="fas fa-atlas"></i> Jisho (dictionnaire)
        </a>
      </h4>
      <p class="subtitle"><i class="fas fa-info-circle"></i> Vous pouvez changer la taille avec le slider</p>
      
      <div class="quit ml" @click="quit">
        <i class="fas fa-times"></i>
      </div>

      <div class="description">
        <div class="kanji_slider">
          <Slider v-model="valueSlider" :orientation="orientation" :direction="direction" @change="signalChange" />
          <!-- <span class="kanji_slider_title">Changer la taille</span> -->
        </div>
        <div class="kanji_draw_content">
          <img class="kanji_img" style="height:400px" ref="kanjiimage" :src="kanjiUrl" />
          <div class="dotted vertical"></div>
          <div class="dotted horizontal"></div>
        </div>
      </div>
      

      <!--svg xmlns="http://www.w3.org/2000/svg" width="109" height="109" viewBox="0 0 109 109">
      <g xmlns:kvg="http://kanjivg.tagaini.net" id="kvg:StrokePaths_04eba" style="fill:none;stroke:#000000;stroke-width:3;stroke-linecap:round;stroke-linejoin:round;">
      <g id="kvg:04eba" kvg:element="人" kvg:radical="general">
        <path id="kvg:04eba-s1" kvg:type="㇒" d="M54.5,20c0.37,2.12,0.23,4.03-0.22,6.27C51.68,39.48,38.25,72.25,16.5,87.25"/>
        <path id="kvg:04eba-s2" kvg:type="㇏" d="M46,54.25c6.12,6,25.51,22.24,35.52,29.72c3.66,2.73,6.94,4.64,11.48,5.53"/>
      </g>
      </g>
      <g xmlns:kvg="http://kanjivg.tagaini.net" id="kvg:StrokeNumbers_04eba" style="font-size:8;fill:#808080">
        <text transform="matrix(1 0 0 1 45.50 19.50)">1</text>
        <text transform="matrix(1 0 0 1 52.50 55.63)">2</text>
      </g>
      </svg>-->

    </div>
    <div v-else>
      <Chargement :title="'Loading Kanji Tracé ...'" />
    </div>
  </div>
</template>

<script>
import Slider from '@vueform/slider/dist/slider.vue2.js'
import Chargement from "@/components/Composants/Chargement.vue";

export default {
  props: ["kanji", "title"],
  name: "KanjiDraw",
  components: {
    Slider,
    Chargement
  },
  data() {
    return {
      kanjiSvg: null,
      kanjiUrl: '',
      kanjiCode: '',
      valueSlider: 40,
      orientation: 'vertical',
      direction: 'rtl'
    };
  },
  methods: {
    // Button
    quit(){
      this.$emit('endKanjiDraw')
    },
    // Find Kanji based on https://github.com/fasiha/kanjivg-explorer
    pad(n, width, padder) {
      padder = padder || '0';
      n = n + '';
      if (n.length >= width) {
          return n;
      } else {
        return new Array(width - n.length + 1).join(padder) + n;
      }
    },
    c2u(s, len) {
      len = len || 5;
      return this.pad(s.charCodeAt(0).toString(16), len);
    },
    kanji2url(k) {
      this.kanjiCode = this.c2u(k);
      return this.c2u(k) + ".svg";
    },
    // API Fetch
    getEnv(){
      return this.$store.getters.getCurrentApiKanji
    },
    getTableKanjiDraw(){
      this.kanjiUrl = this.getEnv() + this.kanji2url(this.kanji)
      /*fetch(this.getEnv() + this.kanji2url(this.kanji))
        .then(res => res.json())
        .then(data => this.kanjiSvg = data)
        .catch(err => console.log(err.message))
        .then(this.changeXmlAttribute())*/
    },
    // Change attribute of xml SVG
    changeXmlAttribute(){
      if(this.kanjiSvg){
        // remove style
        document.getElementById("kvg:StrokePaths_"+this.kanjiCode).style["stroke"] = null;
        document.getElementById("kvg:StrokeNumbers_"+this.kanjiCode).style["fill"] = null;
        // add class
        document.getElementById("kvg:StrokePaths_"+this.kanjiCode).classList.add("kanji_stroke_color");
        document.getElementById("kvg:StrokeNumbers_"+this.kanjiCode).classList.add("kanji_number_color");
      }
    },
    // Slidder Change 
    signalChange(){
      this.$refs.kanjiimage.style.height = this.valueSlider*10 + "px";
    }
  },
  // after Start component before data load
  mounted() {
    this.getTableKanjiDraw();
  },
  // Watch props "kanji" change
  watch: { 
    kanji: function(newVal, oldVal) {
      this.getTableKanjiDraw();
    }
  }
}
</script>

<style src="@vueform/slider/themes/default.css"></style>
<style lang="scss" scoped>
/*-- SVG and IMG --*/
.kanji_stroke_color{
  stroke:#000000;
}
.kanji_number_color{
  fill:#000000;
}
.kanji_img{
  filter: none;
  width: auto;
}
.darkmode{
  .kanji_stroke_color{
    stroke:#ffffff !important;
  }
  .kanji_number_color{
    fill:#ffffff !important;
  }
  .kanji_img{
    filter: brightness(0) invert(1);
  }
}
/*-- Carad --*/
.popup_kanjidraw{
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  .card{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    
    background-color: white;
    border: var(--first-color) 1px solid;
    border-radius: 10px;
    box-shadow: 0 6px 30px -10px #4a74c9;

    padding: 20px;
    z-index: 1000;
    .kanji_draw_title{
      margin-right: 70px;
      margin: 0;
      .button_jisho{
        cursor: pointer;
        border: solid 1px #ddd;
        color: var(--first-color);
        border-radius: 5px;
        padding: 0 3px;
        margin: 0 1px;
        &:hover{
          opacity: 0.75;
          background: rgb(221 221 221 / 50%);
        }
      }
    }
    .quit{
      border-radius: 5px;
      border: solid 1px black;
      padding: 5px 10px;
      position: absolute;
      right: 20px;
      top: 15px;
      cursor:pointer;
      &:hover{
        opacity: 0.75;
        background: rgb(221 221 221 / 50%);
      }
    }
    .kanji_slider{
      margin-top: 50px;
    }
    .description{
      display: flex;
      .kanji_draw_content{
        position: relative;
        border: solid;
        margin: 5px;
        height: 100%;
      }
      .dotted{
        position: absolute;
        opacity: 0.5;
      }
      .vertical{
        right: 50%;
        height: 100%;
        width: 2px;
        top: 0;
        // https://kovart.github.io/dashed-border-generator/
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      }
      .horizontal{
        top: 50%;
        height: 2px;
        width: 100%;
        // https://kovart.github.io/dashed-border-generator/
        background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23333' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
      }
    }
    .subtitle{
      margin-bottom: 0;
    }
  }
}

/*-- Darkmode --*/
.darkmode{
  .popup_kanjidraw .card{
    background-color: black;
    .quit{
      border: solid 1px white;
    }
  }
  .button_jisho{
    //color: white !important;
  }
}
</style>