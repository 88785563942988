<template>
  <div>
    <h1>Les spécificités de la langue japonaise</h1>
    <p class="text_no_margin">
      Ici, seront listé toutes les petites choses qui font la spécificité de la langue japonaise<br>
    </p>

    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Les omissions avec le contexte'" :link="'#omissions_contextes'" />
    <Button1 :text="'Les omissions sans contexte'" :link="'#omissions_sans_contextes'" />
    <Button1 :text="'Comparaison de l\'ordre des compléments dans une phrase française et japonaise'" :link="'#ordres_complements'" />
    <Button1 :text="'Les particules grammaticales'" :link="'#particules_grammaticales'" />
    <Button1 :text="'Les Kanji seul'" :link="'#kanji'" />

    <h3 class="title_subcontent" id="omissions_contextes">Les omissions avec le contexte</h3> 
    <p class="text_no_margin">
      Le japonais est une langue qui aime aller à l'essentiel, faire des phrases très simple, mettre le moins de mot possible dans les phrases.<br>
      Chose que l'on retrouve dans la poésie japonaise avec les Haïku.<br>
      De ce fait, grammaticalement on va pouvoir supprimer tous le superflu dans nos phrases, comme tous ce qui est déjâ sous-entendu par le contexte de conversation.<br>
    </p>
    <!--<p class="box_example">
      あの山は火山だ。= ano yama wa kazan da。= Cette montagne est un volcan. (phrase entière)<br>
      火山だ。= kazan da。= <span class="red_text">C</span>'est un volcan (si dans le contexte de notre conversation, on sait déjâ que l'on parle de la montagne, on peut omettre notre thème de phrase)<br>
      私がリンゴを食べる。= watashi ga ringo o taberu。= C'est moi qui mange la pomme. (phrase entière)e<br>
      私が食べる。= watashi ga taberu。= C'est moi qui <span class="red_text">la</span> mange (si dans le contexte de notre conversation, on sait déjâ que l'on parle de la pomme, on peut l'omettre)<br>
    </p>-->
    <BoxExample 
      :kanji="'あの山は火山だ。'"
      :kanjiraw="'あの山は火山だ。'"
      :kana="''" 
      :roomaji="'ano yama wa kazan da。'"
      :trad="'Cette montagne est un volcan.'" 
      :explication="'Phrase entière.'"
    />
    <BoxExample 
      :kanji="'火山だ。'"
      :kanjiraw="'火山だ。'"
      :kana="''" 
      :roomaji="'kazan da。'"
      :trad="'<span class=\'red_text\'>C</span>\'est un volcan (si dans le contexte de notre conversation, on sait déjâ que l\'on parle de la montagne, on peut omettre notre thème de phrase).'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'私がリンゴを食べる。'"
      :kanjiraw="'私がリンゴを食べる。'"
      :kana="''" 
      :roomaji="'watashi ga ringo o taberu。'"
      :trad="'C\'est moi qui mange la pomme.'" 
      :explication="'Phrase entière.'"
    />
    <BoxExample 
      :kanji="'私が食べる。'"
      :kanjiraw="'私が食べる。'"
      :kana="''" 
      :roomaji="'watashi ga taberu。'"
      :trad="'C\'est moi qui <span class=\'red_text\'>la</span> mange'" 
      :explication="'Si dans le contexte de notre conversation, on sait déjâ que l\'on parle de la pomme, on peut l\'omettre.'"
    />

    <h3 class="title_subcontent" id="omissions_sans_contextes">Les omissions sans contexte</h3> 
    <p class="text_no_margin">
      En japonais on peut même sous entendre des choses qui ne sont pas forcément explicités par le contexte mais que l'on comprend quand même<br>
    </p>
    <!--<p class="box_example">
      (私) 男だ。= (watashi) otoko da。= <span class="red_text">Je</span> suis un homme (si on a aucune contexte particulier qui indique de quoi on parle, alors ça veut dire que l'on parle de sois même)<br>
      (あなた) 女なの? = (anata) onna na no? = Est-ce que <span class="red_text">tu</span> es une femme? (si on a aucun contexte particulier qui indique le thème de la phrase, alors la question se pose forcément sur interlocuteur)<br>
      フランス人だ。= furansujin da。= <span class="red_text">Je</span> suis un français. (sans contexte, on parle de sois même)<br>
      毎朝ミルクを飲む。= maiasa miruku o nomu。= <span class="red_text">je</span> bois du lait tous les matins. (sans contexte, on parle de sois même)<br>
      行くぞ。= ikuzo。= <span class="red_text">J'y</span> vais!<br>
    </p>-->
    <BoxExample 
      :kanji="'(私) 男だ。'"
      :kanjiraw="'私男だ。'"
      :kana="''" 
      :roomaji="'(watashi) otoko da。'"
      :trad="'<span class=\'red_text\'>Je</span> suis un homme'" 
      :explication="'Si on a aucune contexte particulier qui indique de quoi on parle, alors ça veut dire que l\'on parle de soi-même.'"
    />
    <BoxExample 
      :kanji="'(あなた) 女なの?'"
      :kanjiraw="'女なの?'"
      :kana="''" 
      :roomaji="'(anata) onna na no?'"
      :trad="'Est-ce que <span class=\'red_text\'>tu</span> es une femme?'" 
      :explication="'Si on a aucun contexte particulier qui indique le thème de la phrase, alors la question se pose forcément sur interlocuteur.'"
    />
    <BoxExample 
      :kanji="'フランス人だ。'"
      :kanjiraw="'フランス人だ。'"
      :kana="''" 
      :roomaji="'furansujin da。'"
      :trad="'<span class=\'red_text\'>Je</span> suis un français.'" 
      :explication="'Sans contexte, on parle de soi-même.'"
    />
    <BoxExample 
      :kanji="'毎朝ミルクを飲む。'"
      :kanjiraw="'毎朝ミルクを飲む。'"
      :kana="''" 
      :roomaji="'maiasa miruku o nomu。'"
      :trad="'<span class=\'red_text\'>je</span> bois du lait tous les matins.'" 
      :explication="'sans contexte, on parle de soi-même.'"
    />
    <BoxExample 
      :kanji="'行くぞ。'"
      :kanjiraw="'行くぞ。'"
      :kana="''" 
      :roomaji="'ikuzo。'"
      :trad="'<span class=\'red_text\'>J\'y</span> vais!'" 
      :explication="''"
    />

    <h3 class="title_subcontent" id="ordres_complements">Comparaison de l'ordre des compléments dans une phrase française et japonaise</h3> 
    <p class="text_no_margin">
      En français et japonais l'ordre des compléments est inversés.<br>
    </p>
    <p class="box_example">
      Je <span class="red_text">mange</span> <span class="blue_text">des oreilles de girafe</span> <span class="green_text">avec une fourchette</span> dans la cuisine <span class="orange_text">tous les matins</span>.<br>
      <span class="orange_text">毎朝</span>台所で<span class="green_text">フォークで</span><span class="red_text">キリンの耳を</span><span class="blue_text">食べる</span>。= <span class="orange_text">maiasa</span> daidokoro de <span class="green_text">fooku de</span> <span class="blue_text">kirin no mimi o</span> <span class="red_text">taberu</span><br>
      en rouge le <span class="red_text">verbe</span>, en bleu le <span class="blue_text">COD</span>, en vert le <span class="green_text">moyen</span>, en noir le lieu et en orange le <span class="orange_text">complément de temps</span>
    </p>
    <p class="text_no_margin">
      En français on donne d'abord l'élément le plus important de la phrase, le verbe puis on va élargir en donnant des informations de moins en moins important.<br>
      En japonais c'est l'inverse on donne d'abord les informations les moins important, puis plus on va se rapprocher du verbe, plus les informations vont devenir important.<br>
      Ce qui veut dire que dès que l'on a le verbe, on a tous les éléments en tête, pour pouvoir comprendre la phrase.<br>
    </p>

    <h3 class="title_subcontent" id="particules_grammaticales">Les particules grammaticales</h3>
    <p class="text_no_margin">
      En japonais les particules grammaticales donne sa fonction grammaticale au mot.<br>
      Ceci va être utile lorsqu'on veut préciser un mot tout seul, ce qu'il est dans le cadre d'une phrase ou dans l'orientation d'un titre.<br>
    </p>
    <p class="box_example">
      <span class="kanji_button" @click="showDrawKanji('鹿', 'Tracé')">鹿</span> = shika = le Cerf<br>
      鹿<span class="red_text">が</span> = shika <span class="red_text">ga</span> = le Cerf (en ajoutant が (ga) on indique que le cerf, sera le sujet sans préciser quel action il fera)<br>
      鹿<span class="red_text">を</span> = shika <span class="red_text">o</span> = le Cerf (en ajoutant を (o), on indique que le cerf, va être COD d'une action, donc subir une action. Ol subira quelque chose sans que ça soit précisé)<br>
      鹿<span class="red_text">に</span> = shika <span class="red_text">ni</span> = <span class="red_text">Au</span> Cerf<br>
      鹿<span class="red_text">まで</span> = shika <span class="red_text">made</span> = <span class="red_text">Jusqu'au</span> Cerf<br>
      鹿<span class="red_text">へ</span> = shika <span class="red_text">e</span> = <span class="red_text">vers</span> le Cerf<br>
    </p>

    <h3 class="title_subcontent" id="kanji">Les Kanji seul</h3>
    <p class="text_no_margin">
      Les kanji seul représente des idées au sens large, avec plusieurs interprétation ou notion possible, chaque personnes peut avoir sa propre interprétation du kanji.<br>
      Contrairement à un mot qui représente une notion bien précis.<br>
      Plus on va mettre de kanji, plus on va se rapprocher d'un mot et restreindre son sens.<br>
    </p>

    <KanjiDraw v-if="kanjiDrawCurrent" :title="kanjiDrawCurrentTitle" :kanji="kanjiDrawCurrent" @endKanjiDraw="endPopupKanjiDraw"/>

  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";
import KanjiDraw from "@/components/Composants/KanjiDraw.vue";

export default {
  name: 'Classificateurs',
  data(){
    return{
      kanjiDrawCurrent: '',
      kanjiDrawCurrentTitle: '',
    }
  },
  components: {
    Button1,
    BoxExample,
    KanjiDraw
  },
  methods:{
    // KanjiDraw Method
    showDrawKanji(kanji, title){
      this.kanjiDrawCurrent = kanji;
      this.kanjiDrawCurrentTitle = title;
    },
    // event
    endPopupKanjiDraw(){
      this.kanjiDrawCurrent = ''
    },
  }
}
</script>

<style lang="scss" scoped>
/* kanji draw button*/
.kanji_bottom{
  display: flex;
  justify-content: center;
}
.kanji_button{
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 1px;
  &:hover{
    opacity: 0.75;
    background: rgb(221 221 221 / 50%);
  }
}
</style>