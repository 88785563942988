<template>
      <div class="section">
      <h1>
        Katakana
        <input class="search" type="search" v-model="search" placeholder="search occurence" @keyup="searchInTable"/>

		<select class="select" v-model="size" @change="onChange">
          <option value="0.625rem">0.625rem</option>
          <option value="0.75rem">0.75rem</option>
          <option value="0.875rem">0.875rem</option>
          <option selected="selected" value="1rem">1rem</option>
          <option value="1.125rem">1.125rem</option>
          <option value="1.25rem">1.25rem</option>
          <option value="1.5rem">1.5rem</option>
          <option value="1.875rem">1.875rem</option>
          <option value="2rem">2rem</option>
		  </select>
      </h1>

      <table class="content-table katakana" id="katakana">
        <thead>
          <tr>
            <th></th>
            <th>A</th>
            <th>I</th>
            <th>U</th>
            <th>E</th>
            <th>O</th>
          </tr>
        </thead>
        <tr>
          <th></th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ア', 'Tracé de ア (a)')">ア</span>
              <span class="romaji">(a)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('イ', 'Tracé de イ (i)')">イ</span>
              <span class="romaji">(i)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ウ', 'Tracé de ウ (u)')">ウ</span>
              <span class="romaji">(u)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('エ', 'Tracé de エ (e)')">エ</span>
              <span class="romaji">(e)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('オ', 'Tracé de オ (o)')">オ</span>
              <span class="romaji">(o)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>K</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('カ', 'Tracé de カ (ka)')">カ</span>
              <span class="romaji">(ka)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('キ', 'Tracé de キ (ki)')">キ</span>
              <span class="romaji">(ki)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ク', 'Tracé de ク (ku)')">ク</span>
              <span class="romaji">(ku)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ケ', 'Tracé de ケ (ke)')">ケ</span>
              <span class="romaji">(ke)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('コ', 'Tracé de コ (ko)')">コ</span>
              <span class="romaji">(ko)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>S</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('サ', 'Tracé de サ (sa)')">サ</span>
              <span class="romaji">(sa)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('シ', 'Tracé de シ (shi)')">シ</span>
              <span class="romaji">(shi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ス', 'Tracé de ス (su)')">ス</span>
              <span class="romaji">(su)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('セ', 'Tracé de セ (se)')">セ</span>
              <span class="romaji">(se)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ソ', 'Tracé de ソ (so)')">ソ</span>
              <span class="romaji">(so)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>T</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('タ', 'Tracé de タ (ta)')">タ</span>
              <span class="romaji">(ta)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('チ', 'Tracé de チ (chi)')">チ</span>
              <span class="romaji">(chi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ツ', 'Tracé de ツ (tsu)')">ツ</span>
              <span class="romaji">(tsu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('テ', 'Tracé de テ (te)')">テ</span>
              <span class="romaji">(te)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ト', 'Tracé de ト (to)')">ト</span>
              <span class="romaji">(to)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>N</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ナ', 'Tracé de ナ (na)')">ナ</span>
              <span class="romaji">(na)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ニ', 'Tracé de ニ (ni)')">ニ</span>
              <span class="romaji">(ni)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ヌ', 'Tracé de ヌ (nu)')">ヌ</span>
              <span class="romaji">(nu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ネ', 'Tracé de ネ (ne)')">ネ</span>
              <span class="romaji">(ne)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ノ', 'Tracé de ノ (no)')">ノ</span>
              <span class="romaji">(no)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>H</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ハ', 'Tracé de ハ (ha)')">ハ</span>
              <span class="romaji">(ha)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ヒ', 'Tracé de ヒ (hi)')">ヒ</span>
              <span class="romaji">(hi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('フ', 'Tracé de フ (fu)')">フ</span>
              <span class="romaji">(fu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ヘ', 'Tracé de ヘ (he)')">ヘ</span>
              <span class="romaji">(he)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ホ', 'Tracé de ホ (ho)')">ホ</span>
              <span class="romaji">(ho)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>M</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('マ', 'Tracé de マ (ma)')">マ</span>
              <span class="romaji">(ma)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ミ', 'Tracé de ミ (mi)')">ミ</span>
              <span class="romaji">(mi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ム', 'Tracé de ム (mu)')">ム</span>
              <span class="romaji">(mu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('メ', 'Tracé de メ (me)')">メ</span>
              <span class="romaji">(me)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('モ', 'Tracé de モ (mo)')">モ</span>
              <span class="romaji">(mo)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>Y</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ヤ', 'Tracé de ヤ (ya)')">ヤ</span>
              <span class="romaji">(ya)</span>
            </div>
          </td>
          <td></td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ユ', 'Tracé de ユ (yu)')">ユ</span>
              <span class="romaji">(yu)</span>
            </div>
          </td>
          <td></td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ヨ', 'Tracé de ヨ (yo)')">ヨ</span>
              <span class="romaji">(yo)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>R</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ラ', 'Tracé de ラ (ra)')">ラ</span>
              <span class="romaji">(ra)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('リ', 'Tracé de リ (ri)')">リ</span>
              <span class="romaji">(ri)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ル', 'Tracé de ル (ru)')">ル</span>
              <span class="romaji">(ru)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('レ', 'Tracé de レ (re)')">レ</span>
              <span class="romaji">(re)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ロ', 'Tracé de ロ (ro)')">ロ</span>
              <span class="romaji">(ro)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>W</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ワ', 'Tracé de ワ (wa)')">ワ</span>
              <span class="romaji">(wa)</span>
            </div>
          </td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ヲ', 'Tracé de ヲ (wo)')">ヲ</span>
              <span class="romaji">(wo)</span>
            </div>
          </td>
        </tr>
        <tr>
          <th>N</th>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ン', 'Tracé de ン (n)')">ン</span>
              <span class="romaji">(n)</span>
            </div>
          </td>
        </tr>
        <tr class="table_light_blue">
          <th>G</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ガ', 'Tracé de ガ (ga)')">ガ</span>
              <span class="romaji">(ga)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ギ', 'Tracé de ギ (gi)')">ギ</span>
              <span class="romaji">(gi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('グ', 'Tracé de グ (gu)')">グ</span>
              <span class="romaji">(gu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ゲ', 'Tracé de ゲ (ge)')">ゲ</span>
              <span class="romaji">(ge)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ゴ', 'Tracé de ゴ (go)')">ゴ</span>
              <span class="romaji">(go)</span>
            </div>
          </td>
        </tr>
        <tr class="table_light_blue">
          <th>Z</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ザ', 'Tracé de ザ (za)')">ザ</span>
              <span class="romaji">(za)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ジ', 'Tracé de ジ (ji)')">ジ</span>
              <span class="romaji">(ji)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ズ', 'Tracé de ズ (zu)')">ズ</span>
              <span class="romaji">(zu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ゼ', 'Tracé de ゼ (ze)')">ゼ</span>
              <span class="romaji">(ze)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ゾ', 'Tracé de ゾ (zo)')">ゾ</span>
              <span class="romaji">(zo)</span>
            </div>
          </td>
        </tr>
        <tr class="table_light_blue">
          <th>D</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ダ', 'Tracé de ダ (da)')">ダ</span>
              <span class="romaji">(da)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ヂ', 'Tracé de ヂ (dji)')">ヂ</span>
              <span class="romaji">(dji)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ヅ', 'Tracé de ヅ (dzu)')">ヅ</span>
              <span class="romaji">(dzu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('デ', 'Tracé de デ (de)')">デ</span>
              <span class="romaji">(de)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ド', 'Tracé de ド (do)')">ド</span>
              <span class="romaji">(do)</span>
            </div>
          </td>
        </tr>
        <tr class="table_light_blue">
          <th>B</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('バ', 'Tracé de バ (ba)')">バ</span>
              <span class="romaji">(ba)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ビ', 'Tracé de ビ (bi)')">ビ</span>
              <span class="romaji">(bi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ブ', 'Tracé de ブ (bu)')">ブ</span>
              <span class="romaji">(bu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ベ', 'Tracé de ベ (be)')">ベ</span>
              <span class="romaji">(be)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ボ', 'Tracé de ボ (bo)')">ボ</span>
              <span class="romaji">(bo)</span>
            </div>
          </td>
        </tr>
        <tr class="table_light_blue">
          <th>P</th>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('パ', 'Tracé de パ (pa)')">パ</span>
              <span class="romaji">(pa)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ピ', 'Tracé de ピ (pi)')">ピ</span>
              <span class="romaji">(pi)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('プ', 'Tracé de プ (pu)')">プ</span>
              <span class="romaji">(pu)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ペ', 'Tracé de ペ (pe)')">ペ</span>
              <span class="romaji">(pe)</span>
            </div>
          </td>
          <td>
            <div class="kanji_container">
              <span class="kanji kanji_button" @click="showDrawKanji('ポ', 'Tracé de ポ (po)')">ポ</span>
              <span class="romaji">(po)</span>
            </div>
          </td>
        </tr>
      </table>

      <KanjiDraw v-if="kanjiDrawCurrent" :title="kanjiDrawCurrentTitle" :kanji="kanjiDrawCurrent" @endKanjiDraw="endPopupKanjiDraw"/>

    </div>
</template>

<script>
import KanjiDraw from "@/components/Composants/KanjiDraw.vue";

export default {
  data(){
    return{
        size : '1rem',
        search: '',
        kanjiDrawCurrent: '',
        kanjiDrawCurrentTitle: '',
    }
  },
  components: {
    KanjiDraw
  },
  methods: {
    searchInTable(){
      if(this.search.length > 0){
        var kanjis = document.getElementById("katakana").getElementsByClassName("kanji_container")
        kanjis.forEach((l) => l.classList.add("inactif_kanji"));
        kanjis.forEach(element => {
          if(element.innerText.includes(this.search)){
              element.classList.remove("inactif_kanji")
          }
        });
      } else {
        var kanjis = document.getElementById("katakana").getElementsByClassName("kanji_container")
        kanjis.forEach((l) => l.classList.remove("inactif_kanji"));
      }
    },
    onChange(){
      document.getElementById("katakana").style.fontSize = this.size
    },
    // KanjiDraw Method
    showDrawKanji(kanji, title){
      this.kanjiDrawCurrent = kanji;
      this.kanjiDrawCurrentTitle = title;
    },
    // event
    endPopupKanjiDraw(){
      this.kanjiDrawCurrent = ''
    },
  },
  mounted(){
    this.onChange()
  }
}
</script>

<style lang="scss" scoped>
/*section*/
.section{
  display: inline-block;
  margin: 0 15px;
  .inactif_kanji{
      opacity: 0.4;
  }
}

.section .search,
.section .select{
  float: right;
  margin-top: 9px;
  min-height: 40px;
}

.section .select{
  margin-right: 5px;
}
/*form*/
input, select{
  padding: 10px 6px;
  box-shadow: border-box;
  border: 1px solid #ddd;
  color: #555;
}

/*table*/
.content-table .kanji{
  /*font-family: "noto sans cjk";*/
}
.content-table .romaji{
  
}
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.katakana th {
  background-color: #009879;
  color: #ffffff;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

/* kanji draw button*/
.kanji_bottom{
  display: flex;
  justify-content: center;
}
.kanji_button{
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 1px;
  &:hover{
    opacity: 0.75;
    background: rgb(221 221 221 / 50%);
  }
}
</style>