<template>
  <div>
    <h3 id="i" class="title_subcontent">Adjectifs en i</h3>
    <h4 class="title_no_bottom">Exemple utilisation</h4>
    <p class="text_no_margin">L'adjectif se place toujours avant le nom.</p>
    <p class="box_example">
        <span class="red_text">bon</span> manga = <span class="red_text">ii</span> manga <br>
        <span class="red_text">gros</span> manga = <span class="red_text">ookii</span> manga <br>
        manga <span class="red_text">pas cher</span> = <span class="red_text">yasui</span> manga <br>
        manga <span class="red_text">compliqué</span> = <span class="red_text">muzukashii</span> manga <br>
    </p>

    <h4 class="title_no_bottom">Conjugaison</h4>
    <p class="text_no_margin">On conjugue que la partie <span class="blue_text">-i</span> de l'adjectif.</p>
    <p class="text_no_margin"><span class="green_text">Exception</span> pour adjectif "ii" qui est la version moderne de "yoi".</p>
    <p class="text_no_margin"><span class="green_text">Exception</span> pour adjectif "kakkoii" qui répond à la même règle que "ii".</p>
    <p class="box_example">
        ita<span class="red_text">i</span> = douloureux <br>
        oishi<span class="red_text">i</span> = bon<br>
        i<span class="red_text">i</span> = bien<br>
        kakkoi<span class="red_text">i</span> = cool, beau gosse<br>
    </p>
    <p class="text_no_margin">Négatif avec <span class="blue_text">-kunai</span></p>
    <p class="box_example">
        ita<span class="red_text">kunai</span> = <span class="red_text">pas</span> douloureux <br>
        oishi<span class="red_text">kunai</span> = <span class="red_text">pas</span> bon <br>
        yo<span class="red_text">kunai</span> = <span class="red_text">pas</span> bien <br>
        kakko<span class="red_text">yokunai</span> = <span class="red_text">pas</span> cool <br>
    </p>
    <p class="text_no_margin">Passé avec <span class="blue_text">-katta</span></p>
    <p class="box_example">
        ita<span class="red_text">katta</span> = <span class="red_text">c'était</span> douloureux <br>
        oishi<span class="red_text">katta</span> = <span class="red_text">c'était</span> bon <br>
        yo<span class="red_text">katta</span> = <span class="red_text">c'était</span> bien <br>
        kakko<span class="red_text">yokatta</span> = <span class="red_text">c'était</span> cool <br>
    </p>
    <p class="text_no_margin">Négatif Passé avec <span class="blue_text">-kunakatta</span></p>
    <p class="box_example">
        ita<span class="red_text">kunakatta</span> = <span class="red_text">c'était pas</span> douloureux <br>
        oishi<span class="red_text">kunakatta</span> = <span class="red_text">c'était pas</span> bon <br>
        yo<span class="red_text">kunakatta</span> = <span class="red_text">c'était pas</span> bien <br>
        kakko<span class="red_text">yokunakatta</span> = <span class="red_text">c'était pas</span> cool <br>
    </p>
    <p class="text_no_margin">Impression Positif avec <span class="blue_text">-sô</span></p>
    <p class="box_example">
        ita<span class="red_text">sô</span> = <span class="red_text">ça à l'air</span> douloureux <br>
        oishi<span class="red_text">sô</span> = <span class="red_text">ça à l'air</span> bon <br>
        yo<span class="red_text">sasô</span> = <span class="red_text">ça à l'air</span> bien <br>
        kakko<span class="red_text">yosasô</span> = <span class="red_text">ça à l'air</span> bien <br>
    </p>
    <p class="text_no_margin">Impression Négatif avec <span class="blue_text">-kunasasô</span></p>
    <p class="box_example">
        ita<span class="red_text">kunasasô</span> = <span class="red_text">ça n'a pas l'air</span> douloureux <br>
        oishi<span class="red_text">kunasasô</span> = <span class="red_text">ça n'a pas l'air</span> bon <br>
        yo<span class="red_text">kunasasô</span> = <span class="red_text">ça n'a pas l'air</span> bien <br>
        kakko<span class="red_text">yokunasasô</span> = <span class="red_text">ça n'a pas l'air</span> cool <br>
    </p>
    <p class="text_no_margin">Transformation en adverbe avec <span class="blue_text">-ku</span></p>
    <p class="box_example">
        haya<span class="red_text">ku</span> = rapide<span class="red_text">ment</span><br>
        sugo<span class="red_text">ku</span> = incroyable<span class="red_text">ment</span> <br>
        yo<span class="red_text">ku</span> = bien <br>
        kakko<span class="red_text">yoku</span> = cool <br>
    </p>
    
    <div v-if="adjectifi.length">
      <TableVocabulaire :vocabulaire="adjectifi" />
    </div>
    <div v-else>
      <Chargement :title="'Loading Adjectif I ...'" />
    </div>
    
  </div>
</template>

<script>
import TableVocabulaire from "@/components/Tableau/TableVocabulaire.vue";
import Chargement from "@/components/Composants/Chargement.vue";

export default {
  name: "AdjectifI",
  data() {
    return {
      adjectifi: [],
    };
  },
  methods:{
    getEnv(){
      return this.$store.getters.getCurrentApiUrl
    }
  },
  mounted(){
    fetch(this.getEnv() + 'adjectifsi')
      .then(res => res.json())
      .then(data => this.adjectifi = data)
      .catch(err => console.log(err.message))
  },
  components: {
    TableVocabulaire,
    Chargement
  },
};
</script>

<style>
</style>