<template>
      <div class="section">
      <h4>
        <div class="section_title">
          Les Combinaisons Hiragana
        </div>
      </h4>

      <table class="content-table combinations" id="combinations">
        <thead>
          <tr>
            <th></th>
            <th>き (ki)</th>
            <th>ぎ (gi)</th>
            <th>し (shi)</th>
            <th>じ (ji)</th>
            <th>ち (chi)</th>
            <th>に (ni)</th>
            <th>ひ (hi)</th>
            <th>び (bi)</th>
            <th>ぴ (pi)</th>
            <th>み (mi)</th>
            <th>り (ri)</th>
          </tr>
        </thead>
        <tr>
          <th>ゃ (ya)</th>
          <td>きゃ (kya)</td>
          <td>ぎゃ (gya)</td>
          <td>しゃ (sha)</td>
          <td>じゃ (ja)</td>
          <td>ちゃ (cha)</td>
          <td>にゃ (nya)</td>
          <td>ひゃ (hya)</td>
          <td>びゃ (bya)</td>
          <td>ぴゃ (pya)</td>
          <td>みゃ (mya)</td>
          <td>りゃ (rya)</td>
        </tr>
        <tr>
          <th>ゅ (yu)</th>
          <td>きゅ (kyu)</td>
          <td>ぎゅ (gyu)</td>
          <td>しゅ (shu)</td>
          <td>じゅ (ju)</td>
          <td>ちゅ (chu)</td>
          <td>にゅ (nyu)</td>
          <td>ひゅ (hyu)</td>
          <td>びゅ (byu)</td>
          <td>ぴゅ (pyu)</td>
          <td>みゅ (myu)</td>
          <td>りゅ (ryu)</td>
        </tr>
        <tr>
          <th>ょ (yo)</th>
          <td>きょ (kyo)</td>
          <td>ぎょ (gyo)</td>
          <td>しょ (sho)</td>
          <td>じょ (jo)</td>
          <td>ちょ (cho)</td>
          <td>にょ (nyo)</td>
          <td>ひょ (hyo)</td>
          <td>びょ (byo)</td>
          <td>ぴょ (pyo)</td>
          <td>みょ (myo)</td>
          <td>りょ (ryo)</td>
        </tr>
      </table>
    </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
/*section*/
.section{
  display: inline-block;
  margin: 0 15px;
    .inactif_kanji{
      opacity: 0.4;
  }
}

.section .search,
.section .select{
  float: right;
  margin-top: 9px;
  min-height: 40px;
}

.section .select{
  margin-right: 5px;
}
/*form*/
input, select{
  padding: 10px 6px;
  box-shadow: border-box;
  border: 1px solid #ddd;
  color: #555;
}

/*table*/
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.combinations th {
  background-color: #009879;
  color: #ffffff;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
</style>