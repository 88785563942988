<template>
  <div>
    <h1>Les unités de mesure</h1>

    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Quelque unités généraux'" :link="'#unites_generaux'" />
    <Button1 :text="'Les unités de temps et de durée'" :link="'#unites_temps'" />
    <Button1 :text="'Les unités de mesure'" :link="'#unites_mesures'" />

    <!-- Unités généraux -->
    <h3 class="title_subcontent" id="unites_generaux">Quelque unités généraux</h3>
    <p class="text_no_margin">
      Les unités de mesures sont souvent confondus avec les classificateurs numéraux alors que ce sont bien deux choses différents. les classificateurs numéraux permettent de compter et classer des choses dans des groupes alors que les unités de mesure indique seulement à quoi se refère le nombre, à une unité de mesure.<br>
      Pour indiquer une unité de mesure, on va s'implement ajouter notre unité de mesure après un nombre.<br>
      Toutes les unités de mesure peuvent être utilisés avec le suffixe 目 (me) ou le préfixe 第 (dai) pour créer des nombres ordinaux.<br>
      Les unité de mesure répondent aux même règles de particularités de prononciation que les Classificateur numéraux.<br>
      En remplaçant le nombre sino-japonais par 何 (nan), on peut créer des mots intérrogatifs que l'on va eux même pouvoir utilisés avec +か (ka),　+も (mo) et　+でも (demo).<br>
      Première liste d'unité de mesure :<br>
      <span class="text_li"><span class="red_text">階 - カイ (kai)</span>: Étage</span><br>
    </p>
    <!--<p class="box_example">
      一階 = ikkai = Rez de chaussé (1er étage en japonais, le 1er étage commence par le rez de chaussé)<br>
    </p>-->
    <BoxExample 
      :kanji="'一階'"
      :kanjiraw="'一階'"
      :kana="''" 
      :roomaji="'ikkai'"
      :trad="'Rez de chauss'" 
      :explication="'1er étage en japonais, le 1er étage commence par le rez de chaussé'"
    />
    <p class="text_no_margin">
      <span class="text_li"><span class="red_text">段 - ダン (dan)</span>: Marche d'escalier, niveau, grade art martiaux </span>
    </p>
    <!--<p class="box_example">
      六段 = rokudan = 6 ème niveaux, grades, marches
    </p>-->
    <BoxExample 
      :kanji="'六段'"
      :kanjiraw="'六段'"
      :kana="''" 
      :roomaji="'rokudan'"
      :trad="'6 ème niveaux, grades, marches'" 
      :explication="''"
    />
    <p class="text_no_margin">
      <span class="text_li"><span class="red_text">話 - ワ (wa)</span>: Histoire, épisode. Il est souvent utilisé avec 第 (dai) pour établir une liste d'épisode par exemple</span>
    </p>
    <!--<p class="box_example">
      第25話 = dai nijuugo wa = le 25ème épisode
    </p>-->
    <BoxExample 
      :kanji="'第25話'"
      :kanjiraw="'第25話'"
      :kana="''" 
      :roomaji="'dai nijuugo wa'"
      :trad="'le 25ème épisode'" 
      :explication="''"
    />
    <p class="text_no_margin">
      <span class="text_li"><span class="red_text">泊 - ハク (haku)</span>: nuitée dans un hôtel</span>
    </p>
    <!--<p class="box_example">
      五泊 = gohaku = je reste 5 nuits
    </p>-->
    <BoxExample 
      :kanji="'五泊'"
      :kanjiraw="'五泊'"
      :kana="''" 
      :roomaji="'gohaku'"
      :trad="'je reste 5 nuits'" 
      :explication="''"
    />
    <p class="text_no_margin">
      <span class="text_li"><span class="red_text">歳 - サイ (sai)</span>: âge en année </span>
    </p>
    <!--<p class="box_example">
      十五歳 = juugosai = j'ai 15 ans<br>
      二十歳 (nijuusai) = はたち (hatachi) = j'ai 20 ans (petite particularité pour 20 ans qui se prononce hatachi qui est une lecture purement japonais)
    </p>-->
    <BoxExample 
      :kanji="'十五歳'"
      :kanjiraw="'十五歳'"
      :kana="''" 
      :roomaji="'juugosai'"
      :trad="'j\'ai 15 ans'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'二十歳 OR はたち'"
      :kanjiraw="'二十歳'"
      :kana="''" 
      :roomaji="'nijuusai OR hatachi'"
      :trad="'j\'ai 20 ans'" 
      :explication="'petite particularité pour 20 ans qui se prononce hatachi qui est une lecture purement japonais'"
    />
    <p class="text_no_margin">
      <span class="text_li"><span class="red_text">代 - ダイ (dai)</span>: génération, il est souvent utilisé avec 目 (me) pour parler de génération</span>
    </p>
    <!--<p class="box_example">
      四代目 = yondaime = la 4ème génération
    </p>-->
    <BoxExample 
      :kanji="'四代目'"
      :kanjiraw="'四代目'"
      :kana="''" 
      :roomaji="'yondaime'"
      :trad="'la 4ème génération'" 
      :explication="''"
    />
    <p class="text_no_margin">
      <span class="text_li"><span class="red_text">番 - バン (ban)</span>: numéro, qui permet d'indiquer une position dans une liste</span>
    </p>
    <!--<p class="box_example">
      一番 = ichiban = le numéro un
    </p>-->
    <BoxExample 
      :kanji="'一番'"
      :kanjiraw="'一番'"
      :kana="''" 
      :roomaji="'ichiban'"
      :trad="'le numéro un'" 
      :explication="''"
    />
    <p class="text_no_margin">
      <span class="text_li"><span class="red_text">倍 - バイ (bai)</span>: multiplicateur, qui permet d'indiquer combien de fois sont multiplié les choses</span>
    </p>
    <!--<p class="box_example">
      三倍 = sanbai = multiplié par 3 fois
    </p>-->
    <BoxExample 
      :kanji="'三倍'"
      :kanjiraw="'三倍'"
      :kana="''" 
      :roomaji="'sanbai'"
      :trad="'multiplié par 3 fois'" 
      :explication="''"
    />
    <p class="text_no_margin">
      <span class="text_li"><span class="red_text">回 - カイ (kai)</span>: occurrence, la répétition, une énième édition</span>
    </p>
    <!--<p class="box_example">
      三回 = sankai = c'est la 3ème édition
    </p>-->
    <BoxExample 
      :kanji="'三回'"
      :kanjiraw="'三回'"
      :kana="''" 
      :roomaji="'sankai'"
      :trad="'c\'est la 3ème édition'" 
      :explication="''"
    />
    <p class="text_no_margin">
      <span class="text_li"><span class="red_text">度 - ド (do)</span>: occurrence mais de manière générale, le degrè de température, le degré des angles</span>
    </p>
    <!--<p class="box_example">
      六度 = rokudo = c'est la 6ème fois
    </p>-->
    <BoxExample 
      :kanji="'六度'"
      :kanjiraw="'六度'"
      :kana="''" 
      :roomaji="'rokudo'"
      :trad="'c\'est la 6ème fois'" 
      :explication="''"
    />

    <!-- Unités de temps -->
    <h3 class="title_subcontent" id="unites_temps">Les unités de temps et de durée</h3>
    <p class="text_no_margin">
      Le kanji 間 (kan) permet de préciser une durée, il est cependant facultatif sur certains temps car facilement compréhensif sans ambiguité.
    </p>
    <table class="content-table nombres" id="nombres">
      <thead>
        <tr>
          <th>Unité</th>
          <th>Kanji</th>
          <th>Durée</th>
          <th>Description,Exemple</th>
        </tr>
      </thead>
      <tr>
        <th>Seconde</th>
        <td class="kanji_group">
          <span class="kanji_up">ビョウ</span>
          <span class="kanji_down">
            <span class="kanji_button" @click="showDrawKanji('秒', 'Tracé')">秒</span>
            <span class="kanji_button" @click="showDrawKanji('間', 'Tracé')">(間)</span>
          </span>
        </td>
        <td>一秒 (ichibyou)</td>
        <td>1 Seconde (durée), on peut ajouter 間 (kan) si on veut, c'est facultatif </td>
      </tr>
      <tr>
        <th>Minute</th>
        <td class="kanji_group">
          <span class="kanji_up">フン</span>
          <span class="kanji_down">
            <span class="kanji_button" @click="showDrawKanji('分', 'Tracé')">分</span>
            <span class="kanji_button" @click="showDrawKanji('間', 'Tracé')">(間)</span>
          </span>
        </td>
        <td>一分 (ippun)</td>
        <td>1 minute (durée), on peut ajouter 間 (kan) si on veut, c'est facultatif</td>
      </tr>
      <tr>
        <th>Heure</th>
        <td class="kanji_group">
          <span class="kanji_up">ジ</span>
          <span class="kanji_down">
            <span class="kanji_button" @click="showDrawKanji('時', 'Tracé')">時</span>
          </span>
        </td>
        <td>一時間 (ichijikan)</td>
        <td>1h (durée), On ajoute ici le kanji 間 (kan) pour préciser une durée et ne pas confondre une heure dans le temps</td>
      </tr>
      <tr>
        <th>Jour</th>
        <td class="kanji_group">
          <span class="kanji_up">か, ニチ</span>
          <span class="kanji_down">
            <span class="kanji_button" @click="showDrawKanji('日', 'Tracé')">日</span>
          </span>
        </td>
        <td>一日 (ichinichi), ニ日間 (futsukan), 三日間 (mikakan)</td>
        <td>pour la durée, à partir du deux on précise 間 (kan) et on va reprendre le modèle des jours du mois</td>
      </tr>
      <tr>
        <th>Semaine</th>
        <td class="kanji_group">
          <span class="kanji_up">シュウ</span>
          <span class="kanji_down">
            <span class="kanji_button" @click="showDrawKanji('週', 'Tracé')">週</span>
          </span>
        </td>
        <td>一週間 (ishuukan)</td>
        <td>1 semaine (durée), On ajoute ici le kanji 間 (kan) pour préciser une durée et ne pas confondre avec le numéro de la semaine dans l'année</td>
      </tr>
      <tr>
        <th>Mois</th>
        <td class="kanji_group">
          <span class="kanji_up">ガツ, ゲツ</span>
          <span class="kanji_down">
            <span class="kanji_button" @click="showDrawKanji('月', 'Tracé')">月</span>
          </span>
        </td>
        <td>一ヶ月 (ikkagetsu)</td>
        <td>1 mois (durée), on va utiliser un petit ケ (ke) avec 月 (getsu) qui se prononce ヶ月 (kagetsu) pour préciser une durée et ne pas confondre avec le nom du mois</td>
      </tr>
      <tr>
        <th>Année</th>
        <td class="kanji_group">
          <span class="kanji_up">ネン</span>
          <span class="kanji_down">
            <span class="kanji_button" @click="showDrawKanji('年', 'Tracé')">年</span>
          </span>
        </td>
        <td>一年(間) (ichinen)</td>
        <td>l'année une, on peut ajouter 間 (kan) si on veut, c'est facultatif</td>
      </tr>
    </table>
    <p class="text_no_margin">
      Pour poser une question sur la durée on va utiliser 何 (nan) à la place des nombres sino-japonais.<br>
      Lorsqu'on demande quel heure ? on va ajouter に (ni) car on demande un point précis dans le temps.<br>
    </p>
    <!--<p class="box_example">
      何時に = nanjini = quel heure?
    </p>-->
    <BoxExample 
      :kanji="'何時に。'"
      :kanjiraw="'何時に。'"
      :kana="''" 
      :roomaji="'nanjini.'"
      :trad="'quel heure?'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Pour préciser une fréquence, on va utiliser <span class="blue_text">回 (kai).</span>
    </p>
    <!--<p class="box_example">
      <span class="red_text">一週間</span>に<span class="blue_text">ニ回</span> = <span class="red_text">ishuukan</span> ni <span class="blue_text">nikai</span> = <span class="blue_text">deux fois</span> par <span class="red_text">semaine</span>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>一週間</span>に<span class=\'blue_text\'>ニ回</span>。'"
      :kanjiraw="'一週間にニ回。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>ishuukan</span> ni <span class=\'blue_text\'>nikai</span>.'"
      :trad="'<span class=\'blue_text\'>deux fois</span> par <span class=\'red_text\'>semaine</span>.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Pour préciser une antériorité "il y a", on va utiliser <span class="blue_text">前 (mae).</span>
    </p>
    <!--<p class="box_example">
      <span class="red_text">一週間</span><span class="blue_text">前</span> = <span class="red_text">ishuukan</span> <span class="blue_text">mae</span> = <span class="blue_text">il y a</span> <span class="red_text">une semaine</span>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>一週間</span><span class=\'blue_text\'>前</span>。'"
      :kanjiraw="'一週間前。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>ishuukan</span> <span class=\'blue_text\'>mae</span>.'"
      :trad="'<span class=\'blue_text\'>il y a</span> <span class=\'red_text\'>une semaine</span>.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Pour préciser une durée depuis une date antérieur "depuis", on va utiliser <span class="blue_text">前 (mae)</span> et から (kara).
    </p>
    <!--<p class="box_example">
      <span class="red_text">一週間</span><span class="blue_text">前</span>から = <span class="red_text">ishuukan</span> <span class="blue_text">mae</span> kara = depuis une <span class="red_text">semaine</span>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>一週間</span><span class=\'blue_text\'>前</span>から。'"
      :kanjiraw="'一週間前から。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>ishuukan</span> <span class=\'blue_text\'>mae</span> kara.'"
      :trad="'depuis une <span class=\'red_text\'>semaine</span>.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Pour préciser une postériorité "dans", on va utiliser <span class="blue_text">後 (go).</span>
    </p>
    <!--<p class="box_example">
      <span class="red_text">一週間</span><span class="blue_text">後</span> = <span class="red_text">ishuukan</span> <span class="blue_text">go</span> = <span class="blue_text">dans</span> <span class="red_text">une semaine</span>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>一週間</span><span class=\'blue_text\'>後</span>。'"
      :kanjiraw="'一週間後。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>ishuukan</span> <span class=\'blue_text\'>go</span>.'"
      :trad="'<span class=\'blue_text\'>dans</span> <span class=\'red_text\'>une semaine</span>.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Pour préciser une durée qui se prolonge dans le futur "jusqu'a", on va utiliser <span class="blue_text">後 (go)</span> et まで (made).
    </p>
    <!--<p class="box_example">
      <span class="red_text">一週間</span><span class="blue_text">後</span>まで = <span class="red_text">ishuukan</span> <span class="blue_text">go</span> made = jusqu'a <span class="blue_text">dans</span> <span class="red_text">une semaine</span>
    </p>-->
    <BoxExample 
      :kanji="'<span class=\'red_text\'>一週間</span><span class=\'blue_text\'>後</span>まで。'"
      :kanjiraw="'一週間後まで。'"
      :kana="''" 
      :roomaji="'<span class=\'red_text\'>ishuukan</span> <span class=\'blue_text\'>go</span> made.'"
      :trad="'jusqu\'a <span class=\'blue_text\'>dans</span> <span class=\'red_text\'>une semaine</span>.'" 
      :explication="''"
    />

    <!-- Unités de mesure -->
    <h3 class="title_subcontent" id="unites_mesures">Les unités de mesure</h3>
    <p class="text_no_margin">
      Pour les unités de mesure on va mettre en premier le nombre puis l'unité de mesure.<br>
      En japonais on peut utiliser les trois systèmes, même si le système internationale est plus utilisé.<br>
      Le degré se dit <span class="kanji_button" @click="showDrawKanji('度', 'Tracé')">度</span> (do) et le celcius se dit <span class="kanji_button" @click="showDrawKanji('セ', 'Tracé')">セ</span><span class="kanji_button" @click="showDrawKanji('氏', 'Tracé')">氏</span> (seshi).<br>
    </p>
    <!--<p class="box_example">
      20メートル = 20 meetoru = 20 mètres
    </p>-->
    <BoxExample 
      :kanji="'20メートル'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'20 meetoru'"
      :trad="'20 mètres'" 
      :explication="''"
    />
    <table class="content-table nombres" id="nombres">
      <thead>
        <tr>
          <th>Unité</th>
          <th>Kanji</th>
          <th>Internationale</th>
          <th>Autres représentation condensé</th>
        </tr>
      </thead>
      <tr>
        <th>Mètre</th>
        <td>メートル (meetoru)</td>
        <td>m</td>
        <td>㍍</td>
      </tr>
      <tr>
        <th>Gramme</th>
        <td>グラム (guramu)</td>
        <td>g</td>
        <td>㌘</td>
      </tr>
      <tr>
        <th>Kilomètre</th>
        <td>キロメートル (kiromeetoru)</td>
        <td>km</td>
        <td>㌖</td>
      </tr>
      <tr>
        <th>Kilogramme</th>
        <td>キログラム (kiroguramu)</td>
        <td>kg</td>
        <td>㌕</td>
      </tr>
      <tr>
        <th>Kilo</th>
        <td>キロ (kiro)</td>
        <td></td>
        <td>㌔</td>
      </tr>
      <tr>
        <th>Degré</th>
        <td>セ氏20度 (seshi 20 do)</td>
        <td>°C</td>
        <td></td>
      </tr>
    </table>
    <p class="text_no_margin">
      Pour parler de superficies au carré et au cube on va utiliser ces unités là:<br>
      <span class="text_li blue_text">
        <span class="kanji_button" @click="showDrawKanji('平', 'Tracé')">平</span>
        <span class="kanji_button" @click="showDrawKanji('方', 'Tracé')">方</span> - ヘイホウ = carré</span><br>
      <span class="text_li red_text">
        <span class="kanji_button" @click="showDrawKanji('立', 'Tracé')">立</span>
        <span class="kanji_button" @click="showDrawKanji('方', 'Tracé')">方</span> - リッポウ = cube</span><br>
      <span class="text_li green_text">
        <span class="kanji_button" @click="showDrawKanji('毎', 'Tracé')">毎</span> - マイ = chaque</span><br>
    </p>
    <table class="content-table nombres" id="nombres">
      <thead>
        <tr>
          <th>Unité</th>
          <th>Kanji</th>
          <th>Roomaji</th>
          <th>Explication</th>
        </tr>
      </thead>
      <tr>
        <th>m²</th>
        <td><span class="blue_text">平方</span>メートル</td>
        <td><span class="blue_text">heihou</span> meetoru</td>
        <td>pour le carré on va prefixer l'unité par 平方 (heihou)</td>
      </tr>
      <tr>
        <th>m³</th>
        <td><span class="red_text">立方</span>メートル</td>
        <td><span class="red_text">rippou</span> metoru</td>
        <td>pour le cube on va prefixer l'unité par 立方 (rippou)</td>
      </tr>
      <tr>
        <th>km/h</th>
        <td>キロメートル<span class="green_text">毎</span>時</td>
        <td>kiromeetoru <span class="green_text">mai</span> ji</td>
        <td>on rajoute 毎 (mai) pour km chaque heure</td>
      </tr>
      <tr>
        <th>m/s</th>
        <td>メートル<span class="green_text">毎</span>秒</td>
        <td>meetoru <span class="green_text">mai</span> byou</td>
        <td>on rajoute 毎 (mai) pour mètre chaque seconde</td>
      </tr>
      <tr>
        <th>m²/s</th>
        <td><span class="blue_text">平方</span>メートル毎秒</td>
        <td><span class="blue_text">heihou</span> metoru mai byou</td>
        <td></td>
      </tr>
      <tr>
        <th>kg/m³</th>
        <td>キログラム<span class="green_text">毎</span><span class="red_text">立方</span>メート</td>
        <td>kiroguramu <span class="green_text">mai</span> <span class="red_text">rippou</span> metoru</td>
        <td></td>
      </tr>
      <tr>
        <th>V x S</th>
        <td>ボルト秒</td>
        <td>boruto byou</td>
        <td>pour multiplier les unités, on va simplement mettres les unités à la suite, les unes à côtés des autres</td>
      </tr>
      <tr>
        <th>T x m²</th>
        <td>テスラ<span class="blue_text">平方</span>メートル</td>
        <td>tesura <span class="blue_text">heihou</span> metoru</td>
        <td></td>
      </tr>
      <tr>
        <th>m/s²</th>
        <td>メートル<span class="green_text">毎</span>秒<span class="green_text">毎</span>秒</td>
        <td>meetoru <span class="green_text">mai</span> byou <span class="green_text">mai</span> byou</td>
        <td>On ne peut pas utiliser 平方 (heihou) et 立方 (rippou) car il sont reservé à une superficie. On va donc doubler 毎秒 (mai byou) pour exprimer les secondes au carré</td>
      </tr>
      <tr>
        <th>kg x m/s²</th>
        <td>キログラムメートル<span class="green_text">毎</span>秒<span class="green_text">毎</span>秒</td>
        <td>kiroguramu metoru <span class="green_text">mai</span> byou <span class="green_text">mai</span> byou</td>
        <td></td>
      </tr>
    </table>

    <KanjiDraw v-if="kanjiDrawCurrent" :title="kanjiDrawCurrentTitle" :kanji="kanjiDrawCurrent" @endKanjiDraw="endPopupKanjiDraw"/>

  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";
import KanjiDraw from "@/components/Composants/KanjiDraw.vue";

export default {
  name: 'Classificateurs',
  data(){
    return{
      kanjiDrawCurrent: '',
      kanjiDrawCurrentTitle: '',
    }
  },
  components: {
    Button1,
    BoxExample,
    KanjiDraw
  },
  methods:{
    // KanjiDraw Method
    showDrawKanji(kanji, title){
      this.kanjiDrawCurrent = kanji;
      this.kanjiDrawCurrentTitle = title;
    },
    // event
    endPopupKanjiDraw(){
      this.kanjiDrawCurrent = ''
    },
  }
}
</script>

<style lang="scss" scoped>
.kanji_group{
  display: flex;
  flex-direction: column;
}

/*section*/
.section{
  display: inline-block;
  margin: 0 15px;
    .inactif_kanji{
      opacity: 0.4;
  }
}

.section .search,
.section .select{
  float: right;
  margin-top: 9px;
  min-height: 40px;
}

.section .select{
  margin-right: 5px;
}
/*form*/
input, select{
  padding: 10px 6px;
  box-shadow: border-box;
  border: 1px solid #ddd;
  color: #555;
}

/*table*/
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.nombres th {
  background-color: #009879;
  color: #ffffff;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}
/* kanji draw button*/
.kanji_bottom{
  display: flex;
  justify-content: center;
}
.kanji_button{
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 1px;
  &:hover{
    opacity: 0.75;
    background: rgb(221 221 221 / 50%);
  }
}
</style>