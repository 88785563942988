<template>
  <div>
    <h1>Les Connecteurs logiques</h1>
    <p class="text_no_margin">
      Les connecteurs logiques vont permettre de lier des propositions (phrases), des mots entre elles, à enchainer les phrases avec des liens logiques.<br>
    </p>

    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Particules conjonctives de nom'" :link="'#conjonctives_nom'" />
    <Button1 :text="'La Particules conjonctives de proposition'" :link="'#conjonctives_proposition'" />
    <Button1 :text="'Mot de conjonctions'" :link="'#conjonctions'" />

    <h3 class="title_subcontent" id="conjonctives_nom">Particules conjonctives de nom</h3>
    <h4 class="title_no_bottom">Particule と (to) - énumération</h4>
    <p class="text_no_margin">
      Particule と (to) pour dresser une énumération.<br>
      La particule s'applique que sur des noms. 
    </p>
    <!--<p class="box_example">
      犬<span class="red_text">と</span>猫<span class="red_text">と</span>馬 = inu <span class="red_text">to</span> neko <span class="red_text">to</span> uma = un chien<span class="red_text">,</span> un chat <span class="red_text">et</span> un cheval
    </p>-->
    <BoxExample 
      :kanji="'犬<span class=\'red_text\'>と</span>猫<span class=\'red_text\'>と</span>馬'"
      :kanjiraw="'犬と猫と馬'"
      :kana="''" 
      :roomaji="'inu <span class=\'red_text\'>to</span> neko <span class=\'red_text\'>to</span> uma'"
      :trad="'un chien<span class=\'red_text\'>,</span> un chat <span class=\'red_text\'>et</span> un cheval'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Particule や (ya) - énumration non-exhaustive</h4>
    <p class="text_no_margin">
       Particule や (ya) pour dresser une énumration non-exhaustive.<br>
       La particule s'appliquer que sur des noms.
    </p>
    <!--<p class="box_example">
      犬<span class="red_text">や</span>猫<span class="red_text">や</span>馬 = inu <span class="red_text">ya</span> neko <span class="red_text">ya</span> uma = un chien<span class="red_text">,</span> un chat<span class="red_text">,</span> un cheval<span class="red_text">, etc</span>
    </p>-->
    <BoxExample 
      :kanji="'犬<span class=\'red_text\'>や</span>猫<span class=\'red_text\'>や</span>馬'"
      :kanjiraw="'犬や猫や馬'"
      :kana="''" 
      :roomaji="'inu <span class=\'red_text\'>ya</span> neko <span class=\'red_text\'>ya</span> uma'"
      :trad="'un chien<span class=\'red_text\'>,</span> un chat<span class=\'red_text\'>,</span> un cheval<span class=\'red_text\'>, etc</span>'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Particule か (ka) - choix exhaustif</h4>
    <p class="text_no_margin">
      Particule か (ka) pour dresser un choix exhaustif<br>
      La particule peut s'appliquer sur des noms et verbes
    </p>
    <!--<p class="box_example">
      犬<span class="red_text">か</span>猫<span class="red_text">か</span>馬 = inu <span class="red_text">ka</span> neko <span class="red_text">ka</span> uma = un chien <span class="red_text">ou</span> un chat <span class="red_text">ou</span> un cheval
    </p>-->
    <BoxExample 
      :kanji="'犬<span class=\'red_text\'>か</span>猫<span class=\'red_text\'>か</span>馬'"
      :kanjiraw="'犬か猫か馬'"
      :kana="''" 
      :roomaji="'inu <span class=\'red_text\'>ka</span> neko <span class=\'red_text\'>ka</span> uma'"
      :trad="'un chien <span class=\'red_text\'>ou</span> un chat <span class=\'red_text\'>ou</span> un cheval'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Particule なり (nari), やり (yari) et だの (dano) - choix non-exhaustif</h4>
    <p class="text_no_margin">
      Ces particules servent à dresser un choix non-exhaustif.<br>
      Il ne faut pas oublier de rajouter la particule à la fin aussi.<br>
      On peut ajouter ensuite を (o) à la fin pour englober tous notre groupe nominal énoncé.<br>
      La particule peut s'appliquer sur des noms et verbes.<br>
    </p>
    <!--<p class="box_example">
      犬<span class="red_text">なり</span>猫<span class="red_text">なり</span>馬<span class="red_text">なり</span> = inu <span class="red_text">nari</span> neko <span class="red_text">nari</span> uma <span class="red_text">nari</span> = <span class="red_text">entre autres</span> un chien <span class="red_text">ou</span> un chat <span class="red_text">ou</span> un cheval
    </p>-->
    <BoxExample 
      :kanji="'犬<span class=\'red_text\'>なり</span>猫<span class=\'red_text\'>なり</span>馬<span class=\'red_text\'>なり</span>'"
      :kanjiraw="'犬なり猫なり馬なり'"
      :kana="''" 
      :roomaji="'inu <span class=\'red_text\'>nari</span> neko <span class=\'red_text\'>nari</span> uma <span class=\'red_text\'>nari</span>'"
      :trad="'<span class=\'red_text\'>entre autres</span> un chien <span class=\'red_text\'>ou</span> un chat <span class=\'red_text\'>ou</span> un cheval'" 
      :explication="''"
    />

    <h3 class="title_subcontent" id="conjonctives_proposition">Particules conjonctives de proposition</h3>
    <p class="text_no_margin">
      On va ici lier des phrases entre elles, pour créer des phrases complexe. chaque phrase devient une proposition ayant chacune sont verbe.<br>
      Pour ceci : <br>
      <span class="text_li">- On va enlever le thème de la 2nd proposition (phrase).</span><br>
      <span class="text_li">- Ajouter une virgule entre les deux propositions.</span><br> 
      <span class="text_li">- Ajouter une particule conjonctives à la fin de la première proposition, après le verbe. Cette particule va donc s'appliquer au verbe et à toute la première proposition</span><br>
    </p>
    <!--<p class="box_example">
      ティボはパリにいる。ティボはピザおたべる。= tibo wa pari ni iru。tibo wa piza o taberu。= tibo est a paris. tibo mange une pizza. (Phrase normal)<br>
      ティボはパリにいる(particule conjonctive)、ピザおたべる。= tibo wa pari ni iru (particule conjonctive)、piza o taberu。= tibo est a paris, il mange une pizza. (Phrase avec une particule conjonctive)
    </p>-->
    <BoxExample 
      :kanji="'ティボはパリにいる。ティボはピザおたべる。'"
      :kanjiraw="'ティボはパリにいる。ティボはピザおたべる。'"
      :kana="''" 
      :roomaji="'tibo wa pari ni iru。tibo wa piza o taberu。'"
      :trad="'tibo est a paris. tibo mange une pizza.'" 
      :explication="'Phrase normal.'"
    />
    <BoxExample 
      :kanji="'ティボはパリにいる(particule conjonctive)、ピザおたべる。'"
      :kanjiraw="'ティボはパリにいる、ピザおたべる。'"
      :kana="''" 
      :roomaji="'tibo wa pari ni iru (particule conjonctive)、piza o taberu。'"
      :trad="'tibo est a paris, il mange une pizza.'" 
      :explication="'Phrase avec une particule conjonctive.'"
    />

    <h4 class="title_no_bottom">Particule が (ga) ou けれでも/けれど/けど (keredemo/keredo/kedo) ou のに (noni) - Concession (Bien que)</h4>
    <p class="text_no_margin">
      Une autre utilisation de la particule が (ga) pour dresser une concession entre deux proposition.<br>
      À la prononciation が (ga) fait plus distant, il est plus utilisé à l'écrit.<br>
    </p>
    <!--<p class="box_example">
      ティボはパリにいる<span class="red_text">が</span>、ピザおたべる。= tibo wa pari ni iru <span class="red_text">ga</span>、piza o taberu。= <span class="red_text">Bien que</span> tibo soit a paris. il mange une pizza.<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボはパリにいる<span class=\'red_text\'>が</span>、ピザおたべる。'"
      :kanjiraw="'ティボはパリにいるが、ピザおたべる。'"
      :kana="''" 
      :roomaji="'tibo wa pari ni iru <span class=\'red_text\'>ga</span>、piza o taberu。'"
      :trad="'<span class=\'red_text\'>Bien que</span> tibo soit a paris. Il mange une pizza.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Particule けれでも (keredemo) ou /けれど (keredo) pour dresser une concession aussi.<br>
      On peut utiliser aussi けど (kedo), mais plus à l'oral que écrit.<br>
      À la prononciation けれでも (keredemo) fait plus doux, Il est plus utilisé à oral.<br>
    </p>
    <!--<p class="box_example">
      ティボはパリにいる<span class="red_text">けど</span>、ピザおたべる。= tibo wa pari ni iru <span class="red_text">kedo</span>、piza o taberu。= <span class="red_text">Bien que</span> tibo soit a paris. il mange une pizza.<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボはパリにいる<span class=\'red_text\'>けど</span>、ピザおたべる。'"
      :kanjiraw="'ティボはパリにいるけど、ピザおたべる。'"
      :kana="''" 
      :roomaji="'tibo wa pari ni iru <span class=\'red_text\'>kedo</span>、piza o taberu。'"
      :trad="'<span class=\'red_text\'>Bien que</span> tibo soit a paris. Il mange une pizza.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Particule のに (noni) pour dresser une concession aussi.<br>
      À la prononciation のに (noni) indique une certaine nuance de surprise, de regret, un peu de déception.<br>
    </p>
    <!--<p class="box_example">
      ティボはパリにいる<span class="red_text">のに</span>、ピザおたべる。= tibo wa pari ni iru <span class="red_text">noni</span>、piza o taberu。= <span class="red_text">Bien que</span> tibo soit a paris. il mange une pizza. (contexte: on aimerait bien qu'il mange quelque chose de plus français)<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボはパリにいる<span class=\'red_text\'>のに</span>、ピザおたべる。'"
      :kanjiraw="'ティボはパリにいるのに、ピザおたべる。'"
      :kana="''" 
      :roomaji="'tibo wa pari ni iru <span class=\'red_text\'>noni</span>、piza o taberu。'"
      :trad="'<span class=\'red_text\'>Bien que</span> tibo soit a paris. Il mange une pizza.'" 
      :explication="'contexte: On aimerait bien qu\'il mange quelque chose de plus français.'"
    />
    <p class="text_no_margin">
      Il ne faut pas mélanger la conession et l'opposition.<br>
      La concession introduit la première proposition.<br>
      l'opposition introduit la seconde proposition.<br>
    </p>
    <p class="box_example">
      <span class="red_text">Bien que</span> tibo soit a paris. Il mange une pizza. (concession)<br>
      tibo est a paris. <span class="red_text">mais</span> Il mange une pizza. (opposition)<br>
    </p>

    <h4 class="title_no_bottom">Particule がら (kara) - Cause (puisque, comme, car)</h4>
    <p class="text_no_margin">
      Une autre utilisation de la particule がら (kara) pour dresser une cause entre deux propositions.<br>
      がら (kara) exprime une cause d'un point de vue subjectif.<br>
    </p>
    <!--<p class="box_example">
      ティボはパリにいる<span class="red_text">kara</span>、ピザおたべる。= tibo wa pari ni iru <span class="red_text">kara</span>、piza o taberu。= <span class="red_text">Puisque (comme, car)</span> tibo est a paris. il mange une pizza.<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボはパリにいる<span class=\'red_text\'>kara</span>、ピザおたべる。'"
      :kanjiraw="'ティボはパリにいるkara、ピザおたべる。'"
      :kana="''" 
      :roomaji="'tibo wa pari ni iru <span class=\'red_text\'>kara</span>、piza o taberu。'"
      :trad="'<span class=\'red_text\'>Puisque (comme, car)</span> tibo est a paris. il mange une pizza.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Particule ので (node) pour dresser une cause aussi.<br>
      ので (node) exprime une cause d'un point de vue objectif<br>
    </p>
    <!--<p class="box_example">
      ティボはパリにいる<span class="red_text">ので</span>、ピザおたべる。= tibo wa pari ni iru <span class="red_text">node</span>、piza o taberu。= <span class="red_text">Puisque (comme, car)</span> tibo est a paris. il mange une pizza.<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボはパリにいる<span class=\'red_text\'>ので</span>、ピザおたべる。'"
      :kanjiraw="'ティボはパリにいるので、ピザおたべる。'"
      :kana="''" 
      :roomaji="'tibo wa pari ni iru <span class=\'red_text\'>node</span>、piza o taberu。'"
      :trad="'<span class=\'red_text\'>Puisque (comme, car)</span> tibo est a paris. il mange une pizza.'" 
      :explication="''"
    />
    <p class="text_no_margin">
      Il ne faut pas confondre la cause et la conséquence.<br>
      La cause introduit la première proposition.<br>
      La conséquence introduit la second proposition.<br>
    </p>
    <p class="box_example">
      <span class="red_text">Puisque (comme, car)</span> tibo est a paris. il mange une pizza. (cause).<br>
      Tibo est a paris. <span class="red_text">donc</span> il mange une pizza. (conséquence)<br>
    </p>

    <h4 class="title_no_bottom">のに (noni) et ので (node) sont des tournures en の (no)</h4>
    <p class="text_no_margin">
      のに (noni) et ので (node) sont des tournures en の (no) exactement comme のだ (noda).<br>
      Lorsqu'on a un だ (da) sur lequel on applique une tournure en の (no) il se transforme en な (na).<br>
    </p>
    <!--<p class="box_example">
      ギギは男<span class="blue_text">だ</span><span class="red_text">ので</span>。トランクスお買う。= gigi wa otoko <span class="blue_text">da</span> <span class="red_text">node</span>。torankusu o kau = <span class="red_text">Puisque</span> Guigui est un homme, il achète un caleçon. (phrase initial)<br>
      ギギは男<span class="blue_text">な</span><span class="red_text">ので</span>。トランクスお買う。= gigi wa otoko <span class="blue_text">na</span> <span class="red_text">node</span>。torankusu o kau = <span class="red_text">Puisque</span> Guigui est un homme, il achète un caleçon. (だ (da) deviens な (na))
    </p>-->
    <BoxExample 
      :kanji="'ギギは男<span class=\'blue_text\'>だ</span><span class=\'red_text\'>ので</span>。トランクスお買う。'"
      :kanjiraw="'ギギは男だので。トランクスお買う。'"
      :kana="''" 
      :roomaji="'gigi wa otoko <span class=\'blue_text\'>da</span> <span class=\'red_text\'>node</span>。torankusu o kau'"
      :trad="'<span class=\'red_text\'>Puisque</span> Guigui est un homme, il achète un caleçon.'" 
      :explication="'phrase initial'"
    />
    <BoxExample 
      :kanji="'ギギは男<span class=\'blue_text\'>な</span><span class=\'red_text\'>ので</span>。トランクスお買う。'"
      :kanjiraw="'ギギは男なので。トランクスお買う。'"
      :kana="''" 
      :roomaji="'gigi wa otoko <span class=\'blue_text\'>na</span> <span class=\'red_text\'>node</span>。torankusu o kau'"
      :trad="'<span class=\'red_text\'>Puisque</span> Guigui est un homme, il achète un caleçon. (だ (da) deviens な (na))'" 
      :explication="''"
    />

    <h3 class="title_subcontent" id="conjonctions">Mot de conjonctions</h3>
    <p class="text_no_margin">
      La conjonction est le mot qui va introduire le liens logique entre la première proposition et la seconde proposition.<br>
      En aucun cas on relis les deux propositions (phrase), elles restent indépendant.<br>
      Voici une liste non-exhaustive des mot de conjonction :<br>
      <span class="text_li">- <span class="red_text">そして (soshite)</span>: introduit une union "et"</span><br>
      <span class="text_li">- <span class="red_text">その上 (sonoue)</span>: introduit un ajout "de plus"</span><br>
      <span class="text_li">- <span class="red_text">あるいは (aruiwa)</span>: introduit un choix "ou bien"</span><br>
      <span class="text_li">- <span class="red_text">しかし (shikashi)</span>: introduit une opposition "mais"</span><br>
      <span class="text_li">- <span class="red_text">逆に (gyakuni)</span>: introduit un propos inverse "inversement"</span><br>
      <span class="text_li">- <span class="red_text">それで (sorede)</span>: introduit une conséquence "donc"</span><br>
      <span class="text_li">- <span class="red_text">どころで (dokorode)</span>: introduit un changement de sujet "au fait"</span><br>
      <span class="text_li">- <span class="red_text">つまり (tsumari)</span>: introduit un résumé "en un mot, pour résumer"</span><br>
      <span class="text_li">- <span class="red_text">実は (jitsuwa)</span>: introduit une vérité "à vrai dire"</span><br>
      <span class="text_li">- <span class="red_text">一方 (ippou)</span>: introduit une mise en parallèle "d'autre part"</span><br>
    </p>
    <!--<p class="box_example">
      ティボはパリにいる。<span class="red_text">しかし</span>ピザおたべる。= tibo wa pari ni iru。<span class="red_text">shikashi</span> piza o taberu。= Tibo est a paris. <span class="red_text">Mais</span> il mange une pizza.<br>
      ティボはパリにいる。<span class="red_text">それで</span>ピザおたべる。= tibo wa pari ni iru。<span class="red_text">sorede</span> piza o taberu。= Tibo est a paris. <span class="red_text">Donc</span> il mange une pizza.<br>
      ティボはピザを食べる。<span class="red_text">一方</span>、ギギはトランクスを買う。= tibo wa piza o taberu。<span class="red_text">ippou</span>、gigi wa torankusu o kau。= Tibo mange une pizzza. <span class="red_text">D'autre part</span>, gigi achète un caleçon. (on peut aussi avoir deux thèmes différent)<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボはパリにいる。<span class=\'red_text\'>しかし</span>ピザおたべる。'"
      :kanjiraw="'ティボはパリにいる。しかしピザおたべる。'"
      :kana="''" 
      :roomaji="'tibo wa pari ni iru。<span class=\'red_text\'>shikashi</span> piza o taberu。'"
      :trad="'Tibo est a paris. <span class=\'red_text\'>Mais</span> Il mange une pizza.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'ティボはパリにいる。<span class=\'red_text\'>それで</span>ピザおたべる。'"
      :kanjiraw="'ティボはパリにいる。それでピザおたべる。'"
      :kana="''" 
      :roomaji="'tibo wa pari ni iru。<span class=\'red_text\'>sorede</span> piza o taberu。'"
      :trad="'Tibo est a paris. <span class=\'red_text\'>Donc</span> Il mange une pizza.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'ティボはピザを食べる。<span class=\'red_text\'>一方</span>、ギギはトランクスを買う。'"
      :kanjiraw="'ティボはピザを食べる。一方、ギギはトランクスを買う。'"
      :kana="''" 
      :roomaji="'tibo wa piza o taberu。<span class=\'red_text\'>ippou</span>、gigi wa torankusu o kau。'"
      :trad="'Tibo mange une pizzza. <span class=\'red_text\'>D\'autre part</span>, gigi achète un caleçon.'" 
      :explication="'On peut aussi avoir deux thèmes différents.'"
    />
    <p class="text_no_margin">
      Voici une liste non-exhaustive des mots de conjonction créer à partir de particules de conjonctives dont on à préfixé par だ (da) :<br>
      <span class="text_li">- <span class="red_text">だが (daga)</span>: introduit une opposition "cepandant"</span><br>
      <span class="text_li">- <span class="red_text">だけど (dakedo)</span>: introduit une opposition "mais"</span><br>
      <span class="text_li">- <span class="red_text">なのに (nanoni)</span>: introduit une conséquence "malgré cela"</span><br>
      <span class="text_li">- <span class="red_text">だから (dakara)</span>: introduit une conséquence "pour cette raison"</span><br>
      <span class="text_li">- <span class="red_text">なので (nanode)</span>: introduit une conséquence "par conséquent"</span><br>
    </p>
  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  name: 'Classificateurs',
  components: {
    Button1,
    BoxExample
  },
}
</script>

<style>

</style>