<template>
  <div id="popup_findword" class="popup_findword">
    <div class="card" v-if="currentWord">
      <!-- Header -->
      <div class="header">
        <!-- title -->
        <h1 class="popup_title">
          {{ title }}
        </h1>
        <h4 class="subtitle">
          <div v-if="type === 'frtojp'">Trouver le mot Japonais</div>
          <div v-else>Trouver le mot Français</div>
        </h4>
        <!-- reset -->
        <!--<div class="reset mt ml" @click="reset">
          <i class="fas fa-redo"></i>
          Reset
        </div>-->
        <!-- quit -->
        <div class="quit ml" @click="quit">
          <i class="fas fa-times"></i>
          Quitter
        </div>
      </div>
      <!-- debut corp exo -->
      <div v-if="!isExoFinish" class="corp_exo">
        <!-- description -->
        <div class="description">
          <div class="number">
            Vocabulaire restant: {{allWordRemaining.length}}
          </div>
          <!-- FR to JP -->
          <div class="word_translate txt_center m_word" v-if="type === 'frtojp'">
            {{currentWord.translate}} 
            <div class="volume" @click="listenAudio(currentWord.kanji)">
              <i class="fas fa-volume-up"></i>
            </div>
          </div>
          <!-- JP to FR -->
          <div class="m_word" v-else>
            <div class="word_kanji txt_center">
              {{currentWord.kanji}}
            </div>
            <div class="word_furigana txt_center">
              {{currentWord.furigana}}
            </div>
            <div class="volume2" @click="listenAudio(currentWord.kanji)">
              <i class="fas fa-volume-up"></i>
            </div>
          </div>
        </div>
        <!-- Form -->
        <div class="form" v-if="isFormShow">
          <form
            id="form"
            @submit="checkForm"
          >
            <input class="input_font" v-on:keyup="entryChange" v-model="entry" placeholder="réponse">
            <!--<input v-on:keyup="entryKanaChange" @model="entryKana" placeholder="réponse">-->
            <!--<input @model="entryKana" placeholder="réponse" disabled>-->
            <div v-if="type === 'frtojp'" class="kana input_font">
              {{entryKana}}
            </div>
            <div class="submit">
              <button>Valider</button>
            </div>
          </form>
        </div>
        <!-- Response False -->
        <div class="response" v-if="isNextWordShow">
          <!-- Réponse -->
          <div>
            <div class="kanji">
              Kanji: {{currentWord.kanji}}
            </div>
            <div class="furigana">
              Furigana: {{currentWord.furigana}}
            </div>
              Rōmaji: {{currentWord.romaji}}
            <div class="translate">
              Tradution: {{currentWord.translate}}
            </div>
          </div>
          <!-- Message -->
          <div class="message">
            <div class="information">
              {{message}}
            </div>
            <div class="votre_message">
              votre réponse: {{entry}}
              <div class="failed_icons">
                <i class="far fa-times-circle red_text icons"></i>
              </div>
            </div>
          </div>
          <!-- Next Button -->
          <div class="submit" @click="nextWord">
            <button>
              Passer au mot suivant <i class="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <!-- fin corp exo -->
      <div v-else>
        Félicitation! Vous avez appris tous le vocabulaire pour ce thèmes!
        <i class="far fa-check-circle green_text icons"></i>
      </div>
      <!-- Popup information -->
      <div class="popup_information" v-if="isPopupShow">
        <div v-if="isWordValidate">
          <i class="far fa-check-circle green_text icons"></i>
        </div>
        <!--<div v-else>
          <i class="far fa-times-circle red_text icons"></i>
        </div>-->
      </div>
      <!-- Subtitle information -->
      <h5 class="subtitle_help">
        <i class="fas fa-info-circle"></i> Ne mettez pas les traductions situé entre les parenthèses
      </h5>
    </div>
    <!-- EndCard -->
  </div>
</template>

<script>
export default {
  name: 'Findword',
  props: ["title", "vocabulaire", "type"],
  data() {
    return{
      // Table
      currentWord: null,
      allWordRemaining: [],
      allWordFind: [],
      // Form
      entry: '',
      entryKana: '',
      message: '',
      //State
      isFormShow: true,
      isNextWordShow: false,
      isWordValidate: false,
      isPopupShow: false,
      isExoFinish: false,
    }
  },
  methods:{
    // Button
    quit(){
      this.$emit('end')
    },
    reset(){
      this.allWordRemaining = JSON.parse(JSON.stringify(this.vocabulaire)) // clone value. par défaut le JS prend la référence de this.vocabulaire, si on change this.vocabulaire on change la value dans tous le projet
    },
    // Form
    entryChange(){
      this.entryKana = wanakana.toKana(this.entry);
    },
    /*entryKanaChange(){
      this.entry = wanakana.toRomaji(this.entryKana);
      console.log(wanakana.toRomaji(this.entryKana));
    },*/
    nextWord(){
      // Reset params
      this.entry = ''
      this.message = ''
      this.entryKana = ''
      // update panel
      this.isFormShow = true;
      this.isNextWordShow = false;
      // next word
      this.tableOrder()
    },
    checkForm(e) {
      // check response
      if(this.type == 'frtojp')
        this.checkFrToJp()
      else
        this.checkJpToFr()

      if(this.isWordValidate){
        this.showPopup()
        this.nextWord()
      } else {
        // update panel
        this.showPopup()
        this.isFormShow = false;
        this.isNextWordShow = true;
      }
      // prevent default function of form html to change page or go to the top
      e.preventDefault();
    },
    checkFrToJp(){
      if(this.entry.replace(/\s/g, '') === this.currentWord.furigana.replace(/ *\([^)]*\) */g, "").replaceAll(/\s/g,'').toLowerCase()){
        this.isWordValidate = true
        this.SuccessMessage()
      } else if(this.entryKana.replace(/\s/g, '') === this.currentWord.furigana.replace(/ *\([^)]*\) */g, "").replaceAll(/\s/g,'').toLowerCase()){
        this.isWordValidate = true
        this.SuccessMessage()
      } else if(this.entry.replace(/\s/g, '') === this.currentWord.kanji.replace(/ *\([^)]*\) */g, "").replaceAll(/\s/g,'').toLowerCase()){
        this.isWordValidate = true
        this.SuccessMessage()
      } else if (this.entry.replace(/\s/g, '') === this.currentWord.romaji.replace(/ *\([^)]*\) */g, "").replaceAll(/\s/g,'').toLowerCase()){
        this.isWordValidate = true
        this.SuccessMessage()
      } else {
        this.isWordValidate = false
        this.failedMessage()
      }
    },
    checkJpToFr(){
      if(this.entry.replace(/\s/g, '') === this.currentWord.translate.replace(/ *\([^)]*\) */g, "").replaceAll(/\s/g,'').toLowerCase()){
        this.isWordValidate = true
        this.SuccessMessage()
      } else {
        this.isWordValidate = false
        this.failedMessage()
      }
    },
    // Reorder
    tableOrder(){
      if(this.isWordValidate){
        // remove first element array
        this.allWordRemaining.shift();
        // check if word is remaining
        if(this.allWordRemaining.length > 0){
          // update current word
          this.currentWord = this.allWordRemaining[0]
        } else {
          // Finish panel
          this.isExoFinish = true
        }
      } else {
        // remove and push first element array to end of array
        var wordtopush = this.allWordRemaining.shift();
        this.allWordRemaining.push(wordtopush);
        // update current word
        this.currentWord = this.allWordRemaining[0]
      }
    },
    // Message
    failedMessage(){
      this.message = 'Mauvaise réponse'
    },
    SuccessMessage(){
      this.message = 'Bonne réponse'
    },
    // Popup
    showPopup(){
      this.isPopupShow = true
      setTimeout(() => {
        this.hidePopup()
      }, 1000)
    },
    hidePopup(){
      this.isPopupShow = false
    },
    // vocal
    listenAudio(kanji){
      responsiveVoice.speak(kanji, "Japanese Male");
    }
  },
  // https://vuejs.org/v2/guide/instance.html (lifecycle)
  // when the component is create, before data
  mounted() {
    this.allWordRemaining = JSON.parse(JSON.stringify(this.vocabulaire)) // clone value. par défaut le JS prend la référence de this.vocabulaire, si on change this.vocabulaire on change la value dans tous le projet
    this.currentWord = this.allWordRemaining[0]
  },
}
</script>

<style lang="scss" scoped>
.popup_findword{
  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  background-color: rgb(0 0 0 / 50%);
  width: 100%;
  height: 100%;
  .card{
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    
    background-color: white;
    border: var(--first-color) 1px solid;
    border-radius: 10px;
    box-shadow: 0 6px 30px -10px #4a74c9;

    padding: 20px;
    z-index: 1000;
    .header{
      display: flex;
      margin-bottom: 20px;
      .subtitle{
        position: absolute;
        margin-top: 45px;
      }
      .popup_title{
        margin-right: 110px;
      }
      .quit{
        border-radius: 5px;
        border: solid 1px black;
        padding: 10px;
        position: absolute;
        right: 20px;
        cursor:pointer;
        &:hover{
          opacity: 0.75;
          background: rgb(221 221 221 / 50%);
        }
      }
    }
    .word_kanji, .word_translate{
      font-size: 2em;
    }
    .word_translate{
      margin-right: 45px;
    }
    .word_furigana {
      font-size: 1.3em;
    }
    .popup_information{
      position: absolute;
      right: 6%;
      top: 38%;
    }
    .message{
      border: solid 1px #ddd;
      border-radius: 10px;
      padding: 5px;
      margin-top: 5px;
      position: relative;
    }
    .failed_icons{
      position: absolute;
      right: 20px;
      top: 7px;
    }
    .input_font{
      font-size: 1.3em;
    }
    .subtitle_help{
      margin: 10px 0px 0px 0px;
    }
    .volume{
      float: left;
      margin-right: 10px;
      cursor: pointer;
    }
    .volume2{
      position: absolute;
      top: 37%;
      font-size: 2em;
      cursor: pointer;
    }
  }
}
/*-- Global --*/
.icons{
  font-size: 3em;
}
.mt{
  margin-top: 14px;
}
.ml{
  margin-left: 20px;
}
.txt_center{
  text-align: center;
}
.m_word{
  margin: 10px 0px 10px 0px;
}
/*-- Form --*/
input{
  display: block;
  padding: 10px 6px;
  width: 100%;
  box-shadow: 0 6px 30px -10px #ddd;
  color: #555;
}
button{
  background: #0b6dff;
  border: 0;
  padding: 10px 20px;
  margin-top: 20px;
  color: white;
  border-radius: 20px;
  width: 100%;
  cursor:pointer;
  &:focus{
    border: none;
    outline:0;
  }
  &:hover{
    opacity: 0.75;
  }
}
.submit {
  text-align: center;
}
/*-- Darkmode --*/
.darkmode{
  .popup_findword .card{
    background-color: black;
    .quit{
      border: solid 1px white;
    }
  }
}
</style>