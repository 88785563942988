<template>
  <div>
    <h1>Verbes</h1>
    <p>
      Les verbes servent à décrire des actions.
    </p>

    <h3 class="title_subcontent">Accès rapide</h3>
    <Button1 :text="'Trois groupe de verbes'" :link="'#trois_groupe'" />
    <Button1 :text="'Règles utilisation'" :link="'#regles_utilisation'" />
    <Button1 :text="'Les différents groupe'" :link="'#different_groupe'" />
    <Button1 :text="'Tableau récapitulatif'" :link="'#table_verbe'" />
    <Button1 :text="'Liste de quelque verbes existant'" :link="'#liste_verbe'" />

    <h3 class="title_subcontent" id="trois_groupe">Trois groupe de verbes</h3>
    <p class="text_no_margin">
        Le premier groupe régulier (ichidan) correspond aux verbes se terminant par <span class="red_text">-u -ku -gu -su -nu -bu -mu -tsu -ru -wu et kaeru</span>.<br>
        Le deuxième groupe régulier (godan) correspond aux verbes se terminant par <span class="red_text">-eru -iru sauf kaeru</span>.<br>
        Le troisème groupe irrégulier correspond à ces deux verbes <span class="red_text">"kuru" et "suru"</span>.<br>
        Pour la petite information, nous appelons les terminaisons -u, -ku, etc des "okurigana", ce sont des hiragana qui viennent se mettre juste après le Kanji.
    </p>
    <h3 class="title_subcontent" id="regles_utilisation">Règles utilisation</h3>
    <p class="text_no_margin">
      - Le verbe se place à la fin de la phrase.<br>
      - Les pronoms personnels existent, mais ils sont utilisés seulement si le contexte de la phrase et de la situation n'est pas assez clair. En temps normal nous n'utilisons donc pas "je", "tu", "nous", "vous", "il", "elle" etc.<br>
      - Il n'y a pas de temps (futur), tout est déterminé par le contexte de la phrase.<br>
      - Il vaut mieux utiliser la forme polie en discussion normal avec des inconnus.
    </p>
    <p class="box_example">
      ima <span class="red_text">kiku</span> = <span class="red_text">je demande</span> tout de suite <br>
      ashita <span class="red_text">kiku</span> = <span class="red_text">je demande</span> demain <br>
    </p>

    <h3 class="title_subcontent" id="different_groupe">Les différents groupe</h3>
    <!-- Verbe du 1er groupe -->
    <h4 id="1er">Verbe du 1er groupe</h4>
    <p class="text_no_margin">
      le premier groupe régulier (ichidan) correspond aux verbes se terminant par <span class="red_text">-u -ku -gu -su -nu -bu -mu -tsu -ru -wu et kaeru</span><br>
    </p>

    <!-- Verbe du 2nd groupe -->
    <h4 id="2nd">Verbe du 2nd groupe</h4>
    <p class="text_no_margin">
      le deuxième groupe régulier (godan) correspond aux verbes se terminant par <span class="red_text">-eru -iru sauf kaeru</span><br>
    </p>

    <!-- Verbe du 3eme groupe -->
    <h4 id="3eme">Verbe du 3eme groupe</h4>
    <p class="text_no_margin">
      le troisème groupe irrégulier correspond à ces deux verbes <span class="red_text">"kuru" et "suru"</span><br>
    </p>

    <h3 class="title_subcontent">Voir l'ensemble de toutes les conjugaisons possible</h3>
    <p class="text_no_margin">
      <Button1 :text="'Exemple avec Taberu (manger)'" :target="'_blank'" :link="'https://jlearn.net/dictionary/食べる'" />
    </p>

    <h4 class="title_no_bottom">Liste non exhaustive, mais principaux des conjugaisons des verbes les plus utilisés</h4>
    <h3 class="title_subcontent" style="margin-top: 0;">Forme du présent</h3>
    <!-- Forme Dictionnaire -->
    <h4 class="title_no_bottom">Forme du Dictionnaire (Présent) (Neutre) <span class="blue_text">-u</span></h4>
    <p class="text_no_margin">
      C'est la forme neutre, de base présent dans le dictionnaire
    </p>
    <p class="box_example">
      ik<span class="red_text">u</span> = Aller<br>
      tabe<span class="red_text">ru</span> = Manger<br>
    </p>

    <!-- Forme Négative -->
    <h4 class="title_no_bottom">Forme (Présent) (Neutre) (Négative) <span class="blue_text">-nai</span></h4>
    <p class="text_no_margin">
      Forme négative non polie, correspondant au présent
    </p>
    <p class="box_example">
      tabe<span class="red_text">nai</span> = Je ne mange pas
    </p>

    <!-- Forme Polie -->
    <h4 class="title_no_bottom">Forme (Présent) (Polie) <span class="blue_text">-masu</span></h4>
    <p class="text_no_margin">
      Forme de politesse utilisé en tant normal, correspondant au présent
    </p>
    <p class="box_example">
      nomi<span class="red_text">masu</span> = Je bois<br>
    </p>

    <!-- Forme Polie -->
    <h4 class="title_no_bottom">Forme (Présent) (Polie) (Négative) <span class="blue_text">-masen</span></h4>
    <p class="text_no_margin">
      Forme de politesse négative utilisé en tant normal, correspondant au présent
    </p>
    <p class="box_example">
      iki<span class="red_text">masen</span> = Je n'y vais pas
    </p>

    <h3 class="title_subcontent" style="margin-top: 0;">Forme du passé</h3>
    <!-- Forme Passé -->
    <h4 class="title_no_bottom">Forme (Passé) (neutre) <span class="blue_text">-ta</span></h4>
    <p class="box_example">
      banana o tabe<span class="red_text">ta</span> = J'ai mangé une banane
    </p>

    <!-- Forme Passé -->
    <h4 class="title_no_bottom">Forme (Passé) (neutre) (Négative) <span class="blue_text">-nakatta</span></h4>
    <p class="box_example">
      banana o tabe<span class="red_text">nakatta</span> = Je n'ai pas mangé de banane
    </p>

    <!-- Forme Passé -->
    <h4 class="title_no_bottom">Forme (Passé) (Polie) <span class="blue_text">-mashita</span></h4>
    <p class="box_example">
      banana o tabe<span class="red_text">mashita</span> = J'ai mangé une banane
    </p>

    <!-- Forme Passé -->
    <h4 class="title_no_bottom">Forme (Passé) (Polie) (Négative) <span class="blue_text">-masendeshita</span></h4>
    <p class="box_example">
      banana o tabe<span class="red_text">masendeshita</span> = J'ai mangé une banane
    </p>

    <h3 class="title_subcontent" style="margin-top: 0;">Forme de l'invitation</h3>
    <!-- Forme de l'invitation -->
    <h4 class="title_no_bottom">Forme de l'invitation à faire quelque chose (volition) (Neutre) <span class="blue_text">-ou</span></h4>
    <p class="box_example">
      tabe<span class="red_text">you</span> = Mangeons?
    </p>

    <!-- Forme de l'invitation -->
    <h4 class="title_no_bottom">Forme de l'invitation à faire quelque chose (volition) (Polie) <span class="blue_text">-mashou</span></h4>
    <p class="box_example">
      tabe<span class="red_text">mashou</span> = Mangeons?
      nomi<span class="red_text">mashou</span> = Buvons?
    </p>

    <h3 class="title_subcontent" style="margin-top: 0;">Forme de la volonté</h3>
    <!-- Forme Volonté -->
    <h4 class="title_no_bottom">Forme de la volonté (Volonté) (Neutre) <span class="blue_text">-tai</span></h4>
    <p class="box_example">
      nomi<span class="red_text">tai</span> = Je veux boire
    </p>

    <h3 class="title_subcontent" style="margin-top: 0;">Forme de la capacité</h3>
    <!-- Forme Capacité -->
    <h4 class="title_no_bottom">Forme de la Capacité </h4>
    <p class="box_example">
      tabe<span class="red_text">reru</span> = Je peux manger (forme polie)<br>
      tabe<span class="red_text">remasu</span> = Je peux manger (forme neutre)<br>
    </p>

    <h3 class="title_subcontent" style="margin-top: 0;">Forme de la requête</h3>
    <!-- Forme Requete -->
    <h4 class="title_no_bottom">Forme de la requête <span class="blue_text">-te</span></h4>
    <p class="text_no_margin">
      Demander à quelqu'un de faire quelque chose
    </p>
    <p class="box_example">
      tabe<span class="red_text">te</span> = Mange<br>
      tabe<span class="red_text">te kudasi</span> = Mange s'il te plait
    </p>

    <div id="table_verbe">
      <TableVerbe/>
    </div>

    <div v-if="verbe.length" id="liste_verbe">
      <TableVocabulaire :vocabulaire="verbe" :title="'Liste des verbes'"/>
    </div>
    <div v-else>
      <Chargement :title="'Loading Verbes ...'" />
    </div>

  </div>
</template>

<script>
import TableVocabulaire from "@/components/Tableau/TableVocabulaire.vue";
import TableVerbe from "@/components/Tableau/TableVerbe.vue";
import Button1 from "@/components/Buttons/Button1.vue";
import Chargement from "@/components/Composants/Chargement.vue";

export default {
  name: "Verbes",
  data() {
    return {
      verbe:[]
    };
  },
  methods:{
    getEnv(){
      return this.$store.getters.getCurrentApiUrl
    }
  },
  mounted(){
    fetch(this.getEnv() + 'verbes')
      .then(res => res.json())
      .then(data => this.verbe = data)
      .catch(err => console.log(err.message))
  },
  components: {
    TableVocabulaire,
    Button1,
    TableVerbe,
    Chargement
  },
};
</script>

<style>
</style>