<template>
  <div>
      <h1>Les préfixes</h1>
      <h3 class="title_subcontent">Accès rapide</h3>
      <Button1 :text="'Préfixes démonstratifs こ・そ・あ・ど'" :link="'#prefixes_general'" />
      <Button1 :text="'Préfixes démonstratifs こ・そ・あ・ど transformé en adverbe'" :link="'#prefixes_adverbe'" />
      <Button1 :text="'Précision supplémentaire sur l\'utilisation des préfixes démonstratifs こ・そ・あ・ど'" :link="'#prefixes_precision'" />
      <Button1 :text="'Préfixe de politesse et honorifique お'" :link="'#prefixes_honorifique'" />

      <h3 class="title_subcontent" id="prefixes_general">Les préfixes démonstratifs こ・そ・あ・ど</h3>
      <p class="text_no_margin">
        Ce sont des préfixes que l'on va combiner à différentes terminaisons afin de démontrer des choses, d'indiquer des choses selon différentes positions.<br>
        Ces prefixes s'utilisent toujours dans une position de conversation entre un "Locuteur" et un "Interlocuteur".<br>
        <span class="text_li">- Préfixe こ (ko): Désigne quelqu'un ou quelque chose de lié ou proche du locuteur.</span><br>
        <span class="text_li">- Préfixe そ (so): Désigne quelqu'un ou quelque chose de lié ou proche de interlocuteur.</span><br>
        <span class="text_li">- Préfixe あ (a): Désigne quelqu'un ou quelque chose d'extérieur au locuteur et interlocuteur, loin des deux ou qui n'appartient ni à l'un ni à l'autre.</span><br>
        <span class="text_li">- Préfixe ど (do): Désigne quelqu'un ou quelque chose d'indéterminé, c'est un préfixe de question.</span><br>
      </p>

      <h4 class="title_no_bottom">terminaison こ (ko) - Lieu</h4>
      <p class="text_no_margin">
        En ajoutant aux préfixes la terminaison こ (ko), on peut désigner un lieu.<br>
        <span class="text_li">- ここ (koko): Désigne une position proche du locuteur (ici, vers moi).</span><br>
        <span class="text_li">- そこ (soko): Désigne une position proche de l'interlocuteur (là, proche de toi).</span><br>
        <span class="text_li">- あそこ (asoko): Désigne un lieu éloigné du locuteur et de l'interlocuteur, loin des deux. (là-bas, loin de nous deux) (il y a bien un そ "so" exceptionnelement).</span><br>
        <span class="text_li">- どこ (doko): Poser une question sur un lieu (où ?).</span><br>
      </p>
      <!--<p class="box_example">
        ポールは<span class="red_text">あそこ</span>にいる。= pooru wa <span class="red_text">asoko</span> ni iru。 = Paul est <span class="red_text">là-bas</span>. (loin du locuteur et interlocuteur)
      </p>-->
      <BoxExample 
        :kanji="'ポールは<span class=\'red_text\'>あそこ</span>にいる。'"
        :kanjiraw="'ポールはあそこにいる。'"
        :kana="''" 
        :roomaji="'pooru wa <span class=\'red_text\'>asoko</span> ni iru。'"
        :trad="'Paul est <span class=\'red_text\'>là-bas</span>.'" 
        :explication="'Loin du locuteur et de l\'interlocuteur.'"
      />

      <h4 class="title_no_bottom">terminaison れ (re) - Une chose</h4>
      <p class="text_no_margin">
        En ajoutant aux préfixes la terminaison れ (re), on peut désigner une chose concrète ou abstraite.<br>
        Cette terminaison permet de désigner une choses sans la nommer contrairement à の (no) + nom en dessous.<br>
        <span class="text_li">- これ (kore): Désigne quelque chose de proche du locuteur ou qui lui appartient (ceci, à moi).</span><br>
        <span class="text_li">- それ (sore): Désigne quelque chose de proche de interlocuteur ou qui lui appartient (cela, à toi).</span><br>
        <span class="text_li">- あれ (are): Désigne quelque chose de loin du locuteur et de interlocuteur ou qui n'appartient pas aux deux. (cela là-bas, à eux).</span><br>
        <span class="text_li">- どれ (dore): Poser une question sur quelque chose ou lequel (lequel ?).</span><br>
      </p>
      <!--<p class="box_example">
        <span class="red_text">これ</span>はタクシーだ。= <span class="red_text">kore</span> wa takushii da = <span class="red_text">Ceci</span> est un taxi.
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'>これ</span>はタクシーだ。'"
        :kanjiraw="'これはタクシーだ。'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>kore</span> wa takushii da'"
        :trad="'<span class=\'red_text\'>Ceci</span> est un taxi.'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">terminaison の (no) + nom - Une chose nommée</h4>
      <p class="text_no_margin">
        En ajoutant aux préfixes la terminaison の (no) + nom, on peut désigner une chose nommée contrairement à れ (re) qui peut être plus abstrait.<br>
        <span class="text_li">- この (kono) + nom: Désigne quelque chose de proche du locuteur ou qu'il lui appartient (cette chose-ci).</span><br>
        <span class="text_li">- その (sono) + nom: Désigne quelque chose de proche de interlocuteur ou qu'il lui appartient (cette chose-là).</span><br>
        <span class="text_li">- あの (ano) + nom: Désigne quelque chose de loin du locuteur et de interlocuteur ou qu'il n'appartient pas aux deux. (cette chose là-bas).</span><br>
        <span class="text_li">- どの (dono) + nom: Poser une question sur quelque chose ou lequel (quelle chose ?).</span><br>
      </p>
      <!--<p class="box_example">
        <span class="red_text"><b>この</b>車</span>はタクシーだ。 = <span class="red_text"><b>kono</b> kuruma</span> wa takushii da。 = <span class="red_text"><b>Cette</b> voiture</span> est un taxi.
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'><b>この</b>車</span>はタクシーだ。'"
        :kanjiraw="'この車はタクシーだ。'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'><b>kono</b> kuruma</span> wa takushii da。'"
        :trad="'<span class=\'red_text\'><b>Cette</b> voiture</span> est un taxi.'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">terminaison ちら (chira) - Une direction</h4>
      <p class="text_no_margin">
        En ajoutant aux préfixes la terminaison ちら (chira), on peut désigner une direction.<br>
        <span class="text_li">- こちら (kochira): Désigne la direction dans laquelle est orientée le locuteur (cette direction-ci).</span><br>
        <span class="text_li">- そちら (sochira): Désigne la direction dans laquelle est orientée l'interlocuteur (cette direction-là).</span><br>
        <span class="text_li">- あちら (achira): Désigne une autre direction qui n'est ni celle dans laquelle est orientée le locuteur et ni celle dans laquelle est orientée l'interlocuteur. (cette direction là-bas).</span><br>
        <span class="text_li">- どちら (dochira): Poser une question sur une direction (quelle direction ?).</span><br>
      </p>
      <!--<p class="box_example">
        私は<span class="red_text">そちら</span>へいくよ。= watashi wa <span class="red_text">sochira</span> e iku yo。= Je vais dans <span class="red_text">cette direction-là</span>!
      </p>-->
      <BoxExample 
        :kanji="'私は<span class=\'red_text\'>そちら</span>へいくよ。'"
        :kanjiraw="'私はそちらへいくよ。'"
        :kana="''" 
        :roomaji="'watashi wa <span class=\'red_text\'>sochira</span> e iku yo。'"
        :trad="'Je vais dans <span class=\'red_text\'>cette direction-là</span>!'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">terminaison っち (pause et chi) - Une direction de manière familière</h4>
      <p class="text_no_margin">
        En ajoutant aux préfixes la terminaison っち (pause et chi), on peut désigner une direction dans un registre familier.<br>
        Cette exactement la même chose que la terminaison ちら (chira) mais en plus familier.<br>
        <span class="text_li">- こっち (kocchi): Désigne la direction dans laquelle est orientée le locuteur (c'te direction-ci).</span><br>
        <span class="text_li">- そっち (socchi): Désigne la direction dans laquelle est orientée l'interlocuteur (c'te direction-là).</span><br>
        <span class="text_li">- あっち (acchi): Désigne une autre direction qui n'est ni celle dans laquelle est orientée le locuteur et ni celle dans laquelle est orientée l'interlocuteur. (c'te direction là-bas).</span><br>
        <span class="text_li">- どっち (docchi): Poser une question sur une direction (quelle direction ?).</span><br>
      </p>
      <!--<p class="box_example">
        私は<span class="red_text">そっち</span>へいくよ。= watashi wa <span class="red_text">socchi</span> e iku yo。= Je vais dans <span class="red_text">c'te direction-là</span>!
      </p>-->
      <BoxExample 
        :kanji="'私は<span class=\'red_text\'>そっち</span>へいくよ。'"
        :kanjiraw="'私はそっちへいくよ。'"
        :kana="''" 
        :roomaji="'watashi wa <span class=\'red_text\'>socchi</span> e iku yo。'"
        :trad="'Je vais dans <span class=\'red_text\'>c\'te direction-là</span>!'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">terminaison なた (nata) - Une personne</h4>
      <p class="text_no_margin">
        En ajoutant aux préfixes la terminaison なだ (nata), on peut désigner une personne.<br>
        <span class="text_li">- こなた (konata): le mot n'est plus utilisé aujourd'hui.</span><br>
        <span class="text_li">- そなた (sonata): le mot n'est plus utilisé aujourd'hui.</span><br>
        <span class="text_li">- あなた (anata): Pour s'adresser à son interlocuteur (tu, toi).</span><br>
        <span class="text_li">- どなた (donata): Poser une question sur une personne ("qui ?" en version plus polie que だれ (dare)).</span><br>
      </p>
      <!--<p class="box_example">
        <span class="red_text">あなた</span>は<span class="blue_text">どなた</span>ですか。 = <span class="red_text">anata</span> wa <span class="blue_text">donata</span> desu ka。= <span class="red_text">En ce qui vous concerne</span>, <span class="blue_text">Qui</span> êtes-vous ? (on à utilisé la forme polie de "da" => "desu" pour avoir la phrase à la forme polie)
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'>あなた</span>は<span class=\'blue_text\'>どなた</span>ですか。'"
        :kanjiraw="'あなたはどなたですか。'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>anata</span> wa <span class=\'blue_text\'>donata</span> desu ka。'"
        :trad="'<span class=\'red_text\'>En ce qui vous concerne</span>, <span class=\'blue_text\'>Qui</span> êtes-vous ?'" 
        :explication="'On à utilisé la forme polie de “da” => “desu” pour avoir la phrase à la forme polie.'"
      />

      <h4 class="title_no_bottom">terminaison いつ (itsu) - Une personne de manière familière</h4>
      <p class="text_no_margin">
        En ajoutant aux préfixes la terminaison いつ (itsu), on peut désigner une personne de manière familière, méprisante.<br>
        Souvent utilisé par les hommes pour être méchant.<br>
        <span class="text_li">- こいつ (koitsu): en français ça correspond à "ce type-ci".</span><br>
        <span class="text_li">- そいつ (soitsu): en français ça correspond à "ce type-là".</span><br>
        <span class="text_li">- あいつ (aitsu): en français ça correspond à "ce type-là bas".</span><br>
        <span class="text_li">- どいつ (doitsu): Poser une question sur la personne "quel type ?".</span><br>
      </p>
      <!--<p class="box_example">
        <span class="red_text">あいつ</span>は馬鹿だ。= <span class="red_text">aitsu</span> wa baka da。= <span class="red_text">Ce type là-bas</span> est un idiot.
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'>あいつ</span>は馬鹿だ。'"
        :kanjiraw="'あいつは馬鹿だ。'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>aitsu</span> wa baka da。'"
        :trad="'<span class=\'red_text\'>Ce type là-bas</span> est un idiot.'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">terminaison んな (nna) + nom - Un genre de chose nommée</h4>
      <p class="text_no_margin">
        En ajoutant aux préfixes la terminaison　んな (nna) + nom, on peut désigner un genre de chose nommée.<br>
        <span class="text_li">- こんな (konna): Désigner un genre de chose proche du locuteur (ce genre de chose-ci).</span><br>
        <span class="text_li">- そんな (sonna): Désigner un genre de chose proche de l'interlocuteur (ce genre de chose-là).</span><br>
        <span class="text_li">- あんな (anna): Désigner un genre de chose loin du locuteur et interlocuteur (ce genre de chose-là-bas).</span><br>
        <span class="text_li">- どんな (donna): Poser une question sur le genre (quel genre de chose ?).</span><br>
      </p>
      <!--<p class="box_example">
        タクシーは<span class="red_text">こんな</span>車だ。= takushii wa <span class="red_text">konna</span> kuruma da。= Les taxis sont <span class="red_text">ce genre de voiture-ci</span>
      </p>-->
      <BoxExample 
        :kanji="'タクシーは<span class=\'red_text\'>こんな</span>車だ。'"
        :kanjiraw="'タクシーはこんな車だ。'"
        :kana="''" 
        :roomaji="'takushii wa <span class=\'red_text\'>konna</span> kuruma da。'"
        :trad="'Les taxis sont <span class=\'red_text\'>ce genre de voiture-ci</span>'" 
        :explication="''"
      />

      <!-- Adverbe -->
      <h3 class="title_subcontent" id="prefixes_adverbe">Les préfixes démonstratifs こ・そ・あ・ど transformé en adverbe</h3>
      <h4 class="title_no_bottom">terminaison　んなに (nnani) - le degré</h4>
      <p class="text_no_margin">
        En ajoutant aux préfixes la terminaison んなに (nnani), on peut désigner le degré selon lequel sont faite les choses.<br>
        <span class="text_li">- こんなに (konnani): en français ça correspond à "à ce point-ci".</span><br>
        <span class="text_li">- そんなに (sonnani): en français ça correspond à "à ce point-là".</span><br>
        <span class="text_li">- あんなに (annani): en français ça correspond à "à ce point là-bas".</span><br>
        <span class="text_li">- どんなに (donnani): Poser une question sur le degrés "à quel point ?".</span><br>
      </p>
      <!--<p class="box_example">
        ポールは<span class="blue_text">とても</span>ゆっくり話す。= pooru wa <span class="blue_text">totemo</span> yukkuri hanasu。= Paul parle <span class="blue_text">très</span> lentement. (utilisation de adverbe とても (totemo))<br>
        ポールは<span class="red_text">こんなに</span>ゆっくり話す。= pooru wa <span class="red_text">konnani</span> yukkuri hanasu。= Paul parle lentement <span class="red_text">à ce point</span>. (utilisation de adverbe こんなに (konnani) pour insister sur le degrés)
      </p>-->
      <BoxExample 
        :kanji="'ポールは<span class=\'blue_text\'>とても</span>ゆっくり話す。'"
        :kanjiraw="'ポールはとてもゆっくり話す。'"
        :kana="''" 
        :roomaji="'pooru wa <span class=\'blue_text\'>totemo</span> yukkuri hanasu。'"
        :trad="'Paul parle <span class=\'blue_text\'>très</span> lentement.'" 
        :explication="'Utilisation de l\'adverbe とても (totemo).'"
      />
      <BoxExample 
        :kanji="'ポールは<span class=\'red_text\'>こんなに</span>ゆっくり話す。'"
        :kanjiraw="'ポールはこんなにゆっくり話す。'"
        :kana="''" 
        :roomaji="'pooru wa <span class=\'red_text\'>konnani</span> yukkuri hanasu。'"
        :trad="'Paul parle lentement <span class=\'red_text\'>à ce point</span>.'" 
        :explication="'Utilisation de l\'adverbe こんなに (konnani) pour insister sur le degrés.'"
      />

      <h4 class="title_no_bottom">terminaison en Allongement - Une manière</h4>
      <p class="text_no_margin">
        En ajoutant aux préfixes une terminaison en allongement, on peut désigner une manière de faire.<br>
        <span class="text_li">- こう (kou): En français ça correspond à "de cette manière-ci".</span><br>
        <span class="text_li">- そう (sou): En français ça correspond à "de cette manière-là".</span><br>
        <span class="text_li">- あう (au): En français ça correspond à "de cette manière là-bas".</span><br>
        <span class="text_li">- どう (dou): Poser une question sur la manière "comment ?".</span><br>
      </p>
      <!--<p class="box_example">
        ティボはドアお<span class="red_text">どう</span>閉めるの? = tibo wa doa o <span class="red_text">dou</span> shimeru no ? = <span class="red_text">Comment</span> tibo ferme-t-il la porte ?<br>
        具合は<span class="red_text">どう</span>? = guai wa <span class="red_text">dou</span> ? = <span class="red_text">Comment</span> vas-tu ? (la santé)<br>
        お茶は<span class="red_text">どう</span>? = ocha wa <span class="red_text">dou</span> ? = <span class="red_text">Veux-tu</span> du thé ? (en ce qui concerne le thé, comment le veux tu ? (proposition de thé))
      </p>-->
      <BoxExample 
        :kanji="'ティボはドアお<span class=\'red_text\'>どう</span>閉めるの?'"
        :kanjiraw="'ティボはドアおどう閉めるの?'"
        :kana="''" 
        :roomaji="'tibo wa doa o <span class=\'red_text\'>dou</span> shimeru no ?'"
        :trad="'<span class=\'red_text\'>Comment</span> tibo ferme-t-il la porte ?'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'具合は<span class=\'red_text\'>どう</span>?'"
        :kanjiraw="'具合はどう?'"
        :kana="''" 
        :roomaji="'guai wa <span class=\'red_text\'>dou</span> ?'"
        :trad="'<span class=\'red_text\'>Comment</span> vas-tu ?'" 
        :explication="'la santé'"
      />
      <BoxExample 
        :kanji="'お茶は<span class=\'red_text\'>どう</span>?'"
        :kanjiraw="'お茶はどう?'"
        :kana="''" 
        :roomaji="'ocha wa <span class=\'red_text\'>dou</span> ?'"
        :trad="'<span class=\'red_text\'>Veux-tu</span> du thé ?'" 
        :explication="'en ce qui concerne le thé, comment le veux tu ? (proposition de thé)'"
      />
      <p class="text_no_margin">
        On peut utiliser ces 4 mots avec le verbe だ (da), être de déscription.<br>
        On peut aussi utiliser そう (sou) avec le verbe か (ka) sur un ton descendant pour émettre une confirmation.<br>
      </p>
      <!--<p class="box_example">
        <span class="red_text">そう</span>だ = <span class="red_text">sou</span> da = c'est ça. (c'est de cette manière dont tu l'as présenté)<br>
        <span class="red_text">そう</span>か ↘ = <span class="red_text">sou</span> ka ↘ = c'est donc ça.<br>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'>そう</span>だ'"
        :kanjiraw="''"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>sou</span> da'"
        :trad="'c\'est ça. '" 
        :explication="'c\'est de cette manière que tu l\'as présenté.'"
      />
      <BoxExample 
        :kanji="'<span class=\'red_text\'>そう</span>か ↘'"
        :kanjiraw="''"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>sou</span> ka ↘'"
        :trad="'c\'est donc ça.'" 
        :explication="''"
      />
      
      <!-- Petite Précision -->
      <h3 class="title_subcontent" id="prefixes_precision">Précision supplémentaire sur l'utilisation des préfixes démonstratifs こ・そ・あ・ど</h3>
      <h4 class="title_no_bottom">Formulation synonyme pour la terminaison んな (nna) + nom - Un genre de chose nommée.</h4>
      <p class="text_no_margin">
        Pour la terminaison んな (nna) + nom , il existe des synonymes.<br>
        <span class="text_li">こんな (konna) = このような (konoyouna) = こういう (kouiu).</span><br>
        <span class="text_li">そんな (sonna) = そのような (sonoyouna) = そういう (souiu).</span><br>
        <span class="text_li">あんな (anna) = あのような (anoyouna) = ああいう (aaiu).</span><br>
        <span class="text_li">どんな (donna) = どのような (donoyouna) = どういう (douiu).</span><br>
      </p>

      <h4 class="title_no_bottom">Formulation synonyme pour la terminaison んなに (nnani) - le degré</h4>
      <p class="text_no_margin">
        Pour la terminaison んなに (nnani), il existe des synonymes<br>
        <span class="text_li">こんなに (konnani) = このように (konoyouni).</span><br>
        <span class="text_li">そんなに (sonnani) = そのように (sonoyouni).</span><br>
        <span class="text_li">あんなに (annani) = あのように (anoyouni).</span><br>
        <span class="text_li">どんなに (donnani) = どのように (donoyouni).</span><br>
      </p>

      <h4 class="title_no_bottom">Forme polie pour un lieu, une chose, une personne à partir de la terminaison ちら (chira)</h4>
      <p class="text_no_margin">
         On peut utiliser la terminaison ちら (chira) pour une direction comme forme polie pour désigner un lieu, une chose et une personne aussi.<br>
         <span class="text_li">こちら (kochira) = "cette direction-ci", "ici", "ceci", "cette personne-ci".</span><br>
         <span class="text_li">そちら (sochira) = "cette direction-là", "là", "cela", "cette personne-là".</span><br>
         <span class="text_li">あちら (achira) = "cette direction là-bas", "là-bas", "cela là-bas", "cette personne là-bas".</span><br>
         <span class="text_li">どちら (dochira) = "quelle direction", "où ?", "lequel ?", "qui ?".</span><br>
         Ce qui nous fait 3 formes pour dire "qui" : 誰 (dare), どなた (donata) qui est poli et どちら (dochira) qui est poli aussi.<br>
      </p>

      <h4 class="title_no_bottom">choisir 1 élément parmi 2</h4>
      <p class="text_no_margin">
        Pour choisir un élément parmi 2 propositions, on va utiliser どちら (dochira) ou どっち (docchi)<br>
      </p>
      <!--<p class="box_example">
        <span class="blue_text">あなた</span>はワインとビールの<span class="red_text">どっち</span>を飲む? = <span class="blue_text">anata</span> wa wain to biiru no <span class="red_text">docchi</span> o nomu? = <span class="red_text">Que</span> bois-<span class="blue_text">tu</span> entre du vin et de la bière ?
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'blue_text\'>あなた</span>はワインとビールの<span class=\'red_text\'>どっち</span>を飲む?'"
        :kanjiraw="'あなたはワインとビールのどっちを飲む?'"
        :kana="''" 
        :roomaji="'<span class=\'blue_text\'>anata</span> wa wain to biiru no <span class=\'red_text\'>docchi</span> o nomu?'"
        :trad="'<span class=\'red_text\'>Que</span> bois-<span class=\'blue_text\'>tu</span> entre du vin et de la bière ?'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">choisir 1 élément parmi 3 ou +</h4>
      <p class="text_no_margin">
        Pour choisir un élément parmi 3 propositions ou plus on va utiliser どれ (dore).<br>
      </p>
      <!--<p class="box_example">
        <span class="blue_text">あなた</span>はワインとビールとウイスキーの<span class="red_text">どれ</span>を飲む? = <span class="blue_text">anata</span> wa wain to biiru to uisukii no <span class="red_text">dore</span> o nomu? = <span class="red_text">Que</span> bois-<span class="blue_text">tu</span> entre du vin, de la bière et du whisky ?
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'blue_text\'>あなた</span>はワインとビールとウイスキーの<span class=\'red_text\'>どれ</span>を飲む?'"
        :kanjiraw="'あなたはワインとビールとウイスキーのどれを飲む?'"
        :kana="''" 
        :roomaji="'<span class=\'blue_text\'>anata</span> wa wain to biiru to uisukii no <span class=\'red_text\'>dore</span> o nomu?'"
        :trad="'<span class=\'red_text\'>Que</span> bois-<span class=\'blue_text\'>tu</span> entre du vin, de la bière et du whisky ?'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">Englober différents éléments dans un même tout</h4>
      <p class="text_no_margin">
        Pour englober différents éléments dans un même tout.<br>
      </p>
      <!--<p class="box_example">
        <span class="blue_text">あなた</span>は<span class="green_text">このお酒</span>の中で<span class="red_text">どれ</span>お飲む? = <span class="blue_text">anata</span> wa <span class="green_text">kono osake</span> no naka de <span class="red_text">dore</span> o nomu? = <span class="red_text">Que</span> bois-<span class="blue_text">tu</span> parmi <span class="green_text">ces alcools</span> ? (on utilise "osake no naka de" pour dire "parmis ces alcools")
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'blue_text\'>あなた</span>は<span class=\'green_text\'>このお酒</span>の中で<span class=\'red_text\'>どれ</span>お飲む?'"
        :kanjiraw="'あなたはこのお酒の中でどれお飲む?'"
        :kana="''" 
        :roomaji="'<span class=\'blue_text\'>anata</span> wa <span class=\'green_text\'>kono osake</span> no naka de <span class=\'red_text\'>dore</span> o nomu?'"
        :trad="'<span class=\'red_text\'>Que</span> bois-<span class=\'blue_text\'>tu</span> parmi <span class=\'green_text\'>ces alcools</span> ?'" 
        :explication="'On utilise “osake no naka de” pour dire “parmi ces alcools”.'"
      />

      <h4 class="title_no_bottom">Sur de grandes distances</h4>
      <p class="text_no_margin">
        Sur de grandes positions où l'on considère le locuteur et l'interlocuteur dans un même groupe, on peut dire que le locuteur et l'interlocuteur correspond à un même groupe qu'on définiera par こ (ko), ensuite そ (so) désignera une position extérieure au groupe à moyenne distance et あ (a) désignera une position extérieure au groupe à grande distance.<br>
      </p>

      <h4 class="title_no_bottom">Répéter un mot avec そ (so)</h4>
      <p class="text_no_margin">
        On peut utiliser le préfixe そ (so) avec ces différentes terminaison, pour répéter un mot déjà mentionner dans une phrase précédent<br>
      </p>
      <!--<p class="box_example">
        私はテレビを買う。<span class="red_text">それ</span>で映画をみる。= watashi wa terebi o kau。<span class="red_text">sore</span> de eiga o miru = J'achète une télévision. Avec <span class="red_text">ça</span>, je regaraderai un film.<br>
        私は学校に行く。<span class="red_text">そこ</span>で漢字を習う。= watashi wa gakkou ni iku。<span class="red_text">soko</span> de kanji o narau = Je vais à l'école. <span class="red_text">Là</span> j'apprendrai des Kanji.<br>
      </p>-->
      <BoxExample 
        :kanji="'私はテレビを買う。<span class=\'red_text\'>それ</span>で映画をみる。'"
        :kanjiraw="'私はテレビを買う。それで映画をみる。'"
        :kana="''" 
        :roomaji="'watashi wa terebi o kau。<span class=\'red_text\'>sore</span> de eiga o miru'"
        :trad="'J\'achète une télévision. Avec <span class=\'red_text\'>ça</span>, je regaraderai un film.'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'私は学校に行く。<span class=\'red_text\'>そこ</span>で漢字を習う。'"
        :kanjiraw="'私は学校に行く。そこで漢字を習う。'"
        :kana="''" 
        :roomaji="'watashi wa gakkou ni iku。<span class=\'red_text\'>soko</span> de kanji o narau'"
        :trad="'Je vais à l\'école. <span class=\'red_text\'>Là</span> j\'apprendrai des Kanji.'" 
        :explication="''"
      />

      <!-- préfixe de politesse お -->
      <h3 class="title_subcontent" id="prefixes_honorifique">Le préfixe de politesse et honorifique お</h3>
      <p class="text_no_margin">
        Ce préfixe de politesse et honorifique est un préfixe que l'on va placer devant un mot pour embellir un mot, pour parler poliement.<br>
        Son kanji est 御, sa lecture purement japonaise est お, sa lecture sino-japonaise est ご.<br>
        On va souvent utiliser sa lecture purement japonaise お (o) et parfois ご (go) suivant des cas à connaître par coeur.<br> 
        Le préfixe de politesse peut s'utiliser sur des mots au locuteur ou à l'interlocuteur (mon argent, votre argent), contrairement au préfixe honorifique qui s'utilise seulement sur des mots de mon interlocuteur (votre jardin, on ne dira jamais mon jardin avec le prefixe honorifique).
      </p>

      <h4 class="title_no_bottom">Préfixe de politesse お</h4>
      <p class="text_no_margin">
        Ce préfixe de politesse ne se traduit pas, il sert juste à embellir le mot, paraître distingué.<br>
        On peut utiliser une lecture sino-japonais et purement japonais après le préfixe de politesse.<br>
        On ne peut pas mettre ce préfixe de politesse sur tous les mots, seul une liste de mots peut avoir ce préfixe de politesse.<br>
      </p>
      <!--<p class="box_example">
        <span class="red_text">お</span>金 = <span class="red_text">o</span>kane = argent (ça embellit le mot)<br>
        <span class="red_text">お</span>酒 = <span class="red_text">o</span>sake = alcool (ça embellit le mot)<br>
        <span class="red_text">ご</span>飯 = <span class="red_text">o</span>han = riz cuit (ça embellit le mot)<br>
        <span class="red_text">お</span>茶 = <span class="red_text">o</span>cha = thé (ça embellit le mot)<br>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'>お</span>金'"
        :kanjiraw="'お金'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>o</span>kane'"
        :trad="'argent (ça embellit le mot)'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'<span class=\'red_text\'>お</span>酒'"
        :kanjiraw="'お酒'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>o</span>sake'"
        :trad="'alcool (ça embellit le mot)'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'<span class=\'red_text\'>ご</span>飯'"
        :kanjiraw="'ご飯'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>o</span>han'"
        :trad="'riz cuit (ça embellit le mot)'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'<span class=\'red_text\'>お</span>茶'"
        :kanjiraw="'お茶'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>o</span>cha'"
        :trad="'thé (ça embellit le mot)'" 
        :explication="''"
      />
      <p class="text_no_margin">
        Il existe de nouveaux mots créés avec ce préfixe de politesse.<br>
      </p>
      <!--<p class="box_example">
        <span class="red_text">お</span>腹 = <span class="red_text">o</span>naka = le ventre (o + intérieur (le ventre est un belle intérieur))<br>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'>お</span>腹'"
        :kanjiraw="'お腹'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>o</span>naka'"
        :trad="'le ventre (o + intérieur (le ventre est un belle intérieur))'" 
        :explication="''"
      />
      <p class="text_no_margin">
        L'ancienne lecture purement japonaise de ce préfixe de politesse était み (mi) que l'on retrouvera uniquement dans la langue japonaise ancienne.<br>
      </p>
      <!--<p class="box_example">
        <span class="red_text">み</span>空 = <span class="red_text">mi</span>sora = ciel<br>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'>み</span>空'"
        :kanjiraw="'み空'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>mi</span>sora'"
        :trad="'ciel'" 
        :explication="''"
      />
      <p class="text_no_margin">
        Il arrive, mais plus rarement que l'ancien préfixe de politesse se mélange avec le préfixe de politesse récent.
      </p>
      <!--<p class="box_example">
        <span class="red_text">御</span><span class="blue_text">神</span>籤 = <span class="red_text">o</span><span class="blue_text">mi</span> kuji = oracle écrit<br>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'>御</span><span class=\'blue_text\'>神</span>籤'"
        :kanjiraw="'御神籤'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>o</span><span class=\'blue_text\'>mi</span> kuji'"
        :trad="'oracle écrit'" 
        :explication="''"
      />

      <h4 class="title_no_bottom">Préfixe お honorifique</h4>
      <p class="text_no_margin">
        Ce préfixe お ou ご (suivant les cas à connaître par coeur) honorifique va permettre d'exprimer son respect envers l'interlocuteur ou envers une tierce personne, en embelissant les mots de l'interlocuteur ou de cette tierce personne.<br>
        Pour cela il suffit de mettre le préfixe お devant un mot lié à l'interlocuteur ou une tierce personne.<br>
        Le お honorifique ne s'utilise jamais sur soi-même ou sur nos objets à nous, dans la modestie japonaises, il ne faut pas s'embellir soi-même, ça fait prétentieux.<br>
        Du coup dans le contexte comme le お honorifique s'utilise seulement pour désigner l'interlocuteur ou une tierce personne, ceci crée une alternative à "anata no" pour dire "votre".
      </p>
      <p class="box_example">
        Si je parle du jardin de l'interlocuteur: <span class="red_text">お</span>庭 = <span class="red_text">o</span>niwa = votre jardin (mise en valeur du jardin de l'interlocuteur)<br>
        Si je parle de l'interlocuteur: <span class="red_text">お</span>名前 = <span class="red_text">o</span>namae = votre nom (mise en valeur du nom de l'interlocuteur)<br>
        Si je parle du livre de l'interlocuteur: <span class="red_text">ご</span>本 = <span class="red_text">go</span>hon = votre livre (mise en valeur du livre de l'interlocuteur)<br>
      </p>
      <p class="text_no_margin">
        On peut mettre ce préfixe honorifique sur des choses qui n'appartiennent pas à l'interlocuteur, mais dont il est le destinataire.<br>
      </p>
      <!--<p class="box_example">
        <span class="red_text">お</span>礼 = <span class="red_text">o</span>rei = gratitude pour vous<br>
        <span class="red_text">お</span>返事 = <span class="red_text">o</span>henji = réponse pour vous<br>
        <span class="red_text">お</span>前 = <span class="red_text">o</span>mae = pronom personnel très informel. permet de designer ce qui est devant nous "toi, là devant", ceci à un ton de supériorité ou très décontracté avec sa compagne<br>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'>お</span>礼'"
        :kanjiraw="'お礼'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>o</span>rei'"
        :trad="'gratitude pour vous'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'<span class=\'red_text\'>お</span>返事'"
        :kanjiraw="'お返事'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>o</span>henji'"
        :trad="'réponse pour vous'" 
        :explication="''"
      />
      <BoxExample 
        :kanji="'<span class=\'red_text\'>お</span>前'"
        :kanjiraw="'お前'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>o</span>mae'"
        :trad="''" 
        :explication="'Pronom personnel très informel. permet de designer ce qui est devant nous “toi, là devant”, ceci à un ton de supériorité ou très décontracté avec sa compagne.'"
      />
      <p class="text_no_margin">
        On peut combiner ce préfixe お honorifique avec un suffixe honorifique pour créer un mot super honorifique.<br>
      </p>
      <!--<p class="box_example">
        <span class="red_text">お</span><span class="blue_text">客</span><span class="green_text">様</span> = <span class="red_text">o</span><span class="blue_text">kyaku</span><span class="green_text">sama</span> = Monsieur le client ou Chère client<br>
      </p>-->
      <BoxExample 
        :kanji="'<span class=\'red_text\'>お</span><span class=\'blue_text\'>客</span><span class=\'green_text\'>様</span>'"
        :kanjiraw="'お客様'"
        :kana="''" 
        :roomaji="'<span class=\'red_text\'>o</span><span class=\'blue_text\'>kyaku</span><span class=\'green_text\'>sama</span>'"
        :trad="'Monsieur le client ou Cher client'" 
        :explication="''"
      />

  </div>
</template>

<script>
import Button1 from "@/components/Buttons/Button1.vue";
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    Button1,
    BoxExample
  },
}
</script>

<style>

</style>