<template>
<div class="btn_general">
  <div v-if="isInterneBp">
    <router-link
      :to="{ name: link, params: { id: params } }"
      class="btn-wrap"
    >
      <div class="btn-content">{{text}}</div>
    </router-link>
  </div>
  <div v-else>
    <a class="btn-wrap" :href="link" :target="target">
      <div class="btn-content">{{text}}</div>
    </a>
  </div>
  </div>
</template>

<script>
export default {
  name: "Button1",
  props: ["text", "link", "target", "isInterneBp", "params"],
};
</script>

<style lang="scss" scoped>
/*==== BUTTON ====*/
.btn_general{
  display: inline-block;
}
.btn-wrap {
  cursor: pointer;
  background: #1462ff;
  border-radius: 5px;
  box-shadow: 0 6px 30px -10px #4a74c9;
  color: white;
  display: inline-block;
  text-decoration: none;
  margin: 5px;
  .btn-content {
    padding: 5px 15px;
    opacity: 1;
    &:hover {
      opacity: 0.75;
    }
  }
}
</style>