<template>
      <div class="section">
      <h4>
        <div class="section_title">
          Les Nombres
        </div>
      </h4>

      <table class="content-table nombres" id="nombres">
        <thead>
          <tr>
            <th></th>
            <th>Kanji</th>
            <th>Lecture Sino-japonaise</th>
            <th>Lecture Purement japonaise</th>
          </tr>
        </thead>
        <tr>
          <th>0</th>
          <td>
            <div class="kanji_bottom">
              <div class="kanji_button" @click="showDrawKanji('零', 'Tracé')">零</div> 
              ou 
              <div class="kanji_button" @click="showDrawKanji('〇', 'Tracé')">〇</div>
            </div>
          </td>
          <td>レイ / ゼロ (rei/zero)</td>
          <td></td>
        </tr>
        <tr class="red_text">
          <th>1</th>
          <td><div class="kanji_button" @click="showDrawKanji('一', 'Tracé')">一</div></td>
          <td>イチ (ichi)</td>
          <td class="switch_dark--light_black--white">ひと (hito)</td>
        </tr>
        <tr class="red_text">
          <th>2</th>
          <td><div class="kanji_button" @click="showDrawKanji('ニ', 'Tracé')">ニ</div></td>
          <td>ニ (ni)</td>
          <td class="switch_dark--light_black--white">ふた (futa)</td>
        </tr>
        <tr class="red_text">
          <th>3</th>
          <td><div class="kanji_button" @click="showDrawKanji('三', 'Tracé')">三</div></td>
          <td>サン (san)</td>
          <td class="switch_dark--light_black--white">み (mi)</td>
        </tr>
        <tr class="red_text">
          <th>4</th>
          <td><div class="kanji_button" @click="showDrawKanji('四', 'Tracé')">四</div></td>
          <td>シ (shi)</td>
          <td>よ(ん) (yo(n))</td>
        </tr>
        <tr class="red_text">
          <th>5</th>
          <td><div class="kanji_button" @click="showDrawKanji('五', 'Tracé')">五</div></td>
          <td>ゴ (go)</td>
          <td class="switch_dark--light_black--white">いつ (itsu)</td>
        </tr>
        <tr class="red_text">
          <th>6</th>
          <td><div class="kanji_button" @click="showDrawKanji('六', 'Tracé')">六</div></td>
          <td>ロク (roku)</td>
          <td class="switch_dark--light_black--white">む (mu)</td>
        </tr>
        <tr class="red_text">
          <th>7</th>
          <td><div class="kanji_button" @click="showDrawKanji('七', 'Tracé')">七</div></td>
          <td>シチ (shichi)</td>
          <td>なな (nana)</td>
        </tr>
        <tr class="red_text">
          <th>8</th>
          <td><div class="kanji_button" @click="showDrawKanji('八', 'Tracé')">八</div></td>
          <td>ハチ (hachi)</td>
          <td class="switch_dark--light_black--white">や (ya)</td>
        </tr>
        <tr class="red_text">
          <th>9</th>
          <td><div class="kanji_button" @click="showDrawKanji('九', 'Tracé')">九</div></td>
          <td>ク / キュウ (ku/kyuu)</td>
          <td class="switch_dark--light_black--white">ここの (kokono)</td>
        </tr>
        <tr class="blue_text">
          <th>10</th>
          <td><div class="kanji_button" @click="showDrawKanji('十', 'Tracé')">十</div></td>
          <td>ジュウ (juu)</td>
          <td class="switch_dark--light_black--white">とお (too)</td>
        </tr>
        <tr>
          <th>11</th>
          <td>十一</td>
          <td>ジュウイチ (juuichi)</td>
          <td></td>
        </tr>
        <tr>
          <th>20</th>
          <td>ニ十</td>
          <td>ニジュウ (nijuu)</td>
          <td>はた (hata)</td>
        </tr>
        <tr>
          <th>25</th>
          <td>ニ十五</td>
          <td>ニジュウゴ (nijuugo)</td>
          <td></td>
        </tr>
        <tr class="blue_text">
          <th>100</th>
          <td><div class="kanji_button" @click="showDrawKanji('百', 'Tracé')">百</div></td>
          <td>ヒャク (hyaku)</td>
          <td class="switch_dark--light_black--white">もも (momo)</td>
        </tr>
        <tr>
          <th>111</th>
          <td>百十一</td>
          <td>ヒャクジュウイチ (hyakujuuichi)</td>
          <td></td>
        </tr>
        <tr class="green_text">
          <th>300</th>
          <td>三百</td>
          <td>サンビャク (sanbyaku)</td>
          <td></td>
        </tr>
        <tr class="green_text">
          <th>600</th>
          <td>六百</td>
          <td>ロッピャク (roppyaku)</td>
          <td></td>
        </tr>
        <tr class="green_text">
          <th>800</th>
          <td>八百</td>
          <td>ハッピャク (happyaku)</td>
          <td></td>
        </tr>
        <tr class="blue_text">
          <th>1000</th>
          <td><div class="kanji_button" @click="showDrawKanji('千', 'Tracé')">千</div></td>
          <td>セン (sen)</td>
          <td class="switch_dark--light_black--white">ち (chi)</td>
        </tr>
        <tr class="green_text">
          <th>3000</th>
          <td>千</td>
          <td>サンゼン (sanzen)</td>
          <td></td>
        </tr>
        <tr class="green_text">
          <th>8000</th>
          <td>千</td>
          <td>ハッセン (hassen)</td>
          <td></td>
        </tr>
        <tr>
          <th>8600</th>
          <td>千</td>
          <td>ハッセンロッピャク (hassenroppyaku)</td>
          <td></td>
        </tr>
        <tr class="blue_text">
          <th>1 0000 (10<sup>4</sup>)</th>
          <td><div class="kanji_button" @click="showDrawKanji('万', 'Tracé')">一万</div></td>
          <td>いち　マン (ichi man) (paquet "man")</td>
          <td class="switch_dark--light_black--white">よろず (yorozu)</td>
        </tr>
        <tr class="blue_text">
          <th>1 0000 0000 (10<sup>8</sup>)</th>
          <td><div class="kanji_button" @click="showDrawKanji('億', 'Tracé')">一億</div></td>
          <td>いち　オク (ichi oku) (paquet "oku")</td>
          <td></td>
        </tr>
        <tr class="blue_text">
          <th>1 0000 0000 0000 (10<sup>12</sup>)</th>
          <td><div class="kanji_button" @click="showDrawKanji('兆', 'Tracé')">一兆</div></td>
          <td>いち　ちょう (ichi chou) (paquet "chou")</td>
          <td></td>
        </tr>
        <tr class="blue_text">
          <th>1 0000 0000 0000 0000 (10<sup>16</sup>)</th>
          <td><div class="kanji_button" @click="showDrawKanji('京', 'Tracé')">一京</div></td>
          <td>いち　ケイ (ichi kei) (paquet "kei")</td>
          <td></td>
        </tr>
        <tr class="blue_text">
          <th>1 0000 0000 0000 0000 0000 (10<sup>20</sup>)</th>
          <td><div class="kanji_button" @click="showDrawKanji('垓', 'Tracé')">一垓</div></td>
          <td>いち　ガイ (ichi gai) (paquet "gai")</td>
          <td></td>
        </tr>
      </table>

      <KanjiDraw v-if="kanjiDrawCurrent" :title="kanjiDrawCurrentTitle" :kanji="kanjiDrawCurrent" @endKanjiDraw="endPopupKanjiDraw"/>

    </div>
</template>

<script>
import KanjiDraw from "@/components/Composants/KanjiDraw.vue";

export default {
  components: {
    KanjiDraw
  },
  data() {
    return {
      kanjiDrawCurrent: '',
      kanjiDrawCurrentTitle: '',
    };
  },
  methods:{
    // KanjiDraw Method
    showDrawKanji(kanji, title){
      this.kanjiDrawCurrent = kanji;
      this.kanjiDrawCurrentTitle = title;
    },
    // event
    endPopupKanjiDraw(){
      this.kanjiDrawCurrent = ''
    },
  }
}
</script>

<style lang="scss" scoped>
/*section*/
.section{
  display: inline-block;
  margin: 0 15px;
    .inactif_kanji{
      opacity: 0.4;
  }
}

.section .search,
.section .select{
  float: right;
  margin-top: 9px;
  min-height: 40px;
}

.section .select{
  margin-right: 5px;
}
/*form*/
input, select{
  padding: 10px 6px;
  box-shadow: border-box;
  border: 1px solid #ddd;
  color: #555;
}

/*table*/
.table .kanji_container {
  display: flex;
  flex-direction: column;
}

.content-table {
  border-collapse: collapse;
  margin-top: 10px;
  font-size: 0.9em;
  min-width: 400px;
  border-radius: 5px 5px 0 0;
  overflow: hidden;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}

.content-table thead tr {
  background-color: #009879;
  color: #ffffff;
  text-align: left;
  font-weight: bold;
}
.content-table.nombres th {
  background-color: #009879;
  color: #ffffff;
  font-weight: bold;
}

.content-table th,
.content-table td {
  padding: 12px 15px;
  text-align: center;
}

.content-table tbody tr {
  border-bottom: 1px solid #dddddd;
}

.content-table tbody tr:nth-of-type(even) {
  background-color: #f3f3f3;
}

.content-table tbody tr:last-of-type {
  border-bottom: 2px solid #009879;
}

.content-table tbody tr.active-row {
  font-weight: bold;
  color: #009879;
}

/* kanji draw button*/
.kanji_bottom{
  display: flex;
  justify-content: center;
}
.kanji_button{
  cursor: pointer;
  border: solid 1px #ddd;
  border-radius: 5px;
  padding: 0 3px;
  margin: 0 1px;
  &:hover{
    opacity: 0.75;
    background: rgb(221 221 221 / 50%);
  }
}
</style>