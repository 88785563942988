<template>
  <div
    class="l-navbar"
    id="navbar"
    ref="navbar"
    @mouseover="toggleSidebarOpen"
    @mouseleave="toggleSidebarClosed"
  >
    <nav class="nav" id="nav">
      <div>
        <div class="nav__brand">
          <!-- Burger -->
          <i
            class="fas fa-bars fa-sm nav__toggle"
            name="menu-outline"
            id="nav-toggle"
            ref="nav-toggle"
            @click="toggleSidebar"
          ></i>
          <!-- Title -->
          <router-link :to="{ name: 'Home' }" class="nav__logo">
            Japonais Recap
          </router-link>
        </div>
        <div class="nav__list">
          <!-- Accueil -->
          <router-link
            :to="{ name: 'Home' }"
            class="nav__link active"
            @click.self="linkActive"
          >
            <i class="fas fa-home nav__icon" @click.self="subLinkActive"></i>
            <span class="nav__name" @click.self="subLinkActive">Accueil</span>
          </router-link>

          <!-- Hiragana & Katakana -->
          <router-link
            :to="{ name: 'Introduction' }"
            class="nav__link"
            @click.self="linkActive"
          >
            <i class="fas fa-font nav__icon" @click.self="subLinkActive"></i>
            <span class="nav__name" @click.self="subLinkActive"
              >Écriture & Lecture</span
            >
          </router-link>

          <!-- Vocabulaires -->
          <router-link
            :to="{ name: 'Vocabulaires' }"
            class="nav__link"
            @click.self="linkActive"
          >
            <i
              class="fas fa-globe-asia nav__icon"
              @click.self="subLinkActive"
            ></i>
            <span class="nav__name" @click.self="subLinkActive"
              >Vocabulaires</span
            >
          </router-link>

          <!-- Grammaires -->
          <router-link
            :to="{ name: 'Grammaire' }"
            class="nav__link"
            @click.self="linkActive"
          >
            <i
              class="fas fa-pencil-ruler nav__icon"
              @click.self="subLinkActive"
            ></i>
            <span class="nav__name" @click.self="subLinkActive"
              >Grammaire</span
            >
          </router-link>

          <!-- Particules -->
          <router-link
            :to="{ name: 'Particule' }"
            class="nav__link collapse"
            @click.self="linkActive"
          >
            <i class="fas fa-scroll nav__icon" @click.self="subLinkActive"></i>
            <span class="nav__name" @click.self="subLinkActive"
              >Particules</span
            >

            <i
              class="fas fa-chevron-down collapse__link"
              @click="
                collapseSubmenu($event);
                subLinkActive($event);
              "
            ></i>

            <ul class="collapse__menu">
              <router-link
                :to="{ name: 'Particule', params: { id: 'no' } }"
                class="collapse__sublink"
              >
                の (No)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'to' } }"
                class="collapse__sublink"
              >
                と (To)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'ya' } }"
                class="collapse__sublink"
              >
                や (Ya)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'wa' } }"
                class="collapse__sublink"
              >
                は (Wa)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'ni' } }"
                class="collapse__sublink"
              >
                に (Ni)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'de' } }"
                class="collapse__sublink"
              >
                で (De)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'e' } }"
                class="collapse__sublink"
              >
                へ (E)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'wo' } }"
                class="collapse__sublink"
              >
                を (O)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'kara_made' } }"
                class="collapse__sublink"
              >
                から (Kara) & まで (Made)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'ga' } }"
                class="collapse__sublink"
              >
                が (Ga)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'mo' } }"
                class="collapse__sublink"
              >
                も (Mo)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'yori' } }"
                class="collapse__sublink"
              >
                より (Yori)
              </router-link>
              <router-link
                :to="{ name: 'Particule', params: { id: 'ka' } }"
                class="collapse__sublink"
              >
                か (Ka)
              </router-link>
            </ul>
          </router-link>

          <!-- Particules Autre -->
          <router-link
            :to="{ name: 'ParticuleAutre' }"
            class="nav__link collapse"
            @click.self="linkActive"
          >
            <i class="fas fa-scroll nav__icon" @click.self="subLinkActive"></i>
            <span class="nav__name" @click.self="subLinkActive"
              >Autres Particules</span
            >

            <i
              class="fas fa-chevron-down collapse__link"
              @click="
                collapseSubmenu($event);
                subLinkActive($event);
              "
            ></i>

            <ul class="collapse__menu">
              <router-link
                :to="{ name: 'ParticuleAutre', params: { id: 'particuleadverbiale' } }"
                class="collapse__sublink"
              >
                Adverbiales
              </router-link>
              <router-link
                :to="{ name: 'ParticuleAutre', params: { id: 'particuleemphase' } }"
                class="collapse__sublink"
              >
                Emphase
              </router-link>
              <router-link
                :to="{ name: 'ParticuleAutre', params: { id: 'particulefinphrase' } }"
                class="collapse__sublink"
              >
                Fin de phrase
              </router-link>
              <router-link
                :to="{ name: 'ParticuleAutre', params: { id: 'noda' } }"
                class="collapse__sublink"
              >
                Noda
              </router-link>
            </ul>
          </router-link>

          <!-- Adjectifs -->
          <router-link
            :to="{ name: 'Adjectifs' }"
            class="nav__link collapse"
            @click.self="linkActive"
          >
            <i class="fas fa-paste nav__icon" @click.self="subLinkActive"></i>
            <span class="nav__name" @click.self="subLinkActive">Adjectifs</span>

            <i
              class="fas fa-chevron-down collapse__link"
              @click="
                collapseSubmenu($event);
                subLinkActive($event);
              "
            ></i>

            <ul class="collapse__menu">
              <!--<a href="#" class="collapse__sublink">i</a>-->
              <router-link
                :to="{ name: 'Adjectifs', params: { id: 'i' } }"
                class="collapse__sublink"
              >
                i
              </router-link>
              <router-link
                :to="{ name: 'Adjectifs', params: { id: 'na' } }"
                class="collapse__sublink"
              >
                Na
              </router-link>
            </ul>
          </router-link>

          <!-- Verbes -->
          <router-link
            :to="{ name: 'Verbes' }"
            class="nav__link"
            @click.self="linkActive"
          >
            <i class="fas fa-book nav__icon" @click.self="subLinkActive"></i>
            <span class="nav__name" @click.self="subLinkActive">Verbes</span>
          </router-link>

          <!-- Classificateurs -->
          <!--<router-link
            :to="{ name: 'Classificateurs' }"
            class="nav__link"
            @click.self="linkActive"
          >
            <i
              class="fas fa-list-ol nav__icon"
              @click.self="subLinkActive"
            ></i>
            <span class="nav__name" @click.self="subLinkActive"
              >Classificateurs</span
            >
          </router-link>-->

          <!-- Nombre -->
          <!--<router-link
            :to="{ name: 'Compter' }"
            class="nav__link"
            @click.self="linkActive"
          >
            <i
              class="fas fa-sort-numeric-down nav__icon"
              @click.self="subLinkActive"
            ></i>
            <span class="nav__name" @click.self="subLinkActive"
              >Nombre & Compter</span
            >
          </router-link>-->

          <!-- Date -->
          <!--<router-link
            :to="{ name: 'Date' }"
            class="nav__link"
            @click.self="linkActive"
          >
            <i
              class="fas fa-calendar-alt nav__icon"
              @click.self="subLinkActive"
            ></i>
            <span class="nav__name" @click.self="subLinkActive"
              >Dates</span
            >
          </router-link>-->

          <!-- Clavier -->
          <router-link
            :to="{ name: 'Clavier' }"
            class="nav__link"
            @click.self="linkActive"
          >
            <i
              class="fas fa-language nav__icon"
              @click.self="subLinkActive"
            ></i>
            <span class="nav__name" @click.self="subLinkActive"
              >Clavier Japonais</span
            >
          </router-link>

          <!-- Liens Utiles -->
          <router-link
            :to="{ name: 'Link' }"
            class="nav__link"
            @click.self="linkActive"
          >
            <i class="fas fa-link nav__icon" @click.self="subLinkActive"></i>
            <span class="nav__name" @click.self="subLinkActive"
              >Annexes</span
            >
          </router-link>
        </div>
      </div>

      <a href="#" class="nav__link" @click="switchMode" @click.prevent='switchClick'>
        <i ref="iconmode" class="fas fa-moon nav__icon"></i>
        <span ref="textmode" class="nav__name">Mode Dark</span>
      </a>

    </nav>

    <!--<a href="#" class="bp_hide_bar nav__link" @click="switchMode">
      <i ref="iconmode" class="fas fa-moon nav__icon"></i>
      <span ref="textmode" class="nav__name">Mode Dark</span>
    </a>-->
  </div>
</template>

<script>
export default {
  data() {
    return {
      hover: false,
      darkmode: false,
      isMobile: false,
    };
  },
  mounted(){
    this.DetectMobile();
  },
  methods: {
    // DarkMode or LightMode
    switchMode(){
      if(!this.darkmode){
        this.darkmode = true
        document.body.classList.add("darkmode")
        document.body.classList.remove("lightmode")
        this.$refs.iconmode.classList.remove("fa-moon")
        this.$refs.iconmode.classList.add("fa-sun")
        this.$refs.textmode.innerHTML="Mode Light"
      } else {
        this.darkmode = false
        document.body.classList.remove("darkmode")
        document.body.classList.add("lightmode")
        this.$refs.iconmode.classList.add("fa-moon")
        this.$refs.iconmode.classList.remove("fa-sun")
        this.$refs.textmode.innerHTML="Mode Dark"
      }
    },
    switchClick () {
      
    },

    // Sidebar Swipe
    toggleSidebar() {
      this.$refs.navbar.classList.toggle("expander");
      document.getElementById("body-pd").classList.toggle("body-pd");
    },
    toggleSidebarOpen() {
      if(!this.isMobile){
        this.$refs.navbar.classList.add("expander");
        document.getElementById("body-pd").classList.add("body-pd");
      }
    },
    toggleSidebarClosed() {
      if(!this.isMobile){
        this.$refs.navbar.classList.remove("expander");
        document.getElementById("body-pd").classList.remove("body-pd");
      }
    },

    // Detect browser mobile or desktop
    DetectMobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)){
        // true for mobile device
        this.isMobile = true;
      }else{
        // false for not mobile device
        this.isMobile = false;
      }
    },

    // Link Active with colors
    linkActive(event) {
      // event.target get div element clicked
      this.colorLink();
      event.target.classList.add("active");
    },
    subLinkActive(event) {
      this.colorLink();
      event.target.parentElement.classList.add("active");
    },
    colorLink() {
      var linkColor = document.querySelectorAll(".nav__link");
      linkColor.forEach((l) => l.classList.remove("active"));
    },

    // Submenu Open/Close
    collapseSubmenu(event) {
      var collapseMenu = event.target.nextElementSibling;
      collapseMenu.classList.toggle("showCollapse");

      const rotate = collapseMenu.previousElementSibling;
      rotate.classList.toggle("rotate");
    },
  },
};
</script>

<style lang="scss">
/*===== Button ====*/
.bp_hide_bar{
  top: 0;
  position: fixed;
  left: 90px;
  background: var(--bg-color);
  border-radius: 0px 5px 5px 0px;
}
/*===== l NAV =====*/
.l-navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: var(--nav-width);
  height: 100%;
  background-color: var(--bg-color);
  color: var(--white-color);
  padding: 1.5rem 1.5rem 2rem;
  transition: 0.5s;
  z-index: var(--z-fixed);
}

/*===== NAV =====*/
.nav {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-x: hidden;
}
.nav__brand {
  display: grid;
  grid-template-columns: max-content max-content;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
}
.nav__toggle {
  font-size: 1.5rem;
  padding: 0.75rem;
  cursor: pointer;
}
.nav__logo {
  color: var(--white-color);
  font-weight: 600;
  background-color: transparent !important;
}

.nav__link {
  display: grid;
  grid-template-columns: max-content max-content;
  align-items: center;
  column-gap: 0.75rem;
  padding: 0.75rem;
  color: var(--white-color);
  border-radius: 0.5rem;
  margin-bottom: 1rem;
  transition: 0.3s;
  cursor: pointer;
}
.nav__link:hover {
  background-color: var(--first-color);
}
.nav__icon {
  font-size: 1.25rem;
  margin-right: 2px;
}
.nav__name {
  font-size: var(--small-font-size);
}

/*Expander menu*/
.expander {
  width: calc(var(--nav-width) + 10rem);
}

/* Show or Hide Sidebar */
.hide_sidebar{
  transform: translate(-15rem);
  transition: 1s;
}

/*Add padding body*/
.body-pd {
  padding: 2rem 0 0 17rem;
}

/*Active links menu*/
/*.active, */
.router-link-exact-active {
  background-color: var(--first-color);
}

/*===== COLLAPSE =====*/
.collapse {
  grid-template-columns: 20px max-content 1fr;
}
.collapse__link {
  justify-self: flex-end;
  transition: 0.5s;
}
.collapse__menu {
  display: none;
  padding: 0.75rem 2.25rem;
}
.collapse__sublink {
  color: var(--sub-color);
  font-size: var(--small-font-size);
  display: block;
  width: 150px;
}
.collapse__sublink:hover {
  color: var(--white-color);
}

/*Show collapse*/
.showCollapse {
  display: block;
}

/*Rotate icon*/
.rotate {
  transform: rotate(180deg);
}

/*==== SCROLLBAR ====*/
#nav::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}

#nav::-webkit-scrollbar {
  width: 0px;
  background-color: #f5f5f5;
}

#nav::-webkit-scrollbar-thumb {
  background-color: #000000;
  border: 2px solid #555555;
}
</style>