<template>
  <div>
    <!-- Les particules de fin de phrase -->
    <h1 style="margin-top: 10px" id="particulefinphrase">Particules de fin de phrase</h1>
    <h3 class="title_subcontent">À propos des particules d'emphase.</h3>
    <p class="text_no_margin">
     Ce sont les particules qui se mettent à la toute fin d'une phrase, après le verbe.<br>
     Ces particules vont appuyer de différente manières le propos, ce que l'on dit. D'exprimer une certaine relation entre le locuteur et interlocuteur.<br>
     <!-- La majorité de ces particules (sauf か (ka)) de fin de phrase s'utilise plus dans un contexte de connaisance, entre personne qui se connaisent<br>-->
    </p>

    <h4 class="title_no_bottom">La particule よ (yo) - Accentuation</h4>
    <p class="text_no_margin">
      La particule よ (yo) en fin de phrase, permet d'accentuer, appuyer le propos, affirmer son propos, avis.<br>
      En français on peut la comparer au point d'exclamation "!".<br>
    </p>
    <!--<p class="box_example">
      ポールは庭にいる<span class="red_text">よ</span>。= pooru wa niwa ni iru <span class="red_text">yo</span>。= paul est dans le jardin <span class="red_text">!</span>
    </p>-->
    <BoxExample 
      :kanji="'ポールは庭にいる<span class=\'red_text\'>よ</span>。'"
      :kanjiraw="'ポールは庭にいるよ。'"
      :kana="''" 
      :roomaji="'pooru wa niwa ni iru <span class=\'red_text\'>yo</span>.'"
      :trad="'paul est dans le jardin <span class=\'red_text\'>!</span>.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">La particule ね (ne) - Demander une Confirmation ou Confirmer</h4>
    <p class="text_no_margin">
      La particule ね (ne) en fin de phrase en montant l'intonation, permet de demander la confirmation à son interlocuteur<br>
      En français on peut la comparer à "n'est-ce pas ?"<br>
      La particule ね (ne) en fin de phrase en baissant l'intonation, permet de faire une confirmation à son interlocuteur<br>
      En français on peut la comparer à "oui, je confirme"<br>
      La particule ね (ne) donne un côté doux, gentil à la phrase, elle est donc plus souvent utilisé par des femmes que des hommes<br>
    </p>
    <!--<p class="box_example">
      ティボは庭にいる<span class="red_text">ね</span>。= tibo wa niwa ni iru <span class="red_text">ne</span>。= Tibo est dans le jardin, <span class="red_text">n'est-ce pas ?</span> (en montant intonation de ね (ne))<br>
      そです<span class="red_text">ね</span> = so desu <span class="red_text">ne</span> = c'est vrai (en baissant intonation de ね (ne))<br>
    </p>-->
    <BoxExample 
      :kanji="'ティボは庭にいる<span class=\'red_text\'>ね</span>。'"
      :kanjiraw="'ティボは庭にいるね。'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru <span class=\'red_text\'>ne</span>。'"
      :trad="'Tibo est dans le jardin, <span class=\'red_text\'>n\'est-ce pas ?</span>'" 
      :explication="'En montant intonation de ね (ne).'"
    />
    <BoxExample 
      :kanji="'そです<span class=\'red_text\'>ね</span>。'"
      :kanjiraw="''"
      :kana="''" 
      :roomaji="'so desu <span class=\'red_text\'>ne</span>.'"
      :trad="'c\'est vrai'" 
      :explication="'En baissant intonation de ね (ne).'"
    />

    <h4 class="title_no_bottom">La particule な (na)</h4>
    <p class="text_no_margin">
      La particule な (na) permet de souligner son opinion, on se fait la remarque à sois même.<br>
      Cette particule peut être utilisé de façon famillière<br>
    </p>
    <!--<p class="box_example">
      滝は馬鹿だ<span class="red_text">な</span>・・・ = taki wa baka da <span class="red_text">na</span>・・・ = Taki est un idiot... (c'est une remarque, opinion qu'on se dit à sois même)<br>
    </p>-->
    <BoxExample 
      :kanji="'滝は馬鹿だ<span class=\'red_text\'>な</span>・・・'"
      :kanjiraw="'滝は馬鹿だな・・・'"
      :kana="''" 
      :roomaji="'taki wa baka da <span class=\'red_text\'>na</span>・・・'"
      :trad="'Taki est un idiot...'" 
      :explication="'C\'est une remarque, opinion qu\'on se dit à sois même.'"
    />

    <h4 class="title_no_bottom">La particule わ (wa) - Entente</h4>
    <p class="text_no_margin">
      La particule わ (wa) permet d'exprimer son entente, dire que l'on est d'accord, on prend une décision favorable.<br>
      La particule わ (wa) donne un côté doux, gentil à la phrase, elle est donc plus souvent utilisé par des femmes que des hommes.<br>
    </p>
    <!--<p class="box_example">
      私は庭に出る<span class="red_text">わ</span>。= watashi wa niwa ni deru <span class="red_text">wa</span>。= je sors dans le jardin (je décide favorablement d'y aller)
    </p>-->
    <BoxExample 
      :kanji="'私は庭に出る<span class=\'red_text\'>わ</span>。'"
      :kanjiraw="'私は庭に出るわ。'"
      :kana="''" 
      :roomaji="'watashi wa niwa ni deru <span class=\'red_text\'>wa</span>.'"
      :trad="'Je sors dans le jardin.'" 
      :explication="'Je décide favorablement d\'y aller.'"
    />

    <h4 class="title_no_bottom">La particule ぜ (ze) - Entente</h4>
    <p class="text_no_margin">
      La particule ぜ (ze) permet d'exprimer aussi son entente et accentuation, mais en beaucoup plus familier.<br>
      La particule sera plus utilisé par des hommes<br>
    </p>
    <!--<p class="box_example">
      私は庭に出る<span class="red_text">ゼ</span>。= watashi wa niwa ni deru <span class="red_text">ze</span>。 = <span class="red_text">Allez</span>, je sors dans le jardin. (ceci rend très familier la phrase)
    </p>-->
    <BoxExample 
      :kanji="'私は庭に出る<span class=\'red_text\'>ゼ</span>。'"
      :kanjiraw="'私は庭に出るゼ。'"
      :kana="''" 
      :roomaji="'watashi wa niwa ni deru <span class=\'red_text\'>ze</span>.'"
      :trad="'<span class=\'red_text\'>Allez</span>, je sors dans le jardin.'" 
      :explication="'Ceci rend très familier la phrase.'"
    />

    <h4 class="title_no_bottom">La particule とも (tomo) - Évidence</h4>
    <p class="text_no_margin">
      La particule とも (tomo) permet d'exprimer une évidence.<br>
      En français on peut la comparer à "bien sûr".<br>
    </p>
    <!--<p class="box_example">
      滝は馬鹿だ<span class="red_text">とも</span> = taki wa baka da <span class="red_text">tomo</span> = <span class="red_text">Bien sûr</span> que taki est un idiot. (on affirme que notre propos est évident)<br>
    </p>-->
    <BoxExample 
      :kanji="'滝は馬鹿だ<span class=\'red_text\'>とも</span>。'"
      :kanjiraw="'滝は馬鹿だとも。'"
      :kana="''" 
      :roomaji="'taki wa baka da <span class=\'red_text\'>tomo</span>.'"
      :trad="'<span class=\'red_text\'>Bien sûr</span> que taki est un idiot.'" 
      :explication="'On affirme que notre propos est évident.'"
    />

    <h4 class="title_no_bottom">La particule ぞ (zo) - Accentuation</h4>
    <p class="text_no_margin">
      La particule ぞ (zo) permet d'exprimer une accentuation plus forte que よ (yo).<br>
      La particule sera plus utilisé par des hommes et est très famillière, elle donne un ordre ou previent d'un danger imminent.<br>
    </p>
    <!--<p class="box_example">
      庭には滝もいる<span class="red_text">ぞ</span>。= niwa ni wa taki mo iru <span class="red_text">zo</span> = Dans le jardin, il y a aussi Taki! (<span class="red_text">Attention</span>, il y a aussi taki dans le jardin!)
    </p>-->
    <BoxExample 
      :kanji="'庭には滝もいる<span class=\'red_text\'>ぞ</span>。'"
      :kanjiraw="'庭には滝もいるぞ。'"
      :kana="''" 
      :roomaji="'niwa ni wa taki mo iru <span class=\'red_text\'>zo</span>.'"
      :trad="'Dans le jardin, il y a aussi Taki!'" 
      :explication="'<span class=\'red_text\'>Attention</span>, il y a aussi taki dans le jardin!'"
    />

    <h4 class="title_no_bottom">La particule さ (sa) - Accentuation</h4>
    <p class="text_no_margin">
      La particule さ (sa) est aussi une particule d'accentuation comme ぞ (zo) mais d'une autre manière.<br>
      La particule sera plus utilisé par des hommes et est très famillière, elle attire l'attention de notre interlocuteur.<br>
      Si la phrase se termine par "だ" (da), alors la particule さ (sa) remplace cette particule "だ" (da).
    </p>
    <!--<p class="box_example">
      滝も多分庭にいる<span class="red_text">さ</span>。= taki mo tabun niwa ni iru <span class="red_text">sa</span>。 = <span class="red_text">Tu sais!</span>, taki est peut-être aussi dans le jardin! (j'attire l'attention de mon interlocuteur)
    </p>-->
    <BoxExample 
      :kanji="'滝も多分庭にいる<span class=\'red_text\'>さ</span>。'"
      :kanjiraw="'滝も多分庭にいるさ。'"
      :kana="''" 
      :roomaji="'taki mo tabun niwa ni iru <span class=\'red_text\'>sa</span>.'"
      :trad="'<span class=\'red_text\'>Tu sais!</span>, taki est peut-être aussi dans le jardin!'" 
      :explication="'J\'attire l\'attention de mon interlocuteur'"
    />

    <h4 id="ka" class="title_no_bottom">La particule か (ka) - Question</h4>
    <p class="text_no_margin">
      La particule か (ka) permet de poser une question.<br>
      On peut ainsi transformer une phrase déclarative en phrase interrogative juste en ajoutant か (ka).<br>
      On peut aussi poser une question sans か (ka), si on monte intonation en fin de phrase. On fait comprendre à notre interlocuteur que c'est une question que l'on pose.<br>
      Si la phrase se termine par "だ" (da), alors la particule か (ka) remplace cette particule "だ" (da).<br>
    </p>
    <!--<p class="box_example">
      滝は庭にいる = taki wa niwa ni iru = taki est dans le jardin (phrase déclarative)<br>
      滝は庭にいる<span class="red_text">か</span> = taki wa niwa ni iru <span class="red_text">ka</span> = taki est-il dans le jardin ? (phrase interrogative avec か (ka))<br>
      滝は庭にいる? = taki wa niwa ni iru ? = taki est dans le jardin ? (je monte intonation sur ma fin de phrase pour faire comprendre à mon interlocuteur que je pose une question)<br>
    </p>-->
    <BoxExample 
      :kanji="'滝は庭にいる。'"
      :kanjiraw="'滝は庭にいる。'"
      :kana="''" 
      :roomaji="'taki wa niwa ni iru.'"
      :trad="'taki est dans le jardin.'" 
      :explication="'Phrase déclarative.'"
    />
    <BoxExample 
      :kanji="'滝は庭にいる<span class=\'red_text\'>か</span>。'"
      :kanjiraw="'滝は庭にいるか。'"
      :kana="''" 
      :roomaji="'taki wa niwa ni iru <span class=\'red_text\'>ka</span>.'"
      :trad="'taki est-il dans le jardin ?'" 
      :explication="'Phrase interrogative avec か (ka).'"
    />
    <BoxExample 
      :kanji="'滝は庭にいる?'"
      :kanjiraw="'滝は庭にいる?'"
      :kana="''" 
      :roomaji="'taki wa niwa ni iru ?'"
      :trad="'taki est dans le jardin ?'" 
      :explication="'Je monte intonation sur ma fin de phrase pour faire comprendre à mon interlocuteur que je pose une question.'"
    />
    <p class="text_no_margin">
      Attention à votre réponse, si la question est à la forme positive ou négative<br>
      À la forme négative d'une question on répond par "oui" pour confirmer la question négatif et "non" pour désapprouver la question négatif. C'est l'inverse du Français<br>
      PS: voir la conjugaison des verbes, dans l'exemple on a conjugué le verbe "iru" à la forme négative<br>
    </p>
    <!--<p class="box_example">
      滝は庭にいる<span class="red_text">か</span> = taki wa niwa ni iru <span class="red_text">ka</span> = taki est-il dans le jardin <span class="red_text">?</span><br>
      はい = hai = oui, elle y est<br>
      いいえ = iie = non, elle n'y est pas<br>
      滝は庭にいない<span class="red_text">か</span> = taki wa niwa ni inai <span class="red_text">ka</span> = taki n'est-il pas dans le jardin <span class="red_text">?</span><br>
      はい = hai = oui, elle n'y est pas<br>
      いいえ = iie = non, elle y est<br>
    </p>-->
    <BoxExample 
      :kanji="'滝は庭にいる<span class=\'red_text\'>か</span>。'"
      :kanjiraw="'滝は庭にいるか。'"
      :kana="''" 
      :roomaji="'taki wa niwa ni iru <span class=\'red_text\'>ka</span>.'"
      :trad="'taki est-il dans le jardin <span class=\'red_text\'>?</span>.'" 
      :explication="'Phrase de forme positive.'"
    />
    <BoxExample 
      :kanji="''"
      :kanjiraw="''"
      :kana="'はい'" 
      :roomaji="'hai'"
      :trad="'oui, elle y est'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="''"
      :kanjiraw="''"
      :kana="'いいえ'" 
      :roomaji="'iie'"
      :trad="'non, elle n\'y est pas'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'滝は庭にいない<span class=\'red_text\'>か</span>。'"
      :kanjiraw="'滝は庭にいないか。'"
      :kana="''" 
      :roomaji="'taki wa niwa ni inai <span class=\'red_text\'>ka</span>.'"
      :trad="'taki n\'est-il pas dans le jardin <span class=\'red_text\'>?</span>'" 
      :explication="'Phrase de forme négative.'"
    />
    <BoxExample 
      :kanji="''"
      :kanjiraw="''"
      :kana="'はい'" 
      :roomaji="'hai'"
      :trad="'oui, elle n\'y est pas'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="''"
      :kanjiraw="''"
      :kana="'いいえ'" 
      :roomaji="'iie'"
      :trad="'non, elle y est'" 
      :explication="''"
    />
    <p class="text_no_margin">
      On peut aussi répondre à une question en reprenant juste la phrase ou le verbe.<br>
      Rerprendre juste le verbe ne marche pas seulement pour le verbe être de description "だ" (da).<br>
    </p>
    <!--<p class="box_example">
      滝は庭にいる<span class="red_text">か</span> = taki wa niwa ni iru <span class="red_text">ka</span> = taki est-il dans le jardin <span class="red_text">?</span><br>
      滝は庭にいる = taki wa niwa ni iru = taki est dans le jardin<br>
      庭にいる = niwa ni iru = il est dans le jardin<br>
      いる = il y est<br>
    </p>-->
    <BoxExample 
      :kanji="'滝は庭にいる<span class=\'red_text\'>か</span>。'"
      :kanjiraw="'滝は庭にいるか。'"
      :kana="''" 
      :roomaji="'taki wa niwa ni iru <span class=\'red_text\'>ka</span>.'"
      :trad="'taki est-il dans le jardin <span class=\'red_text\'>?</span>'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'滝は庭にいる。'"
      :kanjiraw="'滝は庭にいる。'"
      :kana="''" 
      :roomaji="'taki wa niwa ni iru.'"
      :trad="'taki est dans le jardin.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'庭にいる。'"
      :kanjiraw="'庭にいる。'"
      :kana="''" 
      :roomaji="'niwa ni iru.'"
      :trad="'il est dans le jardin.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="''"
      :kanjiraw="''"
      :kana="'いる'" 
      :roomaji="'iru'"
      :trad="'il y est'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">La particule kashira (かしら) - Incertitude</h4>
    <p class="text_no_margin">
      La particule kashira (かしら) permet d'indiquer une incertitude, un doute, on se pose la question à sois même.<br>
      La particule est un peu plus utilisé par les femmes.<br>
    </p>
    <!--<p class="box_example">
      ティボは庭にいる<span class="red_text">かしら</span> = tibo wa niwa ni iru <span class="red_text">kashira</span> = <span class="red_text">je me demande</span> si tibo est dans le jardin
    </p>-->
    <BoxExample 
      :kanji="'ティボは庭にいる<span class=\'red_text\'>かしら</span>。'"
      :kanjiraw="'ティボは庭にいるかしら。'"
      :kana="''" 
      :roomaji="'tibo wa niwa ni iru <span class=\'red_text\'>kashira</span>.'"
      :trad="'<span class=\'red_text\'>je me demande</span> si tibo est dans le jardin.'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">Additionner les particules de fin de phrase</h4>
    <p class="text_no_margin">
      Il est possible d'additionner les particules de fin de phrase pour avoir leur différents effets dans une même phrase<br>
      Pour la combinaison des particules か (ka) + な (na), elle est plus utilisé par les hommes et possède la même signification que la particule kashira (かしら) - incertitude<br>
    </p>
    <p class="box_example">
      よね = よ (accentue le propos) + ね (demande confirmation) = On pose la question en insistant bien notre propos.<br>
      わよ = わ (on exprime notre entente) + よ (accentue notre propos) = On approuve notre entente en insistant bien.<br>
      かな = か (on pose une question) + な (on se fait la remarque) = On se pose la question à sois même.<br>
    </p>
  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>