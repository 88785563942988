<template>
  <div id="no">
    <h3 class="title_subcontent">Particule : no (の)</h3>
    <p class="text_no_margin">Cette particule permet d'exprimer la possession, l'appartenance.<br>
    Elle peut être comparé à <b>"de"</b> et <b>"du"</b> en français.<br>
    C’est toujours le premier mot qui qualifie le deuxième mot contrairement au français où il s’agit de l'inverse.<br>
    On parle donc en premier du sujet de la conversation et ensuite détailler tous ce qui se rapporte au sujet.<br>
    <span class='blue_text'>Sujet + no + Objet</span><br>
    On peut aussi voir ceci sous l'exemple du possesseur (celui qui détient) et du possédé (celui qui est possédé par le possesseur).<br> 
    <span class='blue_text'>Possesseur + no + Possédé</span><br>
    </p>
    <BoxExample 
      :kanji="'私<span class=\'red_text\'>の</span>本。'"
      :kanjiraw="'私の本。'"
      :kana="'わたし<span class=\'red_text\'>の</span>ほん.'" 
      :roomaji="'watashi <span class=\'red_text\'>no</span> hon.'"
      :trad="'c\'est <span class=\'red_text\'>mon</span> livre'"
    />
    <BoxExample 
      :kanji="'<span class=\'green_text\'>日本</span><span class=\'red_text\'>の</span>映画。'"
      :kanjiraw="'日本の映画。'"
      :kana="''" 
      :roomaji="'<span class=\'green_text\'>nihon</span> <span class=\'red_text\'>no</span> eiga.'"
      :trad="'un film <span class=\'green_text\'>japonais</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'green_text\'>お父さん</span><span class=\'red_text\'>の</span>誕生日。'"
      :kanjiraw="'お父さんの誕生日。'"
      :kana="''" 
      :roomaji="'<span class=\'green_text\'>otosan</span> <span class=\'red_text\'>no</span> tanjoubi.'"
      :trad="'l\'anniversaire <span class=\'red_text\'>de</span> mon <span class=\'green_text\'>père</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'<span class=\'green_text\'>ゼルダ</span><span class=\'red_text\'>の</span>伝説。'"
      :kanjiraw="'ゼルダの伝説。'"
      :roomaji="'<span class=\'green_text\'>zeruda</span> <span class=\'red_text\'>no</span> densetsu <span class=\'blue_text\'>da</span>.'"
      :trad="'<span class=\'blue_text\'>c\'est</span> la légende <span class=\'red_text\'>de</span> <span class=\'green_text\'>zelda</span>.'" 
      :explication="''"
    />
    <BoxExample 
      :kanji="'空<span class=\'red_text\'>の</span>王国<span class=\'red_text\'>の</span>女王<span class=\'red_text\'>の</span>城。'"
      :kanjiraw="'空の王国の女王の城。'"
      :roomaji="'sora <span class=\'red_text\'>no</span> ookoku <span class=\'red_text\'>no</span> joo <span class=\'red_text\'>no</span> shiro.'"
      :trad="'Le château <span class=\'red_text\'>de</span> la princesse <span class=\'red_text\'>du</span> royaume <span class=\'red_text\'>du</span> ciel.'" 
      :explication="'sora (ciel) <span class=\'red_text\'>no</span> ookoku (royaume) <span class=\'red_text\'>no</span> joo (princesse) <span class=\'red_text\'>no</span> shiro (château).'"
    />
    <!--<p class="box_example">
        watashi <span class='red_text'>no</span> hon = c'est <span class='red_text'>mon</span> livre<br>
        ore <span class='red_text'>no</span> kanojo <span class='red_text'>no</span> hon = c'est <span class='red_text'>le</span> livre de <span class='red_text'>ma</span> copine<br>
        <span class='green_text'>nihon</span> <span class='red_text'>no</span> eiga = un film <span class='green_text'>japonais</span><br>
        <span class='green_text'>otosan</span> <span class='red_text'>no</span> tanjoubi = l'anniversaire <span class='red_text'>de</span> mon <span class='green_text'>père</span><br>
        <span class='green_text'>ゼルダ</span><span class='red_text'>の</span>伝説 = <span class='green_text'>zeruda</span> <span class='red_text'>no</span> densetsu <span class='blue_text'>da</span> = <span class='blue_text'>c'est</span> la légende <span class='red_text'>de</span> <span class='green_text'>zelda</span><br>
        空<span class='red_text'>の</span>王国<span class='red_text'>の</span>女王<span class='red_text'>の</span>城 = sora <span class='red_text'>no</span> ookoku <span class='red_text'>no</span> joo <span class='red_text'>no</span> shiro = sora (ciel) <span class='red_text'>no</span> ookoku (royaume) <span class='red_text'>no</span> joo (princess) <span class='red_text'>no</span> shiro (château)  = le château <span class='red_text'>de</span> la princesse <span class='red_text'>du</span> royaume <span class='red_text'>du</span> ciel
    </p>-->
    <p class="text_no_margin">
      Dans de plus rare exception lors d'une traduction, il n'y a pas eu d'inversement car la tournure d'une phrase fait mieux dans ce sens-là.
    </p>
    <BoxExample 
      :kanji="'<span class=\'blue_text\'>進撃</span><span class=\'red_text\'>の</span>巨人。'"
      :kanjiraw="'進撃の巨人。'"
      :roomaji="'<span class=\'blue_text\'>shingeki</span> <span class=\'red_text\'>no</span> kyojin.'"
      :trad="'<span class=\'blue_text\'>l\'attaque</span> <span class=\'red_text\'>des</span> titans.'" 
      :explication="'La vraie traduction de “shingeki no kyojin” est “les titans de l\'attaque”. Cependant dire “l\'attaque des titans” sonne mieux et cette traductions a donc été conservé.'"
    />
    <!--<p class="box_example">
      <span class='blue_text'>進撃</span><span class='red_text'>の</span>巨人 = <span class='blue_text'>shingeki</span> <span class='red_text'>no</span> kyojin = <span class='blue_text'>l'attaque</span> <span class='red_text'>des</span> titan<br>
      la vraie traduction de "shingeki no kyojin" est "les titans de l'attaque". Cependant dire "l'attaque des titans" sonne mieux et cette traductions à donc été conservé 
    </p>-->
  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>