<template>
  <div>
    <!-- Les particules emphase -->
    <h1 style="margin-top: 10px" id="particuleemphase">Particules d'Emphase</h1>
    <h3 class="title_subcontent">A propos des particules d'emphase.</h3>
    <p class="text_no_margin">
      Ces particules permettent de souligner un mot, mettre en relief un mot.<br>
      La particule は (wa) est par exemple une particule d'emphase, puisqu'elle souligne le thème de la phase.<br>
      La particule も (mo) est aussi une particule d'emphase, puisqu'elle souligne le mot qui sera répété.<br>
      Les nouvelles particules d'emphase s'utiliseront en remplaçant les particules que l'on connait comme は (wa).
    </p>

    <h4 class="title_no_bottom">La particule こそ (koso) - détermination</h4>
    <p class="text_no_margin">
      La particule こそ (koso) permet d'appuyer le mot sur lequel la particule est mise.<br>
      En français on peut comparer à "Pour sûr".<br>
    </p>
    <!--<p class="box_example">
      今日<span class="red_text">こそ</span>私が勝つ! = kyou <span class="red_text">koso</span> watashi ga katsu = Aujourd'hui <span class="red_text">pour sûr</span>, c'est moi qui gagne!
    </p>-->
    <BoxExample 
      :kanji="'今日<span class=\'red_text\'>こそ</span>私が勝つ!'"
      :kanjiraw="'今日こそ私が勝つ!'"
      :kana="''" 
      :roomaji="'kyou <span class=\'red_text\'>koso</span> watashi ga katsu!'"
      :trad="'Aujourd\'hui <span class=\'red_text\'>pour sûr</span>, c\'est moi qui gagne!'" 
      :explication="''"
    />

    <h4 class="title_no_bottom">La particule さえ (sae) - quelque chose d'étonnant</h4>
    <p class="text_no_margin">
      La particule さえ (sae) permet d'insister sur quelque chose qui ne devrait pas être ainsi, quelque chose d'étonnant.<br>
      さえ (sae) aura une connotation plus négative.<br>
      En français on peut comparer à "même" en parlant négativemnt.<br>
    </p>
    <!--<p class="box_example">
      人魚<span class="red_text">さえ</span>時々溺れる。= ningyo <span class="red_text">sae</span> tokidoki oboreru = <span class="red_text">même</span> les sirènes se noient parafois. (c'est étonnant que les sirènes se noient aussi. montrer une anomalie)
    </p>-->
    <BoxExample 
      :kanji="'人魚<span class=\'red_text\'>さえ</span>時々溺れる。'"
      :kanjiraw="'人魚さえ時々溺れる。'"
      :kana="''" 
      :roomaji="'ningyo <span class=\'red_text\'>sae</span> tokidoki oboreru.'"
      :trad="'<span class=\'red_text\'>même</span> les sirènes se noient parafois.'" 
      :explication="'C\'est étonnant que les sirènes se noient aussi. Pour montrer une anomalie.'"
    />

    <h4 class="title_no_bottom">La particule でも (demo) - quelque chose de ridicule</h4>
    <p class="text_no_margin">
      La particule でも (demo) s'utilise pour des choses plus extrème, plus ridicule. Il est utiliser pour se moquer<br>
      でも (demo) aura une connotation plus positive.<br>
      En français on peut comparer à "même" en parlant positivement.<br>
    </p>
    <!--<p class="box_example">
      子供<span class="red_text">でも</span>日本酒を飲む。= kodomo <span class="red_text">demo</span> nihonshu o nomu。= <span class="red_text">même</span> les enfants boivent du saké. (si des enfants en boivent, alors tous le monde peut en boire. il y a une idée de moquerie gentil)
    </p>-->
    <BoxExample 
      :kanji="'子供<span class=\'red_text\'>でも</span>日本酒を飲む。'"
      :kanjiraw="'子供でも日本酒を飲む。'"
      :kana="''" 
      :roomaji="'kodomo <span class=\'red_text\'>demo</span> nihonshu o nomu.'"
      :trad="'<span class=\'red_text\'>même</span> les enfants boivent du saké.'" 
      :explication="'Si des enfants en boivent, alors tous le monde peut en boire. Il y a une idée de moquerie gentil.'"
    />
  </div>
</template>

<script>
import BoxExample from "@/components/Composants/BoxExample.vue";

export default {
  components: {
    BoxExample
  }
}
</script>

<style>

</style>